import { combineReducers } from "@reduxjs/toolkit";
import { accAPI } from "../services/index";
import { corePlusIncentivesApi } from "../services/corePlusIncentivesApi";
import { foundersPlatinumProgressAPI } from "../services/foundersPlatinumProgressAPI";
import { businessSelectorApi } from "../services/businessSelectorAPI";
import { monthlyPerformanceDashboardAPI } from "../services/monthyPerformanceDashboardAPI";
import { shellConfigApi } from "../services/getShellConfigAPI";
import { savedKpiPreferencesAPI } from "../services/savedKpiPreferencesAPI";
import { annualPerformanceDashboardAPI } from "../services/annualPerformanceDashboardAPI";
import { hatSummaryAPI } from "../services/hatSummaryAPI";
import { frontlineGroupGrowthAPI } from "../services/frontlineGroupGrowthAPI";
import { annualPggAPI } from "../services/annualPggAPI";
import { ssiAPI } from "../services/ssiAPI";
import { personalGroupGrowthAPI } from "../services/personalGroupGrowthAPI";
import { pggGcrAPI } from "../services/personalGroupGrowthGCR";
import { monthlyPGGAPI } from "../services/monthlyPGGAPI";
import { lsiAPI } from "../services/lsiAPI";
import { performancePlusEliteAPI } from "../services/performancePlusEliteOverviewAPI";
import demoReducer from "./demo";
import periodReducer from "./period";
import profileReducer from "./profile";
import bronzeIncentiveReducer from "./bronzeIncentive";
import bootReducer from "./boot";
import loginLocalStorageReducer from "./loginLocalStorage";
import eligibilityTabReducer from "./eligibilityTab";
import bronzeGroupTrackingTabReducer from "./bronzeGroupTrackingTab";
import customersReducer from "./customer/customers";
import customerFilterReducer from "./customer/filter";
import mbiFilterReducer from "./mbi/filter";
import customerSortReducer from "./customer/sort";
import ordersReducer from "./order/orders";
import orderFilterReducer from "./order/filter";
import BGTTrackingFilterReducer from "./BronzeGroupTracking/tracking/filter";
import orderSortReducer from "./order/sort";
import newCustomersReducer from "./customer/newCustomers";
import profileOrdersReducer from "./order/profileOrders";
import customerExportReducer from "./customer/customerExport";
import trackingSortReducer from "./BronzeGroupTracking/tracking/sort";
import bronzeGroupTrackingReducer from "./BronzeGroupTracking/bronzeGroupTracking";
import bgtGraduatedFilterReducer from "./BronzeGroupTracking/graduated/filter";
import bgtGraduatedSort from "./BronzeGroupTracking/graduated/sort";
import aboNameReducer from "./aboName";
import businessSelectorReducer from "./businessSelector";
import savedKpiPreferencesReducer from "./savedKpiPreferences";
import componentLoaderReducer from "./componentLoader";

const rootReducer = combineReducers({
  demo: demoReducer,
  period: periodReducer,
  customerFilter: customerFilterReducer,
  mbiFilter: mbiFilterReducer,
  profile: profileReducer,
  bronzeIncentive: bronzeIncentiveReducer,
  customerExport: customerExportReducer,
  orderFilter: orderFilterReducer,
  BGTTrackingFilter: BGTTrackingFilterReducer,
  boot: bootReducer,
  loginLocalStorage: loginLocalStorageReducer,
  [corePlusIncentivesApi.reducerPath]: corePlusIncentivesApi.reducer,
  [foundersPlatinumProgressAPI.reducerPath]: foundersPlatinumProgressAPI.reducer,
  [hatSummaryAPI.reducerPath]: hatSummaryAPI.reducer,
  [businessSelectorApi.reducerPath]: businessSelectorApi.reducer,
  [monthlyPerformanceDashboardAPI.reducerPath]: monthlyPerformanceDashboardAPI.reducer,
  [savedKpiPreferencesAPI.reducerPath]: savedKpiPreferencesAPI.reducer,
  eligiblityTab: eligibilityTabReducer,
  bronzeGroupTrackingTab: bronzeGroupTrackingTabReducer,
  [accAPI.reducerPath]: accAPI.reducer,
  customers: customersReducer,
  newCustomers: newCustomersReducer,
  customerSort: customerSortReducer,
  orderSort: orderSortReducer,
  orders: ordersReducer,
  profileOrders: profileOrdersReducer,
  trackingSort: trackingSortReducer,
  bronzeGroupTracking: bronzeGroupTrackingReducer,
  BGTGraduatedFilter: bgtGraduatedFilterReducer,
  BGTGraduatedSort: bgtGraduatedSort,
  aboName: aboNameReducer,
  businessSelectorData: businessSelectorReducer,
  savedKpiPreferencesData: savedKpiPreferencesReducer,
  [shellConfigApi.reducerPath]: shellConfigApi.reducer,
  [annualPerformanceDashboardAPI.reducerPath]: annualPerformanceDashboardAPI.reducer,
  [frontlineGroupGrowthAPI.reducerPath]: frontlineGroupGrowthAPI.reducer,
  [annualPggAPI.reducerPath]: annualPggAPI.reducer,
  [ssiAPI.reducerPath]: ssiAPI.reducer,
  [lsiAPI.reducerPath]: lsiAPI.reducer,
  [personalGroupGrowthAPI.reducerPath]: personalGroupGrowthAPI.reducer,
  [performancePlusEliteAPI.reducerPath]: performancePlusEliteAPI.reducer,
  componentLoader: componentLoaderReducer,
  [pggGcrAPI.reducerPath]: pggGcrAPI.reducer,
  [monthlyPGGAPI.reducerPath]: monthlyPGGAPI.reducer,
});

export default rootReducer;
