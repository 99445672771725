import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BGT_TABS } from "../common/enums/bronze-group-tracking-enums";

const initialState = {
  defaultView: BGT_TABS.OVERVIEW_TAB,
  activeView: BGT_TABS.OVERVIEW_TAB,
  activeTabs: [BGT_TABS.OVERVIEW_TAB, BGT_TABS.TRACKING_TAB, BGT_TABS.GRADUATED_TAB],
};

const { actions, reducer } = createSlice({
  name: "bronzeGroupTrackingTab",
  initialState,
  reducers: {
    setActiveTabView: (state, action: PayloadAction<BGT_TABS>) => {
      const { payload } = action;
      return {
        ...state,
        activeView: payload,
      };
    },
  },
});

export const { setActiveTabView } = actions;

export default reducer;
