import "./IconTable.scss";
import { ReactComponent as TableIcon } from "./svg/icon_table.svg";
import { ReactComponent as TableIconBlue } from "./svg/icon_table_blue.svg";

export const IconTable = ({
  width = "2.5rem",
  height = "2.5rem",
  color = "",
}: {
  width?: string;
  height?: string;
  color?: string;
}) => {
  const renderIcon = () => {
    switch (color) {
      case "blue":
        return <TableIconBlue />;
      default:
        return <TableIcon />;
    }
  };

  return (
    <span className={`icon-table icon-table__${color}`} style={{ width, height }} data-testid="icon-table">
      {renderIcon()}
    </span>
  );
};
