import { AwsRum, AwsRumConfig } from "aws-rum-web";

let awsRum: AwsRum;

try {
  const localAwsRumConfig: AwsRumConfig = {
    sessionSampleRate: 0.1,
    guestRoleArn: "arn:aws:iam::046317447102:role/RUM-Monitor-us-east-1-046317447102-4124665321861-Unauth",
    identityPoolId: "us-east-1:f071e407-502e-47ea-90d9-c973b3ab1e2d",
    endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
    telemetries: ["performance", "errors"],
    allowCookies: true,
    enableXRay: true,
  };

  const APPLICATION_ID = "d613a4fb-eab8-4717-b15c-c1bf31767e7e";
  const APPLICATION_VERSION = "1.0.0";
  const APPLICATION_REGION = "us-east-1";

  if (window.location.hostname.includes("localhost")) {
    console.warn("Running Local Host App Monitor");
    awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, localAwsRumConfig);
  }

  const _awsRum: string = sessionStorage.getItem("awsRum") || "{}";
  let awsRumData;
  if (_awsRum !== "undefined") {
    awsRumData = JSON.parse(_awsRum);
  }

  // Converts RegExp strings to RegExp objects
  const convertRegExpStringToObject = (regexpList: any[]) => {
    for (let i = 0; i < regexpList.length; i++) {
      regexpList[i] = new RegExp(regexpList[i]);
    }
  };

  const processConfigData = (rumConfig: { telemetries: any }) => {
    rumConfig.telemetries.forEach((_element: any) => {
      if (_element instanceof Array && _element[0] === "http" && _element[1]) {
        if (_element[1]["urlsToInclude"]) {
          convertRegExpStringToObject(_element[1]["urlsToInclude"]);
        }
        if (_element[1]["urlsToExclude"]) {
          convertRegExpStringToObject(_element[1]["urlsToExclude"]);
        }
      }
    });
    return rumConfig;
  };

  // Use environment awsRum from configs when running in non "localhost" domain
  if (awsRumData && !window.location.hostname.includes("localhost")) {
    const { awsRumConfig = {}, awsRumApp = {} } = awsRumData;
    const processedAwsRumConfig = "telemetries" in awsRumConfig ? processConfigData(awsRumConfig) : awsRumConfig;
    awsRum = new AwsRum(awsRumApp.appId, awsRumApp.appVersion, awsRumApp.appRegion, processedAwsRumConfig);
  }
} catch (error) {
  console.error(error);
}

export { awsRum };
