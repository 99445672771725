import { useTranslation } from "react-i18next";
import defaultContent from "../../../content/visionBonus";
import TrackingProgressTile from "../../../common/components/TrackingProgressTile/TrackingProgressTile";

type Props = {
  isLoading: boolean;
  data: {
    totalRequirements: number;
    requirementsMet: number;
    requirementMetStatuses: Array<boolean>;
  };
};
const VisionBonusProgress = (props: Props) => {
  const { t } = useTranslation(["visionBonus"]);
  const { isLoading, data } = props;
  const { totalRequirements, requirementsMet, requirementMetStatuses } = data;

  return (
    <TrackingProgressTile
      isLoading={isLoading}
      totalRequirements={totalRequirements}
      totalRequirementsMet={requirementsMet}
      requirementMetStatuses={requirementMetStatuses}
      id="Vision-Bonus-Tracking-Progress-Tile"
      trackingContent={t("progressTile", {
        returnObjects: true,
        defaultValue: defaultContent.progressTile,
      })}
    />
  );
};

export default VisionBonusProgress;
