import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store";
import { getDefaultHomePage } from "../../common/util/navigationUtil";

export const HomePage = () => {
  const { configuration } = useAppSelector((state) => state.boot);

  const navigate = useNavigate();

  useEffect(() => {
    navigate(getDefaultHomePage(configuration.cppModHomePage));
  }, [configuration, navigate]);

  return <></>;
};
