import { useTranslation } from "react-i18next";
import ChartLegend from "../ChartLegend/ChartLegend";
import defaultContent from "../../../../../content/personalPVChart";
import PieChartLoader from "../..//Loader/PieChartLoader/PieChartLoader";
import "./PersonalPVChart.scss";
import PVGreyChart from "../PVGreyChart/PVGreyChart";
import { formattedPV } from "../../../../../components/impersonation/util";
import { LeafPV } from "../../../../../common/leaf/LeafFormat/LeafPV/LeafPV";
import { LeafErrorMessage } from "../../../../../common/leaf/LeafErrorMessage/LeafErrorMessage";
import { ppvChartErrorTrack } from "../../../../../common/tealium/Customers/Overview/Chart/PersonalPVTrack";
import PersonalPVDonut from "./PersonalPVDonut/PersonalPVDonut";

type Props = {
  isLoading: boolean;
  personalPV: number;
  customerPV: number;
  isError: boolean;
  period: string;
};

const PersonalPVChart = (props: Props) => {
  const { t } = useTranslation(["personalPVChart"]);
  const { isLoading, personalPV, customerPV, isError, period } = props;

  if (isError === true) {
    ppvChartErrorTrack(period);
  }

  const validatePV = (pv: number) => {
    return pv <= personalPV && personalPV >= 0 && pv >= 0;
  };

  const getPercentage = (showGreyChart: boolean, pv: number) => {
    const masked = " - ";
    const needToMask = showGreyChart === true || pv <= 0;
    const percentage = (pv / personalPV) * 100;
    return needToMask === false ? formattedPV(percentage) : masked;
  };

  const isValidPV = validatePV(customerPV);
  const zeroPV = personalPV === 0 && customerPV === 0;
  const showGreyChart = isValidPV === false || zeroPV === true;

  const personalOrderPV = personalPV - customerPV;
  const personalOrderPVPercent = getPercentage(showGreyChart, personalOrderPV);
  const customerPVPercent = getPercentage(showGreyChart, customerPV);

  const PPVChart = () =>
    showGreyChart ? (
      <PVGreyChart />
    ) : (
      <PersonalPVDonut
        textContent={{
          legendPersonalOrderPvText: t("legendPersonalOrderPvText", defaultContent["legendPersonalOrderPvText"]),
          legendCustomerPvText: t("legendCustomerPvText", defaultContent["legendCustomerPvText"]),
        }}
        personalOrderPV={personalOrderPV}
        customerPV={customerPV}
      />
    );

  const PersonalPVContainer = () => (
    <div className="personal-pv">
      <div className="personal-pv__container">
        <div className="personal-pv__title">{t("title", defaultContent["title"])}</div>
        <LeafErrorMessage show={isError} />
        {isError === false && (
          <>
            <div className="personal-pv__subtitle">
              <span className="personal-pv__total">{t("subtitle", defaultContent["subtitle"])} </span>
              <span className="personal-pv__ppv-value">
                <LeafPV pv={personalPV} />
              </span>
              <span className="personal-pv__ppv"> {t("ppv", defaultContent["ppv"])}</span>
            </div>
            <PPVChart />
            <ChartLegend
              legends={[
                {
                  id: "popv",
                  text: t("legendPersonalOrderPvText", defaultContent["legendPersonalOrderPvText"]),
                  value: t("legendPersonalOrderPvValue", defaultContent["legendPersonalOrderPvValue"], {
                    PV: formattedPV(personalOrderPV),
                    PERCENTAGE: personalOrderPVPercent,
                  }),
                  color: "purple",
                },
                {
                  id: "cpv",
                  text: t("legendCustomerPvText", defaultContent["legendCustomerPvText"]),
                  value: t("legendCustomerPvValue", defaultContent["legendCustomerPvValue"], {
                    PV: formattedPV(customerPV),
                    PERCENTAGE: customerPVPercent,
                  }),
                  color: "blue",
                },
              ]}
            />
          </>
        )}
      </div>
    </div>
  );

  return <PieChartLoader isLoading={isLoading} View={PersonalPVContainer} />;
};

export default PersonalPVChart;
