import "./IconCloseThick.scss";

type Props = {
  width: string;
  height: string;
};

export const IconCloseThick = (props: Props) => {
  const { width, height } = props;
  return <span className="icon-close-thick" style={{ width, height, backgroundSize: `${width} ${height}` }}></span>;
};
