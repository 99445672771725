import "./IconErrorClock.scss";
import { ReactComponent as ClockError } from "./svg/icon-error-clock.svg";

export const IconErrorClock = ({ id = "" }: { id?: string }) => {
  return (
    <span id={id} className="Icon-Clock">
      <ClockError />
    </span>
  );
};
