import { useState, useEffect } from "react";
import "./BadgeRequirementTile.scss";
import { QUALIFICATION_STATUS } from "../../../enums/incentive-enums";
import LeafTile from "../../../leaf/LeafTile/LeafTile";
import { IconShield, BADGE_TYPE, BADGE_SIZE } from "../../../icons/IconShield/IconShield";
import { AwardProgressBadge } from "../../AwardProgressBadge/AwardProgressBadge";
import { AWARD_PROGRESS } from "../../../enums/award-progress-codes";

type Props = {
  title: string;
  description: string;
  status: QUALIFICATION_STATUS;
  showLabel?: boolean;
  hideBorder?: boolean;
  hideDescription?: boolean;
};

export const BadgeRequirementTile = ({
  title,
  description,
  status,
  showLabel = true,
  hideBorder = false,
  hideDescription = false,
}: Props) => {
  const [badgeType, setBadgeType] = useState(BADGE_TYPE.POTENTIAL);
  const [progressStatus, setProgressStatus] = useState<AWARD_PROGRESS>(AWARD_PROGRESS.NOT_TRACKING);

  useEffect(() => {
    switch (status) {
      case QUALIFICATION_STATUS.QUALIFIED:
        setBadgeType(BADGE_TYPE.EARNED);
        setProgressStatus(AWARD_PROGRESS.ACHIEVED);
        break;
      case QUALIFICATION_STATUS.NOT_QUALIFIED:
      case QUALIFICATION_STATUS.NOT_TRACKING:
        setBadgeType(BADGE_TYPE.NOT_EARNED);
        setProgressStatus(AWARD_PROGRESS.NOT_TRACKING);
        break;
      default:
        setBadgeType(BADGE_TYPE.POTENTIAL);
        setProgressStatus(AWARD_PROGRESS.TRACKING);
        break;
    }
  }, [status]);

  return (
    <LeafTile
      title={title}
      showMetStatus={true}
      met={status === QUALIFICATION_STATUS.QUALIFIED}
      hideBorder={hideBorder}
    >
      <div className="BadgeReq">
        <div className="BadgeReq__icon">
          <IconShield size={BADGE_SIZE.EXTRA_LARGE} type={badgeType} />
        </div>
        {hideDescription === false && <div className="BadgeReq__desc">{description}</div>}
        {showLabel ? (
          <AwardProgressBadge status={progressStatus} hideIcon={true} />
        ) : (
          <div className="BadgeReq__phantomLabel"></div>
        )}
      </div>
    </LeafTile>
  );
};
