import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import "./MBI.scss";
import MBILoader from "./MBILoader/MBILoader";

const MBI = () => {
  return (
    <main className="mbi">
      <Suspense fallback={<MBILoader />}>
        <Outlet />
      </Suspense>
    </main>
  );
};

export default MBI;
