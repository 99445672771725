import { track } from "@amwaycommon/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../../common/enums/tealium-enums";

const actionMapper: { [key: string]: string } = {
  overview: "open_customer_overview",
  list: "open_customer_list",
  order: "open_order_history",
};

const clickCategoryMapper: { [key: string]: string } = {
  overview: TEALIUM.CATEGORY.GLOBAL_CML_OVERVIEW,
  list: TEALIUM.CATEGORY.GLOBAL_CUSTOMER_LIST,
  order: TEALIUM.CATEGORY.GLOBAL_ORDER_HISTORY,
};

export const tabMenuClickTrack = (tabMenuLink: string) => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    clickCategoryMapper[tabMenuLink],
    "",
    actionMapper[tabMenuLink],
  );
};
