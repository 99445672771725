import { ReactEventHandler } from "react";
import { Trans, useTranslation } from "react-i18next";
import LeafFilterButton from "../../../leaf/LeafFilter/LeafFilterButton/LeafFilterButton";
import LeafIncentiveLegCounts from "../../../leaf/LeafIncentiveLegCounts/LeafIncentiveLegCounts";
import LeafTag from "../../../leaf/LeafTag/LeafTag";
import LeafTile from "../../../leaf/LeafTile/LeafTile";
import defaultContent from "../../../../content/legsPercentangeTile";
import "./LegsPercentageTile.scss";

type Props = {
  isLoading: boolean;
  circleCheckmarkId: string;
  met: boolean;
  title: string;
  actual: number;
  target: number;
  short: number;
  viewDownlineButtonOnClick: ReactEventHandler;
  viewDownlineButtonText: string;
};

export const LegsPercentageTile = ({
  isLoading,
  circleCheckmarkId,
  met,
  title,
  actual,
  target,
  short,
  viewDownlineButtonOnClick,
  viewDownlineButtonText,
}: Props) => {
  const { t } = useTranslation(["legsPercentangeTile"]);

  const translationKey = short > 1 ? "addtionalLegs" : "addtionalLeg";

  return (
    <div className="LeafLegsPercentageTile">
      <LeafTile
        showMetStatus={true}
        circleCheckmarkId={circleCheckmarkId}
        met={met}
        title={title}
        isLoading={isLoading}
      >
        <div className="LeafLegsRequirement">
          <span className="LeafLegsRequirement__tag">
            <LeafTag hideTag={met} variant={"orange"}>
              <Trans
                t={t}
                i18nKey={translationKey}
                defaults={defaultContent[translationKey]}
                values={{
                  legs: short,
                }}
                components={{ 1: <strong></strong> }}
              />
            </LeafTag>
          </span>
          <LeafIncentiveLegCounts isLoading={isLoading} actual={actual} target={target} />
          <LeafFilterButton text={viewDownlineButtonText} onButtonClick={viewDownlineButtonOnClick} />
        </div>
      </LeafTile>
    </div>
  );
};
