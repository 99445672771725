const content: { [key: string]: any } = {
  bronzefoundation: "Bronze Foundation+",
  pathtobronze: "Path to Bronze+",
  bronzebuilder: "Bronze Builder+",
  bronzeToastMessage: {
    BFI: "Your Bronze Foundation+ eligibility has restarted. You can earn an additional 12 payments within a new {noOfMonths} month window. The restarted new window begins the first month you earn the Bronze Foundation Incentive+.",
    BBI: "Your Bronze Builder+ eligibility has restarted. You can earn an additional 12 payments within a new {noOfMonths} month window. The restarted new window begins the first month you earn the Bronze Builder Incentive+.",
  },
};

export default content;
