import { track } from "@amwaycommon/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../../../enums/tealium-enums";

export const customerTypeChartClickTrack = () => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.GLOBAL_CML_OVERVIEW,
    "",
    "open_customer_type_chart",
  );
};

export const customerTypeChartErrorTrack = (period: string) => {
  track(TEALIUM.U_TAG_METHOD.VIEW, "", "", TEALIUM.CATEGORY.GLOBAL_CML, `overview_customer_type_error:${period}`, "");
};
