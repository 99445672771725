import { getLoginAffAbo } from "../../../../../reducers/model/boot";
import { useGetRecentOrderStatQuery } from "../../../../../services/recentOrderStatAPI";
import { useAppSelector } from "../../../../../store";
import Statistic from "../Statistic";

const OrderStatistic = () => {
  const { selectedPeriod } = useAppSelector(({ period }) => period);

  const boot = useAppSelector(({ boot }) => boot);
  const affAbo = getLoginAffAbo({ boot });

  const { data, isFetching } = useGetRecentOrderStatQuery({ affAbo, period: selectedPeriod });
  const { current = 0, previous = 0, entryDate = "" } = data || {};

  return (
    <Statistic
      isFetching={isFetching}
      current={current}
      previous={previous}
      entryDate={entryDate}
      selectedPeriod={selectedPeriod}
      module="Order"
    />
  );
};

export default OrderStatistic;
