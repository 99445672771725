import "./PerformanceBonusLevelText.scss";
import { useTranslation } from "react-i18next";
import { IconRisingArrow } from "../../../../../common/icons/IconRisingArrow/IconRisingArrow";
import defaultContent from "../../../../../content/monthlyPerformanceDashboard";
import { BADGE_SIZE, BADGE_TYPE, IconShield } from "../../../../../common/icons/IconShield/IconShield";

type Props = {
  corePlanQualified: boolean;
  currentBonusAmount: number;
};

export const PerformanceBonusLevelText = ({ corePlanQualified, currentBonusAmount }: Props) => {
  const { t } = useTranslation(["monthlyPerformanceDashboard"]);

  const performanceBonusNotMet = () => {
    return (
      <div className={"perfBonus_container"}>
        <span className={"perfBonus_container__icon"}>
          <IconRisingArrow />
        </span>
        <p className={"perfBonus_container__not-met"}>
          {t("performanceBonusLevelNotMet", defaultContent["performanceBonusLevelNotMet"], {
            performanceBonusLevel: `${currentBonusAmount}%`,
          })}
        </p>
      </div>
    );
  };

  const performanceBonusMet = () => {
    return (
      <div className={"perfBonus_container"}>
        <span className={"perfBonus_container__icon"}>
          <IconShield type={BADGE_TYPE.EARNED} size={BADGE_SIZE.EXTRA_SMALL} />
        </span>
        <p className={"perfBonus_container__met"}>
          {t("performanceBonusLevelMet", defaultContent["performanceBonusLevelMet"])}
        </p>
      </div>
    );
  };

  return corePlanQualified ? performanceBonusMet() : performanceBonusNotMet();
};
