import { ReactComponent as IconPersonOutline1 } from "./svg/IconPersonOutline1.svg";
import { ReactComponent as IconPersonOutline2 } from "./svg/IconPersonOutline2.svg";
import { ReactComponent as IconPersonOutline3 } from "./svg/IconPersonOutline3.svg";
import { ReactComponent as IconPersonSolid1 } from "./svg/IconPersonSolid1.svg";
import { ReactComponent as IconPersonSolid2 } from "./svg/IconPersonSolid2.svg";
import { ReactComponent as IconPersonSolid3 } from "./svg/IconPersonSolid3.svg";
import "./IconPerson.scss";

export enum IconPersonColor {
  GREEN = "green",
  GRAY = "gray",
  ORANGE = "orange",
}

export enum IconPersonType {
  SOLID = "solid",
  OUTLINE = "outline",
}

type Props = {
  color: IconPersonColor;
  type: IconPersonType;
  count: number;
};

const IconPerson = (props: Props) => {
  const { color, count, type } = props;

  const IconCountSolid = () => {
    switch (count) {
      case 1:
        return <IconPersonSolid1 />;
      case 2:
        return <IconPersonSolid2 />;
      case 3:
      default:
        return <IconPersonSolid3 />;
    }
  };

  const IconCountOutline = () => {
    switch (count) {
      case 1:
        return <IconPersonOutline1 />;
      case 2:
        return <IconPersonOutline2 />;
      case 3:
      default:
        return <IconPersonOutline3 />;
    }
  };

  const Icon = () => {
    switch (type) {
      case "solid":
        return <IconCountSolid />;

      case "outline":
      default:
        return <IconCountOutline />;
    }
  };

  return (
    <span className={`IconPerson ${color}`}>
      <Icon />
    </span>
  );
};

export default IconPerson;
