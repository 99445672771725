import "./ListLoader.scss";
import React from "react";
import LeafRectangleLoader from "../../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";

type Props = {
  isLoading: boolean;
  View: React.ElementType;
};

const ListLoader = (props: Props) => {
  const { isLoading, View } = props;
  const height = "1.75rem";

  const RenderCardBox = () => {
    return (
      <div className="box__card">
        <div className="box__card__container">
          <LeafRectangleLoader isLoading={isLoading} height={height} width={"50%"} />
        </div>
      </div>
    );
  };

  const ListLoaderView = () => {
    return (
      <div className="box">
        <div className="box__container">
          <LeafRectangleLoader isLoading={isLoading} height={height} width={"45%"} />
        </div>
        <div className="box__list">
          <RenderCardBox />
          <RenderCardBox />
        </div>
      </div>
    );
  };

  return isLoading === true ? <ListLoaderView /> : <View />;
};

export default ListLoader;
