export enum BROWSER_SUPPORT {
  FULL_SUPPORT,
  GRAYLIST,
  UNSUPPORTED,
}

export enum BROWSERS {
  CHROME = "chrome",
  EDGE = "edge",
  FIREFOX = "firefox",
  SAFARI = "safari",
}
