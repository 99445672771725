import { useTranslation } from "react-i18next";
import { IconInfoCircle } from "../../icons/IconInfoCircle/IconInfoCircle";
import LeafTooltip from "../LeafTooltip/LeafTooltip";
import defaultContent from "../../../content/vcsReasons";
import "./LeafVCSReason.scss";

type Props = {
  vcsReasons: string[];
  showAsTooltips: boolean;
  orderIndex: number;
  orderDate?: string;
};

const LeafVCSReason = (props: Props) => {
  const { t } = useTranslation(["vcsReasons"]);
  const { vcsReasons, showAsTooltips, orderIndex, orderDate } = props;
  const uniqueVCSReasons = [...new Set(vcsReasons)];

  const VCSReasons = () => (
    <ul className={`leaf-vcs-reason__list leaf-vcs-reason__list--${vcsReasons.length > 1 ? "multiple" : "single"}`}>
      {uniqueVCSReasons.map((reason) => (
        <li key={`${orderIndex}_${orderDate}_${reason}`} data-testid={`${orderIndex}_${orderDate}_${reason}`}>
          {t(reason, defaultContent[reason])}
        </li>
      ))}
    </ul>
  );

  return (
    <div className="leaf-vcs-reason" data-testid={`leafVCSReason`}>
      {vcsReasons && (
        <>
          {showAsTooltips === true ? (
            <LeafTooltip children={<VCSReasons />} id={`${orderIndex}_${orderDate}`} icon={<IconInfoCircle />} />
          ) : (
            <VCSReasons />
          )}
        </>
      )}
    </div>
  );
};

export default LeafVCSReason;
