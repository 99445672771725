import { ReactNode } from "react";
import LeafTile from "../../leaf/LeafTile/LeafTile";
import "./TrackProgressTile.scss";
import LeafButton from "../../leaf/LeafButton/LeafButton";

type Props = {
  isLoading: boolean;
  id?: string;
  title: string | ReactNode;
  circlesSection: ReactNode;
  subtext?: string | ReactNode;
  buttonAreaLabel?: string;
  buttonFunction?: Function;
};

export const TrackProgressTile = ({
  isLoading,
  id,
  title,
  circlesSection,
  subtext,
  buttonAreaLabel,
  buttonFunction,
}: Props) => {
  const showButton = buttonAreaLabel && buttonFunction && !isLoading;
  buttonFunction = buttonFunction || (() => {});

  return (
    <LeafTile id={id} isLoading={isLoading}>
      <div className="Tracking">
        <div className="Tracking__header" tabIndex={0}>
          {title}
        </div>
        {circlesSection}
        <div className="Tracking__subtext" tabIndex={0}>
          {subtext}
        </div>
        {showButton && (
          <LeafButton clickAction={buttonFunction} id={(id ? id : "Tracking Progress") + " Button"}>
            {buttonAreaLabel}
          </LeafButton>
        )}
      </div>
    </LeafTile>
  );
};
