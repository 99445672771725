import { CustomerPVStatResponse, CustomerPVStatParams } from "../reducers/model/overview/customerPVStat";
import { accAPI } from ".";

export const customerPVStatAPI = accAPI.injectEndpoints({
  endpoints: (build) => ({
    getCustomerPVStat: build.query<CustomerPVStatResponse, CustomerPVStatParams>({
      query: (params) => {
        const { affAbo, period } = params;
        const queryParams = new URLSearchParams({
          period,
        });
        return `/customer/v2/${affAbo}/stat/customerPV/?${queryParams}`;
      },
    }),
  }),
});

export const { useGetCustomerPVStatQuery } = customerPVStatAPI;
