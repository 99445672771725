import { TtciData } from "../../common/interfaces/ttciData";
import { TTCI_STATUS } from "../../common/enums/ttci-enums";

export const returnDefaultTtciData = (): TtciData => {
  return {
    awardLevels: [
      {
        awardLevel: "",
        awardCode: 0,
        bonus: 0,
        achieved: false,
        firstTime: false,
        denied: false,
        held: false,
        status: "",
        manualApproval: false,
      },
    ],
    bonus: 0,
    bonusCurrency: "USD",
    displayDateError: false,
    errors: [],
    status: "",
    manuallyApproved: false,
    noLongerQualify: false,
    timeStamp: 0,
    displayAnaVolTrackingWarning: false,
    displayAnaMissedToast: false,
    displayTrackingGraphics: true,
    displayVCS: true,
    displayPPV: true,
    ppv: {
      target: 0,
      value: 0,
      exempt: false,
      met: true,
      additionalNeeded: 0,
    },
    vcsPercent: {
      target: 0,
      value: 0,
      exempt: false,
      met: true,
      additionalNeeded: 0,
    },
    ppvCumulative: 0,
    vcsCumulative: 0,
  };
};

export const getStatusForTealium = (data: TtciData) => {
  const { status } = data;
  if (TTCI_STATUS.TRACKING === status) {
    return "tracking";
  } else if (TTCI_STATUS.NOT_QUALIFIED === status) {
    return "ineligible";
  } else if (TTCI_STATUS.ACHIEVED === status) {
    return "earned";
  } else if (TTCI_STATUS.HELD === status || TTCI_STATUS.DENIED === status || TTCI_STATUS.NOT_TRACKING === status) {
    return "not earned";
  }
};
