import { ChartDonut } from "@patternfly/react-charts";
import "./PersonalPVDonut.scss";

type Props = {
  customerPV: number;
  personalOrderPV: number;
  textContent: {
    legendPersonalOrderPvText: string;
    legendCustomerPvText: string;
  };
};

const PersonalPVDonut = (props: Props) => {
  const { personalOrderPV, customerPV, textContent } = props;
  return (
    <div className="personal-pv-donut-chart__container">
      <div className="personal-pv-donut-chart" data-testid="personal-pv-chart">
        <ChartDonut
          constrainToVisibleArea={true}
          data={[
            { x: textContent.legendPersonalOrderPvText, y: personalOrderPV },
            { x: textContent.legendCustomerPvText, y: customerPV },
          ]}
          labels={({ datum }) => null}
          colorScale={["#94A3CD", "#38539A"]}
          innerRadius={70}
          padding={{
            left: 25,
            right: 25,
          }}
        />
      </div>
    </div>
  );
};

export default PersonalPVDonut;
