import { ReactNode } from "react";
import "./LeafCheckBox.scss";

type Props = {
  id: string; // string key for identifying the list item
  label: string | ReactNode;
  checked: boolean;
  disabled?: boolean;
  onChange: Function;
  customClass?: string; // to customize styles if needed
};

const LeafCheckBox = (props: Props) => {
  const { id, label, checked, disabled = false, onChange, customClass = "" } = props;
  const labelClass = "leaf-checkbox" + (customClass ? " " + customClass : "");

  const handleEnterPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.stopPropagation();
      e.preventDefault();
      onChange(e);
    }
  };

  return (
    <li>
      <label className={labelClass} key={id} id={id} htmlFor={id}>
        <input
          key={"input_" + id}
          id={"input_" + id}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={(e) => onChange(e)}
          data-testid={id}
          className={checked ? "checked" : ""}
          tabIndex={0}
          onKeyDown={handleEnterPress}
        />
        <span>{label}</span>
      </label>
    </li>
  );
};

export default LeafCheckBox;
