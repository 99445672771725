import LeafFilterByCheckBox from "./LeafFilterByCheckBox/LeafFilterByCheckBox";
import "./LeafFilterBy.scss";

type Item = {
  id: string;
  text: string;
  checked: boolean;
  enable: boolean;
};

type Props = {
  filters: Item[];
  itemOnClick: Function;
};

const LeafFilterBy = (props: Props) => {
  const { filters, itemOnClick } = props;

  return (
    <ul className="leaf-filter-by">
      {filters.map((filter) => (
        <LeafFilterByCheckBox key={filter.id} itemOnClick={itemOnClick} item={filter} />
      ))}
    </ul>
  );
};

export default LeafFilterBy;
