import { PLATINUM_SPONSOR } from "../../../../../constants/sponsor-constants";
import { BasicSponsor } from "../../LeafSponsorship";
import LeafSponsorDetail from "../LeafSponsorDetail";

type Sponsor = { isPlatinum: boolean } & BasicSponsor;

type Props = {
  sponsor: Sponsor;
  platinumSponsor: BasicSponsor;
  fosterSponsor: Sponsor;
};

const LeafPlatinumSponsor = (props: Props) => {
  const { sponsor, platinumSponsor, fosterSponsor } = props;
  const { aboNo, affNo, isValid } = platinumSponsor;
  const isPlatinumSponsor =
    platinumSponsor.isValid === true && sponsor.isPlatinum === false && fosterSponsor.isPlatinum === false;

  return (
    <LeafSponsorDetail
      sponsor={{
        aboNo,
        affNo,
        isValid,
        isPlatinum: true,
      }}
      show={isPlatinumSponsor}
      sponsorType={PLATINUM_SPONSOR}
    />
  );
};

export default LeafPlatinumSponsor;
