/**
 * Converts a URLSearchParams to a plain JS object
 * @param {URLSearchParams} urlParams
 * @return {object} urlParams as an object of keys to values
 */
export function urlParamsToObj(urlParams: URLSearchParams) {
  const paramsObj: any = {};
  urlParams.forEach((value, key) => {
    paramsObj[key] = decodeURIComponent(value);
  });
  return paramsObj;
}

/**
 * Cleans up AFF/ABO values in an object, namely by handling leading zeroes correctly and making sure the pieces are stored as strings.
 * @param {object} container object containing aff and abo values
 * @return {object} object sharing all keys with aff and abo cleaned up
 */
export function cleanAffAbo(container: { aff: string; abo: string }) {
  // remove leading zero's
  const abo = parseInt(container.abo, 10).toString();
  // pad aff scope if missing leading zeros
  const aff = `${"0".repeat(3 - container.aff.toString().length)}${container.aff.toString()}`;
  return { ...container, abo, aff };
}

export function setSessionStorageCompat({ scope = {}, data = {} }: { scope: any; data: any }) {
  // required by old P2 GCC components; also used to calc refresh token
  // request time
  // eslint-disable-next-line no-param-reassign
  data.cacheDateInMS = Date.now().toString();

  // This mucks the object values into a double escaped value. This is
  // because if acc-boot is used to set the sessionStorage, old GCC auth
  // receiver components will fail to read the value properly. Note, this is
  // terrible on so many levels, but acc-boot cleans this out for normal
  // usage within the store
  //
  // The level of displeasure I have for this cannot be overstated
  const baseObject = JSON.stringify({ ...scope, ...data })
    .replace(/:"/g, ':"\\"')
    .replace(/",/g, '\\"",')
    .replace(/"}/g, '\\""}');

  // set the sessionStorage key
  sessionStorage.setItem("persist:userPersistence", baseObject);
}

/**
 * Stores OAUth information for retrieval down the pipeline
 */
export function parseOauth(data: any) {
  // do we have a token?
  if (data.access_token) {
    // Break up the scope param and reduce into a single object
    let scope = data.scope
      .split(" ")
      .map((item: string) => {
        // eslint-disable-next-line prefer-const
        let [key, value] = item.split("=");
        switch (key) {
          case "aboNum":
            key = "abo";
            break;
          case "salesPlanAff":
            key = "aff";
            break;
          default:
        }
        return { [key]: value };
      })
      .reduce((a: Record<string, string>, v: Record<string, string>) => Object.assign(a, v), {});

    // delete the string scope from the object
    delete data.scope;

    scope = cleanAffAbo(scope);

    return { scope, data };
  } else {
    return undefined;
  }
}
