const content: { [key: string]: string } = {
  pageTitle: "Monthly Dashboard",
  monthlyPerformance: "Monthly Performance",
  bonusBreakdown: "Bonus Breakdown",
  keyMetrics: "Key Metrics",
  internationalBusiness: "International Business",
  performanceBonusSchedule: "Performance Bonus Schedule",
  performanceBonusScheduleButton: "Performance Bonus Schedule Button",
  bonusScheduleTableVolume: "<1>{volume}</1> or more",
  bonusScheduleTableFirstPercent: "<1>{percent}%</1> of your Business Volume",
  bonusScheduleTablePercent: "<1>{percent}%</1> of BV",
  performanceBonusLevelNotMet: "You are at {performanceBonusLevel} Performance Bonus Level.",
  performanceBonusLevelMet: "Congratulations! You are Core Plan Qualified.",
  performanceBonusTotalMonthlyPv: "Total Monthly PV",
  performanceBonus: "Performance Bonus",
  gpv: "GPV",
  bonus: "Bonus",
  qualifiedLegs: "Qualified Legs",
  withQLegs: "(With {qLegs} Q-legs)",
  infoDrawerHeader: "What does this mean?",
  infoDrawerGpvTitle: "GPV",
  infoDrawerGpvDesc:
    "Group PV (GPV) includes ABO's own PV, customer and/or member PV and pass-up PV from personally sponsored downline who are below <1>{mpPerfBonus}</1>% Performance Bonus level. Determines Performance Bonus level.",
  infoDrawerCorePlanTitle: "Core Plan Qualified",
  infoDrawerCorePlanDesc:
    "Achievement of a qualified month in one of the 3 ways: reaching <1>{maxPerfGpvTarget}</1> GPV, reaching <2>{pvTargetWithOneQleg}</2> GPV in addition to one in-market <3>{maxPerfBonusPercent}</3>% leg, or having two in-market <4>{maxPerfBonusPercent}</4>% legs.",
  infoDrawerCurrentEarningsTitle: "Your Current Earnings",
  infoDrawerCurrentEarningsDesc:
    "Estimated earnings based on your business performance so far during the month (year), including Performance Bonus and Core Plus+ incentives.",
  infoDrawerDifferentialBonusTitle: "Differential Bonus",
  infoDrawerDifferentialBonusDesc:
    "Performance Bonus that has 'rolled up' from downlines. Differential bonus multipliers are calculated as the difference between your performance percent and the frontline's. See the Performance Bonus component for more detail.",
  infoDrawerRubyPvTitle: "Ruby PV",
  infoDrawerRubyPvDesc:
    "Ruby PV includes Personal PV plus Pass-Up PV from in-market legs that are below <1>{mpPerfBonus}</1>% Performance Bonus level. Excludes GPV from downline Platinums, even if below <1>{mpPerfBonus}</1>%.",
  infoDrawerLeadershipPvTitle: "Leadership PV",
  infoDrawerLeadershipPvDesc:
    "Personal Volume plus Pass-Up from in-market frontlines below <1>{maxPerfBonusPercent}</1>% Leadership Percentage.<br>Unlike Performance Bonus Percentage, the <1>{maxPerfBonusPercent}</1>% Leadership Percentage does not go to the upline in case of a Foster Relationship.<br>Leadership Volume & Leadership Percentage are using PV ranges & Percentage levels per Performance Bonus Schedule.",
  primary: "Primary",
  internationalBusinesses: "International Businesses",
};

export default content;
