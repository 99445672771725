import { ReactComponent as FlagIcon } from "./svg/ch-flag.svg";
import "../flag-icon.scss";

export const CHFlag = () => {
  return (
    <span className="flag-icon">
      <FlagIcon />
    </span>
  );
};
