const content: { [key: string]: string } = {
  new: "New",
  verified: "Verified",
  nonVerified: "Non-Verified",
  customer: "Customer",
  member: "Member",
  moreTags: "+{MORE_TAGS_COUNT} tags",
  primary: "Primary",
  newLastMonth:
    "Customers or Members who have joined in the previous month are included in the filter for the current period.",
  migratedTooltip:
    "Migrated status refers to users in your business who have recently changed their business nature to Member or Customer.",
  migrated: "Migrated",
  guestCustomer: "Guest Customer",
  shareBar: "Sharebar Customer",
};

export default content;
