import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import defaultContent from "../../../content/aboProfileSlider";
import defaultPerfContent from "../../../content/aboProfilePerfHistory";
import { useAppDispatch, useAppSelector } from "../../../store";
import { hideProfile } from "../../../reducers/profile";
import { closeProfileClickTrack } from "../../../common/tealium/ABOProfile/Profile/IndexTrack";
import { TimeStamp } from "../../../common/components/TimeStamp/TimeStamp";
import LeafPanel from "../../../common/leaf/LeafPanel/LeafPanel";
import ABOProfileBody from "./ABOProfileBody/ABOProfileBody";
import ABOBusinessKPIBody from "./ABOBusinessKPIBody/ABOBusinessKPIBody";
import "./ABOProfileSlider.scss";

export type Props = {
  showOnlyPerformanceHistory: boolean;
  previousElement?: HTMLElement;
};

const ABOProfileSlider = (props: Props) => {
  const { previousElement, showOnlyPerformanceHistory } = props;
  const { t } = useTranslation(["aboProfileSlider", "common", "aboProfilePerfHistory"]);
  const isShowProfile = useAppSelector((state) => state.profile.isShowProfile);
  const dispatch = useAppDispatch();
  const contentRef = useRef<HTMLDivElement>(null);

  const closeProfile = () => {
    dispatch(hideProfile());
    closeProfileClickTrack();
  };

  // Prevents the modal from opening then closing right away when using keyboard space or enter.
  useEffect(() => {
    if (isShowProfile && contentRef.current) {
      contentRef.current.focus();
    }
  }, [isShowProfile]);

  return (
    <LeafPanel
      title={
        showOnlyPerformanceHistory
          ? t("title", defaultPerfContent["title"])
          : t("aboProfile", defaultContent["aboProfile"])
      }
      show={isShowProfile}
      onCloseClick={closeProfile}
      id={"abo-profile-slider"}
      previousElement={previousElement}
      applyScroll={true}
    >
      <div className="abo-profile-slider">
        <div ref={contentRef} tabIndex={-1}>
          {showOnlyPerformanceHistory ? (
            <ABOBusinessKPIBody isShowProfile={isShowProfile} />
          ) : (
            <ABOProfileBody isShowProfile={isShowProfile} />
          )}
        </div>
        <div className="abo-profile-slider__timestamp abo-profile-slider__timestamp--desktop">
          <TimeStamp dateFormat={"long"} />
        </div>
        <div className="abo-profile-slider__timestamp abo-profile-slider__timestamp--mobile">
          <TimeStamp dateFormat={"short"} />
        </div>
      </div>
    </LeafPanel>
  );
};

export default ABOProfileSlider;
