const content: { [key: string]: string } = {
  ADRNM: "The order address does not match the registered address of the customer or member",
  ADRNU: "The order address has been registered to someone else",
  CCNAM: "The name on the credit card does not match the name of the customer or member",
  CCNUM: "The credit card number on the order has been registered to someone else ",
  CDNRO: "The customer indicated that they did not request this order",
  CTYID: "The customer/member identity verification failed",
  ERROR: "There was an error calculating VCS eligibility for this order",
  IMPER: "The order was placed by somebody other than the customer/member",
  ITEMQ: "The maximum quantity was exceeded on at least one item in this order ",
  ITMPV: "The maximum PV limit was exceeded on this order",
  OCOAF: "The customer ordered from more than one ABO this month",
  ORDTY: "Orders of this type do not qualify as verified customer sales",
  OVERR: "The VCS flag was manually overridden by Amway",
  PHONE: "The phone number on the order has been registered to someone else ",
  PMTMD: "The payment method used on the order is not eligible as a verified customer sale",
  PMTNU: "The payment method on the order has been used by someone else",
  PPEND: "The payment method used on the order is pending verification.",
  RBLOC: "Orders for this buyer are blocked from qualifying as verified customer sales",
  SATYP: "Sales to this type of account are not verified customer sales",
  VBLOC: "Orders for this Volume IBO are blocked from qualifying as verified customer sales",
  VCUST: "The buyer is not a verified customer/member",
  VSMAT: "The order recipient matches the Volume ABO account on the order",
};

export default content;
