const content: { [key: string]: string } = {
  nonVCSOrder: "Non-VCS Order",
  vcsOrder: "VCS Order",
  return: "Return",
  upliftOrder: "Uplift Order",
  adjustment: "Adjustment",
  reworks: "Rework",
  rmsAdjust: "RMS Mass Upload Adjustment",
  cvr: "CVR",
  mars: "MARS",
  backDate: "Back Date",
  other: "Other",
  order: "Order",
};

export default content;
