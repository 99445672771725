import { track } from "@amwaycommon/acc-mixins/src/modules/tealium/index";
import { useEffect } from "react";
import TEALIUM from "../../enums/tealium-enums";

const clickCategoryMapper: { [key: string]: string } = {
  pathtobronze: TEALIUM.CATEGORY.PATH_TO_BRONZE_INCENTIVE,
  bronzefoundation: TEALIUM.CATEGORY.BRONZE_FOUNDATION_INCENTIVE,
  bronzebuilder: TEALIUM.CATEGORY.BRONZE_BUILDER_INCENTIVE,
};

export const useBronzeIncentivePageLoadTrack = ({ period, currentView }: { period: string; currentView: string }) => {
  useEffect(() => {
    track(
      TEALIUM.U_TAG_METHOD.VIEW,
      "",
      clickCategoryMapper[currentView],
      clickCategoryMapper[currentView],
      `period_selected${period}`,
      "",
    );
  }, [period, currentView]);
};
