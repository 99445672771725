import { ReactNode } from "react";
import PathToBronze from "../../PathToBronze/PathToBronze";
import BronzeFoundation from "../../BronzeFoundation/BronzeFoundation";
import BronzeBuilder from "../../BronzeBuilder/BronzeBuilder";
import { useAppSelector } from "../../../../store";
import { AWARD_CODES } from "../../../../common/enums/award-codes";
import { isProgramActiveBulkCheck } from "../../../../common/util/checkProgramActiveStatus";

export type BronzeView = {
  id: string;
  BronzeView: ReactNode;
  selected: boolean;
  group: number[];
};

export enum BRONZE_VIEW_IDS {
  PTB = "pathtobronze",
  BFI = "bronzefoundation",
  BBI = "bronzebuilder",
}

export const getViewId = (awardCode: number) => {
  const {
    BRONZE_FOUNDATION,
    RESTART_BRONZE_FOUNDATION,
    RESTART_TWO_BRONZE_FOUNDATION,
    BRONZE_BUILDER,
    RESTART_BRONZE_BUILDER,
    RESTART_TWO_BRONZE_BUILDER,
    PATH_TO_BRONZE,
  } = AWARD_CODES;

  switch (awardCode) {
    case BRONZE_FOUNDATION:
    case RESTART_BRONZE_FOUNDATION:
    case RESTART_TWO_BRONZE_FOUNDATION:
      return BRONZE_VIEW_IDS.BFI;
    case BRONZE_BUILDER:
    case RESTART_BRONZE_BUILDER:
    case RESTART_TWO_BRONZE_BUILDER:
      return BRONZE_VIEW_IDS.BBI;
    case PATH_TO_BRONZE:
      return BRONZE_VIEW_IDS.PTB;
    default:
      return "";
  }
};

const BronzeIncentivesViews = (): BronzeView[] => {
  const eligibleIncentives = useAppSelector((store) => store?.boot?.shellConfig?.eligibleIncentives);

  const { BRONZE_FOUNDATION, BRONZE_BUILDER, PATH_TO_BRONZE } = AWARD_CODES;

  const bronzeView = [
    {
      id: BRONZE_VIEW_IDS.PTB,
      BronzeView: <PathToBronze showHeaderFooter={false} />,
      selected: false,
      group: [PATH_TO_BRONZE],
    },
    {
      id: BRONZE_VIEW_IDS.BFI,
      BronzeView: <BronzeFoundation />,
      selected: false,
      group: [BRONZE_FOUNDATION],
    },
    {
      id: BRONZE_VIEW_IDS.BBI,
      BronzeView: <BronzeBuilder />,
      selected: false,
      group: [BRONZE_BUILDER],
    },
  ]
    .filter(({ group }) => isProgramActiveBulkCheck(eligibleIncentives, group))
    .map((item, index) => ({
      ...item,
      selected: index === 0,
    }));

  return bronzeView as BronzeView[];
};

export default BronzeIncentivesViews;
