import { ReactNode, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { lockBodyScrolling } from "../../util/cssUtils";
import { FocusTrap } from "../../components/FocusTrap/FocusTrap";
import { IconClose } from "../../icons/IconClose/IconClose";
import commonContent from "../../../content/common";
import "./LeafModal.scss";

type Props = {
  open: boolean;
  onCloseCallbackFn?: Function;
  title?: string;
  subtitle?: string | JSX.Element;
  description?: string | JSX.Element;
  children?: ReactNode;
};

export const LeafModal = ({ open, onCloseCallbackFn = () => {}, title, subtitle, description, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpenedByElement, setModalOpenedByElement] = useState<Element>();
  const modalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open) {
      setIsOpen(open);
      setTimeout(() => {
        if (modalContentRef.current) {
          modalContentRef.current.focus();
        }
      }, 0);
    }
  }, [open]);

  lockBodyScrolling(isOpen);

  const { t } = useTranslation(["common"]);

  if (!isOpen) return null;

  const handleOnClose = () => {
    setIsOpen(false);
    onCloseCallbackFn(false);
    if (modalOpenedByElement) {
      (modalOpenedByElement as HTMLElement).focus();
      setModalOpenedByElement(undefined);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleOnClose();
    }
  };

  const returnSubtitle = () => {
    if (subtitle) {
      return <p className="leaf-modal__modal-subtitle">{subtitle}</p>;
    }
    return <></>;
  };

  return open ? (
    <div>
      <div onClick={handleOnClose} className="leaf-modal__backdrop" data-testid={`leafModal-overlay`}></div>
      <FocusTrap aria-modal={true} aria-hidden={!open} role="dialog" handleOnClose={handleOnClose} aria-label={title}>
        <div className="leaf-modal__modal-container">
          <div className="leaf-modal__close-icon">
            <button
              className="leaf-modal__close-icon__close"
              aria-label={`${t("closeButton", commonContent["closeButton"])}`}
              onClick={handleOnClose}
              tabIndex={-1}
              id="leafModalCloseButton"
              onKeyDown={handleKeyDown}
            >
              <IconClose width="1.4rem" height="1.4rem" />
            </button>
          </div>
          <div className="leaf-modal__modalHeader">
            <h1 className="leaf-modal__title">{title}</h1>
            {returnSubtitle()}
          </div>
          <div className="leaf-modal__modal-content" ref={modalContentRef}>
            <div className="leaf-modal__modal-body">
              <div className="leaf-modal__modal-desc">{description}</div>
              {children}
            </div>
          </div>
        </div>
      </FocusTrap>
    </div>
  ) : (
    <></>
  );
};
