import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setBoot } from "../boot";
import {
  getUpdatedSortOptions,
  SORTBY_ORDER_DATE,
  SORTBY_ORDER_PV,
  SortOption,
  SortState,
  SORTBY_ASC_ORDER,
  SORTBY_DESC_ORDER,
  getEnabledSortOptions,
  setDefaultSelected,
  getFirstOption,
} from "../model/sort";

const initialSortBy = [
  {
    id: "descOrderDate",
    order: SORTBY_DESC_ORDER,
    selected: true,
    value: SORTBY_ORDER_DATE,
  },
  {
    id: "ascOrderDate",
    order: SORTBY_ASC_ORDER,
    selected: false,
    value: SORTBY_ORDER_DATE,
  },
  {
    id: "ascOrderPV",
    order: SORTBY_ASC_ORDER,
    selected: false,
    value: SORTBY_ORDER_PV,
  },
  {
    id: "descOrderPV",
    order: SORTBY_DESC_ORDER,
    selected: false,
    value: SORTBY_ORDER_PV,
  },
];

const initialState: SortState = {
  sortOrder: initialSortBy[0].order,
  sortValue: initialSortBy[0].value,
  sortBy: initialSortBy,
  sortByLastUpdateAt: 0,
};

const { actions, reducer } = createSlice({
  name: "orderSort",
  initialState,
  reducers: {
    // resets the sort to default options
    resetSort: (state) => {
      const { sortBy } = state;

      return {
        ...state,
        ...getFirstOption(sortBy),
        sortBy: setDefaultSelected(sortBy),
      };
    },
    // updates sort list based on user selection
    updateSortBy: (state, action: PayloadAction<SortOption>) => {
      const { payload } = action;
      return {
        ...state,
        sortBy: getUpdatedSortOptions(state.sortBy, payload),
        sortValue: payload.value,
        sortOrder: payload.order,
      };
    },
  },
  extraReducers: (builder) => {
    // listen to boot event and update sort configuration accordingly
    builder.addCase(setBoot, (state, { payload }) => {
      const {
        orderList: { sortBy: sortByConfig },
      } = payload.configuration.globalCML;

      return {
        ...state,
        ...getEnabledSortOptions(state.sortBy, sortByConfig),
      };
    });
  },
});

export const { resetSort, updateSortBy: updateSortOption } = actions;

export default reducer;
