import React, { isValidElement } from "react";
import "./LeafTable.scss";

export type TableHeader = {
  value: React.ReactNode | string;
  width?: string;
  padding?: string;
};

export type RowColumn = {
  value: React.ReactNode | string;
  height?: string;
  columnAlign?: "left" | "right" | "center" | undefined;
};

export type TableRow = {
  row: RowColumn[];
};

export type LeafTableProps = {
  header: TableHeader[];
  rows: TableRow[];
  columnAlign?: "left" | "right" | "center";
  headerStyle?: "default" | "clear";
  hideHeader?: boolean;
  rowHeight?: string;
  ariaLabel?: string;
  combinedCells?: Map<string, Set<string>>;
};

const getHeaderStyle = (hideHeader: boolean, headerStyle: string) => {
  if (hideHeader) {
    return "leaf-table__header__hidden";
  }
  if (headerStyle === "default") {
    return "leaf-table__header-default";
  }
  if (headerStyle === "clear") {
    return "leaf-table__header-clear";
  }
};

const LeafTable = ({
  header,
  rows,
  columnAlign,
  hideHeader = false,
  rowHeight = "3rem",
  headerStyle = "default",
  ariaLabel,
  combinedCells,
}: LeafTableProps) => {
  const rowStyle = rowHeight !== null ? { height: rowHeight } : {};

  // Gets the text from a React Node
  const getTextFromReactNode = (node: React.ReactNode): string => {
    if (typeof node === "string" || typeof node === "number") {
      return node.toString();
    }

    if (isValidElement(node)) {
      const children = node.props.children;
      return getTextFromReactNode(children);
    }

    if (Array.isArray(node)) {
      return node.map((child) => getTextFromReactNode(child)).join("");
    }

    return "";
  };

  // Checks if the position being checked is in the correct column
  const checkIfCorrectColumn = (positions: Set<string> | undefined, col: number): boolean => {
    for (const position of positions!) {
      const [rowStr, colStr] = position.split(",");
      const column = parseInt(colStr, 10);
      if (col === column) {
        return true;
      }
    }
    return false;
  };

  // Renders cells that should be combined within each of the columns
  const renderCombined = (rowIndex: number, colIndex: number, column: RowColumn) => {
    const colToCheck = `${rowIndex},${colIndex}`;
    const value = getTextFromReactNode(column.value);

    if (combinedCells?.has(value)) {
      if (checkIfCorrectColumn(combinedCells?.get(value), colIndex)) {
        if (combinedCells?.get(value)?.has(colToCheck) && !combinedCells?.get(value)?.has("USED")) {
          const size = combinedCells.get(value)!.size;
          combinedCells.get(value)!.add("USED");
          return (
            <td
              key={colIndex}
              style={{
                textAlign: column.columnAlign || columnAlign,
                height: column.height,
                padding: column.columnAlign ? "0px 20px 0px 20px" : undefined,
              }}
              role="cell"
              rowSpan={size}
            >
              {column.value}
            </td>
          );
        }
        return <></>;
      }
    }
    return (
      <td
        key={colIndex}
        style={{
          textAlign: column.columnAlign || columnAlign,
          height: column.height,
          padding: column.columnAlign ? "0px 20px 0px 20px" : undefined,
        }}
        role="cell"
      >
        {column.value}
      </td>
    );
  };

  return (
    <table cellSpacing="0" cellPadding="0" className="leaf-table" tabIndex={0} role="table" aria-label={ariaLabel}>
      <thead>
        <tr>
          {header.map((item, index) => (
            <th
              key={index}
              className={getHeaderStyle(hideHeader, headerStyle)}
              style={{ textAlign: columnAlign, width: item.width, padding: item.padding }}
              scope="col"
              role="columnheader"
            >
              {item.value}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr className="leaf-table leaf-table__row" key={rowIndex} style={rowStyle}>
            {row.row.map((column, colIndex) => (
              <>
                {combinedCells ? (
                  <>{renderCombined(rowIndex, colIndex, column)}</>
                ) : (
                  <>
                    <td
                      key={colIndex}
                      style={{
                        textAlign: column.columnAlign || columnAlign,
                        height: column.height,
                        padding: column.columnAlign ? "0px 20px 0px 20px" : undefined,
                      }}
                      role="cell"
                    >
                      {column.value}
                    </td>
                  </>
                )}
              </>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default LeafTable;
