import { useTranslation } from "react-i18next";
import { TtciEligibilityAwards } from "../../../../common/interfaces/ttciData";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import { LeafDisplayCard } from "../../../../common/leaf/LeafDisplayCard/LeafDisplayCard";
import { getAwardLabelKeyFromAwardCode } from "../../../../common/util/awardLabels";
import defaultContent from "../../../../content/ttci";
import commonContent from "../../../../content/common";
import { Card } from "../TtciEligibilityModal";
import { TTCI_STATUS } from "../../../../common/enums/ttci-enums";
import "../TtciEligibilityModal.scss";
import { TagColor } from "../../../../common/leaf/LeafTag/LeafTag";

const getEligibleTag = (status: string) => {
  let tagStatus = "";
  let tagColor: TagColor = "blue";
  switch (status) {
    case TTCI_STATUS.ELIGIBLE:
    case TTCI_STATUS.TRACKING:
      tagStatus = "tracking";
      tagColor = "blue";
      break;
    case TTCI_STATUS.QUALIFIED:
      tagStatus = "achieved";
      tagColor = "green";
      break;
    case TTCI_STATUS.DENIED:
      tagStatus = "denied";
      tagColor = "red";
      break;
  }
  return {
    tagStatus,
    tagColor,
  };
};

export const createEligibleLeafCards = (data: TtciEligibilityAwards[]) => {
  const eliligibleCards: Card[] = [];
  data.forEach((eligibilityData) => {
    if (eligibilityData.firstTimeAwardEligible && eligibilityData.status !== TTCI_STATUS.HELD) {
      const { tagStatus, tagColor } = getEligibleTag(eligibilityData.status);
      eliligibleCards.push({
        awardLabel: getAwardLabelKeyFromAwardCode(eligibilityData.awardCode, eligibilityData.awardLevel),
        awardCode: eligibilityData.awardCode,
        isRequalAward: false,
        tagStatus: tagStatus,
        tagColorVariant: tagColor,
        id: `${eligibilityData.awardCode}-firstTime`,
      });
    }
    if (
      eligibilityData.requalAwardEligible &&
      eligibilityData.requalificationAward.requalAwardStatus !== TTCI_STATUS.HELD
    ) {
      const { tagStatus, tagColor } = getEligibleTag(eligibilityData.requalificationAward.requalAwardStatus);
      eliligibleCards.push({
        awardLabel: getAwardLabelKeyFromAwardCode(eligibilityData.awardCode, eligibilityData.awardLevel),
        awardCode: eligibilityData.awardCode,
        isRequalAward: true,
        tagStatus: tagStatus,
        tagColorVariant: tagColor,
        id: `${eligibilityData.awardCode}-requal`,
      });
    }
  });

  return eliligibleCards.sort((a, b) => a.awardCode - b.awardCode);
};

type Props = {
  data: TtciEligibilityAwards[];
};

export const EliligibleTab = ({ data }: Props) => {
  const { t } = useTranslation(["ttci", "common"]);
  const eligibleDesc = `${t("ttciEligibility.eligibleDesc", defaultContent.ttciEligibility["eligibleDesc"])}`;
  const eliligibleCards = createEligibleLeafCards(data);

  const getAwardLabel = (isRequalAward: boolean, awardCode: number, awardLabel: string) => {
    const requalification = isRequalAward ? ` ${t(`common:requalification`, commonContent.requalification)}` : "";
    const label = `${t(
      `common:awardLabels.${getAwardLabelKeyFromAwardCode(awardCode, awardLabel)}`,
      commonContent.awardLabels[getAwardLabelKeyFromAwardCode(awardCode, awardLabel)],
    )}${requalification}`;
    return label;
  };

  const returnLeafCards = (cards: Card[]) => {
    if (cards.length > 0) {
      return cards.map((d) => {
        return (
          <LeafDisplayCard
            id={d.id}
            key={d.id}
            awardLabel={getAwardLabel(d.isRequalAward, d.awardCode, d.awardLabel)}
            awardStatus={`${t(`${d.tagStatus}`, defaultContent[d.tagStatus])}`}
            leafTagVariant={d.tagColorVariant}
          ></LeafDisplayCard>
        );
      });
    }
    return (
      <LeafDisplayCard
        id={"no-eligible-pins"}
        key={"no-eligible-pins"}
        awardLabel={`${t(`ttciEligibility.noEligiblePins`, defaultContent.ttciEligibility["noEligiblePins"])}.`}
        awardStatus={""}
        leafTagVariant={"blue"}
      ></LeafDisplayCard>
    );
  };

  return (
    <LeafTile isLoading={false} description={eligibleDesc}>
      <div>{returnLeafCards(eliligibleCards)}</div>
    </LeafTile>
  );
};
