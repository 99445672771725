import { shouldPolyfill } from "@formatjs/intl-datetimeformat/should-polyfill";

export const shouldPolyfillLocale = async (locale: string) => {
  const unsupportedLocale = shouldPolyfill(locale);
  // This locale is supported
  if (!unsupportedLocale) return;

  // Load the polyfill 1st BEFORE loading data
  await import("@formatjs/intl-datetimeformat/polyfill-force");

  // Parallelize CLDR data loading
  const dataPolyfills = [
    import("@formatjs/intl-datetimeformat/add-all-tz"),
    import(`@formatjs/intl-datetimeformat/locale-data/${unsupportedLocale}`),
  ];

  await Promise.all(dataPolyfills);
};
