import { useGetOrderExportNotificationQuery } from "../../../../services/exportAPI";
import { MODULE_ORDER } from "../../../../common/constants/export-constants";
import CustomerExportNotifyContainer from "../CustomerExportNotifyContainer";

const OrderHistoryNotify = () => {
  const { data, isFetching } = useGetOrderExportNotificationQuery(
    { moduleId: MODULE_ORDER },
    {
      pollingInterval: 30000,
    },
  );

  const { exports = [] } = data || {};
  const haveDone = exports.some(({ done }) => done);
  const showToast = haveDone === true && isFetching === false;

  return <CustomerExportNotifyContainer showToast={showToast} />;
};

export default OrderHistoryNotify;
