import { Trans, useTranslation } from "react-i18next";
import LeafTile from "../LeafTile/LeafTile";
import "./LeafIncentiveProgress.scss";
import defaultContent from "../../../content/leafIncentiveProgress";
import { IconCheckCircle } from "../../icons/IconCheckCircle/IconCheckCircle";

type Props = {
  requiredCount: number;
  metCount: number;
  status: boolean[];
  progressContentKey?: string;
  getTrackIconAriaLabel?: (index: number, isMet: boolean) => string;
  isLoading: boolean;
  show?: boolean;
};

const LeafIncentiveProgress = (props: Props) => {
  const { t } = useTranslation(["leafIncentiveProgress"]);

  const {
    show = true,
    isLoading,
    metCount,
    status = [],
    requiredCount,
    progressContentKey = "bronzeProgressText",
    getTrackIconAriaLabel,
  } = props;
  const completed = metCount === requiredCount;

  if (show === false) {
    return <></>;
  }

  return (
    <LeafTile isLoading={isLoading}>
      <div className="LeafIncentiveProgress">
        <div className="LeafIncentiveProgress__title">
          <h2>{t("title", defaultContent["title"])}</h2>
        </div>
        <div className="LeafIncentiveProgress__trackingIcons">
          {status.map((isMet, i) => (
            <div
              key={i}
              tabIndex={0}
              className="LeafIncentiveProgress__trackingIcons icon"
              aria-label={getTrackIconAriaLabel ? getTrackIconAriaLabel(i, isMet) : ""}
            >
              <IconCheckCircle key={i} id={`${i}`} color={isMet ? "green" : "gray"} size="xlg" />
            </div>
          ))}
        </div>
        <div className="LeafIncentiveProgress__progress">
          <div className="LeafIncentiveProgress__progress__text">
            <Trans
              t={t}
              i18nKey={progressContentKey}
              values={{ metCount, requiredCount }}
              components={{
                tracking: (
                  <span
                    className={`LeafIncentiveProgress__progress__text__count ${completed ? "completed" : "onTrack"}`}
                  />
                ),
              }}
            >
              {defaultContent[progressContentKey]}
            </Trans>
          </div>
        </div>
      </div>
    </LeafTile>
  );
};

export default LeafIncentiveProgress;
