import { createApi } from "@reduxjs/toolkit/query/react";
import { PggOverviewResponse, PggPQMonthsResponse } from "../common/interfaces/groupGrowthIncentives";
import { dynamicBaseQuery } from ".";

interface PGGBaseRequestParams {
  abo: string;
  aff: string;
  locale: string;
  period: string;
}

interface PGGExtendedRequestParams extends PGGBaseRequestParams {
  isoCountryCode: string;
  isoCurrencyCode: string;
}

export const annualPggAPI = createApi({
  reducerPath: "annualPggAPI",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getPggOverview: builder.query<PggOverviewResponse, PGGExtendedRequestParams>({
      query: (params) => {
        const { abo, aff, locale, period, isoCountryCode, isoCurrencyCode } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
          IsoCountryCode: isoCountryCode,
          IsoCurrencyCode: isoCurrencyCode,
        });

        return `/incentives-v2/api/pgg/overview?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
    getPggPQMonths: builder.query<PggPQMonthsResponse, PGGExtendedRequestParams>({
      query: (params) => {
        const { abo, aff, locale, period, isoCountryCode, isoCurrencyCode } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
          IsoCountryCode: isoCountryCode,
          IsoCurrencyCode: isoCurrencyCode,
        });

        return `/incentives-v2/api/pgg/pqmonths?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
  }),
});

export const { useGetPggOverviewQuery, useGetPggPQMonthsQuery } = annualPggAPI;
