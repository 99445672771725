import "./LeafProgressBar.scss";

export const LeafProgressBar = ({
  current,
  target,
  currentLabel = "",
  targetLabel = "",
}: {
  current: number;
  target: number;
  currentLabel?: string;
  targetLabel?: string;
}) => {
  const getProgress = () => {
    return (current / target) * 100;
  };

  const hasReachedTarget = current >= target;
  return (
    <section className="leafProgressBar">
      <span className="leafProgressBar__current">{currentLabel}</span>
      <span className="leafProgressBar__target">{targetLabel}</span>

      <div className="leafProgressBar__progress">
        <div className="leafProgressBar__progress-bar">
          <div
            className={`leafProgressBar__progress-filled${hasReachedTarget ? "--met" : ""}`}
            style={{ width: `${getProgress()}%` }}
          ></div>
        </div>
      </div>
    </section>
  );
};
