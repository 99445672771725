import { useTranslation } from "react-i18next";
import { IconShield, BADGE_SIZE, BADGE_TYPE } from "../../../common/icons/IconShield/IconShield";
import defaultContent from "../../../content/badgeLegend";
import "./BadgeLegend.scss";

type Props = {
  status?: BADGE_TYPE[];
  size?: BADGE_SIZE;
};

const defaultStatus = [BADGE_TYPE.EARNED, BADGE_TYPE.POTENTIAL, BADGE_TYPE.NOT_EARNED, BADGE_TYPE.UNAVAILABLE];

export const BadgeLegend = ({ status = defaultStatus, size = BADGE_SIZE.SMALL }: Props) => {
  const { t } = useTranslation(["badgeLegend"]);

  const renderIcon = (badgeStatus: BADGE_TYPE, size: BADGE_SIZE, index: number, arrayLength: number) => {
    const container = `badgeLegend__container ${index === 0 ? "badgeLegend__first" : ""} ${
      index === arrayLength - 1 ? "badgeLegend__last" : ""
    }`;

    switch (badgeStatus) {
      case BADGE_TYPE.EARNED:
        return (
          <div className={container}>
            <IconShield type={BADGE_TYPE.EARNED} size={size} />
            {`${t("earned", defaultContent["earned"])}`}
          </div>
        );
      case BADGE_TYPE.POTENTIAL:
        return (
          <div className={container}>
            <IconShield size={size} />
            {`${t("potential", defaultContent["potential"])}`}
          </div>
        );
      case BADGE_TYPE.NOT_EARNED:
        return (
          <div className={container}>
            <IconShield type={BADGE_TYPE.NOT_EARNED} size={size} />
            {`${t("notEarned", defaultContent["notEarned"])}`}
          </div>
        );
      case BADGE_TYPE.UNAVAILABLE:
        return (
          <div className={container}>
            <IconShield type={BADGE_TYPE.UNAVAILABLE} size={size} />
            {`${t("unavailable", defaultContent["unavailable"])}`}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="badgeLegend">
      {status.map((badgeStatus, index) => (
        <div key={index}>{renderIcon(badgeStatus, size, index, status.length)}</div>
      ))}
    </div>
  );
};
