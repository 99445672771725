const content: { [key: string]: string } = {
  restartEligibility: "Restart Eligibility",
  eligibility: "Eligibility",
  opportunitiesRemaining: "Opportunities Remaining",
  achievedMonths: "Achieved Months",
  missedMonths: "Missed Months",
  months: "Months",
};

export default content;
