import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import defaultContent from "../../../../content/qcGrowth";
import LeafTable from "../../../../common/leaf/LeafTable/LeafTable";
import { IconCheckCircle } from "../../../../common/icons/IconCheckCircle/IconCheckCircle";
import { LeafModal } from "../../../../common/leaf/LeafModal/LeafModal";
import LeafRectangleLoader from "../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { ErrorTile } from "../../../../common/components/ErrorTile/ErrorTile";
import { AWARD_CODES } from "../../../../common/enums/award-codes";
import "./QCGMultiplierBase.scss";
import {
  BonusAwardDetail,
  MultiplierBaseDetailsData,
  MultiplierBaseResponse,
} from "../../../../common/interfaces/multiplierBase";
import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";
import { AWARD_PROGRESS } from "../../../../common/enums/award-progress-codes";
import { AwardProgressBadge } from "../../../../common/components/AwardProgressBadge/AwardProgressBadge";

type Props = {
  qcgResponseData: {
    multiplierBaseData: MultiplierBaseResponse;
    isLoading: boolean;
    errorStatus: boolean;
    refetch: Function;
  };
  modalIsOpen: boolean;
  toggleModal: Function;
  performanceYear: string;
  multiplierBase: string;
};

const getTagStatus = (status: string): AWARD_PROGRESS => {
  if (status === QUALIFICATION_STATUS.TRACKING) {
    return AWARD_PROGRESS.TRACKING;
  } else if (status === QUALIFICATION_STATUS.QUALIFIED) {
    return AWARD_PROGRESS.ACHIEVED;
  } else {
    return AWARD_PROGRESS.NOT_TRACKING;
  }
};

const getAwardContentKey = (awardNumber: number) => {
  switch (awardNumber) {
    case AWARD_CODES.LEADERSHIP:
      return "leadershipBonus";
    case AWARD_CODES.FOSTER:
      return "fosterBonus";
    case AWARD_CODES.PERFORMANCE_BONUS:
      return "performanceBonus";
    case AWARD_CODES.RUBY:
      return "rubyBonus";
    case AWARD_CODES.DEPTH:
      return "depthBonus";
    default:
      return "";
  }
};

const createRows = (bonusAwardDetail: BonusAwardDetail[], performanceYear: string, translation: TFunction) => {
  if (bonusAwardDetail && bonusAwardDetail.length > 0) {
    const rows = new Array(bonusAwardDetail.length);
    bonusAwardDetail.forEach((_, index) => {
      const bonusAward = getAwardContentKey(bonusAwardDetail[index].BonusAward);
      const bonusAwardContent = `${translation(
        `multiplierBase.${bonusAward}`,
        defaultContent["multiplierBase"][`${bonusAward}`],
        {
          performanceYear: performanceYear,
        },
      )}`;
      rows[index] = {
        row: [
          { value: bonusAwardContent, columnAlign: "left" },
          { value: bonusAwardDetail[index].BonusTotal, columnAlign: "left" },
        ],
      };
    });

    return rows;
  }
  return [];
};

const returnDividerLine = (index: number, multiplierBaseDetails: MultiplierBaseDetailsData[]) => {
  const activeSections = multiplierBaseDetails.filter((d) => d.DisplaySectionHeader).length;
  if (activeSections > 1 && index !== multiplierBaseDetails.length - 1) {
    return <span className="QCGMultiplierBase__divider-line"></span>;
  }
  return <></>;
};

export const QCGMultiplierBase = ({
  qcgResponseData,
  modalIsOpen,
  toggleModal,
  performanceYear,
  multiplierBase,
}: Props) => {
  const { t } = useTranslation(["qcGrowth"]);
  const { isLoading, errorStatus, refetch, multiplierBaseData } = qcgResponseData;
  const { MultiplierBaseDetails, Status } = multiplierBaseData;
  const tagStatus = getTagStatus(Status);

  const renderLoadingOrError = () => {
    if (isLoading) {
      return (
        <LeafRectangleLoader isLoading={isLoading} numRows={1} width="100%" height="10rem" margin={"1rem 0 0.5rem 0"} />
      );
    }
    if (errorStatus) {
      return <ErrorTile clickFunction={refetch} errorStatus={errorStatus} isLoading={isLoading} />;
    }
    return <></>;
  };

  const renderComponent = () => {
    return (
      <>
        <section className="QCGMultiplierBase__header-container">
          <h2>{`${t("multiplierBase.title", defaultContent["multiplierBase"]["title"], {
            performanceYear: performanceYear,
          })}`}</h2>
          {Status === QUALIFICATION_STATUS.TRACKING ? <></> : <AwardProgressBadge status={tagStatus} hideIcon={true} />}
        </section>
        {MultiplierBaseDetails.map((base, index) => {
          if (base.BonusAwards && base.BonusAwards.length > 0) {
            return (
              <div className={`QCGMultiplierBase__multiplier-section`} key={index}>
                <section>
                  {base.DisplaySectionHeader ? (
                    <div>
                      <IconCheckCircle color={base.Achieved ? "green" : "gray"} size="md" solidCheckCircle={false} />
                      <p className={`${base.Achieved ? "QCGMultiplierBase__multiplier-section__green" : ""}`}>{`${t(
                        `multiplierBase.${base.MultiplierType}`,
                        String(defaultContent["multiplierBase"][`${base.MultiplierType}`]),
                      )}`}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                  <LeafTable
                    header={[
                      {
                        value: t("multiplierBase.bonus", defaultContent["multiplierBase"]["bonus"], {}),
                        width: "70%",
                        padding: "0",
                      },
                      {
                        value: t("multiplierBase.amount", defaultContent["multiplierBase"]["amount"], {}),
                        width: "30%",
                      },
                    ]}
                    rows={createRows(base.BonusAwards, performanceYear, t)}
                    columnAlign={"left"}
                    headerStyle={"clear"}
                    hideHeader={false}
                  />
                </section>
                {returnDividerLine(index, MultiplierBaseDetails)}
              </div>
            );
          }
          return <></>;
        })}
        <section className="QCGMultiplierBase__total-container">
          <p>{`${t("multiplierBase.totalBonusLabel", String(defaultContent["multiplierBase"]["totalBonusLabel"]))}`}</p>
          <span>{multiplierBase}</span>
        </section>
      </>
    );
  };

  return (
    <div id="qcg-multiplier-base" className="QCGMultiplierBase">
      <LeafModal open={modalIsOpen} onCloseCallbackFn={toggleModal}>
        {!isLoading && !errorStatus ? <>{renderComponent()}</> : <>{renderLoadingOrError()}</>}
      </LeafModal>
    </div>
  );
};
