import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/customerTags";
import { IconInfoCircle } from "../../../../icons/IconInfoCircle/IconInfoCircle";
import LeafTooltip from "../../../LeafTooltip/LeafTooltip";
import "./LeafMigratedTag.scss";

type Props = {
  isNewMigrated?: boolean;
  aboNo: string;
};

const LeafMigratedTag = (props: Props) => {
  const { isNewMigrated, aboNo } = props;
  const { t } = useTranslation(["customerTags"]);
  const isNewMigratedOnClick = (event: SyntheticEvent) => {
    event.stopPropagation();
  };
  return isNewMigrated ? (
    <span className="tag-migrated" onClick={isNewMigratedOnClick}>
      <LeafTooltip
        content={`${t("migratedTooltip", defaultContent["migratedTooltip"])}`}
        id={`migrated_${aboNo}`}
        icon={<IconInfoCircle />}
      />
      {t("migrated", defaultContent["migrated"])}
    </span>
  ) : (
    <></>
  );
};

export default LeafMigratedTag;
