import { Navigate, Route } from "react-router-dom";
import VisionBonusPageLoader from "../components/VisionBonus/VisionBonusPageLoader/VisionBonusPageLoader";
import VisionBonus from "../components/VisionBonus/VisionBonus";

export const VISION_BONUS_BASE_PATH = "/vision-bonus";

export const VisionBonusRoutes = () => {
  return (
    <Route path={VISION_BONUS_BASE_PATH} element={<VisionBonusPageLoader />}>
      <Route path={""} element={<VisionBonus />} />
      <Route path={VISION_BONUS_BASE_PATH} element={<Navigate to={""} />} />
    </Route>
  );
};
