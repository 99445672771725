const content: { [key: string]: string } = {
  title: "Customer Type",
  subtitle: "Total:",
  counts: "Counts",
  legendPreferredCustomerText: "Preferred Customer",
  legendPreferredCustomerValue: "({COUNT}) {PERCENTAGE}%",
  legendGuestCustomerText: "Guest Customer",
  legendGuestCustomerValue: "({COUNT}) {PERCENTAGE}%",
  legendSharebarCustomerText: "Sharebar Customer",
  legendSharebarCustomerValue: "({COUNT}) {PERCENTAGE}%",
};

export default content;
