import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Requirement, BronzeGroupTrackingApiData, AboTileData } from "../../common/interfaces/bronzeGroupTracking";
import { openProfileModal } from "../../reducers/BronzeGroupTracking/bronzeGroupTracking";
import { useAppDispatch, useAppSelector } from "../../store";
import LeafTag from "../../common/leaf/LeafTag/LeafTag";
import { BRONZE_TAGS } from "../../reducers/model/bgtFilters";
import defaultContent from "../../content/bronzeGroupTracking";
import { isProgramActiveBulkCheck } from "../../common/util/checkProgramActiveStatus";
import { AWARD_CODES } from "../../common/enums/award-codes";
import { bgtOpenMiniProfileClickActionTrack } from "../../common/tealium/BronzeGroupTracking/BronzeGroupTracking";
import { BGT_TABS } from "../../common/enums/bronze-group-tracking-enums";
import { EligibilityGroup } from "../../common/interfaces/bgtMiniProfile";
import defaultUserContent from "../../content/leafAboName";
import { storedLocale } from "../impersonation/util";
import { checkUpperCaseEqual } from "../../common/util/common";

export const isReq = (requirements: Requirement, req: { requirement: string; requirementType: string }) => {
  return (
    checkUpperCaseEqual(requirements.requirement, req.requirement) &&
    checkUpperCaseEqual(requirements.requirementType, req.requirementType)
  );
};

export const returnDefaultBronzeTrackingData = (): BronzeGroupTrackingApiData => {
  return {
    requirementFilters: [],
    restartIsActive: true,
    awardProgress: {
      trackingTilesData: [],
      graduatedTilesData: [],
    },
    errors: [],
  };
};

interface BGTListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  children: ReactNode;
  aboTileData: AboTileData;
  key: number;
}

export const BGTListItem = (props: BGTListItemProps) => {
  const { children, aboTileData, ...htmlAttributes } = props;
  const dispatch = useAppDispatch();
  const displayProfileModal = () => {
    dispatch(openProfileModal(aboTileData));
  };

  const { activeView } = useAppSelector((state) => state.bronzeGroupTrackingTab);

  const handleRowClicked = () => {
    displayProfileModal();
    bgtOpenMiniProfileClickActionTrack(activeView);
  };

  const handleRowEnter = (e: React.KeyboardEvent<HTMLLIElement>) => {
    if (e.key === "Enter") {
      displayProfileModal();
    }
  };

  return (
    <li tabIndex={0} onClick={handleRowClicked} onKeyDown={handleRowEnter} {...htmlAttributes}>
      {children}
    </li>
  );
};

const LeafTagColor = (tag: string) => {
  switch (tag) {
    case BRONZE_TAGS.CLOSE:
      return "lightBlue";
    case BRONZE_TAGS.NEW:
      return "medOrange";
    case BRONZE_TAGS.ACHIEVED:
      return "medGreen";
    case BRONZE_TAGS.IN_WINDOW:
      return "medRed";
    default:
      return "medOrange";
  }
};

export const BGTTags = (tag: string, restart = false, specialHide = false) => {
  const { t } = useTranslation(["bronzeGroupTracking"]);

  if (restart) {
    return (
      <LeafTag key={tag} variant="lightYellow" hideTag={!restart || specialHide}>{`${t(
        `restarted`,
        defaultContent["restarted"],
      )}`}</LeafTag>
    );
  }

  const tagColor = LeafTagColor(tag);
  return (
    tag.length > 0 && (
      <LeafTag key={tag} hideTag={!tag || specialHide} variant={tagColor}>{`${t(
        `${tag}`,
        defaultContent[tag],
      )}`}</LeafTag>
    )
  );
};

export const useBronzeIncentiveStatus = () => {
  const [isBronzeFoundationActive, setIsBronzeFoundationActive] = useState<boolean>(false);
  const [isBronzeBuilderActive, setIsBronzeBuilderActive] = useState<boolean>(false);
  const { eligibleIncentives } = useAppSelector((state) => state.boot.shellConfig);

  useEffect(() => {
    const bronzeFoundation = isProgramActiveBulkCheck(eligibleIncentives, [
      AWARD_CODES.BRONZE_FOUNDATION,
      AWARD_CODES.RESTART_BRONZE_FOUNDATION,
      AWARD_CODES.RESTART_TWO_BRONZE_FOUNDATION,
    ]);
    const bronzeBuilder = isProgramActiveBulkCheck(eligibleIncentives, [
      AWARD_CODES.BRONZE_BUILDER,
      AWARD_CODES.RESTART_BRONZE_BUILDER,
      AWARD_CODES.RESTART_TWO_BRONZE_BUILDER,
    ]);

    setIsBronzeFoundationActive(bronzeFoundation);
    setIsBronzeBuilderActive(bronzeBuilder);
  }, [eligibleIncentives]);

  return { isBronzeFoundationActive, isBronzeBuilderActive };
};

export const returnDefaultBgtMiniProfileData = () => {
  return {
    bronzeFoundation: {
      firstTime: {
        graduationDate: null,
        eligibilityWindow: {
          startPeriod: 0,
          endPeriod: 0,
        },
        opportunitiesRemaining: 0,
        achievedMonths: 0,
        missedMonths: 0,
      },
      restartOne: {
        graduationDate: null,
        eligibilityWindow: {
          startPeriod: 0,
          endPeriod: 0,
        },
        opportunitiesRemaining: 0,
        achievedMonths: 0,
        missedMonths: 0,
      },
      restartTwo: {
        graduationDate: null,
        eligibilityWindow: {
          startPeriod: 0,
          endPeriod: 0,
        },
        opportunitiesRemaining: 0,
        achievedMonths: 0,
        missedMonths: 0,
      },
    },
    bronzeBuilder: {
      firstTime: {
        graduationDate: null,
        eligibilityWindow: {
          startPeriod: 0,
          endPeriod: 0,
        },
        opportunitiesRemaining: 0,
        achievedMonths: 0,
        missedMonths: 0,
      },
      restartOne: {
        graduationDate: null,
        eligibilityWindow: {
          startPeriod: 0,
          endPeriod: 0,
        },
        opportunitiesRemaining: 0,
        achievedMonths: 0,
        missedMonths: 0,
      },
      restartTwo: {
        graduationDate: null,
        eligibilityWindow: {
          startPeriod: 0,
          endPeriod: 0,
        },
        opportunitiesRemaining: 0,
        achievedMonths: 0,
        missedMonths: 0,
      },
    },
    errors: [],
  };
};

export const getLatestGradDate = (dataSets: EligibilityGroup[], selectedTab: string) => {
  if (selectedTab === BGT_TABS.TRACKING_TAB || !dataSets) return "";
  const gradDates: number[] = [];
  dataSets.forEach((dataSet) => {
    if (dataSet.graduationDate) gradDates.push(dataSet.graduationDate);
  });

  return gradDates.length ? Math.max(...gradDates).toString() : "";
};

export const GetABODisplayName = ({
  aboName,
  aboLocalName,
  aboIsoLanguage,
}: {
  aboName: string;
  aboLocalName: string;
  aboIsoLanguage: string;
}) => {
  const { t } = useTranslation(["leafAboName"]);
  const locale = storedLocale();
  const confidentialUser = t("ConfidentialUser", defaultUserContent["ConfidentialUser"]);
  const language = locale.slice(0, 2);
  const preferredName = aboLocalName && aboIsoLanguage === language ? aboLocalName : aboName;
  const displayName = preferredName && preferredName.length > 0 ? preferredName : confidentialUser;

  return <>{displayName}</>;
};

export const resetEligMonths = (eligGroup: EligibilityGroup): EligibilityGroup => {
  return {
    ...eligGroup,
    opportunitiesRemaining:
      eligGroup && eligGroup.opportunitiesRemaining + eligGroup.achievedMonths + eligGroup.missedMonths,
    achievedMonths: 0,
    missedMonths: 0,
  };
};
