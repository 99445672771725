const content: { [key: string]: any } = {
  pageTitle: {
    personalGroupGrowthIncentive: "Personal Group Growth Incentive+",
  },
  bannerTile: {
    keepGoing: "Keep going to earn the Personal Group Growth Incentive+!",
    notTracking:
      "You are not currently tracking to achieve the Personal Group Growth Incentive+ for PY{performanceYear}.",
    description:
      "Earn extra on the way to Founders and beyond. At the end of the Performance Year, the Personal Group Growth Incentive+ rewards you with a Performance Bonus multiplier based on the months you qualified.",
    potentialEarnings: "Potential PY{performanceYear} Earnings",
    incentiveCap: "Incentive is capped at {bonusCap}",
    contactCustomerService: "If you require more information, please contact Customer Support for further details.",
    held: "Your award is on hold.",
    denied: "Your PY{performanceYear} business is not eligible for the Personal Group Growth+ Incentive.",
    earned: "Great Job! You are earning the Personal Group Growth Incentive+.",
    tracking: "Keep going to earn the Personal Group Growth+!",
    manuallyApproved: "The Personal Group Growth+ has been manually approved.",
    noLongerEligible: "It is no longer possible for you to achieve the Personal Group Growth+ for PY{performanceYear}.",
    newPY: "The year ahead belongs to you",
    newPYDesc:
      "Happy new Performance Year! Watch this space: information for the new performance year is coming soon. Updated information will become available when last performance year’s baselines have been confirmed.",
  },
  progressTile: {
    title: "Year to Date Totals",
    pqStatus: "<1>PY{perfYear}</1> <2>PQ Status</2>",
    spMonths: "<1>SP </1> <2>Months</2>",
    rubyPv: "<1>Ruby</1> <2>PV</2>",
    requirements: "View Requirements",
  },
  incentiveCalculation: {
    sectionHeader: "How is this incentive calculated?",
    paymentBase: "Payment Base Total",
    multiplier: "Current Income Multiplier",
    potential: "Potential PY{perfYear} Personal Group Growth Incentive+",
    showDetails: "Show Incentive Calculation Details",
    hideDetails: "Hide Incentive Calculation Details",
    pqMonths: "{pqMonths} PQ Months",
    pqBase: "PQ Base",
    pqStatus: "PY{perfYear} PQ Status",
    trackingPQMonths: "{trackingPQMonths} PQ Months",
    trackingPQMonthsStatus: "PY{perfYear} Tracking PQs",
  },
  requirementsModal: {
    title: "Requirements",
    rubyPv: "Min. Annual Ruby PV",
    pqMonth: "PQ Month",
    spMonth: "SP Months",
    requirements: "Requirements",
  },
  ytdTotals: {
    title: "Year to Date Totals",
    performanceYear: "PY{perfYear}",
    perfTotal: "Performance Bonus Total",
  },
  ytdTotalsModal: {
    title: "YTD Totals",
    performanceYear: "PY{perfYear}",
    spMonths: "SP Months",
    rubyPv: "Ruby PV",
    performanceBonusTotal: "Performance Bonus Total",
  },
  multiplierMatrix: {
    potentialMultiplier: "Potential Multiplier = {multiplier}",
    maintain: "Maintain ({max})",
    growMax: "Grow ({max}+)",
    growRange: "Grow({start}-{end})",
    q1q5: "Q1-Q5",
    q1q6: "Q1-Q6",
    q6q12: "Q6-Q12",
    q7q11: "Q7-Q11",
    q12: "Q12",
    doesNotMaintain: "Does Not Maintain FQ",
    doesMaintain: "Maintains FQ*",
    booster1:
      "*In order to earn this extra kicker multiplier, you must maintain or grow your FQ from prior year and have a minimum 120,000 NTD in Leadership Bonus",
    booster2:
      "*In order to earn this extra kicker multiplier, you must have 12 countable Q months, maintain or grow your FQ from prior year, and have a minimum 120,000 Ruby PV",
  },
  spMonths: "SP Months",
  rubyPV: "Ruby PV",
};

export default content;
