// @ts-expect-error
import { init } from "@amwaycommon/acc-boot/acc-boot";
import { formatAboPV } from "@amwaycommon/acc-mixins/l10n";
import { merge } from "lodash";
import { fetchConfig, regionalConfigPath, storedIsoCountry } from "../../common/util/config";
import { store } from "../../store";

/**
 * Holds fetch() actions that request can be used by multiple instances
 */
const CONTENT_FETCH_CACHE: any = {};

/**
 * Holds global promise for init.
 * @returns {Promise}
 */
export function initCoreplusStore() {
  // if for some reason init isn't on global yet then set and return it
  if (!window.initCoreplus) {
    window.initCoreplus = init({
      setup: {
        globalStore: true,
        globalName: "accBoot",
        bypassLocaleInit: true,
      },
      api: {
        path: regionalConfigPath(),
      },
    });
  }

  return window.initCoreplus;
}

export const getValues = async () => {
  await initCoreplusStore();

  return {
    configuration: store.getState().boot.configuration,

    aff: getAff(),

    abo: getAbo(),

    user: store.getState().boot.user,
  };
};

/**
 * Returns locale from redux store
 * @returns {string}
 */
export const storedLocale = (): string => {
  return store.getState().loginLocalStorage.loginFields.locale || "en-US";
};

/**
 * Returns marketCode from redux store
 * @returns {string}
 */
export const marketCode = () => {
  return store.getState().boot.user.isoCountryCode || "US";
};

/**
 * Returns aff from store.
 * @returns {string}
 */
export const getAff = () => {
  return store.getState().boot.user.aff;
};

/**
 * Returns formattedAboPV
 * @returns {string}
 */
export const formattedPV = (pv: number): string => {
  const locale = storedLocale();
  const market = marketCode();
  return formatAboPV(pv, locale, market);
};

/**
 * Returns abo from store.
 * @returns {string}
 */
export const getAbo = () => {
  return store.getState().boot.user.abo;
};

/**
 * Inits and returns aff and abo from store.
 * @returns {object}
 */
export const getAffAbo = async () => {
  await initCoreplusStore();
  return {
    aff: getAff(),
    abo: getAbo(),
  };
};

/**
 * Returns defaultLos from localStorage
 * @returns {string}
 */
export const storedDefaultLos = () => {
  return sessionStorage.getItem("defaultLos") || "los";
};

/**
 * Attempts to fetch content with the given parameters. If the fetch fails,
 * this function returns the fallback content.
 * @param {*} page - Page Id, used to build content path
 * @param {*} ssoOrigin - Single sign on origin, used to build content path
 * @param {*} locale - Locale, used to build content path
 * @param {*} fallback - Default content, returned if fetch fails
 */

export const fetchContent = async (page: string, ssoOrigin: string, locale: string, fallback = {}) => {
  // Build content path
  const fetchedConfig = await fetchConfig();
  let contentBase;
  if (fetchedConfig) contentBase = fetchedConfig.contentUrlBase;
  const contentPath = `${contentBase}/${ssoOrigin}/text/${page}/${locale}.json`;

  let responseFound;

  // Find if we have a request in the cache that we could use
  if (contentPath in CONTENT_FETCH_CACHE) {
    responseFound = CONTENT_FETCH_CACHE[contentPath];
  } else {
    // Fetch a new request
    responseFound = {
      request: fetch(contentPath),
    };

    // Setting new request into the cache
    CONTENT_FETCH_CACHE[contentPath] = responseFound;
  }

  try {
    const response = await responseFound.request;

    const data = await response.clone().json();

    return merge(fallback, data);
  } catch (e) {
    return fallback;
  }
};

/**
 * This function global user id
 * @param {String} aff - affiliate number.
 * @param {String} abo - abo number.
 * @returns {String} global id.
 */
export const _createGlobalUserID = (aff: string, abo: string) => {
  const padLen = 11;
  const padChar = "0";
  const arrayLen = padLen + 1 - abo.length;
  return aff + Array(arrayLen).join(padChar) + abo;
};

/**
 * Adds a log entry to a session variable for
 * the purpose of debugging user issues.
 */
export const sessionLog = (entry: string) => {
  const sessionKey = "_log";
  const previousLog = sessionStorage.getItem(sessionKey) || "";
  const date = new Date().toUTCString();
  const page = window.location.pathname;
  sessionStorage.setItem(sessionKey, `${previousLog} [${date} ${page}] ${entry}`);
};

/**
 * stringToKebabTransform
 *
 * Converts a string to kebab case
 * @param {string} input
 * @returns string
 */
export const stringToKebabTransform = (input: any) => {
  // regex performs some positive lookaround to look for locations to add a '-',  one of the following conditions exist
  // 1. add a '-' if previous character is lower case, current is upper
  // 2. add a '-' if previous is upper, current is upper, next is lower (JSONParser -> json-parser)
  // ** has some limitations (e.g. JSONAPI -> jsonapi, not json-api)
  const kebabStr =
    (input &&
      input
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map((x: any) => x.toLowerCase())
        .join("-")) ||
    input;

  return kebabStr;
};

// Transform enum
export const AttributeTransform = {
  kebab: stringToKebabTransform,
};

Object.freeze(AttributeTransform);
