import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/recentOrderItem";
import "./RecentOrderItem.scss";
import LeafDateTime from "../../../../../common/leaf/LeafDateTime/LeafDateTime";
import { LeafPV } from "../../../../../common/leaf/LeafFormat/LeafPV/LeafPV";
import LeafABOName from "../../../../../common/leaf/LeafProfile/LeafABOName/LeafABOName";
import { CustomerOrder } from "../../../../../reducers/model/order";
import OrderType from "../../../OrderHistory/OrderHistoryTable/OrdersByPeriod/Order/OrderType/OrderType";
import { isProgramActive } from "../../../../../common/util/checkProgramActiveStatus";
import { AWARD_CODES } from "../../../../../common/enums/award-codes";
import { EligibleIncentive } from "../../../../../reducers/model/shellConfig";

type Props = {
  customerOrder: CustomerOrder;
  orderIndex: number;
  showVCSOrderTag: boolean;
  eligibleIncentives: EligibleIncentive[];
};

const RecentOrderItem = (props: Props) => {
  const { customerOrder, orderIndex, showVCSOrderTag, eligibleIncentives } = props;
  const { orderNumber, date, pv, vcsFlag, name, localName, privacyFlag, isoLanguageCode, vcsReasons, source } =
    customerOrder;

  const { t } = useTranslation(["recentOrderItem"]);

  return (
    <li className="recentOrderItem" key={orderNumber}>
      <div className="recentOrderItem__div-infoDiv">
        <div className="recentOrderItem__div-info">
          <div className="recentOrderItem__date">
            <LeafDateTime
              date={date}
              options={{ month: "long", day: "numeric", year: "numeric" }}
              ignoreTimeZone={true}
            />
          </div>
          <div className="recentOrderItem__orderNumber">
            {" "}
            {t("orderNumber", defaultContent["orderNumber"])} {`#${orderNumber}`}{" "}
          </div>
        </div>

        <div className="recentOrderItem__div">
          <div className="recentOrderItem__name">
            <LeafABOName name={name} localName={localName} privacyFlag={privacyFlag} languageCode={isoLanguageCode} />
          </div>
        </div>

        <div className="recentOrderItem__div">
          <div className="recentOrderItem__vcs">
            <OrderType
              vcsFlag={vcsFlag}
              source={source}
              vcsReasons={vcsReasons}
              isCompact={true}
              orderIndex={orderIndex}
              orderDate={date}
              showVCSOrderTag={isProgramActive(eligibleIncentives, AWARD_CODES.CSI, showVCSOrderTag)}
            />
          </div>
          <div className="recentOrderItem__vcsPV">
            <LeafPV pv={pv} />
            <span className="recentOrderItem__pvText">
              {vcsFlag === true ? t("vcsPvText", defaultContent["vcsPvText"]) : t("pvText", defaultContent["pvText"])}
            </span>
          </div>
        </div>
      </div>
    </li>
  );
};

export default RecentOrderItem;
