import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

export const foundersPlatinumProgressAPI = createApi({
  reducerPath: "foundersPlatinumProgressAPI",
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const { user, configuration } = (getState() as RootState).boot;
      if (user?.access_token) {
        headers.set("Authorization", `Bearer ${user.access_token}`);
        headers.set("x-mashery-domain", configuration.requestUrlBase);
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getFoundersPlatinumProgress: builder.query({
      query: (params) => ({
        url: `${params.baseUrl}/coreplus/foundersplatinumprogress?aboNo=${params.aboNo}&affNo=${params.affNo}&fiscalYear=${params.fiscalYear}&period=${params.period}`,
      }),
    }),
  }),
});

export const { useGetFoundersPlatinumProgressQuery } = foundersPlatinumProgressAPI;
