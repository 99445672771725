import "./LeafBackdrop.scss";

type Props = {
  variant?: "invisible" | "";
  handleBackdropClick: Function;
};
export const LeafBackdrop = ({ variant, handleBackdropClick }: Props) => {
  const baseStyle = "leafBackdrop";
  const variantStyle = ` ${baseStyle}--${variant}`;
  const classname = variant ? baseStyle + variantStyle : baseStyle;
  return <div className={classname} onClick={() => handleBackdropClick()} data-testid="leaf-backdrop"></div>;
};
