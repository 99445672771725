import { useEffect, useState } from "react";
import { useAppSelector } from "../../../store";
import {
  useGetFGGBaselineMultiplierDetailsQuery,
  useGetFGGMultiplierDetailsQuery,
} from "../../../services/frontlineGroupGrowthAPI";
import { storedLocale } from "../../impersonation/util";
import {
  FGGMultiplierDetailsBaselineResponse,
  FGGMultiplierDetailsResponse,
} from "../../../common/interfaces/frontlineGroupGrowth";
import { lambdaErrorCheck } from "../../../common/util/errorHandling";

export const FetchMultiplerBaselineData = (period: string, skipRequest: boolean) => {
  const { user } = useAppSelector((state) => state.boot);

  const {
    data: payloadData,
    isFetching,
    isError,
    refetch,
  } = useGetFGGBaselineMultiplierDetailsQuery(
    {
      abo: user.abo,
      aff: user.aff,
      period: period,
      locale: storedLocale(),
    },
    { skip: skipRequest },
  );

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [baselineMultiplierData, setBaselineMultiplierData] = useState<FGGMultiplierDetailsBaselineResponse>({
    BaselineFqLeg: [],
    QualTotals: {
      LastPyQualCount: 0,
      SecondLastPYQualCount: 0,
      AverageQualCount: 0,
    },
    CallResults: [],
    Error: "",
  });

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (!isFetching && !isError && payloadData) {
      setBaselineMultiplierData(payloadData);
      const foundLambdaError = lambdaErrorCheck(payloadData.Error);
      setErrorStatus(foundLambdaError);
    }
  }, [isFetching, period, isError, payloadData]);

  return { isLoading, baselineMultiplierData, errorStatus, refetch };
};

export const FetchMultiplerDetailsData = (period: string, skipRequest: boolean) => {
  const { user } = useAppSelector((state) => state.boot);
  const {
    data: payloadData,
    isFetching,
    isError,
    refetch,
  } = useGetFGGMultiplierDetailsQuery(
    {
      abo: user.abo,
      aff: user.aff,
      period: period,
      locale: storedLocale(),
    },
    { skip: skipRequest },
  );

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [multiplierDetailsData, setMultiplierDetailsDataData] = useState<FGGMultiplierDetailsResponse>({
    FqLegs: [],
    CallResults: [],
    Error: "",
  });

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (!isFetching && !isError && payloadData) {
      setMultiplierDetailsDataData(payloadData);
      const foundLambdaError = lambdaErrorCheck(payloadData.Error);
      setErrorStatus(foundLambdaError);
    }
  }, [isFetching, period, isError, payloadData]);

  return { isLoading, multiplierDetailsData, errorStatus, refetch };
};
