const content: { [key: string]: string } = {
  browserNotSupported: "Browser Not Supported",
  browserBanner: "For an optimal experience, we recommend switching to a supported browser version.",
  readMoreLink: "Read More",
  bannerModal:
    "For an optimal experience, we recommend switching to the latest version of one of the following supported browsers",
  chrome: "Chrome",
  safari: "Safari",
  firefox: "Firefox",
  edge: "Edge",
};

export default content;
