import { IconCheckCircle } from "../../../icons/IconCheckCircle/IconCheckCircle";
import { Requirement } from "../../../interfaces/bronzeGroupTracking";
import "./LeafCheckmarksBase.scss";

interface LeafCheckmarksBaseProps extends React.ComponentProps<"div"> {
  requirements: Requirement[];
  displayOrder?: Partial<Requirement>[];
}
/**
 * Displays checkmarks for each requirement based on the met property
 * @param {Requirement[]} requirements - Array of Requirements
 * @param displayOrder - Array of order to display requirements
 */
export const LeafCheckmarksBase = ({ requirements, displayOrder }: LeafCheckmarksBaseProps) => {
  if (displayOrder && displayOrder.length > 0) {
    const findDisplaySequence = (req: Partial<Requirement>) => {
      const index = displayOrder.findIndex(
        ({ requirement, requirementType }) =>
          requirement === req.requirement && requirementType === req.requirementType,
      );

      // if requirement is not found in the sort sequence, place at the end
      return index >= 0 ? index : displayOrder.length + 1;
    };
    requirements.sort((reqA, reqB) => findDisplaySequence(reqA) - findDisplaySequence(reqB));
  }

  return (
    <section className="leaf-checkmarks-base__checkmarks">
      {requirements.map((req) => (
        <IconCheckCircle
          key={req.requirement}
          id={req.requirement}
          color={req.met ? "green" : "gray"}
          size="sm"
          title={req.text}
        />
      ))}
    </section>
  );
};
