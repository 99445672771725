import { formatCurrency } from "@amwaycommon/acc-mixins/l10n";
import "./PtbBannerTile.scss";
import { Trans, useTranslation } from "react-i18next";
import defaultContent from "../../../../content/newAboActivation";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import { storedLocale } from "../../../impersonation/util";
import { PathToBronzeData } from "../../../../common/interfaces/pathToBronze";
import { LeafColorBlock } from "../../../../common/leaf/LeafColorBlock/LeafColorBlock";
import { useAppSelector } from "../../../../store";
import { getLegByLegNumber } from "../pathToBronzeUtils";
import { LeafLearnMoreLink } from "../../../../common/leaf/LeafLearnMoreLink/LeafLearnMoreLink";
import { getFullMonth } from "../../../../common/util/period";
import { learnMoreOnClick } from "../../../../common/tealium/BronzeIncentive/PathToBronze/LearnMoreButtonTrack";

type Props = {
  data: PathToBronzeData;
  isLoading: boolean;
  selectedPeriod: string;
  extraBonusesMet: number;
};

export const PtbBannerTile = ({ data, isLoading, selectedPeriod, extraBonusesMet }: Props) => {
  const { t } = useTranslation(["newAboActivation"]);
  const locale = storedLocale();
  const {
    earning,
    denied,
    manualApproval,
    held,
    bonus,
    bonusCurrency,
    legRequirements,
    personalVolume,
    perfBonusLevel,
    country,
  } = data;
  const { configuration } = useAppSelector((store) => store.boot);
  const learnMoreLink = configuration.dashboardLinks?.learnMore?.pathToBronze ?? "";
  const bonusAmountToFormat = earning || held || manualApproval ? bonus : 0;
  const firstLegReq = getLegByLegNumber(legRequirements, 1);
  const maxBonus = getLegByLegNumber(legRequirements, legRequirements.length);
  const formattedBonusAmount = formatCurrency(bonusAmountToFormat, bonusCurrency, locale, country, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: "currency",
  });
  const maxBonusAvailable = formatCurrency(maxBonus.bonusAmount, bonusCurrency, locale, country, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: "currency",
  });

  const __getAllReqsMetStatus = () => {
    if (firstLegReq && personalVolume && perfBonusLevel) {
      return (firstLegReq.met && personalVolume.met && (perfBonusLevel.met || !perfBonusLevel.required)) || false;
    }
    return false;
  };
  const allReqsMet = __getAllReqsMetStatus();

  const __getBannerTileText = () => {
    if (denied || earning || (!earning && !held && !denied && !manualApproval)) {
      return (
        <div className="leaf-block">
          <LeafColorBlock>
            <div>{`${t("aboActivationLabel", defaultContent["aboActivationLabel"])}`}</div>
            <div className="lower-content">{formattedBonusAmount}</div>
          </LeafColorBlock>
        </div>
      );
    }
    return <></>;
  };

  const __getDescription = () => {
    if ((!manualApproval && !denied && !held && !extraBonusesMet) || !maxBonus.met) {
      return (
        <div className="PtbBannerTile__formatBonusAmount">
          <p className="description">{`${t(
            "downlineGrowthDescription",
            defaultContent["downlineGrowthDescription"],
          )}`}</p>
          <p>
            <Trans>
              {`${t("bonusAmountDescription", defaultContent["bonusAmountDescription"], {
                amount: maxBonusAvailable,
              })}`}
              <span></span>
            </Trans>
          </p>
        </div>
      );
    }
    if (manualApproval || denied || held) {
      return <>{t("customerCareContactDescription", defaultContent["customerCareContactDescription"])}</>;
    }
    if (__getAllReqsMetStatus()) {
      return <>{t("downlineGrowthDescription", defaultContent["downlineGrowthDescription"])}</>;
    }
    if (earning) {
      return <>{t("downlineGrowthDescription", defaultContent["downlineGrowthDescription"])}</>;
    }
  };

  const __getTitle = () => {
    let contentString = "";
    if (manualApproval) {
      contentString = "manuallyApprovedIncentiveTitle";
    } else if (denied) {
      return t("deniedIncentiveTitle", defaultContent["deniedIncentiveTitle"], {
        month: getFullMonth(selectedPeriod, locale),
      });
    } else if (held) {
      contentString = "onHoldIncentiveTitle";
    } else if (earning && !extraBonusesMet) {
      contentString = "earningIncentiveTitle";
    } else if (allReqsMet && earning) {
      contentString = "allReqsMet";
    } else if (!earning && !held && !denied && !manualApproval) {
      contentString = "notEarningIncentiveTitle";
    }
    return t(`${contentString}`, defaultContent[contentString]);
  };

  return (
    <LeafTile
      title={`${__getTitle()}`}
      description={__getDescription()}
      isLoading={isLoading}
      showMetStatus={false}
      circleCheckmarkId=""
    >
      {!denied && __getBannerTileText()}
      {learnMoreLink && (
        <div className="PtbBannerTile__learnMoreLink" data-testid="ptb-learn-more-link">
          <LeafLearnMoreLink href={learnMoreLink} onClickFn={learnMoreOnClick} />
        </div>
      )}
    </LeafTile>
  );
};
