import "./ABOProfileBody.scss";
import ABOProfileHeader from "./ABOProfileHeader/ABOProfileHeader";
import ABOProfilePrimaryApplicantCard from "./ABOProfilePrimaryApplicantCard/ABOProfilePrimaryApplicantCard";
import ABOProfileTabViews from "./ABOProfileTabViews/ABOProfileTabViews";
import ABOProfileApplicantCarousel from "./ABOProfileApplicantCarousel/ABOProfileApplicantCarousel";

type Props = {
  isShowProfile: boolean;
};

const ABOProfileBody = (props: Props) => {
  const { isShowProfile } = props;

  if (isShowProfile === false) {
    return <></>;
  }

  return (
    <>
      <div className="abo-profile-body">
        <div className="abo-profile-body__abo-info">
          <div className="abo-profile-body__abo-info__header">
            <ABOProfileHeader />
          </div>

          <div className="abo-profile-body__abo-info__primary-applicant">
            <ABOProfilePrimaryApplicantCard />
          </div>

          <div className="abo-profile-body__abo-info__applicant-carousel">
            <ABOProfileApplicantCarousel />
          </div>
        </div>

        <div className="abo-profile-body__tabs">
          <ABOProfileTabViews />
        </div>
      </div>
    </>
  );
};

export default ABOProfileBody;
