import { useTranslation } from "react-i18next";
import { IconBack } from "../../../common/icons/IconBack/IconBack";
import defaultContent from "../../../content/aboApplicantSlider";
import commonContent from "../../../content/common";
import { useAppDispatch, useAppSelector } from "../../../store";
import { hideApplicant } from "../../../reducers/profile";
import LeafPanel from "../../../common/leaf/LeafPanel/LeafPanel";
import ABOApplicantBody from "./ABOApplicantBody/ABOApplicantBody";

const ABOApplicantSlider = () => {
  const { t } = useTranslation(["aboApplicantSlider", "common"]);
  const isShowApplicant = useAppSelector((state) => state.profile.isShowApplicant);
  const dispatch = useAppDispatch();

  const closeApplicant = () => {
    dispatch(hideApplicant());
  };

  return (
    <LeafPanel
      title={t("applicants", defaultContent["applicants"])}
      show={isShowApplicant}
      onCloseClick={closeApplicant}
      id={"abo-applicant-slider"}
      exitIcon={<IconBack width="0.8125rem" height="1.25rem" />}
      exitIconAriaLabel={`${t("backButton", commonContent["backButton"])}`}
      background="veryLightGrey"
      lockBackgroundScrolling={false}
    >
      <ABOApplicantBody isShowApplicant={isShowApplicant} />
    </LeafPanel>
  );
};

export default ABOApplicantSlider;
