import "./WheelSpinner.scss";
import { useTranslation } from "react-i18next";
import defaultContent from "../../../content/common";
import { WheelSpinnerColumn } from "./WheelSpinnerColumn";

export type WheelSpinnerProps = {
  column: Array<string>;
  selectedValue: string;
  onChange: CallableFunction;
  onSave: CallableFunction;
  isOpen: boolean;
  formatter: Function;
};

export const WheelSpinner = ({
  column: columnItems,
  selectedValue: selectedValues,
  onChange,
  onSave,
  isOpen,
  formatter,
}: WheelSpinnerProps) => {
  const { t } = useTranslation(["common"]);
  return (
    <div className="wheel-container">
      <div className="wheel-container__wheel">
        <WheelSpinnerColumn
          options={columnItems}
          value={selectedValues}
          onChange={onChange}
          isOpen={isOpen}
          formatter={formatter}
        />
      </div>

      <div className="wheel-container__selected-row"></div>

      <div className="wheel-container__footer">
        <button
          className="wheel-container__save-date"
          id="wheel-container__save-date"
          tabIndex={0}
          onClick={() => onSave()}
          aria-label={`${t("setButtonText", defaultContent["setButtonText"])}`}
        >
          {`${t("setButtonText", defaultContent["setButtonText"])}`}
        </button>
      </div>
    </div>
  );
};
