import "./LeafCard.scss";
import { IconCarrot } from "../../icons/IconCarrot/IconCarrot";

type Props = {
  abo: string;
  country: string;
  clickAction: Function;
  id: string;
};

export const LeafCard = ({ abo, country, clickAction, id }: Props) => {
  return (
    <div data-testid={id} id={id} className="leaf-card" onClick={() => clickAction()}>
      <div className="leaf-card__info">
        <div className="leaf-card__info-title">{country}</div>
        <div className="leaf-card__info-content">{abo}</div>
      </div>
      <div className="leaf-card__navigation">
        <div className="leaf-card__navigation-arrow">
          <IconCarrot orientation="right" color="black" />
        </div>
      </div>
    </div>
  );
};
