export interface SortOption {
  id: string;
  order: string;
  selected: boolean;
  value: string;
}

export interface SortState {
  sortValue: string;
  sortOrder: string;
  sortBy: SortOption[];
  sortByLastUpdateAt?: number;
}

export const SORTBY_LAST_ORDER_DATE = "LAST_ORDER_DATE";
export const SORTBY_TOTAL_CUSTOMER_PV = "TOTAL_CUSTOMER_PV";
export const SORTBY_ORDER_DATE = "ORDER_DATE";
export const SORTBY_ORDER_PV = "ORDER_PV";
export const SORTBY_ASC_ORDER = "ASC";
export const SORTBY_DESC_ORDER = "DESC";
export const SORTBY_TOTAL_CUSTOMER_ORDER = "TOTAL_CUSTOMER_ORDER";

export const getUpdatedSortOptions = (sortList: SortOption[], option: SortOption) => {
  return sortList.map((sortOption) => ({
    ...sortOption,
    selected: sortOption.id === option.id,
  }));
};

export const setDefaultSelected = (sortBy: SortOption[]) => {
  return sortBy.map((sortOption, index) => ({
    ...sortOption,
    selected: index === 0, // set first option to selected
  }));
};

export const getFirstOption = (sortBy: SortOption[]) => {
  const [firstOption] = sortBy;

  return {
    sortOrder: firstOption?.order || "",
    sortValue: firstOption?.value || "",
  };
};

export const getEnabledSortOptions = (sortBy: SortOption[], sortByConfig: string[]): SortState => {
  const enabledSortBy = sortBy.filter(({ id }) => sortByConfig.includes(id));

  const defaultSelected = setDefaultSelected(enabledSortBy);

  return {
    sortBy: defaultSelected,
    ...getFirstOption(defaultSelected),
    sortByLastUpdateAt: new Date().getTime(),
  };
};
