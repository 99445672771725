import { Navigate, Route } from "react-router-dom";
import { lazy } from "react";
import MBI from "../components/MBI/MBI";
const MBIMonthlyBonuses = lazy(() => import("../components/MBI/MBIMonthlyBonuses/MBIMonthlyBonuses"));
const ILBStatement = lazy(() => import("../components/MBI/ILBStatement/ILBStatement"));

export const MBI_BASE_PATH = "/mbi/";
export const MBI_MONTHLY = `monthly`;
export const MBI_ILB = `ilb`;

export const MBI_ROUTE_PATHS = [MBI_MONTHLY, MBI_ILB].map((route) => `${MBI_BASE_PATH}${route}`);

export const MBIRoutes = () => {
  return (
    <Route path={MBI_BASE_PATH} element={<MBI />}>
      <Route path={MBI_MONTHLY} element={<MBIMonthlyBonuses />} />
      <Route path={MBI_ILB} element={<ILBStatement />} />
      <Route path={MBI_BASE_PATH} element={<Navigate to={MBI_MONTHLY} />} />
    </Route>
  );
};
