import { useTranslation } from "react-i18next";
import LeafAdditionalRegInfo from "../../../../../../../common/leaf/LeafAdditionalRegInfo/LeafAdditionalRegInfo";
import defaultContent from "../../../../../../../content/aboProfileAdditionalInfo";
import "./ABOProfileAdditionalInfo.scss";
import { useAppSelector } from "../../../../../../../store";

type Props = {
  orderBlock: boolean;
  sponsorBlock: boolean;
  autoRenewal: boolean;
  contractSigned: boolean;
};

const ABOProfileAdditionalInfo = (props: Props) => {
  const { t } = useTranslation(["aboProfileAdditionalInfo"]);
  const profile = useAppSelector((state) => state.boot?.configuration?.profile);
  const {
    showAutoRenewal,
    showContractSigned,
    showOrderBlock,
    showSponsorBlock,
    displayStatusFormat = "true_false",
  } = profile?.additionalRegInfo || {};

  const { orderBlock, sponsorBlock, autoRenewal, contractSigned } = props;

  const showInfos = [showOrderBlock, showSponsorBlock, showAutoRenewal, showContractSigned];
  const noAdditionalInfo = showInfos.every((showInfo) => showInfo === false);

  if (noAdditionalInfo === true) return <></>;

  return (
    <div className="abo-profile-additional-info">
      <h2 className="abo-profile-additional-info__header">
        {t("additionalRegistrationInformation", defaultContent["additionalRegistrationInformation"])}
      </h2>
      <div className="abo-profile-additional-info__content">
        <LeafAdditionalRegInfo
          title="orderBlock"
          status={orderBlock}
          show={showOrderBlock}
          labelFormat={displayStatusFormat}
        />
        <LeafAdditionalRegInfo
          title="sponsorBlock"
          status={sponsorBlock}
          show={showSponsorBlock}
          labelFormat={displayStatusFormat}
        />
        <LeafAdditionalRegInfo
          title="autoRenewal"
          status={autoRenewal}
          show={showAutoRenewal}
          labelFormat={displayStatusFormat}
        />
        <LeafAdditionalRegInfo
          title="contractSigned"
          status={contractSigned}
          show={showContractSigned}
          labelFormat={displayStatusFormat}
        />
      </div>
    </div>
  );
};

export default ABOProfileAdditionalInfo;
