import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";
import { getAuthHeader } from "../reducers/model/boot";

// Create our baseQuery instance
const rawBaseQuery = fetchBaseQuery({
  prepareHeaders: (headers, { getState }) => {
    const rootState = getState() as RootState;
    const { authorization } = getAuthHeader(rootState);

    headers.set("Authorization", authorization);
    headers.set("Content-Type", "application/json");
    headers.set("x-mashery-domain", rootState.boot.configuration.requestUrlBase);
    return headers;
  },
});

const getBaseURL = (state: RootState, urlBase = "graphql") => {
  const { boot } = state;
  const { configuration } = boot;

  switch (urlBase) {
    case "graphql":
      return configuration.graphQLUrlBase;
    case "export":
      return configuration.exportsApiUrlBase;
    case "lambda":
      return configuration.lambdaUrlBase;
    case "shellLambda":
      return configuration.shellLambdaUrlBase;
    case "annualDashboard":
      return configuration.annualDashboardApiUrl;
    case "apiBaseUrl":
      return configuration.apiBaseUrl;
    default:
      return "";
  }
};

export const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  { urlBase?: string }
> = async (args, api, extraOptions) => {
  const state = api.getState() as RootState;
  const urlBase = extraOptions?.urlBase;
  const baseURL = getBaseURL(state, urlBase);

  // gracefully handle scenarios where data to generate the URL is missing
  if (!baseURL) {
    return {
      error: {
        status: 400,
        statusText: "Bad Request",
        data: "URLBase is not defined",
      },
    };
  }

  const urlEnd = typeof args === "string" ? args : args.url;
  // construct a dynamically generated portion of the url
  const adjustedUrl = `${baseURL}${urlEnd}`;
  const adjustedArgs = typeof args === "string" ? adjustedUrl : { ...args, url: adjustedUrl };
  // provide the amended url and other params to the raw base query
  return rawBaseQuery(adjustedArgs, api, extraOptions);
};

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const accAPI = createApi({
  reducerPath: "accAPI",
  baseQuery: dynamicBaseQuery,
  endpoints: () => ({}),
});
