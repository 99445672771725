import LeafApplicantCard from "../../../../../common/leaf/LeafProfile/LeafApplicant/LeafApplicantCard/LeafApplicantCard";
import LeafProfileLoader from "../../../../../common/leaf/LeafProfile/LeafProfileLoader/LeafProfileLoader";
import { showApplicant } from "../../../../../reducers/profile";
import { useGetProfileQuery } from "../../../../../services/profileAPI";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import {
  viewAllApplicantsClickTrack,
  cardActionClickTrack,
} from "../../../../../common/tealium/CustomerProfile/Profile/ApplicantCardTrack";

const CustomerApplicantCard = () => {
  const dispatch = useAppDispatch();

  const affAbo = useAppSelector((state) => state.profile.affAbo);
  const { selectedPeriod } = useAppSelector(({ period }) => period);

  const { isFetching, data } = useGetProfileQuery({ period: selectedPeriod, affAbo });
  const boot = useAppSelector(({ boot }) => boot);
  const profileInfo = boot?.configuration?.profile?.customerInfo || {};

  const viewAllApplicant = () => {
    dispatch(showApplicant());
    viewAllApplicantsClickTrack();
  };

  const applicantTrackOnclick = (event?: string) => {
    cardActionClickTrack(event, true);
  };

  if (isFetching === false && data?.aboInfo && data.partyInfo) {
    const { aboInfo, partyInfo } = data;
    return (
      <LeafApplicantCard
        aboInfo={aboInfo}
        partyInfo={partyInfo}
        viewAllLinkOnclick={viewAllApplicant}
        applicantTrackOnclick={applicantTrackOnclick}
        profileInfo={profileInfo}
      />
    );
  }

  return <LeafProfileLoader row={1} applicant={true} isFetching={isFetching} />;
};

export default CustomerApplicantCard;
