import "./ChartLegend.scss";

type ChartLegendProps = {
  id: string;
  color: string;
  text: string;
  value: string;
};

type Props = {
  legends: ChartLegendProps[];
};

const ChartLegend = (props: Props) => {
  const { legends } = props;

  return (
    <div className="chart-legend__legend-container">
      {legends.map((legend: ChartLegendProps) => (
        <div className="chart-legend__legend" key={legend.id}>
          <div className={`chart-legend__legend__${legend.color}`}>
            <svg viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="6" cy="6.00146" r="6" />
            </svg>
            <div className="chart-legend__text">{legend.text}</div>
          </div>
          <div className="chart-legend__text" data-testid={`${legend.id}-value`}>
            {legend.value}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChartLegend;
