import "./BonusBreakdownTile.scss";
import { useTranslation } from "react-i18next";
import defaultContent from "../../content/bonusBreakdown";
import { BonusBreakdownLineData } from "../../common/interfaces/monthlyPerformanceDash";
import { BonusBreakdownTileLine } from "./BonusBreakdownTileLine/BonusBreakdownTileLine";

type Props = {
  title: string;
  description?: string;
  subTitle: string;
  bonusSubTotalTitle: string;
  bonusSubTotalValue: string;
  bonusBreakdown: BonusBreakdownLineData[];
};

export const BonusBreakdownTile = ({
  title,
  description = "",
  subTitle,
  bonusSubTotalTitle,
  bonusSubTotalValue,
  bonusBreakdown,
}: Props) => {
  const { t } = useTranslation(["bonusBreakdown"]);
  const bold = "Bbt__bold";
  const dark = "Bbt__dark";

  let darkBackground = false;
  const lines = bonusBreakdown.map((item, index) => {
    darkBackground = !darkBackground;
    let givenClass = "";
    if (darkBackground) {
      givenClass += dark;
    }
    if (item) {
      givenClass += " " + bold;
    }
    return (
      <div key={"line" + index}>
        <BonusBreakdownTileLine
          givenClass={givenClass}
          id={"line" + index}
          title={item.incentiveTitle}
          tag={item.tealiumTag}
          amount={item.amount}
          link={item.link}
        />
        {item.group ? (
          item.group.map((i, ind) => {
            darkBackground = !darkBackground;
            givenClass = darkBackground ? dark + " " + bold : bold;
            return (
              <BonusBreakdownTileLine
                givenClass={givenClass}
                key={"line" + index + ind}
                id={"line" + index + ind}
                title={i.incentiveTitle}
                tag={item.tealiumTag}
                amount={i.amount}
                link={i.link}
                subsection={true}
                modalFn={i.modalFn}
              />
            );
          })
        ) : (
          <></>
        )}
      </div>
    );
  });

  const descr = description ? (
    <div className="Bbt__section-description">
      <span className="Bbt__section-description-ref">1</span>
      {description}
    </div>
  ) : (
    <></>
  );

  return (
    <div className="Bbt">
      <div className="Bbt__section-title">
        <h2>
          {title}
          {description ? <span className="Bbt__section-title-ref">1</span> : ""}
        </h2>
      </div>
      {descr}
      <div className="Bbt__section-subtitle">
        <div>{subTitle}</div>
        <div>{t("amount", defaultContent["amount"])}</div>
      </div>
      {lines}
      <div className="Bbt__total">
        <div>{bonusSubTotalTitle}</div>
        <div className="Bbt__total-amount">{bonusSubTotalValue}</div>
      </div>
    </div>
  );
};
