const content: { [key: string]: string } = {
  ppv: "Personal PV",
  gpv: "Group PV",
  rubyPV: "Ruby PV",
  leadershipPV: "Leadership PV",
  vcsPV: "VCS PV",
  vcsPercent: "VCS %",
  qcPV: "Qualified Customer PV",
  personalOrders: "Personal Orders",
  groupOrders: "Group Orders",
  newABOs: "New ABOs",
  totalABOs: "Group Size",
  newCustomers: "New Customers",
  customerOrders: "Customer Orders",
  totalCustomers: "Total Customers",
  title: "Business KPIs",
  performanceYear: "PY",
  noData: "There is no data available for the selected performance year.",
  kpiSelector: "KPI Selector",
  pySelector: "PY Selector",
  performanceBonus: "Performance Bonus",
};

export default content;
