import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setBoot } from "../boot";
import {
  getEnabledSortOptions,
  getFirstOption,
  getUpdatedSortOptions,
  setDefaultSelected,
  SORTBY_ASC_ORDER,
  SORTBY_DESC_ORDER,
  SORTBY_LAST_ORDER_DATE,
  SORTBY_TOTAL_CUSTOMER_PV,
  SORTBY_TOTAL_CUSTOMER_ORDER,
  SortOption,
  SortState,
} from "../model/sort";

const initialSortBy = [
  {
    id: "descLastOrderDate",
    order: SORTBY_DESC_ORDER,
    selected: true,
    value: SORTBY_LAST_ORDER_DATE,
  },
  {
    id: "ascLastOrderDate",
    order: SORTBY_ASC_ORDER,
    selected: false,
    value: SORTBY_LAST_ORDER_DATE,
  },
  {
    id: "ascTotalCustomerPV",
    order: SORTBY_ASC_ORDER,
    selected: false,
    value: SORTBY_TOTAL_CUSTOMER_PV,
  },
  {
    id: "descTotalCustomerPV",
    order: SORTBY_DESC_ORDER,
    selected: false,
    value: SORTBY_TOTAL_CUSTOMER_PV,
  },
  {
    id: "ascNumberOrders",
    order: SORTBY_ASC_ORDER,
    selected: false,
    value: SORTBY_TOTAL_CUSTOMER_ORDER,
  },
  {
    id: "descNumberOrders",
    order: SORTBY_DESC_ORDER,
    selected: false,
    value: SORTBY_TOTAL_CUSTOMER_ORDER,
  },
];

const initialState: SortState = {
  sortOrder: initialSortBy[0].order,
  sortValue: initialSortBy[0].value,
  sortBy: initialSortBy,
  sortByLastUpdateAt: 0,
};

const { actions, reducer } = createSlice({
  name: "customerSort",
  initialState,
  reducers: {
    // resets the sort to default options
    resetSort: (state) => {
      const { sortBy } = state;

      return {
        ...state,
        ...getFirstOption(sortBy),
        sortBy: setDefaultSelected(sortBy),
      };
    },

    // updates sort list based on user selection
    updateSortOption: (state, action: PayloadAction<SortOption>) => {
      const { payload } = action;
      return {
        ...state,
        sortBy: getUpdatedSortOptions(state.sortBy, payload),
        sortValue: payload.value,
        sortOrder: payload.order,
      };
    },
  },
  extraReducers: (builder) => {
    // listen to boot event and update sort configuration accordingly
    builder.addCase(setBoot, (state, { payload }) => {
      const {
        customerList: { sortBy: sortByConfig = [] },
      } = payload.configuration.globalCML;

      return {
        ...state,
        ...getEnabledSortOptions(state.sortBy, sortByConfig),
      };
    });
  },
});

export const { resetSort, updateSortOption } = actions;

export default reducer;
