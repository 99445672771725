import { Route, Navigate } from "react-router-dom";
import { DROPDOWN_KEYS, ROUTE_PATHS } from "../common/enums/routing-enums";
import { NavDropdown, NavDropdownItem, NavDropdownMin } from "../common/interfaces/routing";
import { ErrorPage, ERROR_TYPE } from "../components/ErrorPage/ErrorPage";
import { HomePage } from "../components/HomePage/HomePage";
import { authRoutes, AUTH_ROUTE_GROUP_KEYS, AUTH_COMPONENT_MAP } from "./AuthRouting";
import { errorRoutes, ERROR_ROUTE_GROUP_KEYS, ERROR_COMPONENT_MAP } from "./ErrorRouting";
import { ACTION_REPORTS_COMPONENT_MAP, ACTION_REPORTS_ROUTE_GROUP_LABELS, arRoutes } from "./ARRouting";
import { defaultMenuConfig } from "./MenuConfig/defaultMenuConfig";
import { EARLY_INCENTIVES_COMPONENT_MAP } from "./MenuConfig/earlyIncentivesDropdown";
import { PERFORMANCE_COMPONENT_MAP } from "./MenuConfig/performanceDropdown";
import { MY_GROUP_COMPONENT_MAP } from "./MenuConfig/myGroupDropdown";
import { LEADER_GROWTH_COMPONENT_MAP } from "./MenuConfig/leaderGrowthDropdown";
import { RESOURCES_COMPONENT_MAP } from "./MenuConfig/resourcesDropdown";

export const returnRoutesByKey = (key: string, menuConfig: NavDropdown[]): NavDropdownItem[] => {
  const items = menuConfig.find((item: NavDropdown) => item.key === key)?.items;
  if (!items || items.length <= 0) {
    return [];
  }
  return items;
};

const getRouteItems = (key: string, menuConfig: NavDropdown[]): NavDropdownItem[] => {
  const routes = returnRoutesByKey(key, menuConfig);
  return routes.filter(({ skip }) => !skip);
};

/**
 * Returns the parent navigation group that `path` belongs to
 * @param {string} path
 * @returns NavDropdown
 */
export const getNavGroupFromPath = (path: string, menuConfig: NavDropdown[]): NavDropdown => {
  for (const navGroup of menuConfig) {
    if (navGroup.items.find((route) => route.path === path || path === "/")) {
      return navGroup;
    }
  }
  return {} as NavDropdown;
};

/**
 * Common function Returns the paths when key and related configs are passed
 */
export const returnRoutesByGivenKey = (label: string, NavDropdown: NavDropdownMin[]) => {
  const items = NavDropdown.find((item: any) => item.label === label)?.items;
  if (!items || items.length <= 0) {
    return [];
  }
  return items;
};

export const COMPONENT_ROUTE_MAP: { [key: string]: JSX.Element } = {
  ...PERFORMANCE_COMPONENT_MAP,
  ...EARLY_INCENTIVES_COMPONENT_MAP,
  ...MY_GROUP_COMPONENT_MAP,
  ...LEADER_GROWTH_COMPONENT_MAP,
  ...RESOURCES_COMPONENT_MAP,
  ...ACTION_REPORTS_COMPONENT_MAP,
  ...ERROR_COMPONENT_MAP,
  ...AUTH_COMPONENT_MAP,
  [ROUTE_PATHS.CML]: <Navigate to={ROUTE_PATHS.CUSTOMER_OVERVIEW} />,

  "/": <HomePage />,
  "*": <ErrorPage errorType={ERROR_TYPE.PAGE_UNAVAILABLE} />,
};

export const ComponentRoutes = () => {
  const routes = [
    ...returnRoutesByGivenKey(ERROR_ROUTE_GROUP_KEYS.ERROR, errorRoutes),
    ...returnRoutesByGivenKey(AUTH_ROUTE_GROUP_KEYS.IMPERSONATION, authRoutes),
    ...getRouteItems(DROPDOWN_KEYS.PERFORMANCE, defaultMenuConfig),
    ...getRouteItems(DROPDOWN_KEYS.EARLY_INCENTIVES, defaultMenuConfig),
    ...getRouteItems(DROPDOWN_KEYS.MY_GROUP, defaultMenuConfig),
    ...getRouteItems(DROPDOWN_KEYS.LEADER_GROWTH, defaultMenuConfig),
    ...getRouteItems(DROPDOWN_KEYS.RESOURCES, defaultMenuConfig),
    ...returnRoutesByGivenKey(ACTION_REPORTS_ROUTE_GROUP_LABELS.DASHBOARD_LINKS, arRoutes),
    // !!important!! DO NOT remove this redirect route, it is for markets that still using old link to re-route to new link
    { path: ROUTE_PATHS.CML },
    { path: "/" },
    { path: "*" },
  ];

  return routes.map((route, index) => {
    return <Route path={route.path} element={COMPONENT_ROUTE_MAP[route.path]} key={index} />;
  });
};
