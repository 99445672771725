import { useTranslation } from "react-i18next";
import LeafProfileLoader from "../../../../../../common/leaf/LeafProfile/LeafProfileLoader/LeafProfileLoader";
import LeafSponsorship from "../../../../../../common/leaf/LeafProfile/LeafSponsorship/LeafSponsorship";
import { LeafErrorMessage } from "../../../../../../common/leaf/LeafErrorMessage/LeafErrorMessage";
import { useGetABOProfileQuery } from "../../../../../../services/profileAPI";
import { useAppSelector } from "../../../../../../store";
import defaultContent from "../../../../../../content/sponsorshipTab";
import "./SponsorshipTab.scss";

const SponsorshipTab = () => {
  const { selectedPeriod } = useAppSelector(({ period }) => period);
  const { t } = useTranslation(["sponsorshipTab"]);

  const user = useAppSelector((state) => state.boot?.user);
  const affAbo = useAppSelector((state) => state.profile.affAbo);

  const { isFetching, isError, data } = useGetABOProfileQuery({
    period: selectedPeriod,
    affAbo,
    reqAffAbo: `${user.aff}-${user.abo}`,
  });

  if (isFetching === true) {
    return <LeafProfileLoader row={1} applicant={true} isFetching={isFetching} />;
  }

  if (isError === true) {
    return <LeafErrorMessage show={isError} />;
  }

  if (data?.aboInfo) {
    const { aboInfo } = data;
    const { sponsor, platinumSponsor, fosterSponsor, internationalSponsor } = aboInfo;

    return (
      <div className="sponsorship-tab" tabIndex={0}>
        <h2 className="sponsorship-tab__header">{t("sponsorshipTitle", defaultContent["sponsorshipTitle"])}</h2>
        <LeafSponsorship
          sponsor={sponsor}
          platinumSponsor={platinumSponsor}
          internationalSponsor={internationalSponsor}
          fosterSponsor={fosterSponsor}
        />
      </div>
    );
  }

  return <></>;
};

export default SponsorshipTab;
