import { track } from "@amwaycommon/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../../common/enums/tealium-enums";

const actionMapper: { [key: string]: string } = {
  pathtobronze: "path_to_bronze",
  bronzefoundation: "bronze_foundation",
  bronzebuilder: "bronze_builder",
};

const clickCategoryMapper: { [key: string]: string } = {
  pathtobronze: TEALIUM.CATEGORY.PATH_TO_BRONZE_INCENTIVE,
  bronzefoundation: TEALIUM.CATEGORY.BRONZE_FOUNDATION_INCENTIVE,
  bronzebuilder: TEALIUM.CATEGORY.BRONZE_BUILDER_INCENTIVE,
};

export const tabMenuClickTrack = (tabMenuLink: string, currentView: string) => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "bronze_incentive_toggle",
    clickCategoryMapper[currentView],
    "",
    actionMapper[tabMenuLink],
  );
};
