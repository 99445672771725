import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store";
import Note from "../../../Note/Note";
import CustomerApplicantCard from "./CustomerApplicantCard/CustomerApplicantCard";
import CustomerProfileTabViews from "./CustomerProfileTabViews/CustomerProfileTabViews";
import CustomerProfileHeader from "./CustomerProfileHeader/CustomerProfileHeader";
import "./CustomerProfileBody.scss";

type Props = {
  isShowProfile: boolean;
};

const CustomerProfileBody = (props: Props) => {
  useTranslation(["note"]);
  const { isShowProfile } = props;

  const boot = useAppSelector((state) => state.boot);
  const showCustomerProfileApplicant = boot?.configuration?.profile?.showCustomerProfileApplicant;

  if (isShowProfile === true) {
    return (
      <div className="customer-profile-body">
        <div className="customer-profile-body__abo-details">
          <div className="customer-profile-body__abo-info">
            <CustomerProfileHeader />
          </div>

          <div className="customer-profile-body__abo-card">
            {showCustomerProfileApplicant === true && <CustomerApplicantCard />}
            <Note />
          </div>
        </div>

        <div className="customer-profile-body__tabs">
          <CustomerProfileTabViews />
        </div>
      </div>
    );
  }

  return <></>;
};

export default CustomerProfileBody;
