import { storedLocale } from "../../../components/impersonation/util";

type Props = {
  // LeafDateTime props are based on DateTimeFormatOptions
  date: string;
  defaultValue?: string;
  ignoreTimeZone?: boolean;
  options?: Intl.DateTimeFormatOptions;
};

const LeafDateTime = (props: Props) => {
  const { date, options, ignoreTimeZone = false, defaultValue = "--" } = props;
  const locale = storedLocale();

  try {
    const dateObject = ignoreTimeZone ? new Date(`${date.split("T")[0]}T00:00:00Z`) : new Date(date);
    const timeZone = ignoreTimeZone ? "UTC" : Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formatterOptions = { ...options, timeZone };
    const formatter = new Intl.DateTimeFormat(locale, formatterOptions);
    return <>{formatter.format(dateObject)}</>;
  } catch (error) {
    return <>{defaultValue}</>;
  }
};

export default LeafDateTime;
