import "./ImpersonationTable.scss";

type TableHeader = {
  value: React.ReactNode | string;
  width?: string;
};

type RowColumn = {
  value: React.ReactNode | string;
  height?: string;
  width?: string;
  columnAlign?: "left" | "right" | "center" | undefined;
};

type TableRow = {
  row: RowColumn[];
};

export type LeafTableProps = {
  header: TableHeader[];
  rows: TableRow[];
  columnAlign: "left" | "right" | "center";
  hideHeader?: boolean;
  rowHeight?: string;
};

export const ImpersonationTable = ({ rows, columnAlign }: LeafTableProps) => {
  return (
    <table cellSpacing="0" cellPadding="0" className="impersonation-table">
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr className="impersonation-table impersonation-table__row" key={rowIndex}>
            {row.row.map((column, colIndex) => (
              <td
                key={colIndex}
                style={{
                  textAlign: column.columnAlign || columnAlign,
                  height: column.height,
                  padding: column.columnAlign ? "0px 20px 0px 20px" : undefined,
                  width: column.height ? column.height : "auto",
                }}
              >
                {column.value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
