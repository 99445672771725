import { ChartDonut } from "@patternfly/react-charts";
import "./CustomerPVDonut.scss";

type Props = {
  nonVcsPV: number;
  otherNonVcsPV: number;
  vcsPv: number;
  textContent: {
    legendVcsPvText: string;
    legendNonVcsPvText: string;
    legendOtherNonVcsPvText: string;
  };
};

const CustomerPVDonut = (props: Props) => {
  const { vcsPv, nonVcsPV, otherNonVcsPV, textContent } = props;
  return (
    <div className="customer-pv-donut-chart__container">
      <div className="customer-pv-donut-chart" data-testid="customer-pv-donut">
        <ChartDonut
          constrainToVisibleArea={true}
          data={[
            { x: textContent.legendVcsPvText, y: vcsPv },
            { x: textContent.legendNonVcsPvText, y: nonVcsPV },
            { x: textContent.legendOtherNonVcsPvText, y: otherNonVcsPV },
          ]}
          labels={({ datum }) => null}
          colorScale={["#f8c86a", "#f89d6a", "#8f4700"]}
          innerRadius={70}
          padding={{
            left: 25,
            right: 25,
          }}
        />
      </div>
    </div>
  );
};

export default CustomerPVDonut;
