import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import { useGetAnnualIncentiveTrackingQuery } from "../../../../services/annualPerformanceDashboardAPI";
import { AnnualIncentiveTrackingResponse } from "../../../../common/interfaces/annualPerformanceDashboard";
import { storedLocale } from "../../../impersonation/util";
import { isProgramActive } from "../../../../common/util/checkProgramActiveStatus";
import { AWARD_CODES } from "../../../../common/enums/award-codes";

export const defaultAnnualIncentiveTracking = (): AnnualIncentiveTrackingResponse => {
  return {
    CallResult: [],
    PggTile: {
      BaselineMonths: "0",
      TrackingMonths: "0",
      TrackingBonusAmount: "0",
      DisableTile: false,
    },
    FggTile: {
      BaselineMonths: "0",
      TrackingMonths: "0",
      TrackingBonusAmount: "0",
      DisableTile: false,
    },
    FaaTile: {
      DbrStatus: false,
      FoundersPlatinumLegs: "0",
      FaaPoints: "0",
      FaaPercent: "0",
      TrackingBonus: "0",
      LastMonthProcessed: 0,
      DisableTile: false,
    },
  };
};

export const FetchAnnualIncentiveTracking = ({ period }: { period: string }) => {
  const { user, shellConfig } = useAppSelector((state) => state.boot);

  const annualTrackingAwardCodes = [AWARD_CODES.ANNUAL_PGG, AWARD_CODES.PGG_GCR, AWARD_CODES.FGG, AWARD_CODES.FAA];
  const eligibleIncentives = annualTrackingAwardCodes.filter((awardCode) =>
    isProgramActive(shellConfig.eligibleIncentives, awardCode),
  );

  const {
    data: payloadData,
    isFetching,
    isError,
    refetch,
  } = useGetAnnualIncentiveTrackingQuery({
    abo: user.abo,
    aff: user.aff,
    period,
    eligibleIncentives: eligibleIncentives.map((i) => i.toString()),
    locale: storedLocale(),
    isoCountryCode: user.isoCountryCode,
    isoCurrencyCode: shellConfig.isoCurrencyCode,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [annualIncentivesTrackingData, setAnnualIncentivesTrackingData] = useState<AnnualIncentiveTrackingResponse>(
    defaultAnnualIncentiveTracking(),
  );

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (!isFetching && !isError && payloadData) {
      setAnnualIncentivesTrackingData(payloadData);
      setErrorStatus(payloadData.Error?.every((i) => i !== null) || false);
    }
  }, [isFetching, period, isError, payloadData]);

  return {
    isLoading,
    annualIncentivesTrackingData,
    eligibleIncentives,
    errorStatus,
    refetch,
  };
};
