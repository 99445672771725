import { useTranslation } from "react-i18next";
import { storedLocale } from "../../../components/impersonation/util";
import { getFormattedTimestamp } from "../../util/getTimestamp";
import commonContent from "../../../content/common";
import "./TimeStamp.scss";

type Props = {
  dateFormat: "long" | "short";
};

/**
 * Returns common Timestamp with translations and styles.
 *
 * dateFormat: "long" = "Totals as of April 16, 2024 at 13:50 EDT"
 * dateFormat: "short" = "Totals as of Apr 16, 2024 at 13:50 EDT"
 */
export const TimeStamp = ({ dateFormat }: Props) => {
  const { t } = useTranslation(["common"]);
  const locale = storedLocale();
  const { longDate, shortDate } = getFormattedTimestamp(locale);

  const renderTimeFormat = (date: string) => (
    <p id={`${dateFormat}Date-timestamp`} className={`TimeStamp`}>
      {t("totalsAsOf", commonContent["totalsAsOf"], { ns: "common", timestamp: date })}
    </p>
  );

  return (
    <>
      {dateFormat === "long" && renderTimeFormat(longDate)}
      {dateFormat === "short" && renderTimeFormat(shortDate)}
    </>
  );
};
