import "./IconPlainTable.scss";
import { ReactComponent as IconPlainBlack } from "./svg/icon_plain_table.svg";
import { ReactComponent as IconPlainBlue } from "./svg/icon_plain_table_blue.svg";
import { ReactComponent as IconPlainPurple } from "./svg/icon_plain_table_purple.svg";

export const IconPlainTable = ({ color = "black" }: { color?: "black" | "blue" | "purple" }) => {
  const renderIcon = () => {
    switch (color) {
      case "blue":
        return <IconPlainBlue />;
      case "purple":
        return <IconPlainPurple />;
      default:
        return <IconPlainBlack />;
    }
  };
  return <span className={`icon-plain-table ${color}`}>{renderIcon()}</span>;
};
