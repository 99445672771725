import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/leafBirthdayInfo";
import LeafDateTime from "../../../LeafDateTime/LeafDateTime";
import "./LeafBirthdayInfo.scss";

type Props = {
  date: string;
  privacyFlag?: boolean;
  show?: boolean;
};

// todo: handle leap year use case
const LeafBirthdayInfo = (props: Props) => {
  const { date, privacyFlag, show } = props;
  const { t } = useTranslation(["leafBirthdayInfo"]);

  const renderInfo = date ? (
    <LeafDateTime date={date} options={{ month: "long", day: "numeric" }} ignoreTimeZone={true} />
  ) : (
    t("NoBirthdayDate", defaultContent["NoBirthdayDate"])
  );

  const privacyView = privacyFlag === true ? "-" : renderInfo;

  if (show === true) {
    return (
      <div className="leaf-birthday">
        <div className="leaf-birthday__container">
          <div className="leaf-birthday__content-title">{t("Birthday", defaultContent["Birthday"])}</div>
          <div className="leaf-birthday__content-data">{privacyView}</div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default LeafBirthdayInfo;
