const content: { [key: string]: any } = {
  personal: {
    title: "Personal Bonus",
    subTitle:
      "Your personal bonus is the total earnings from your purchases as well as from your customers and members.",
    table: {
      personalBV: "Personal BV",
      bonusPercent: "Performance Bonus",
      personalBonusAmount: "Current Personal Total",
      nonBonusable: "Non-bonusable Personal BV",
      total: "Personal Bonus Total",
    },
  },
  differential: {
    title: "Differential Bonus",
    subTitle:
      "Your Differential Multiplier is the <1>difference between</1> your performance bonus level and your frontline's performance bonus level.",
    table: {
      aboBonusPercent: "{aboBonusPercent} Performance Bonus",
      aboGpv: "{aboGpv} GPV",
      personalBV: "Group BV ({countryCode})",
      bonusPercent: "Differential Multiplier ({bonusPercent})",
      personalBonusAmount: "Current Differential Total",
      nonBonusable: "Non-bonusable Leg BV",
      total: "Total Differential Bonus",
    },
    exportTitle: "Export",
  },
};

export default content;
