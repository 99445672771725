import { MutableRefObject, RefObject, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import defaultContent from "../../../../content/annualGcrPGG";
import { IconTable } from "../../../../common/icons/IconTable/IconTable";
import { LeafIconButton } from "../../../../common/leaf/LeafIconButton/LeafIconButton";
import {
  GCRIncentiveData,
  GCRPGGRequirement,
  GCRMonthTotals,
} from "../../../../common/interfaces/groupGrowthIncentives";
import LeafRectangleLoader from "../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { getPerformanceYearShort } from "../../../../common/util/period";
import TEALIUM from "../../../../common/enums/tealium-enums";
import { RequirementModal } from "./Requirements/RequirementModal";
import { YtdTotalsModal } from "./YtdTotalsModal/YtdTotalsModal";
import "./YtdTotals.scss";

type Props = {
  monthTotals: GCRMonthTotals[];
  gcrRequirements: GCRPGGRequirement[];
  incentiveData: GCRIncentiveData;
  selectedPeriod: string;
  isLoading: boolean;
};

export const YtdTotals = ({ monthTotals, gcrRequirements, incentiveData, selectedPeriod, isLoading }: Props) => {
  const { t } = useTranslation(["annualGcrPGG"]);
  const requirementModalButton = useRef<HTMLButtonElement>(null);
  const ytdTotalsModalButton = useRef<HTMLButtonElement>(null);
  const prevOpenRef = useRef(false);
  const [requirementModal, setRequirementModal] = useState(false);
  const [ytdTotalsModal, setYtdTotalsModal] = useState(false);
  const requirementsLoading = false;

  const setModalBtnFocus = (
    modalBtnRef: RefObject<HTMLButtonElement>,
    prevOpenRef: MutableRefObject<boolean>,
    showCurrentModal: boolean,
  ) => {
    if (modalBtnRef && modalBtnRef.current) modalBtnRef.current.focus();
    prevOpenRef.current = showCurrentModal;
  };

  const toggleRequirementModal = () => {
    setRequirementModal(!requirementModal);
    setModalBtnFocus(requirementModalButton, prevOpenRef, requirementModal);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "personal_group_growth_gcr",
      "personal_group_growth_gcr",
      `requirements_tab_open/close`,
      "requirements_tab",
    );
  };

  const toggleYtdTotalsModal = () => {
    setYtdTotalsModal(!ytdTotalsModal);
    setModalBtnFocus(ytdTotalsModalButton, prevOpenRef, ytdTotalsModal);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "personal_group_growth_gcr",
      "personal_group_growth_gcr",
      `YTD_modal_open/close`,
      "YTD_modal",
    );
  };

  return (
    <div className="YearToDateSection">
      <LeafTile>
        {!isLoading ? (
          <>
            <div className="YearToDate">
              <div className="YearToDate__header">
                <div className="YearToDate__header-placeholder"></div>
                <div className="YearToDate__title" tabIndex={0}>
                  <Trans>{t("progressTile.title", defaultContent.progressTile["title"])}</Trans>
                </div>
                <div className="YearToDate__button-holder">
                  <LeafIconButton
                    Icon={<IconTable />}
                    HoverIcon={<IconTable color="blue" />}
                    aria-label={`${t(
                      "performanceBonusScheduleButton",
                      defaultContent["performanceBonusScheduleButton"],
                      {
                        ns: "common",
                      },
                    )}`}
                    className="Mpd__section-title-button"
                    onClick={toggleYtdTotalsModal}
                    id="mpdIconTableButton"
                  />
                </div>
              </div>
              <div className="YearToDate__sections">
                <div className="YearToDate__section">
                  <div className="YearToDate__section-title">{t("spMonths", defaultContent.spMonths as string)}</div>

                  <div className="YearToDate__section-number">{incentiveData.CurrentYTDSPMonths}</div>
                </div>
                <div className="vertical-divider" />
                <div className="YearToDate__section">
                  <div className="YearToDate__section-title">
                    {t("incentiveCalculation.pqStatus", defaultContent.incentiveCalculation["pqStatus"], {
                      perfYear: getPerformanceYearShort(selectedPeriod),
                    })}
                  </div>

                  <div className="YearToDate__section-number">{incentiveData.CurrentYTDPQMonths}</div>
                </div>
                <div className="vertical-divider" />
                <div className="YearToDate__section">
                  <div className="YearToDate__section-title">{t("rubyPV", defaultContent.rubyPV as string)}</div>
                  <div className="YearToDate__section-number">{incentiveData.AnnualRubyPV}</div>
                </div>
              </div>
              <div className="YearToDate__button-container">
                <button className="YearToDate__button" onClick={toggleRequirementModal}>
                  <span className="MultiplierDetail__button-text">
                    <Trans>{t("progressTile.requirements", defaultContent.progressTile["requirements"])}</Trans>
                  </span>
                </button>
              </div>
            </div>
            <RequirementModal
              open={requirementModal}
              toggleModal={toggleRequirementModal}
              requirementData={gcrRequirements}
              isLoading={requirementsLoading}
            />
            <YtdTotalsModal
              showYTDTotalsModal={ytdTotalsModal}
              toggleModal={toggleYtdTotalsModal}
              monthTotals={monthTotals}
            />
          </>
        ) : (
          <>
            <LeafRectangleLoader width="100%" height="30%" margin=".5rem 0" isLoading={true} />
            <LeafRectangleLoader width="100%" height="30%" margin=".5rem 0" isLoading={true} />
            <LeafRectangleLoader width="100%" height="30%" margin=".5rem 0" isLoading={true} />
          </>
        )}
      </LeafTile>
    </div>
  );
};
