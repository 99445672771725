import { ReactNode, useRef } from "react";
import LeafRectangleLoader from "../LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import LeafTabViewHeader from "./LeafTabViewHeader/LeafTabViewHeader";
import "./LeafTabViews.scss";

export type TabView = {
  id?: string;
  title?: string;
  View?: ReactNode;
  disabled?: boolean;
};

export type Props = {
  Views: TabView[];
  setViewOnClick: Function;
  activeView: string;
  isLoading?: boolean;
};

const LeafTabViews = (props: Props) => {
  const { Views, setViewOnClick, activeView, isLoading = false } = props;
  const tabViewRef = useRef<HTMLDivElement>(null);
  const tabContentRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleKeyDown = (event: any) => {
    const tabView = tabViewRef.current;
    const tabElements = tabView?.querySelectorAll<HTMLElement>(".leaf-tab-view-header-item__nav-item");
    const arrayFromTabElements = Array.from(tabElements || []);
    const lastIndex = arrayFromTabElements.length - 1;
    if (event.key === "ArrowLeft" && tabElements) {
      const index = arrayFromTabElements.findIndex((element) => element.id === event.target.id);
      index === 0 ? tabElements[lastIndex].focus() : tabElements[index - 1].focus();
    }
    if (event.key === "ArrowRight" && tabElements) {
      const index = arrayFromTabElements.findIndex((element) => element.id === event.target.id);
      index === lastIndex ? tabElements[0].focus() : tabElements[index + 1].focus();
    }
    // Focus on the tab content when down arrow is pressed
    if (event.key === "ArrowDown" && tabView) {
      const index = arrayFromTabElements.findIndex((element) => element.id === event.target.id);
      const activeContent = tabContentRefs.current[index];
      if (activeContent) {
        // Find the first focusable element within the active content
        const firstFocusableElement = activeContent.querySelector<HTMLElement>(
          'a, button, input, select, textarea,[tabindex]:not([tabindex="-1"])',
        );
        if (firstFocusableElement) {
          firstFocusableElement.focus();
        } else {
          // Focus the active content itself if no focusable element is found
          activeContent.focus();
        }
      }
    }
  };

  return (
    <div className="leaf-tab-views" onKeyDown={handleKeyDown} ref={tabViewRef}>
      <ul className="leaf-tab-views__header" role="tablist">
        {Views.map(({ id, title, disabled = false }) => (
          <li key={id}>
            <LeafTabViewHeader
              tabItem={{ id, title, disabled, activeView }}
              key={id}
              tabHeaderOnClick={setViewOnClick}
            />
          </li>
        ))}
      </ul>

      <div className="leaf-tab-views__content">
        <LeafRectangleLoader isLoading={isLoading} width="18.75rem" height="1.5rem">
          {Views.map(({ id, View }, index) => (
            <div
              className={`leaf-tab-views__item${id === activeView ? "--active" : ""}`}
              key={id}
              role="tabpanel"
              id={`tabpanel-${id}`}
              aria-labelledby={id}
              ref={(el) => (tabContentRefs.current[index] = el)}
              tabIndex={id === activeView ? 0 : -1} // Make the active tab content focusable
            >
              {id === activeView ? View : ""}
            </div>
          ))}
        </LeafRectangleLoader>
      </div>
    </div>
  );
};

export default LeafTabViews;
