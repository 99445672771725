import { AmwayIdLogin } from "../../src/components/impersonation/amway-id-login";
import { Impersonate } from "../../src/components/impersonation/impersonate";
import { ErrorPage } from "../components/ErrorPage/ErrorPage";
import { MaintenancePage } from "../components/Maintenance/Maintenance";
import { JwtImpersonation } from "../../src/components/impersonation/acc-jwt-impersonation";
import { MasheryImpersonation } from "../../src/components/impersonation/acc-mashery-impersonation";
import { Impersonation } from "../../src/components/impersonation/impersonation";
import { NavDropdownMin } from "../common/interfaces/routing";
import { AccountSelector } from "../components/impersonation/AccountSelector/AccountSelector";

export enum AUTH_ROUTE_LABELS {
  AMWAY_ID_LOGIN = "amway-id-login",
  AMWAY_ID_JWT_TOKEN = "amway-id-jwt-token",
  IMPERSONATE = "impersonate",
  MESSAGE = "message",
  JWT_RECEIVER = "jwt-receiver",
  TOKEN_RECEIVER = "token",
  IMPERSONATION = "impersonation",
  ACCOUNT_SELECTOR = "business-selector",
  MAINTENANCE = "maintenance",
}

export enum AUTH_ROUTE_PATHS {
  AMWAY_ID_LOGIN = "/amway-id-login.html",
  AMWAY_ID_JWT_TOKEN = "/amway-id-jwt-token.html",
  IMPERSONATE = "/impersonate.html",
  MESSAGE = "/message/:code",
  JWT_RECEIVER = "/jwt-receiver.html",
  TOKEN_RECEIVER = "/token.html",
  IMPERSONATION = "/impersonation.html",
  ACCOUNT_SELECTOR = "/business-selector",
  MAINTENANCE = "/maintenance",
}

export const authRoutePaths = Object.values(AUTH_ROUTE_PATHS).map((route) => route.toString());

export const ROUTES_WITH_NO_TEALIUM_TRACKING: string[] = [
  AUTH_ROUTE_PATHS.AMWAY_ID_LOGIN,
  AUTH_ROUTE_PATHS.AMWAY_ID_JWT_TOKEN,
  AUTH_ROUTE_PATHS.IMPERSONATE,
  AUTH_ROUTE_PATHS.JWT_RECEIVER,
  AUTH_ROUTE_PATHS.TOKEN_RECEIVER,
  AUTH_ROUTE_PATHS.IMPERSONATION,
];

export enum AUTH_ROUTE_GROUP_KEYS {
  IMPERSONATION = "impersonation",
}

export const AUTH_COMPONENT_MAP = {
  [AUTH_ROUTE_PATHS.AMWAY_ID_LOGIN]: <AmwayIdLogin />,
  [AUTH_ROUTE_PATHS.AMWAY_ID_JWT_TOKEN]: <JwtImpersonation />,
  [AUTH_ROUTE_PATHS.IMPERSONATE]: <Impersonate />,
  [AUTH_ROUTE_PATHS.MESSAGE]: <ErrorPage />,
  [AUTH_ROUTE_PATHS.JWT_RECEIVER]: <JwtImpersonation />,
  [AUTH_ROUTE_PATHS.TOKEN_RECEIVER]: <MasheryImpersonation />,
  [AUTH_ROUTE_PATHS.IMPERSONATION]: <Impersonation />,
  [AUTH_ROUTE_PATHS.ACCOUNT_SELECTOR]: <AccountSelector />,
  [AUTH_ROUTE_PATHS.MAINTENANCE]: <MaintenancePage />,
};

export const authRoutes: NavDropdownMin[] = [
  {
    key: AUTH_ROUTE_GROUP_KEYS.IMPERSONATION,
    label: AUTH_ROUTE_GROUP_KEYS.IMPERSONATION,
    items: [
      {
        label: AUTH_ROUTE_LABELS.AMWAY_ID_LOGIN,
        path: AUTH_ROUTE_PATHS.AMWAY_ID_LOGIN,
      },
      {
        label: AUTH_ROUTE_LABELS.AMWAY_ID_JWT_TOKEN,
        path: AUTH_ROUTE_PATHS.AMWAY_ID_JWT_TOKEN,
      },
      {
        label: AUTH_ROUTE_LABELS.IMPERSONATE,
        path: AUTH_ROUTE_PATHS.IMPERSONATE,
      },
      {
        label: AUTH_ROUTE_LABELS.MESSAGE,
        path: AUTH_ROUTE_PATHS.MESSAGE,
      },
      {
        label: AUTH_ROUTE_LABELS.JWT_RECEIVER,
        path: AUTH_ROUTE_PATHS.JWT_RECEIVER,
      },
      {
        label: AUTH_ROUTE_LABELS.TOKEN_RECEIVER,
        path: AUTH_ROUTE_PATHS.TOKEN_RECEIVER,
      },
      {
        label: AUTH_ROUTE_LABELS.IMPERSONATION,
        path: AUTH_ROUTE_PATHS.IMPERSONATION,
      },
      {
        label: AUTH_ROUTE_LABELS.ACCOUNT_SELECTOR,
        path: AUTH_ROUTE_PATHS.ACCOUNT_SELECTOR,
      },
      {
        label: AUTH_ROUTE_LABELS.MAINTENANCE,
        path: AUTH_ROUTE_PATHS.MAINTENANCE,
      },
    ],
  },
];
