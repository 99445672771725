import { useTranslation } from "react-i18next";
import { IconCheckCircle } from "../../../icons/IconCheckCircle/IconCheckCircle";
import { Requirement } from "../../../interfaces/bronzeGroupTracking";
import commonContent from "../../../../content/common";
import defaultContent from "../../../../content/bronzeGroupTracking";
import "./LeafCheckmarksFull.scss";
import { isReq } from "../../../../components/BronzeGroupTracking/bronzeGroupTrackingUtils";
import {
  BRONZE_REQ_ORDER,
  REQUIREMENT_TYPES,
  THREE_LEGS_3_PERCENT,
  THREE_LEGS_6_PERCENT,
  TWO_LEGS_3_PERCENT_WITH_VCS,
} from "../../../constants/requirements-constants";

interface LeafCheckmarksFullProps extends React.ComponentProps<"div"> {
  requirements: Requirement[];
  displayOrder?: Partial<Requirement>[];
}
/**
 * Displays checkmarks for each requirement based on the met property
 * @param {Requirement[]} requirements - Array of Requirements
 * @param displayOrder - Array of order to display requirements
 */
export const LeafCheckmarksFull = ({ requirements, displayOrder }: LeafCheckmarksFullProps) => {
  const { t } = useTranslation(["bronzeGroupTracking", "common"]);
  const exemptLabel = t("common:exempt", commonContent.exempt).toString();

  const getReqText = (requirement: Requirement) => {
    for (const reqOrderItem of BRONZE_REQ_ORDER) {
      if (isReq(requirement, reqOrderItem)) {
        return t(reqOrderItem.text, defaultContent[reqOrderItem.text]).toString();
      }
    }
    return "";
  };

  let sortedRequirements = requirements;
  if (displayOrder && displayOrder.length > 0) {
    sortedRequirements = [...requirements].sort((reqA, reqB) => {
      return findDisplaySequence(reqA, displayOrder) - findDisplaySequence(reqB, displayOrder);
    });
  }

  const mappedRequirements = sortedRequirements.map((req) => {
    const reqTitle = req.text || getReqText(req);
    const progress = req.exemptionApplied ? exemptLabel : getProgress(req);
    return (
      <div key={req.requirement} className="leaf-checkmarks-full__item">
        <div className="leaf-checkmarks-full__req-info">
          <IconCheckCircle
            key={req.requirement}
            id={req.requirement}
            color={req.met || req.exemptionApplied ? "green" : "gray"}
            size="sm"
            title={req.text}
          />
          <div>{reqTitle}</div>
        </div>
        {progress}
      </div>
    );
  });

  return (
    <div className="leaf-checkmarks-full-container leaf-tile">
      <div className="leaf-checkmarks-full">{mappedRequirements}</div>
    </div>
  );
};

function formatPercentWithDecimals({ value, maxDecimals }: { value: number; maxDecimals: number }) {
  const decimals = value.toString().split(".")[1];
  // if number has decimals limit to maxDecimals
  return decimals && decimals.length > 0 ? value.toFixed(maxDecimals) : value;
}

const formatReqValue = (req: Requirement, value: number) => {
  if (
    req.requirementType === REQUIREMENT_TYPES.PERCENT &&
    ![THREE_LEGS_3_PERCENT.requirement, THREE_LEGS_6_PERCENT.requirement].includes(req.requirement)
  ) {
    return `${formatPercentWithDecimals({ value, maxDecimals: 2 })}%`;
  }
  return `${value}`;
};

const getProgress = (req: Requirement) => {
  const actual = req.requirement === TWO_LEGS_3_PERCENT_WITH_VCS.requirement ? req.tracking : req.actual;
  return (
    <div className="leaf-checkmarks-full__progress">
      <span className={req.met ? "leaf-checkmarks-full__req-met" : "leaf-checkmarks-full__req-not-met"}>
        {formatReqValue(req, actual)}
      </span>
      /<span>{formatReqValue(req, req.target)}</span>
    </div>
  );
};

const findDisplaySequence = (req: Partial<Requirement>, displayOrder: any[]) => {
  const index = displayOrder.findIndex(
    ({ requirement, requirementType }) => requirement === req.requirement && requirementType === req.requirementType,
  );
  // if requirement is not found in the sort sequence, place at the end
  return index >= 0 ? index : displayOrder.length + 1;
};
