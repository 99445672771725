const content: { [key: string]: string } = {
  header: "How do I qualify? Here is the list of requirements.",
  legsPercentTitle: "{leg} Legs at {percent}",
  viewDownlineButton: "View Downline Information",
  perfBonusLevelTitle: "{perfBonus} Performance Bonus Level",
  perfBonusLevelTag: "Additional <strong>{neededGPV} {volumeType} needed</strong>",
  ppvTitle: "{PPV} PPV",
  vcsPVTitle: "{VCSPV} VCS PV",
  pcpvTitle: "{PCPV} PCPV",
  vcsBonusLegsTitle: "Two {percent} Legs with {vcspv} VCS PV",
  papvTitle: "{PAPV} PAPV",
};

export default content;
