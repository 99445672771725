import { useTranslation } from "react-i18next";
import { CURRENT_PIN, HIGHEST_PIN, TRACKING_PIN } from "../../constants/pin-info-constants";
import defaultContent from "../../../content/leafPinInfo";
import LeafPinLevelName from "../LeafPinLevelName/LeafPinLevelName";
import LeafDateTime from "../LeafDateTime/LeafDateTime";
import "./LeafPinInfo.scss";
import { getPeriodToDate } from "../../util/period";

type Props = {
  title: typeof CURRENT_PIN | typeof HIGHEST_PIN | typeof TRACKING_PIN;
  pinLevelCode: number;
  date?: string;
  show?: boolean;
};

const LeafPinInfo = (props: Props) => {
  const { t } = useTranslation(["leafPinInfo"]);
  const { title, pinLevelCode, date = "", show = true } = props;
  const options = {
    year: "numeric",
    month: "long",
  } as Intl.DateTimeFormatOptions;

  const getPeriodFromDateString = () => {
    const [month, year] = date.split("-");
    return `${month.padStart(2, "0")}${year}`;
  };

  const period = getPeriodFromDateString();
  const ISODate = getPeriodToDate(period);

  if (pinLevelCode <= 0) return <></>;
  if (show === false) return <></>;

  return (
    <div className="leaf-pin-info">
      <div className="leaf-pin-info__title" aria-label={`${t(title, defaultContent[title])}`}>
        {t(title, defaultContent[title])}
      </div>
      <div className="leaf-pin-info__sub-title">
        {date.length > 0 && (
          <div className="leaf-pin-info__date">
            <LeafDateTime date={ISODate.toISOString()} options={options} />
            <p className="leaf-pin-info__divider">-</p>
          </div>
        )}
        <div>
          <LeafPinLevelName pinLevelCode={pinLevelCode} />
        </div>
      </div>
    </div>
  );
};

export default LeafPinInfo;
