import { ReactNode } from "react";
import "./LeafColorBlock.scss";

type Props = {
  children: ReactNode;
};

export const LeafColorBlock = ({ children }: Props) => {
  return (
    <div className="leaf-color-block" data-testid="leafColorBlock">
      {children}
    </div>
  );
};
