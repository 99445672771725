import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { FILTER_TYPE, IconFilter } from "../../../common/icons/IconFilter/IconFilter";
import LeafBadgeCount from "../../../common/leaf/LeafBadgeCount/LeafBadgeCount";
import LeafSearchInput from "../../../common/leaf/LeafSearchInput/LeafSearchInput";
import defaultContent from "../../../content/bronzeGroupTracking";
import { LeafSortButton } from "../../../common/leaf/LeafSortButton/LeafSortButton";
import "./BGTTracking.scss";
import BGTFilterModal from "../BGTFilterModal/BGTFilterModal";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  updateFilterOption,
  updateFilterOptions,
  resetTrackingFilter,
  updateSearchKeyword,
  searchBronzeGroupTracking,
  filterTrackingBGT,
  resetTrackingSearch,
} from "../../../reducers/BronzeGroupTracking/tracking/filter";
import {
  applyFilterClickTrack,
  cancelFilterClickTrack,
  clearAllFilterClickTrack,
  filterMenuClickTrack,
  openFilterClickTrack,
} from "../../../common/tealium/BronzeGroupTracking/FilterMenuTrack";
import { FilterBTGOption } from "../../../reducers/model/bgtFilters";
import { getCurrentlySelectedSort, getSortedData } from "../../../reducers/model/bgtSort";
import { updateFinalTrackingData } from "../../../reducers/BronzeGroupTracking/bronzeGroupTracking";
import { updateSortOption } from "../../../reducers/BronzeGroupTracking/tracking/sort";
import {
  bgtSearchClickActionTrack,
  bgtSortByClickActionTrack,
  bgtSortSelectedActionTrack,
} from "../../../common/tealium/BronzeGroupTracking/BronzeGroupTracking";
import { LeafIconButton } from "../../../common/leaf/LeafIconButton/LeafIconButton";
import TrackingSort from "./TrackingSort/TrackingSort";
import { BGTTrackingTable } from "./BGTTrackingList/BGTTrackingList";

interface Search {
  minChar: number;
  minDigit: number;
}

export default function BGTTracking() {
  const { t } = useTranslation(["bronzeGroupTracking"]);
  const dispatch = useAppDispatch();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const { filterCount, searchKeyword, filteredTrackingData, trackingTilesData } = useAppSelector(
    ({ BGTTrackingFilter }) => BGTTrackingFilter,
  );
  const { sortBy } = useAppSelector((state) => state.trackingSort);

  const [modalOpenSortBy, setModalOpenSortBy] = useState(sortBy);
  const { finalTrackingTilesData } = useAppSelector(({ bronzeGroupTracking }) => bronzeGroupTracking);
  const { configuration } = useAppSelector((state) => state.boot);
  const [showSortModal, setShowSortModal] = useState(false);
  const [applySortChanges, setApplySortChanges] = useState(false);
  const { selectedSort } = useAppSelector((state) => state.trackingSort);
  const { minChar = 1, minDigit = 1 } = configuration.bronzeGroupTracking?.tracking?.search || ({} as Search);
  const { activeView } = useAppSelector((state) => state.bronzeGroupTrackingTab);
  const [filterType, setFilterType] = useState(FILTER_TYPE.ENABLED);

  const handleShowFilterModal = () => {
    if (filterType === FILTER_TYPE.ENABLED) {
      setShowFilterModal(true);
      openFilterClickTrack();
    }
  };

  const handleModalFilterClose = () => {
    setShowFilterModal(false);
    cancelFilterClickTrack();
  };

  const clearAllOnClick = () => {
    dispatch(resetTrackingFilter());
    setShowFilterModal(false);
    clearAllFilterClickTrack();
  };

  const applyOnClick = (allFilters: { filters: FilterBTGOption[] }) => {
    const { filters } = allFilters;
    dispatch(updateFilterOptions(filters));
    setShowFilterModal(false);
    filterMenuClickTrack(filters);
    applyFilterClickTrack();
    dispatch(filterTrackingBGT());
  };

  /**
   * Closes the sort modal and sets the sort reducer back to it's state when modal opened.
   */
  const onSortModalClose = (val: boolean) => {
    setModalOpenSortBy(modalOpenSortBy);
    dispatch(updateSortOption(getCurrentlySelectedSort(modalOpenSortBy)));
    setShowSortModal(val);
  };

  const applySortChangesFunction = () => {
    setShowSortModal(false);
    setApplySortChanges(true);
    bgtSortSelectedActionTrack({ sortValue: selectedSort?.id, tab: activeView });
  };

  const updateSearch = (input: string) => {
    setFilterType(FILTER_TYPE.DISABLED);
    dispatch(updateSearchKeyword(input));
    bgtSearchClickActionTrack({ action: "search", tab: activeView });
  };

  const resetSearch = () => {
    setFilterType(FILTER_TYPE.ENABLED);
    dispatch(resetTrackingSearch());
    bgtSearchClickActionTrack({ tab: activeView });
  };

  const openSortModal = () => {
    setShowSortModal(true);
    bgtSortByClickActionTrack(activeView);
  };

  useEffect(() => {
    dispatch(searchBronzeGroupTracking({ searchKeyword }));
  }, [dispatch, searchKeyword, trackingTilesData]);

  useEffect(() => {
    dispatch(
      updateFinalTrackingData({
        finalTrackingTilesData: getSortedData(selectedSort, filteredTrackingData),
      }),
    );
    setModalOpenSortBy(sortBy);
    setApplySortChanges(false);
  }, [dispatch, selectedSort, sortBy, filteredTrackingData, trackingTilesData, searchKeyword, applySortChanges]);

  return (
    <div className="tracking-content" id="bronzeGroupTracking-tracking">
      <div className="tracking-content__search-features">
        <div className="tracking-content__icons-and-search">
          <div className="tracking-content__filter">
            <LeafIconButton
              className="tracking-content__icon-filter"
              onClick={handleShowFilterModal}
              Icon={<IconFilter type={filterType} />}
              aria-label={`${t("filter", defaultContent["filter"])}`}
              data-testid="btg-filter-btn"
            />
            <div className="tracking-content__filter-badge-count">
              <LeafBadgeCount count={filterCount} hideZero={true} />
            </div>
          </div>
          <div className="tracking-content__search">
            <LeafSearchInput
              minDigit={minDigit}
              minChar={minChar}
              placeholder={t("search", defaultContent["search"]).toString()}
              callback={updateSearch}
              clearOnClick={resetSearch}
              debounceMS={500}
              searchKeyword={searchKeyword}
              key={searchKeyword}
            />
          </div>
        </div>
        <div className="tracking-content__sort-button">
          <LeafSortButton currentSortMethod={selectedSort.id} clickFunction={openSortModal} />
        </div>
        <TrackingSort
          {...{
            show: showSortModal,
            onCloseClick: onSortModalClose,
            applyChangesFunction: applySortChangesFunction,
          }}
        ></TrackingSort>
      </div>
      <div className="tracking-content__filter-container">
        <BGTFilterModal
          title={t("filter", defaultContent["filter"]).toString() + " (" + filterCount + ")"}
          show={showFilterModal}
          onCloseClick={handleModalFilterClose}
          clearAllOnClick={clearAllOnClick}
          applyOnClick={applyOnClick}
          checkFilterOnClick={() => {}}
          id="BGT-tracking-filter-component"
          tab="tracking"
        />
      </div>
      <BGTTrackingTable trackingTilesData={finalTrackingTilesData} />
    </div>
  );
}
