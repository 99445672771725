import "./LeafBadgeCount.scss";

type Props = {
  count: number;
  hideZero: boolean;
};

const LeafBadgeCount = ({ count, hideZero }: Props) => {
  return hideZero && count < 1 ? (
    <></>
  ) : (
    <div data-testid={"leaf-badge-count"} className={"leaf-badge-count"}>
      <span className={"leaf-badge-count__text"}>{count}</span>
    </div>
  );
};

export default LeafBadgeCount;
