const content: { [key: string]: string } = {
  bbiEligibleTitle: "You are currently eligible for the Bronze Builder Incentive+",
  bfiEligibleTitle: "You are currently eligible for the Bronze Foundation Incentive+",
  bbiCompletedTitle: "Congratulations! You have graduated from the Bronze Builder Incentive+",
  bfiCompletedTitle: "Congratulations! You have graduated from the Bronze Foundation Incentive+",
  subHeadlineContent:
    "You're eligible to earn this incentive <tracking>{remainingIncentiveEarnings} more times</tracking> until {dateText}.",
  eligibilityButton: "View Eligibility",
};
export default content;
