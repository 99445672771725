import { TimeStamp } from "../TimeStamp/TimeStamp";
import "./PageTitleSection.scss";
type Props = {
  title: string;
  infoButton?: React.ReactNode;
  hideTimeStamp?: boolean;
};
export const PageTitleSection = ({ title, infoButton, hideTimeStamp = false }: Props) => {
  return (
    <div className="PageTitleSection">
      <div className="PageTitleSection--header">
        <h1 className="PageTitleSection--title" id="title-section-header">
          {title}
        </h1>
        {infoButton}
      </div>
      {!hideTimeStamp && (
        <div className="PageTitleSection--timestamp">
          <TimeStamp dateFormat={"long"} />
        </div>
      )}
    </div>
  );
};
