import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Customer {
  name: string;
  isVcs: boolean;
}

const initialState = {
  name: "foo",
  isVcs: true,
};

const { actions, reducer } = createSlice({
  name: "demo",
  initialState,
  reducers: {
    updateCustomer: (state, action: PayloadAction<Customer>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateCustomer } = actions;

export default reducer;
