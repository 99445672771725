import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import defaultContent from "../../../../../content/annualPGG";
import TEALIUM from "../../../../../common/enums/tealium-enums";
import { getSelectedPerformanceYearData } from "../../../../../common/util/period";
import { IconPlainTable } from "../../../../../common/icons/IconPlainTable/IconPlainTable";
import { MultiplierDetail } from "../../../../../common/components/MultiplierDetail/MultiplierDetail";
import { hideButtonAndSkipRequest, pyIsTwoPriorThanSelectedPY, setModalBtnFocus } from "../MultiplierDetailsUtil";
import { PQModal } from "../../../../../common/components/PQModal/PQModal";
import { FetchPQMonthData } from "../MultiplierDetailsDataLayer";

interface Props {
  previousPQs: string;
  prevOpenRef: MutableRefObject<boolean>;
  selectedPeriod: string;
}

export const PreviousPQ = ({ previousPQs, prevOpenRef, selectedPeriod }: Props) => {
  const { t } = useTranslation(["annualPGG"]);
  const [showPreviousPqModal, setShowPreviousPqModal] = useState(false);
  const previousPqModalButton = useRef<HTMLButtonElement>(null);
  const skipRequest = hideButtonAndSkipRequest(previousPQs, selectedPeriod);

  const { selectedPYEndPeriod: selectedPyPeriod } = getSelectedPerformanceYearData(selectedPeriod, 1);
  const { pqMonthData, isLoading, errorStatus, refetch } = FetchPQMonthData(selectedPyPeriod, skipRequest);

  const previousPqModalToggle = () => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TEALIUM.CATEGORY.PERSONAL_GROUP_GROWTH,
      `previous_PQ_months_${!showPreviousPqModal ? "open" : "close"}`,
      "view_details_modal",
    );
    setShowPreviousPqModal(!showPreviousPqModal);
    setModalBtnFocus(previousPqModalButton, prevOpenRef, showPreviousPqModal);
  };

  const getButtonInfo = () => {
    if (skipRequest) return null;

    return {
      callback: previousPqModalToggle,
      ref: previousPqModalButton,
      text: t("multiplierDetails.viewDetails", String(defaultContent.multiplierDetails.viewDetails)),
      icon: <IconPlainTable />,
    };
  };

  return (
    <>
      <MultiplierDetail
        title={t("multiplierDetails.pqMonths", defaultContent.multiplierDetails.pqMonths, { pq: previousPQs })}
        subtitle={t("multiplierDetails.previousPy", String(defaultContent.multiplierDetails.previousPy))}
        description={""}
        buttonInfo={getButtonInfo()}
      />
      <PQModal
        toggleModal={previousPqModalToggle}
        displayModal={showPreviousPqModal}
        pqMonths={pqMonthData.PQs}
        header={"previousYtd"}
        performanceYear={selectedPyPeriod}
        isLoading={isLoading}
        showBooster={pqMonthData.ShowBoosterRequirement}
        errorStatus={errorStatus}
        refetchCallback={refetch}
      />
    </>
  );
};
