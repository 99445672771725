import * as Flag from "../icons/Flags";

type CountryFlagMapping = {
  [key: string]: React.ElementType;
};

const countryFlagMapping: CountryFlagMapping = {
  AR: Flag.ARFlag,
  AU: Flag.AUFlag,
  AT: Flag.ATFlag,
  BE: Flag.BEFlag,
  BW: Flag.BWFlag,
  BR: Flag.BRFlag,
  BN: Flag.BNFlag,
  BG: Flag.BGFlag,
  CA: Flag.CAFlag,
  CL: Flag.CLFlag,
  CN: Flag.CNFlag,
  CO: Flag.COFlag,
  CR: Flag.CRFlag,
  HR: Flag.HRFlag,
  CZ: Flag.CZFlag,
  DK: Flag.DKFlag,
  DO: Flag.DOFlag,
  SV: Flag.SVFlag,
  EE: Flag.EEFlag,
  FI: Flag.FIFlag,
  FL: Flag.FLFlag,
  FR: Flag.FRFlag,
  DE: Flag.DEFlag,
  GR: Flag.GRFlag,
  GT: Flag.GTFlag,
  HT: Flag.HTFlag,
  HN: Flag.HNFlag,
  HK: Flag.HKFlag,
  HU: Flag.HUFlag,
  IN: Flag.INFlag,
  ID: Flag.IDFlag,
  IE: Flag.IEFlag,
  IT: Flag.ITFlag,
  JP: Flag.JPFlag,
  KZ: Flag.KZFlag,
  KR: Flag.KRFlag,
  KG: Flag.KGFlag,
  LV: Flag.LVFlag,
  LT: Flag.LTFlag,
  MN: Flag.MNFlag,
  MY: Flag.MYFlag,
  MX: Flag.MXFlag,
  NA: Flag.NAFlag,
  NL: Flag.NLFlag,
  NZ: Flag.NZFlag,
  NO: Flag.NOFlag,
  PA: Flag.PAFlag,
  PH: Flag.PHFlag,
  PL: Flag.PLFlag,
  PT: Flag.PTFlag,
  RO: Flag.ROFlag,
  RU: Flag.RUFlag,
  SG: Flag.SGFlag,
  SK: Flag.SKFlag,
  SI: Flag.SIFlag,
  ZA: Flag.ZAFlag,
  ES: Flag.ESFlag,
  SE: Flag.SEFlag,
  CH: Flag.CHFlag,
  TW: Flag.TWFlag,
  TH: Flag.THFlag,
  TR: Flag.TRFlag,
  UA: Flag.UAFlag,
  UK: Flag.UKFlag,
  GB: Flag.GBFlag,
  US: Flag.USFlag,
  UY: Flag.UYFlag,
  VE: Flag.VEFlag,
  VN: Flag.VNFlag,
};

/**
 *
 * @param countryCode "US"
 * @returns Flag Icon Component
 */
export const getCountryFlag = (countryCode: string): JSX.Element => {
  if (!countryCode) return <></>;
  const FlagIcon = countryFlagMapping[countryCode.toUpperCase()] || Flag.DefaultFlag;
  return <FlagIcon />;
};
