import "./LeafCircleLoader.scss";

type Props = {
  isLoading: boolean;
  width: string;
  height: string;
};

const LeafCircleLoader = (props: Props) => {
  const { isLoading, width, height } = props;
  return (
    <>{isLoading && <div className="circleLoader" style={{ width, height }} data-testid={`leafCircleLoader`}></div>}</>
  );
};

export default LeafCircleLoader;
