import { FilterGroup, getFilterQueryParams } from "../filters";

export type OrderListHeader = {
  authorization: string;
};

export type OrderListFilterQueryParams = {
  filterOrderByVcs?: string;
  filterOrderBySourceType?: string;
  businessNature?: string;
};

export type OrderListQueryParams = {
  pageNum: number;
  pageSize: number;
  period: string;
  sortValue: string;
  sortOrder: string;
  searchBy?: string;
  locale?: string;
  customerIds?: number;
  confidentialUserText?: string;
  businessNature?: string;
} & OrderListFilterQueryParams;

export type CustomerOrder = {
  orderNumber: string;
  businessNature: string;
  date: string;
  currencyCode: string;
  orderTotal: number;
  pv: number;
  bv: number;
  vcsFlag: boolean;
  vcsReasons: string[];
  source: string;
  affNo: string;
  aboNo: string;
  name: string;
  localName: string;
  isoLanguageCode: string;
  privacyFlag: boolean;
  affAbo: string;
};

export type OrderResponse = {
  period: number;
  periodDate: string;
  pageNum: number;
  pageSize: number;
  pageLimit: number;
  totalOrderCount: number;
  affNo: string;
  aboNo: string;
  affAbo: string;
  nextPageNum: number;
  nextPeriod: number;
  customerOrderList: CustomerOrder[];
};

export type OrderGroup = {
  period: number;
  periodDate: string;
  totalOrderCount: number;
  customerOrderList: CustomerOrder[];
};

export type OrderList = {
  period: number;
  pageNum: number;
  pageSize: number;
  pageLimit: number;
  totalOrderCount: number;
  allOrderCount: number;
  affNo: string;
  aboNo: string;
  affAbo: string;
  nextPageNum: number;
  nextPeriod: number;
  orderGroups: OrderGroup[];
  isFetching?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  refreshFilterTime: number;
  requestId?: string;
};

export const initialState: OrderList = {
  period: 0,
  pageNum: 0,
  pageSize: 10,
  pageLimit: 0,
  totalOrderCount: 0,
  allOrderCount: 0,
  affNo: "",
  aboNo: "",
  affAbo: "",
  nextPageNum: 1,
  nextPeriod: 0,
  orderGroups: [],
  isFetching: false,
  isError: false,
  isSuccess: false,
  refreshFilterTime: 0,
  requestId: "",
};

export const getOrderGroup = (period: number, orderGroups: OrderGroup[]) => {
  return orderGroups.find((order) => order.period === period);
};

export const updateOrderList = (orderGroup: OrderGroup, customerOrderList: CustomerOrder[]) => {
  return {
    ...orderGroup,
    customerOrderList: [...orderGroup.customerOrderList, ...customerOrderList],
  };
};

export const createOrderGroup = ({
  period,
  periodDate,
  totalOrderCount,
  customerOrderList,
}: Pick<OrderResponse, "period" | "periodDate" | "totalOrderCount" | "customerOrderList">) => {
  return {
    period,
    periodDate,
    totalOrderCount,
    customerOrderList,
  };
};

export const upsertOrderGroup = (
  orderData: Pick<OrderResponse, "period" | "periodDate" | "totalOrderCount" | "customerOrderList">,
  orderGroups: OrderGroup[],
) => {
  const { period, customerOrderList } = orderData;
  const orderGroup = getOrderGroup(period, orderGroups);

  return orderGroup ? updateOrderList(orderGroup, customerOrderList) : createOrderGroup(orderData);
};

export const updateOrderGroups = (period: number, orderGroups: OrderGroup[], updatedGroup: OrderGroup) => {
  return orderGroups.map((order) => (order.period === period ? updatedGroup : order));
};

export const insertOrderGroup = (orderGroups: OrderGroup[], updatedGroup: OrderGroup) => {
  return [...orderGroups, { ...updatedGroup }];
};

export const upsertOrderGroups = (period: number, orderGroups: OrderGroup[], updatedGroup: OrderGroup) => {
  const orderGroup = getOrderGroup(period, orderGroups);

  return orderGroup
    ? updateOrderGroups(period, orderGroups, updatedGroup)
    : insertOrderGroup(orderGroups, updatedGroup);
};

export const includeGuestCustomerBN = (enableGuestCustomer: boolean) => {
  return enableGuestCustomer === true ? { businessNature: "3,4,17" } : {};
};

export const getOrderFilterQueryParams = (
  filterGroups: FilterGroup[],
  enableGuestCustomer: boolean,
): OrderListFilterQueryParams => {
  const filterQueryParams = getFilterQueryParams<OrderListFilterQueryParams>(filterGroups);
  const businessNatureQueryParams = includeGuestCustomerBN(enableGuestCustomer);
  return { ...filterQueryParams, ...businessNatureQueryParams };
};

export const resetList = (requestId?: string): OrderList => ({
  ...initialState,
  requestId,
  refreshFilterTime: new Date().getTime(),
});
