import { BronzeRequirementsResponse } from "../../../../reducers/model/bronze/requirements";

const bronzeIncentiveProgressOrder = (bronzeRequirements?: BronzeRequirementsResponse): boolean[] => {
  const {
    personalVolume,
    personalOrderPV,
    personalActivityPV,
    vcsPercent,
    verifiedCustomerPV,
    performanceBonus,
    legsPercentage,
    vcsBonusLegs,
  } = bronzeRequirements || {};

  // The order of the requirements here will also reflect on the order of (BFI/BBIRequirements) component
  return [
    personalVolume,
    personalOrderPV,
    personalActivityPV,
    vcsPercent,
    verifiedCustomerPV,
    performanceBonus,
    legsPercentage,
    vcsBonusLegs,
  ]
    .filter((req) => req?.required)
    .map((req) => Boolean(req?.met));
};

export default bronzeIncentiveProgressOrder;
