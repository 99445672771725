import IboOnBoardingActionReport from "../components/ActionReports/IboOnBoardingActionReport";
import CvrActionReport from "../components/ActionReports/CvrActionReport";
import { ROUTE_LABELS, ROUTE_PATHS } from "../common/enums/routing-enums";
import { NavDropdownMin } from "../common/interfaces/routing";
import BirthdaysActionReport from "../components/ActionReports/BirthdaysActionReport";
import WhosNewActionReport from "../components/ActionReports/WhosNewActionReport";
import WhosCloseActionReport from "../components/ActionReports/WhosCloseActionReport";
import CbrActionReport from "../components/ActionReports/CbrActionReport";
import RenewalsActionReport from "../components/ActionReports/RenewalsActionReport";
import NBBVActionReport from "../components/ActionReports/NBBVActionReport";
import { withActionReportSelectorRouting } from "../components/ActionReports/utils/ActionReportSelectorRouting";

export enum ACTION_REPORTS_ROUTE_GROUP_LABELS {
  DASHBOARD_LINKS = "arDashboard",
}

export const ACTION_REPORTS_COMPONENT_MAP = {
  [ROUTE_PATHS.RENEWALS]: withActionReportSelectorRouting(<RenewalsActionReport />),
  [ROUTE_PATHS.IBO_ONBOARDING]: withActionReportSelectorRouting(<IboOnBoardingActionReport />),
  [ROUTE_PATHS.CBR]: withActionReportSelectorRouting(<CbrActionReport />),
  [ROUTE_PATHS.CVR]: withActionReportSelectorRouting(<CvrActionReport />),
  [ROUTE_PATHS.BIRTHDAYS_ACTION_REPORT]: withActionReportSelectorRouting(<BirthdaysActionReport />),
  [ROUTE_PATHS.WHOS_NEW_ACTION_REPORT]: withActionReportSelectorRouting(<WhosNewActionReport />),
  [ROUTE_PATHS.WHOS_CLOSE_ACTION_REPORT]: withActionReportSelectorRouting(<WhosCloseActionReport />),
  [ROUTE_PATHS.NON_BONUSABLE_BV_ACTION_REPORT]: withActionReportSelectorRouting(<NBBVActionReport />),
};

export const arRoutes: NavDropdownMin[] = [
  {
    key: ACTION_REPORTS_ROUTE_GROUP_LABELS.DASHBOARD_LINKS,
    label: ACTION_REPORTS_ROUTE_GROUP_LABELS.DASHBOARD_LINKS,
    items: [
      {
        label: ROUTE_LABELS.RENEWALS,
        path: ROUTE_PATHS.RENEWALS,
      },
      {
        label: ROUTE_LABELS.IBO_ONBOARDING,
        path: ROUTE_PATHS.IBO_ONBOARDING,
      },
      {
        label: ROUTE_LABELS.CBR,
        path: ROUTE_PATHS.CBR,
      },
      {
        label: ROUTE_LABELS.CVR,
        path: ROUTE_PATHS.CVR,
      },
      {
        label: ROUTE_LABELS.BIRTHDAYS_ACTION_REPORT,
        path: ROUTE_PATHS.BIRTHDAYS_ACTION_REPORT,
      },
      {
        label: ROUTE_LABELS.WHOS_NEW_ACTION_REPORT,
        path: ROUTE_PATHS.WHOS_NEW_ACTION_REPORT,
      },
      {
        label: ROUTE_LABELS.WHOS_CLOSE_ACTION_REPORT,
        path: ROUTE_PATHS.WHOS_CLOSE_ACTION_REPORT,
      },
      {
        label: ROUTE_LABELS.NON_BONUSABLE_BV_ACTION_REPORT,
        path: ROUTE_PATHS.NON_BONUSABLE_BV_ACTION_REPORT,
      },
    ],
  },
];
