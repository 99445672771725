import LeafRectangleLoader from "../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import "./MBILoader.scss";

export const MBILoader = () => (
  <div className="mbi-loader">
    <div className="mbi-loader-header">
      <LeafRectangleLoader isLoading={true} numRows={1} width="95%" height="2rem" />
    </div>
    <div className="mbi-loader-top">
      <div className="mbi-loader-top-left">
        <LeafRectangleLoader isLoading={true} numRows={1} width="95%" height="16rem" />
      </div>
      <div className="mbi-loader-top-right">
        <LeafRectangleLoader isLoading={true} numRows={1} width="95%" height="7rem" />
        <LeafRectangleLoader isLoading={true} numRows={1} width="95%" height="7rem" />
      </div>
    </div>
    <div className="mbi-loader-bottom">
      <LeafRectangleLoader isLoading={true} numRows={5} width="95%" height="2rem" />
    </div>
  </div>
);

export default MBILoader;
