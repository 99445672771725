import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../../../content/aboProfilePinInfo";
import "./ABOProfilePinInfo.scss";
import LeafPinInfo from "../../../../../../../common/leaf/LeafPinInfo/LeafPinInfo";

type Props = {
  currentPin: number;
  currentPinDate?: string;
  highestAwardAchieved: number;
  highestAwardAchievedDate?: string;
  highestTrackingAward: number;
  showHighestPin: boolean;
};
const ABOProfilePinInfo = (props: Props) => {
  const { t } = useTranslation(["aboProfilePinInfo"]);
  const {
    currentPin,
    currentPinDate,
    highestAwardAchieved,
    highestAwardAchievedDate,
    highestTrackingAward,
    showHighestPin,
  } = props;

  if (currentPin <= 0 && highestAwardAchieved <= 0 && highestTrackingAward <= 0) return <></>;

  return (
    <div className="abo-profile-pin-info">
      <h2 className="abo-profile-pin-info__header">{t("pinInformation", defaultContent["pinInformation"])}</h2>
      <div className="abo-profile-pin-info__content">
        <LeafPinInfo title="currentPin" pinLevelCode={currentPin} date={currentPinDate} />
        <LeafPinInfo
          title="highestPin"
          pinLevelCode={highestAwardAchieved}
          date={highestAwardAchievedDate}
          show={showHighestPin}
        />
        <LeafPinInfo title="trackingPin" pinLevelCode={highestTrackingAward} />
      </div>
    </div>
  );
};

export default ABOProfilePinInfo;
