import "./IconCheckCircle.scss";
import { ReactComponent as CircleCheckSVG } from "./svg/icon_checkCircle.svg";
import { ReactComponent as SolidCircleCheckSVG } from "./svg/icon_solidCheckCircle.svg";

interface IconCheckCircleProps extends React.ComponentProps<"img"> {
  color?: "green" | "gray" | "blue";
  size?: "xs" | "sm" | "md" | "lg" | "xlg";
  id?: string;
  solidCheckCircle?: boolean;
}

export const IconCheckCircle = ({
  color = "gray",
  size = "xs",
  id = "",
  solidCheckCircle = false,
  title = "",
}: IconCheckCircleProps) => {
  return (
    <span title={title} role="img" id={id} className={`icon-checkCircle--${size} icon-checkCircle__${color}`}>
      {solidCheckCircle ? <SolidCircleCheckSVG /> : <CircleCheckSVG />}
    </span>
  );
};
