/**
 * Use to return a long date and short date of the local timezone at the time of request.
 *
 * @param locale string en-US
 * @example "en-US longDate" - April 17, 2024 at 2:41 PM EDT
 * @example "en-US shortDate" - Apr 17, 2024, 2:41 PM EDT
 * @example "it-IT longDate" - 17 aprile 2024 alle ore 14:40 GMT-4
 * @example "it-IT shortDate" - 17 apr 2024, 14:41 GMT-4
 */
export const getFormattedTimestamp = (locale: string) => {
  const createDates = (locale: string) => {
    const dateObject = new Date();
    const longDateFormatter = Intl.DateTimeFormat(locale, {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    });
    const shortDateFormatter = Intl.DateTimeFormat(locale, {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    });

    const longDate = longDateFormatter.format(dateObject);
    const shortDate = shortDateFormatter.format(dateObject);

    return { longDate, shortDate };
  };

  try {
    return createDates(locale);
  } catch (err) {
    console.warn("Invalid Locale Provided: Using default");
    return createDates("default");
  }
};
