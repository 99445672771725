import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PerformanceProgressBar } from "../../../common/components/PerformanceProgressBar/PerformanceProgressBar";
import colors from "../../../common/scss/colors.module.scss";
import defaultContent from "../../../content/ppe";
import { IconShield } from "../../../common/icons/IconShield/IconShield";
import { IconCheckCircle } from "../../../common/icons/IconCheckCircle/IconCheckCircle";
import { IconInfoCircle } from "../../../common/icons/IconInfoCircle/IconInfoCircle";
import { LeafModal } from "../../../common/leaf/LeafModal/LeafModal";
import LeafRectangleLoader from "../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { PPERubyRequirement } from "../PerfPlusEliteTypes";
import "./RubyPerformanceTile.scss";

type props = {
  rubyRequirements: PPERubyRequirement[];
  maxBonusPercent: string;
  isLoading: boolean;
};

export const RubyPerformanceTile = ({ rubyRequirements, maxBonusPercent, isLoading }: props) => {
  const [showModal, setShowModal] = useState(false);

  const handleInfoClick = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  enum award {
    PerformancePlus,
    PerformancePlusElite,
  }

  const incentive = rubyRequirements.length > 1 ? award.PerformancePlusElite : award.PerformancePlus;

  const { t } = useTranslation(["ppe"]);

  const formatRubyPvString = t(
    `requirements.rubyProgressTile.rubyPV`,
    defaultContent.requirements.rubyProgressTile.rubyPV as string,
  );

  const getTitle = () => {
    switch (incentive) {
      case award.PerformancePlus:
        return t(
          `requirements.rubyProgressTile.performancePlusLbl`,
          defaultContent.requirements.rubyProgressTile.performancePlusLbl as string,
          {
            percent: rubyRequirements[0].Percent.Target.Formatted,
          },
        );
      case award.PerformancePlusElite:
        return t(
          `requirements.rubyProgressTile.performancePlusEliteLbl`,
          defaultContent.requirements.rubyProgressTile.performancePlusEliteLbl as string,
          {
            plusPercent: rubyRequirements[0].Percent.Target.Formatted,
            elitePercent: rubyRequirements[1].Percent.Target.Formatted,
          },
        );
    }
  };

  const getCaretValues = () => {
    const requirement = rubyRequirements[rubyRequirements.length - 1];
    return {
      current: {
        actual: requirement.Percent.Current.Actual,
        display: requirement.Volume.Current.Formatted,
      },
      target: {
        actual: requirement.Percent.Target.Actual,
        display: `${requirement.Volume.Target.Formatted} ${formatRubyPvString}`,
      },
    };
  };

  const getMilestones = () => {
    const milestones = rubyRequirements.map((requirement) => {
      return {
        value: requirement.Percent.Target.Actual,
        label: requirement.Percent.Target.Formatted,
        subLabel: (
          <span className="rubyPerformanceTile__milestones">
            <span>{requirement.Volume.Target.Formatted}</span>
            <span>{formatRubyPvString}</span>
          </span>
        ),
      };
    });

    return [
      {
        value: 0,
        label: "0%",
        subLabel: (
          <span className="rubyPerformanceTile__milestones">
            <span>0</span>
            <span>{formatRubyPvString}</span>
          </span>
        ),
      },
      ...milestones,
    ];
  };

  const ProgressBadges = () => {
    return (
      <div className="rubyPerformanceTile__progressBadges">
        <div className={`rubyPerformanceTile__progressBadgeRow${rubyRequirements[0].Met ? "--met" : ""}`}>
          <IconShield />
          <span>
            {t(
              `requirements.rubyProgressTile.performancePlusLbl`,
              defaultContent.requirements.rubyProgressTile.performancePlusLbl as string,
              {
                percent: rubyRequirements[0].Percent.Target.Formatted,
              },
            )}
          </span>
        </div>
        <div className={`rubyPerformanceTile__progressBadgeRow${rubyRequirements[1].Met ? "--met" : ""}`}>
          <IconShield />
          <span>
            {t(
              `requirements.rubyProgressTile.performanceEliteLbl`,
              defaultContent.requirements.rubyProgressTile.performanceEliteLbl as string,
              {
                percent: rubyRequirements[1].Percent.Target.Formatted,
              },
            )}
          </span>
        </div>
      </div>
    );
  };

  const getProgressRatio = () => {
    if (incentive === award.PerformancePlus) return;

    const performancePlus = rubyRequirements[0];
    const performanceElite = rubyRequirements[1];

    /**
     * For Performance Plus and Elite the progress bar scales aren't equal proportions
     * so we have to calculate the fill percent manually
     * ex:
     * first half:       0 - 10,000 PV  (volume up to Performance Plus)
     * second half: 10,001 - 12,500 PV  (volume from Performance Plus to Performance Elite)
     */
    if (!performancePlus.Met) {
      return (performanceElite.Volume.Current.Actual / performancePlus.Volume.Target.Actual) * 50;
    } else {
      return (performanceElite.Volume.Current.Actual / performanceElite.Volume.Target.Actual) * 100;
    }
  };

  const SkeletonLoader = () => {
    return (
      <>
        <LeafRectangleLoader isLoading={true} width="100%" height="1.5rem" />
        <LeafRectangleLoader isLoading={true} width="100%" height="1.15625rem" />
        <LeafRectangleLoader isLoading={true} width="100%" height="6.25rem" />
      </>
    );
  };

  return (
    <div className="rubyPerformanceTile">
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <>
          <div className="rubyPerformanceTile__header">
            <IconCheckCircle id={""} color={rubyRequirements[0].Met ? "green" : "gray"} size="md" />
            <span className="rubyPerformanceTile__title">
              {getTitle()}{" "}
              <button onClick={handleInfoClick}>
                <IconInfoCircle />
              </button>
            </span>
          </div>
          {incentive === award.PerformancePlusElite && <ProgressBadges />}
          <PerformanceProgressBar
            colorScales={[
              { threshold: 0, color: colors.dataVizOrange },
              { threshold: incentive === award.PerformancePlusElite ? 50 : 100, color: colors.lightGreen },
            ]}
            progressValues={getCaretValues()}
            progressMilestones={getMilestones()}
            progressRatio={getProgressRatio()}
            displayMilestoneMarkers={incentive === award.PerformancePlusElite}
          />

          <LeafModal
            title={`${t("rubyPvModal.title", defaultContent.rubyPvModal.title as string)}`}
            open={showModal}
            onCloseCallbackFn={closeModal}
          >
            <p className="rubyPerformanceTile__rubyPvModal--body">
              {t(`rubyPvModal.description`, defaultContent.rubyPvModal.description as string, {
                bonusLevel: maxBonusPercent,
              })}
            </p>
          </LeafModal>
        </>
      )}
    </div>
  );
};
