import { useTranslation } from "react-i18next";
import LeafABOAddress from "../../../../../../../common/leaf/LeafProfile/LeafABOAddress/LeafABOAddress";
import defaultContent from "../../../../../../../content/aboProfilePrimaryAddress";
import { Address } from "../../../../../../../reducers/model/profile";
import "./ABOProfilePrimaryAddress.scss";
import { cardActionClickTrack } from "../../../../../../../common/tealium/ABOProfile/Profile/ApplicantCardTrack";

type Props = {
  address: Address;
  privacyFlag: boolean;
};

const ABOProfilePrimaryAddress = (props: Props) => {
  const { t } = useTranslation(["aboProfilePrimaryAddress"]);
  const { address, privacyFlag } = props;
  const { addressLines, municipality, region, postalCode, country } = address;

  const addressInfo = [municipality, region, postalCode, country];
  const noAddressInfo = addressInfo.every((addressInfo) => addressInfo === "--") && addressLines.length === 0;
  const hideAddress = noAddressInfo === true || privacyFlag === true;

  const applicantTrackOnclick = (event?: string) => {
    cardActionClickTrack(event, true);
  };

  if (hideAddress === true) return <></>;

  return (
    <div>
      <h2 className="abo-profile-primary-address__header">{t("primaryAddress", defaultContent["primaryAddress"])}</h2>
      <div className="abo-profile-primary-address__content">
        <LeafABOAddress
          addresses={address}
          privacyFlag={privacyFlag}
          applicantTrackOnclick={applicantTrackOnclick}
          hideLabel={true}
        />
      </div>
    </div>
  );
};

export default ABOProfilePrimaryAddress;
