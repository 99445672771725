import { NavDropdownMin } from "../common/interfaces/routing";
import { ErrorPage, ERROR_TYPE, IconType } from "../components/ErrorPage/ErrorPage";

export enum ERROR_ROUTE_GROUP_KEYS {
  ERROR = "error",
}
export const ERROR_ROUTE_BASE_PATH = "/error";

export enum ERROR_ROUTE_LABELS {
  DEMO = "demo",
}
export enum ERROR_ROUTE_SUBPATHS {
  DEMO = "/demo",
  AUTHENTICATION_FAILED = "/authenticationFailed",
  CML_LOAD_FAILED = "/cmlLoadFailed",
  COMPONENT_ERROR = "/componentError",
  EDLOS_LOAD_FAILED = "/edLosLoadFail",
  GENERIC_ERROR = "/genericError",
  INCENTIVE_UNAVAILABLE_FOR_PERIOD = "/incentiveUnavailableForPeriod",
  INCORRECT_ACCOUNT = "/incorrectAccount",
  PAGE_UNAVAILABLE = "/pageUnavailable",
  SYSTEM_MAINTENANCE = "/systemMaintenance",
  UNSUPPORTED_BROWSER = "/unsupportedBrowser",
  USER_SESSION_EXPIRED = "/userSessionExpired",
}

export const getAllErrorRoutes = () => Object.values(ERROR_ROUTE_SUBPATHS).map((route) => getFullRoutePath(route));

export const getFullRoutePath = (routeSubPath: string) => {
  return ERROR_ROUTE_BASE_PATH + routeSubPath;
};

export const ERROR_COMPONENT_MAP = {
  [getFullRoutePath(ERROR_ROUTE_SUBPATHS.AUTHENTICATION_FAILED)]: (
    <ErrorPage errorType={ERROR_TYPE.AUTHENTICATION_FAILED} />
  ),
  [getFullRoutePath(ERROR_ROUTE_SUBPATHS.CML_LOAD_FAILED)]: <ErrorPage errorType={ERROR_TYPE.CML_LOAD_FAILED} />,
  [getFullRoutePath(ERROR_ROUTE_SUBPATHS.COMPONENT_ERROR)]: <ErrorPage errorType={ERROR_TYPE.COMPONENT_ERROR} />,
  [getFullRoutePath(ERROR_ROUTE_SUBPATHS.EDLOS_LOAD_FAILED)]: <ErrorPage errorType={ERROR_TYPE.EDLOS_LOAD_FAILED} />,
  [getFullRoutePath(ERROR_ROUTE_SUBPATHS.GENERIC_ERROR)]: <ErrorPage errorType={ERROR_TYPE.GENERIC_ERROR} />,
  [getFullRoutePath(ERROR_ROUTE_SUBPATHS.INCENTIVE_UNAVAILABLE_FOR_PERIOD)]: (
    <ErrorPage errorType={ERROR_TYPE.INCENTIVE_UNAVAILABLE_FOR_PERIOD} />
  ),
  [getFullRoutePath(ERROR_ROUTE_SUBPATHS.INCORRECT_ACCOUNT)]: <ErrorPage errorType={ERROR_TYPE.INCORRECT_ACCOUNT} />,
  [getFullRoutePath(ERROR_ROUTE_SUBPATHS.PAGE_UNAVAILABLE)]: <ErrorPage errorType={ERROR_TYPE.PAGE_UNAVAILABLE} />,
  [getFullRoutePath(ERROR_ROUTE_SUBPATHS.SYSTEM_MAINTENANCE)]: <ErrorPage errorType={ERROR_TYPE.SYSTEM_MAINTENANCE} />,
  [getFullRoutePath(ERROR_ROUTE_SUBPATHS.UNSUPPORTED_BROWSER)]: (
    <ErrorPage errorType={ERROR_TYPE.UNSUPPORTED_BROWSER} iconType={IconType.Globe} />
  ),
  [getFullRoutePath(ERROR_ROUTE_SUBPATHS.USER_SESSION_EXPIRED)]: (
    <ErrorPage errorType={ERROR_TYPE.USER_SESSION_EXPIRED} />
  ),
};

export const errorRoutes: NavDropdownMin[] = [
  {
    key: ERROR_ROUTE_GROUP_KEYS.ERROR,
    label: ERROR_ROUTE_GROUP_KEYS.ERROR,
    items: [
      {
        label: ERROR_TYPE.AUTHENTICATION_FAILED,
        path: getFullRoutePath(ERROR_ROUTE_SUBPATHS.AUTHENTICATION_FAILED),
      },
      {
        label: ERROR_TYPE.CML_LOAD_FAILED,
        path: getFullRoutePath(ERROR_ROUTE_SUBPATHS.CML_LOAD_FAILED),
      },
      {
        label: ERROR_TYPE.COMPONENT_ERROR,
        path: getFullRoutePath(ERROR_ROUTE_SUBPATHS.COMPONENT_ERROR),
      },
      {
        label: ERROR_TYPE.EDLOS_LOAD_FAILED,
        path: getFullRoutePath(ERROR_ROUTE_SUBPATHS.EDLOS_LOAD_FAILED),
      },
      {
        label: ERROR_TYPE.GENERIC_ERROR,
        path: getFullRoutePath(ERROR_ROUTE_SUBPATHS.GENERIC_ERROR),
      },
      {
        label: ERROR_TYPE.INCENTIVE_UNAVAILABLE_FOR_PERIOD,
        path: getFullRoutePath(ERROR_ROUTE_SUBPATHS.INCENTIVE_UNAVAILABLE_FOR_PERIOD),
      },
      {
        label: ERROR_TYPE.INCORRECT_ACCOUNT,
        path: getFullRoutePath(ERROR_ROUTE_SUBPATHS.INCORRECT_ACCOUNT),
      },
      {
        label: ERROR_TYPE.PAGE_UNAVAILABLE,
        path: getFullRoutePath(ERROR_ROUTE_SUBPATHS.PAGE_UNAVAILABLE),
      },
      {
        label: ERROR_TYPE.SYSTEM_MAINTENANCE,
        path: getFullRoutePath(ERROR_ROUTE_SUBPATHS.SYSTEM_MAINTENANCE),
      },
      {
        label: ERROR_TYPE.UNSUPPORTED_BROWSER,
        path: getFullRoutePath(ERROR_ROUTE_SUBPATHS.UNSUPPORTED_BROWSER),
      },
      {
        label: ERROR_TYPE.USER_SESSION_EXPIRED,
        path: getFullRoutePath(ERROR_ROUTE_SUBPATHS.USER_SESSION_EXPIRED),
      },
    ],
  },
];
