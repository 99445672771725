const content: { [key: string]: string | any } = {
  text: "Sort By",
  options: {
    highLow: "High - Low",
    lowHigh: "Low - High",
    aZ: "A - Z",
    Za: "Z - A",
    descGraduationDate: "Graduation Record by Date",
  },
};

export default content;
