import { Markets } from "@amwaycommon/acc-es-data/model";
import { Config } from "../../common/interfaces/config";
import { ImpersonationDetails, AuthConfigs } from "../../common/interfaces/impersonation";
import { fetchConfig } from "../../common/util/config";
import { store } from "../../store";
import { setUser } from "../../reducers/boot";
import { SetLoginFields } from "../../reducers/model/boot";

export const setProgress = (progress: number) => {
  const progressBar = document.querySelector(".impersonation__bar") as HTMLElement;
  if (progressBar) {
    progressBar.style.width = `${progress}%`;
  }
};

export const loadAmwayIdAuthConfigs = (defaultConfig: Config): AuthConfigs => {
  return {
    UrlBase: defaultConfig.amwayIdAuthUrlBase!,
    AuthServer: defaultConfig.amwayIdAuthServer!,
    ClientId: defaultConfig.amwayIdClientId!,
    RedirectUri: defaultConfig.cppModAmwayIdRedirectUri!,
    IdpDomain: defaultConfig.amwayIdIdpDomain!,
    UseGluuAuth: defaultConfig.useGluuAuth,
    GluuTokenUrl: defaultConfig.gluuTokenUrl,
  };
};

export const parseJwt = (token: string) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    throw e;
  }
};

export const loadOktaAuthConfigs = (defaultConfig: Config): AuthConfigs => {
  return {
    UrlBase: defaultConfig.oktaUrlBase!,
    AuthServer: defaultConfig.oktaAuthServerId!,
    ClientId: defaultConfig.oktaClientId!,
    RedirectUri: defaultConfig.cppModOktaRedirectUri!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    IdpDomain: defaultConfig?.impersonationOkta?.idpDomain!,
  };
};

export const parseHash = (hash: string) => {
  return hash
    .substring(1)
    .split("&")
    .map((i) => i.split("="))
    .reduce((acc: any, cur: any) => {
      acc[cur[0]] = cur[1] || "";
      return acc;
    }, {});
};

export const normalizeLocale = (locale: string | null) => {
  if (locale) {
    const parts = locale.split("-");
    if (parts[0] && parts[1]) {
      locale = parts[0].toLowerCase() + "-" + parts[1].toUpperCase();
    }
  }
  return locale;
};

export const normalizeSsoOrigin = (ssOrigin: string | null) => {
  return ssOrigin ? ssOrigin.toUpperCase() : ssOrigin;
};

export const getLambdaEnvironment = (env: string) => {
  switch (env.toLowerCase()) {
    case "test":
    case "qa":
    default:
      return "preprod";
    case "prod":
      return "prod";
  }
};

export const parseScope = (scope: any) => {
  return decodeURIComponent(scope)
    .split("+")
    .map((i) => i.split("="))
    .reduce((acc: any, cur: any) => {
      acc[cur[0]] = cur[1] || "";
      return acc;
    }, {});
};

export const padAff = (aff: string) => {
  return `${"0".repeat(3 - aff.toString().length)}${aff.toString()}`;
};

export const redirectUnavailable = (navigate: Function) => {
  navigate("/error/pageUnavailable");
};

export const redirectRestricted = (navigate: Function) => {
  navigate("/error/userRestriction");
};

export const redirectAuthFailed = (navigate: Function) => {
  navigate("/error/authenticationFailed");
};

export const redirectComingSoon = (navigate: Function) => {
  navigate("/error/genericError");
};

export const redirectBusinessSelector = (businessList: any, navigate: Function) => {
  const businesses = encodeURIComponent(JSON.stringify(businessList));
  navigate(`/business-selector?businesses=${businesses}`);
};

export const redirectLocaleFailed = (navigate: Function) => {
  navigate("/error/invalidLocale");
};

export const redirectAccountFailed = (navigate: Function) => {
  navigate("/error/incorrectAccount");
};

export const affiliates = new Markets().list.map((m) => ({
  country: m.ctry,
  spcode: m.aff,
  label: m.name,
  iso: m.iso,
}));

export const authorization = (impersonationDetails: ImpersonationDetails) => {
  const { abo, partyId, locale, country, sameWindow, entryPage, targetAbo } = impersonationDetails;
  // Find the affiliate record
  const aff = affiliates.filter((aff) => parseInt(aff.country) === parseInt(country))[0];
  SetLoginFields({ fields: { ssoOrigin: aff.iso } });
  store.dispatch(setUser({ user: { isoCountryCode: aff.iso } }));

  if (partyId !== "" && partyId) {
    impersonateWithJwtAuth(abo, aff.spcode, partyId, locale, sameWindow!, entryPage, targetAbo);
  }
  // Mashery auth
  else {
    impersonateWithMasheryAuth(abo, aff.spcode, locale, sameWindow!, entryPage, targetAbo);
  }
};

/**
 * Kicks off the impersonation process with Mashery authentication
 * @param {number} abo
 * @param {string} spcode
 */
async function impersonateWithMasheryAuth(
  abo: string,
  spcode: string,
  locale: string,
  sameWindow: boolean,
  entryPage: string | undefined,
  targetAbo: string | undefined,
) {
  // not removing local storage here as this has to be taken out in another ticket abot-4332 and need some regression testing as well.
  // Load the configuration file
  await fetchConfig().then((config) => {
    const crypto = (window as any).crypto || (window as any).msCrypto;
    const rnd = crypto.getRandomValues(new Uint32Array(1))[0]; // Compliant for security-sensitive use cases

    const nonce = parseInt(((Date.now() * rnd) % Number.MAX_SAFE_INTEGER).toString()).toString();

    // Save some website params to localStorage
    // so they are persisted after auth
    SetLoginFields({
      fields: { locale: locale, loginOrigin: "impersonation", entryPage: entryPage, targetAbo: targetAbo },
    });

    const local = window.location.href.startsWith("http://localhost");
    const localJwtTokenReceiverUrl = "http://localhost:8000/token.html";
    const oktaRedirectUri = local ? localJwtTokenReceiverUrl : config.cppModTokenReceiverUri;

    const urlToSubmit = `${config.requestUrlBase}${config.apiAuthorizeURL}?response_type=token&client_id=${config.impersonationApiClientId}&scope=aboNum%3d${abo}+salesPlanAff%3d${spcode}&redirect_uri=${oktaRedirectUri}&nonce=${nonce}`;
    window.open(urlToSubmit, sameWindow ? "_self" : "_blank");
    // refreshRecentlyUsed();
  });
}

/**
 * Kicks off the impersonation process with JWT authentication
 * @param {number} abo
 * @param {string} spcode
 * @param {string} partyId
 */
async function impersonateWithJwtAuth(
  abo: string,
  spcode: string,
  partyId: string,
  locale: string,
  sameWindow: boolean,
  entryPage: string | undefined,
  targetAbo: string | undefined,
) {
  // We need to determine which Okta instance to call.
  // To do that, we need to determine the right market config file to load.
  // To do that, we need to know this user's ISO country code.
  // We can't get that info... but for this porpose, we can use
  // the ISO country code of the user's affiliate's primary market.

  // Temporarily store this in localStorage so our utility functions will use it.
  // This will be overwitten by the jwt receiver.

  try {
    await fetchConfig().then((config) => {
      // Save some website params to localStorage
      // so they are persisted after auth
      SetLoginFields({
        fields: {
          locale: locale,
          loginOrigin: "impersonation",
          entryPage: entryPage,
          targetAbo: targetAbo,
        },
      });

      const state = {
        aboNumber: abo,
        salesPlanAff: spcode,
        partyId: partyId,
      };
      const encodedState = window.btoa(JSON.stringify(state));

      const local = window.location.href.startsWith("http://localhost");
      const localJwtTokenReceiverUrl = "http://localhost:8000/jwt-receiver.html";
      const oktaRedirectUri = local ? localJwtTokenReceiverUrl : config.cppModOktaRedirectUri;

      const urlToSubmit = `${config.oktaUrlBase}${config.oktaAuthServer}?client_id=${config.oktaClientId}&response_type=code&scope=${config.impersonationOkta.oktaScope}&redirect_uri=${oktaRedirectUri}&state=${encodedState}`;
      window.open(urlToSubmit, sameWindow ? "_self" : "_blank");
    });
  } catch (error) {
    console.log("Error fetching config - configuration.json");
  }
}

export const isGatewayError = (e: { message: string }) =>
  e?.message && (e?.message.includes("504") || e?.message.includes("502"));
