import "./Maintenance.scss";
import { useTranslation } from "react-i18next";
import defaultContent from "../../content/maintenance";
import { IconAmway } from "../../common/icons/IconAmway/IconAmway";

export const MaintenancePage = () => {
  const { t } = useTranslation(["maintenance"]);

  return (
    <div className="maintenance">
      <IconAmway />
      <h1>{t("sorryMaintenance", defaultContent["sorryMaintenance"])}</h1>
      <p>{t("tryAgainLater", defaultContent["tryAgainLater"])}</p>
    </div>
  );
};
