import { useTranslation } from "react-i18next";
import "./ErrorTile.scss";
import { IconChain } from "../../icons/IconChain/IconChain";
import defaultContent from "../../../content/errorTile";
import { IconRefresh } from "../../icons/IconRefresh/IconRefresh";

type Props = {
  clickFunction: Function;
  errorStatus: boolean;
  isLoading: boolean;
  errorMessage?: string;
  btnText?: string;
};

export const ErrorTile = ({ clickFunction, errorStatus, isLoading, errorMessage, btnText }: Props) => {
  const { t } = useTranslation(["errorTile"]);

  const renderError = (
    <button className={"ErrorTile ErrorTile__try-again-btn"} onClick={() => clickFunction()}>
      <IconChain />
      <p className={"ErrorTile__error-msg"}>
        {errorMessage ? errorMessage : t("somethingWentWrong", defaultContent["somethingWentWrong"])}
      </p>
      <div className={"ErrorTile__try-again-btn"}>
        <span>
          <IconRefresh color="blue" />
        </span>
        <p>{btnText ? btnText : t("tryAgain", defaultContent["tryAgain"])}</p>
      </div>
    </button>
  );

  return errorStatus && !isLoading ? renderError : <></>;
};
