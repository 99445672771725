import LeafRectangleLoader from "../../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import "./MonthlyPerformanceTileLoader.scss";

type Props = {
  isLoading: boolean;
};

export const MonthlyPerformanceTileLoader = ({ isLoading }: Props) => {
  return (
    <div className={"mpd-loader-container"}>
      <div className={"mpd-loader-btn"}>
        <LeafRectangleLoader
          isLoading={isLoading}
          numRows={1}
          width="2.25rem"
          height="2.25rem"
          margin={"0 0 0.5rem 0"}
        />
      </div>
      <LeafRectangleLoader isLoading={isLoading} numRows={1} width="100%" height="2rem" margin={"0 0 0.5rem 0"} />
      <LeafRectangleLoader isLoading={isLoading} numRows={1} width="100%" height="1.5rem" margin={"0 0 0.75rem 0"} />
      <LeafRectangleLoader isLoading={isLoading} numRows={1} width="100%" height="4.5rem" margin={"0"} />
    </div>
  );
};
