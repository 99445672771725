import { useEffect, useState } from "react";
import { IconClose } from "../../icons/IconClose/IconClose";
import { IconSearch } from "../../icons/IconSearch/IconSearch";
import "./LeafSearchDropdown.scss";
import { LeafBackdrop } from "../LeafBackdrop/LeafBackdrop";

type Props = {
  handleSelect: Function;
  optionsMap: { [key: string]: string };
  placeholder?: string;
  selectedValue?: string;
};

/**
 * Common input with searchable dropdown.
 *
 * The optionsMap parameter is an object where each key represents a unique identifier
 * and each corresponding value represents the value displayed in the dropdown.
 *
 * If the input matches any part of the optionsMap "corresponding key value" it sets the key as the selectedKey.
 *
 * See AFF_MARKET_MAP in common/constants for an example.
 *
 * @param handleSelect Function
 * @param optionsMap Object containing key-value pairs of identifiers and displayed value
 */
export const LeafSearchDropdown = ({ handleSelect, optionsMap, placeholder = "", selectedValue = "" }: Props) => {
  const [inputText, setInputText] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const filteredOptions = Object.entries(optionsMap).filter(([_, item]) =>
    item.toLowerCase().includes(inputText.toLowerCase()),
  );

  useEffect(() => {
    if (selectedValue) {
      handleSelectOption(selectedValue);
    }
  }, [selectedValue]);

  const findSelectedKey = (item: string) =>
    Object.keys(optionsMap).find((key) => key.toLowerCase() === item.toLowerCase());

  const handleSelectOption = (item: string) => {
    const selectedKey = findSelectedKey(item);
    if (selectedKey) {
      setInputText(optionsMap[selectedKey]);
      setIsOpen(false);
      handleSelect(selectedKey);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputText(value);
    setIsOpen(value !== "");
    const selectedKey = findSelectedKey(value);
    if (selectedKey) {
      setInputText(optionsMap[selectedKey]);
      handleSelect(value);
      setIsOpen(false);
    } else {
      handleSelect(value);
    }
  };

  const handleOnFocus = () => {
    setIsOpen(!inputText ? true : false);
  };

  const handleDropdownToggle = () => {
    setInputText("");
    setIsOpen(!isOpen);
  };

  return (
    <div className="searchableDropdown">
      <div className={`searchableDropdown__input-container${isOpen ? "--active" : ""}`}>
        <input
          id="searchable-dropdown"
          name="searchable-dropdown"
          onChange={handleInputChange}
          value={inputText}
          placeholder={placeholder}
          className="searchableDropdown__input"
          onFocus={handleOnFocus}
          autoComplete="off"
        />
        <span
          className="searchableDropdown__toggle-btn"
          onClick={handleDropdownToggle}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleDropdownToggle();
            }
          }}
        >
          {inputText === "" ? <IconSearch /> : <IconClose height="1.25rem" width="1rem" />}
        </span>
      </div>
      {isOpen && (
        <>
          {" "}
          <LeafBackdrop variant="invisible" handleBackdropClick={() => setIsOpen(false)} />
          <div className="searchableDropdown__options-list-wrapper">
            <ul className="searchableDropdown__options-list" role="listbox" data-tooltip-hidden>
              {filteredOptions.map(([key, item]) => (
                <li
                  tabIndex={0}
                  key={key}
                  onClick={() => handleSelectOption(key)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSelectOption(key);
                    }
                  }}
                  className="searchableDropdown__option"
                  role="option"
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};
