const content: { [key: string]: string } = {
  positiveOrder: "<positive>{context}</positive> more orders than the previous month",
  negativeOrder: "<negative>{context}</negative> less orders than the previous month",
  neutralOrder: "<neutral>The same number</neutral> of orders as previous month",
  positiveCustomerPV: "<positive>{context}</positive> more Customer PV than the previous month",
  negativeCustomerPV: "<negative>{context}</negative> less Customer PV than the previous month",
  neutralCustomerPV: "<neutral>The same</neutral> Customer PV as previous month",
  positiveNewCustomer: "<positive>{context}</positive> more customers than the previous month",
  negativeNewCustomer: "<negative>{context}</negative> less customers than the previous month",
  neutralNewCustomer: "<neutral>The same number</neutral> of customers as previous month",
  newJoinerMessage: "Data is usually available after one performance month.",
};

export default content;
