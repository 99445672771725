import { useTranslation } from "react-i18next";
import defaultContent from "../../../../content/leafAboType";
import "./LeafABOType.scss";

type Props = {
  businessNature: number;
  registrationChannelCd: string;
};

const LeafABOType = (props: Props) => {
  const { t } = useTranslation(["leafAboType"]);
  const { businessNature, registrationChannelCd } = props;
  return (
    <div className="leaf-abo-type">
      {businessNature === 17 && registrationChannelCd === "ShareBar"
        ? t(`171`, defaultContent[`171`])
        : t(`${businessNature}`, defaultContent[businessNature])}
    </div>
  );
};

export default LeafABOType;
