const content: { [key: string]: string } = {
  title: "{month} Stats",
  newCust: "New Customers",
  customerPV: "Customer PV",
  vcsPV: "Verified Customer PV",
  nonVcsPv: "Non-Verified Customer PV",
  totalOrders: "Total Customer Orders",
  totalCustomers: "Total Customers",
  multipleOrders: "Multiple Orders",
  idleCustomers: "Idle Customers",
};

export default content;
