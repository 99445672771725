import { Trans, useTranslation } from "react-i18next";
import LeafPanel, { ModalProps } from "../../../../common/leaf/LeafPanel/LeafPanel";
import defaultContent from "../../../../content/newAboActivation";
import { LeafBadgeGrid as BadgeGrid, BonusHist } from "../../../../common/leaf/LeafBadgeGrid/LeafBadgeGrid";
import "./EligibleModal.scss";
import { AwardCap } from "../../../../common/interfaces/pathToBronze";
import { getPerformanceYearShort, getCurrentPeriod } from "../../../../common/util/period";
import { useAppSelector } from "../../../../store";

type EligibleProps = {
  bonusHistory: BonusHist[];
  awardCap: AwardCap;
  selectedPeriod: string;
} & ModalProps;

const EligibilityModal = ({ show, onCloseClick, title, bonusHistory, awardCap, selectedPeriod }: EligibleProps) => {
  const selectedPeriodPY = getPerformanceYearShort(selectedPeriod);
  const currentPY = getPerformanceYearShort(getCurrentPeriod());
  const { configuration } = useAppSelector((state) => state.boot);
  const show18MonthWindow =
    configuration.pathToBronze?.extendedWindowStart &&
    Number(selectedPeriod) >= configuration.pathToBronze.extendedWindowStart;

  const getModalHeader = () => {
    // Completed year (i.e selected period is not the current performance year)
    if (selectedPeriodPY !== currentPY) {
      return t("eligibleModal.closedYearHeader", defaultContent["eligibleModal"]["closedYearHeader"], {
        YY: currentPY,
      });
    }

    // ABO has earned the PTB incentive 1 - 5 times (in window)
    if (awardCap.actual > 0 && awardCap.actual < awardCap.target) {
      if (show18MonthWindow) {
        return t("eligibleModal.extended.earningHeader", defaultContent["eligibleModal"]["extended"]["earningHeader"], {
          x: awardCap.actual,
        });
      } else {
        return t("eligibleModal.earningHeader", defaultContent["eligibleModal"]["earningHeader"], {
          x: awardCap.actual,
          y: selectedPeriodPY,
        });
      }
    }

    // eligiblity met (graduated)
    if (awardCap.actual >= awardCap.target || awardCap.remaining === 0) {
      return t("eligibleModal.metHeader", defaultContent["eligibleModal"]["metHeader"]);
    }

    // ABO has not yet earned the PTB incentive
    return t("eligibleModal.notEarningHeader", defaultContent["eligibleModal"]["notEarningHeader"]);
  };

  const { t } = useTranslation(["newAboActivation"]);

  return (
    <LeafPanel id={"ptb-eligiblity-modal"} {...{ show, onCloseClick, title }}>
      <div className="eligible__card">
        <p className="eligible__card-title" data-testid="title">
          <Trans>{getModalHeader()}</Trans>
        </p>
        <p className="eligible__card-description">
          <Trans>
            {show18MonthWindow
              ? t(
                  "eligibleModal.extended.eligibleDescription",
                  defaultContent["eligibleModal"]["extended"]["eligibleDescription"],
                  {
                    max: awardCap.target,
                  },
                )
              : t("eligibleModal.eligibleDescription", defaultContent["eligibleModal"]["eligibleDescription"], {
                  max: awardCap.target,
                })}
          </Trans>
        </p>
        <BadgeGrid history={bonusHistory} />
      </div>
      <h1 className="eligible__description">
        <Trans>{t("eligibleModal.whoIsEligibleHeader", defaultContent["eligibleModal"]["whoIsEligibleHeader"])}</Trans>
      </h1>
      <div className="eligible__card">
        <Trans>
          {t("eligibleModal.whoIsEligibleDescription", defaultContent["eligibleModal"]["whoIsEligibleDescription"])}
        </Trans>
      </div>
    </LeafPanel>
  );
};

export default EligibilityModal;
