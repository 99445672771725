// Get country name based on country code
export const getCountryNameFromCode = (code: string, locale: string) => {
  try {
    const displayNames = new Intl.DisplayNames(locale, { type: "region" });
    return displayNames.of(code);
  } catch (error) {
    console.warn("Invalid Country Code", code);
  }
  return code;
};
