const content: { [key: string]: string } = {
  title: "Total Personal PV",
  subtitle: "Total:",
  ppv: "PPV",
  legendPersonalOrderPvText: "Personal Order PV",
  legendPersonalOrderPvValue: "({PV}) {PERCENTAGE}%",
  legendCustomerPvText: "Customer PV",
  legendCustomerPvValue: "({PV}) {PERCENTAGE}%",
};

export default content;
