import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setBoot } from "../boot";
import {
  FilterOption,
  FilterState,
  getFilterMenu,
  getQuickFilterMenu,
  getUpdatedFilterGroups,
  getUpdatedMenuConfig,
  updateFilterMenuEnableStatus,
} from "../model/filters";

export const CUSTOMER_FILTER_IDS = {
  VERIFIED: "verified",
  NO_VERIFIED: "noVerified",
  NEW_CUSTOMER: "newCustomer",
  CUSTOMER: "customer",
  MEMBER: "member",
  GUEST: "guest",
  SHAREBAR: "sharebar",
  CURRENT_RENEWAL_CUSTOMER: "isCurrentMonthRenewalDate",
};

const initialFilterGroup = [
  {
    group: "isVerified",
    queryParamValue: void 0,
    isMultipleOption: false,
    options: [
      {
        id: CUSTOMER_FILTER_IDS.VERIFIED,
        checked: false,
        quickFilter: true,
        value: true,
        enable: false,
      },
      {
        id: CUSTOMER_FILTER_IDS.NO_VERIFIED,
        checked: false,
        quickFilter: true,
        value: false,
        enable: false,
      },
    ],
  },
  {
    group: "isNew",
    queryParamValue: void 0,
    isMultipleOption: true,
    options: [
      {
        id: CUSTOMER_FILTER_IDS.NEW_CUSTOMER,
        checked: false,
        quickFilter: true,
        value: true,
        enable: false,
      },
    ],
  },
  {
    group: "businessNature",
    queryParamValue: void 0,
    isMultipleOption: false,
    divider: ",",
    options: [
      {
        id: CUSTOMER_FILTER_IDS.CUSTOMER,
        checked: false,
        quickFilter: false,
        value: 4,
        enable: false,
      },
      {
        id: CUSTOMER_FILTER_IDS.MEMBER,
        checked: false,
        quickFilter: false,
        value: 3,
        enable: false,
      },
      {
        id: CUSTOMER_FILTER_IDS.GUEST,
        checked: false,
        quickFilter: false,
        value: 170,
        enable: false,
      },
      {
        id: CUSTOMER_FILTER_IDS.SHAREBAR,
        checked: false,
        quickFilter: false,
        value: 171,
        enable: false,
      },
    ],
  },
  {
    group: "isCurrentMonthRenewalDate",
    queryParamValue: void 0,
    isMultipleOption: true,
    options: [
      {
        id: CUSTOMER_FILTER_IDS.CURRENT_RENEWAL_CUSTOMER,
        checked: false,
        quickFilter: false,
        value: true,
        enable: false,
      },
    ],
  },
];

const intialFilterMenu = getFilterMenu(initialFilterGroup);
const initialQuickFilterMenu = getQuickFilterMenu(intialFilterMenu);

const initialState: FilterState = {
  showFilterMenu: false,
  reset: true,
  filterGroups: initialFilterGroup,
  filterMenu: intialFilterMenu,
  quickFilterMenu: initialQuickFilterMenu,
  searchKeyword: "",
  filterCount: 0,
  filterLastUpdatedAt: 0,
};

const { actions, reducer } = createSlice({
  name: "customerFilter",
  initialState,
  reducers: {
    toggleFilterMenu: (state) => ({
      ...state,
      showFilterMenu: !state.showFilterMenu,
    }),
    // resets the filter to default options
    resetFilter: (state) => {
      const { filterMenu, filterGroups } = state;

      const _filterMenu = filterMenu.map((filter) => ({
        ...filter,
        checked: false,
      }));

      return {
        ...initialState,
        ...getUpdatedFilterGroups(filterGroups, _filterMenu),
        searchKeyword: state.searchKeyword,
        showFilterMenu: state.showFilterMenu,
      };
    },
    // updates the filter options after clicking on apply filters
    updateFilterOptions: (state, action: PayloadAction<FilterOption[]>) => {
      const { payload } = action;
      const { filterGroups } = state;
      const updatedFilters = getUpdatedFilterGroups(filterGroups, payload);
      return {
        ...state,
        ...updatedFilters,
      };
    },
    // updates single filter option, applicable for quickFilterMenu
    updateFilterOption: (state, action: PayloadAction<FilterOption>) => {
      const { payload } = action;
      const { filterGroups } = state;
      return {
        ...state,
        ...getUpdatedFilterGroups(filterGroups, [payload]),
      };
    },
    // search customer
    updateSearchKeyword: (state, action: PayloadAction<string>) => ({
      ...state,
      searchKeyword: action.payload,
      filterLastUpdatedAt: new Date().getTime(),
    }),
    // Resets the existing filter and applies the given filter
    updateSelectedFilter: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      const { filterMenu, filterGroups } = state;

      const _filterMenu = filterMenu.map((filter) => ({
        ...filter,
        checked: filter.id === payload,
      }));

      return {
        ...state,
        ...getUpdatedFilterGroups(filterGroups, _filterMenu),
      };
    },
    // update filter and quick filter menu
    updateFilterMenuByPeriod: (
      state,
      action: PayloadAction<{ filterOptions: FilterOption[]; toggleEnable: boolean }>,
    ) => {
      const { filterGroups } = state;
      const { payload } = action;
      const { filterOptions, toggleEnable } = payload;

      return {
        ...initialState,
        ...updateFilterMenuEnableStatus(filterGroups, filterOptions, toggleEnable),
        filterLastUpdatedAt: new Date().getTime(),
        searchKeyword: state.searchKeyword,
        showFilterMenu: state.showFilterMenu,
      };
    },
    // turn on/off for the menu option that suppose to display on current month only
    updateCurrentMonthOnlyFilterMenu: (
      state,
      action: PayloadAction<{ filterOptions: FilterOption[]; toggleEnable: boolean }>,
    ) => {
      const { filterGroups } = state;
      const { payload } = action;
      const { filterOptions, toggleEnable } = payload;

      return {
        ...initialState,
        ...updateFilterMenuEnableStatus(filterGroups, filterOptions, toggleEnable, "currentMonthOnly"),
        filterLastUpdatedAt: new Date().getTime(),
        searchKeyword: state.searchKeyword,
        showFilterMenu: state.showFilterMenu,
      };
    },
  },
  extraReducers: (builder) => {
    // listen to boot event and update filter configuration accordingly
    builder.addCase(setBoot, (state, { payload }) => {
      const {
        customerList: { filters },
      } = payload.configuration.globalCML;

      const { filterGroups, filterMenu } = state;
      const enableMenu = getUpdatedMenuConfig(filters, filterMenu);
      const updatedFilters = getUpdatedFilterGroups(filterGroups, enableMenu);

      return {
        ...state,
        ...updatedFilters,
        filterLastUpdatedAt: new Date().getTime(),
      };
    });
  },
});

export const {
  toggleFilterMenu,
  resetFilter,
  updateFilterOptions,
  updateFilterOption,
  updateSearchKeyword,
  updateSelectedFilter,
  updateFilterMenuByPeriod,
  updateCurrentMonthOnlyFilterMenu,
} = actions;

export default reducer;
