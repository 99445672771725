export enum REQUIREMENT_NAME {
  PERSONAL = "personal",
  BASIS_PLATINUM = "basisCheckForPlatinumWithEq",
  BASIS_FOUNDERS_PLATINUM = "basisCheckForFoundersPlatinumWithEq",
  BASIC_FOUNDERS_PLATINUM_AWARD = "basisCheckForFoundersPlatinumAward",
  BASIS_FOUNDERS_PLATINUM_ANA = "basisCheckForFoundersPlatinumWithEqAna",
  PERCENTAGE_FGI = "ytdVcsPercentageCheckForfrontlineGrowthIncentive",
  PERCENTAGE_PGG = "ytdVcsPercentageCheckForPersonalGroupGrowth",
  PERCENTAGE_PPV = "ytdVcsPercentageOfPpv",
  RUBY = "ruby",
  ANNUAL_RUBY = "annualRuby",
  EMERALD_BONUS_RECIPIENT = "emeraldBonusRecipient",
  CHECK_FOR_SILVER = "growthOrMaintainCheckForsilver",
  CHECK_FOR_PLATINUM = "growthOrMaintainCheckForPlatinum",
  LEADERSHIP_AND_FOSTER = "leadershipAndFoster",
  ANNUAL_PGG_TRACKING = "annualPggTracking",
  ANNUAL_PGGI_TRACKING = "annualPggiTracking",
  VCS_TO_PPV = "ratioOfVcsToPpvPersonalOts",
  ANNUAL_GPV = "annualGpv",
  MONTHLY_GPV = "monthlyGpv",
  NEW_FQS = "newFqs",
  DIAMOND_BONUS = "diamondBonus",
  MAINTAIN_GAR = "maintainGar",
  MAINTAIN_QC = "maintainQc",
}

export enum REQUIREMENT_TYPE {
  AWARD = "Award",
  VOLUME = "Volume",
  PERCENT = "PERCENT",
}

export enum MULTIPLIER_DATA_TYPE {
  LEG = "Leg",
  INCENTIVE = "Incentive",
}
