import { ChartDonut } from "@patternfly/react-charts";
import "./CustomerTypeDonut.scss";

type Props = {
  totalMemberTypeCustomer: number;
  totalGuestTypeCustomer: number;
  totalShareBarTypeCustomer: number;
  textContent: {
    legendPreferredCustomerText: string;
    legendGuestCustomerText: string;
    legendSharebarCustomerText: string;
  };
};

const CustomerTypeDonut = (props: Props) => {
  const { totalMemberTypeCustomer, totalGuestTypeCustomer, totalShareBarTypeCustomer, textContent } = props;
  return (
    <div className="customer-type-donut-chart__container">
      <div className="customer-type-donut-chart">
        <ChartDonut
          constrainToVisibleArea={true}
          data={[
            { x: textContent.legendPreferredCustomerText, y: totalMemberTypeCustomer },
            { x: textContent.legendGuestCustomerText, y: totalGuestTypeCustomer },
            { x: textContent.legendSharebarCustomerText, y: totalShareBarTypeCustomer },
          ]}
          labels={({ datum }) => null}
          colorScale={["#E4BCBA", "#EB8593", "#B6577E"]}
          innerRadius={70}
          padding={{
            left: 25,
            right: 25,
          }}
        />
      </div>
    </div>
  );
};

export default CustomerTypeDonut;
