import { QUALIFICATION_STATUS } from "../../common/enums/incentive-enums";
import { REQUIREMENT_NAME } from "../../common/enums/requirements";
import { PQRequirementsType } from "../../common/interfaces/leadershipSeminar";
import { LSOverviewResponse } from "./LSRequirements/LSRequirements";

export const mockPQRequirementData: PQRequirementsType = {
  PQHistory: [
    {
      Status: QUALIFICATION_STATUS.QUALIFIED,
      Period: "202309",
    },
    {
      Status: QUALIFICATION_STATUS.QUALIFIED,
      Period: "202310",
    },
    {
      Status: QUALIFICATION_STATUS.QUALIFIED,
      Period: "202311",
    },
    {
      Status: QUALIFICATION_STATUS.QUALIFIED,
      Period: "202312",
    },
    {
      Status: QUALIFICATION_STATUS.QUALIFIED,
      Period: "202401",
    },
    {
      Status: QUALIFICATION_STATUS.QUALIFIED,
      Period: "202402",
    },
    {
      Status: QUALIFICATION_STATUS.QUALIFIED,
      Period: "202403",
    },
    {
      Status: QUALIFICATION_STATUS.QUALIFIED,
      Period: "202404",
    },
    {
      Status: QUALIFICATION_STATUS.QUALIFIED,
      Period: "202405",
    },
    {
      Status: QUALIFICATION_STATUS.QUALIFIED,
      Period: "202406",
    },
    {
      Status: QUALIFICATION_STATUS.TRACKING,
      Period: "202407",
    },
    {
      Status: QUALIFICATION_STATUS.TRACKING,
      Period: "202408",
    },
  ],
  TotalPqs: 12,
  AchievedPqs: 10,
  Status: QUALIFICATION_STATUS.NOT_QUALIFIED,
  ShowPQRequirement: true,
};

export const requirementMockData = {
  VolumeRequirements: [
    {
      Name: REQUIREMENT_NAME.PERSONAL,
      Met: true,
      Exempt: false,
      Volume: {
        Current: {
          Actual: 20611.63,
          Formatted: "20,611.63",
        },
        Target: {
          Actual: 1800,
          Formatted: "1,800.00",
        },
        Remaining: {
          Actual: 0,
          Formatted: "0.00",
        },
      },
    },
  ],
  PercentRequirements: [
    {
      Name: REQUIREMENT_NAME.CHECK_FOR_PLATINUM,
      Met: true,
      Percent: {
        Current: {
          Actual: 94,
          Formatted: "94%",
        },
        Target: {
          Actual: 60,
          Formatted: "60%",
        },
        Remaining: {
          Actual: 0,
          Formatted: "0%",
        },
      },
      Volume: {
        Current: {
          Actual: 19375.350000000002,
          Formatted: "19,375.35",
        },
        Target: {
          Actual: 20611.629999999997,
          Formatted: "20,611.63",
        },
        Remaining: {
          Actual: 0,
          Formatted: "0.00",
        },
      },
    },
  ],
  BadgeRequirements: [
    {
      Name: REQUIREMENT_NAME.BASIS_FOUNDERS_PLATINUM,
      Met: true,
      Target: "1",
      Status: QUALIFICATION_STATUS.QUALIFIED,
      ShowLabel: true,
    },
  ],
  TotalRequirements: 3,
  RequirementsMet: 3,
  RequirementMetStatuses: [true, true, true],
};

export const lsOverviewResponseInitial: LSOverviewResponse = {
  Status: QUALIFICATION_STATUS.NOT_QUALIFIED,
  AnnualRequirements: {
    VolumeRequirements: [],
    PercentRequirements: [],
    BadgeRequirements: [],
    TotalRequirements: 0,
    RequirementsMet: 0,
    RequirementMetStatuses: [],
  },
  MonthlyRequirements: {
    MonthlyRubyRequirement: [],
    BadgeRequirements: [],
    TotalRequirements: 0,
    RequirementsMet: 0,
    RequirementMetStatuses: [],
  },
  AutomaticallyApproved: false,
  Options: {
    ShowOptions: false,
    FirstOption: {
      AnnualRequirements: {
        VolumeRequirements: [],
        PercentRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
        FQRequirement: undefined,
      },
      MonthlyRequirements: {
        MonthlyRubyRequirement: [],
        BadgeRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
      },
    },
    SecondOption: {
      AnnualRequirements: {
        VolumeRequirements: [],
        PercentRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
        FQRequirement: undefined,
      },
      MonthlyRequirements: {
        MonthlyRubyRequirement: [],
        BadgeRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
      },
    },
  },
  secondTime: false,
  hideRequirements: false,
  PQRequirementsData: {
    ShowPQRequirement: false,
    PQHistory: [],
    TotalPqs: 0,
    AchievedPqs: 0,
    Status: QUALIFICATION_STATUS.NOT_QUALIFIED,
  },
  CallResults: [],
  Error: undefined,
};

// Scenario 1 (Default)
export const lsOverviewResponseKRDefault: LSOverviewResponse = {
  Status: QUALIFICATION_STATUS.QUALIFIED,
  AnnualRequirements: {
    VolumeRequirements: [],
    PercentRequirements: [],
    BadgeRequirements: [
      {
        Name: REQUIREMENT_NAME.BASIS_FOUNDERS_PLATINUM,
        Met: true,
        Target: "1",
        Status: QUALIFICATION_STATUS.QUALIFIED,
        ShowLabel: true,
      },
    ],
    TotalRequirements: 1,
    RequirementsMet: 1,
    RequirementMetStatuses: [true],
  },
  MonthlyRequirements: {
    MonthlyRubyRequirement: [],
    BadgeRequirements: [],
    TotalRequirements: 0,
    RequirementsMet: 0,
    RequirementMetStatuses: [],
  },
  AutomaticallyApproved: false,
  Options: {
    ShowOptions: false,
    FirstOption: {
      AnnualRequirements: {
        VolumeRequirements: [],
        PercentRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
        FQRequirement: undefined,
      },
      MonthlyRequirements: {
        MonthlyRubyRequirement: [],
        BadgeRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
      },
    },

    SecondOption: {
      AnnualRequirements: {
        VolumeRequirements: [],
        PercentRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
        FQRequirement: undefined,
      },
      MonthlyRequirements: {
        MonthlyRubyRequirement: [],
        BadgeRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
      },
    },
  },
  secondTime: false,
  hideRequirements: false,
  PQRequirementsData: {
    ShowPQRequirement: false,
    PQHistory: [],
    TotalPqs: 0,
    AchievedPqs: 0,
    Status: QUALIFICATION_STATUS.QUALIFIED,
  },
  CallResults: [],
  Error: undefined,
};

export const lsOverviewResponseKRNewFQs: LSOverviewResponse = {
  Status: QUALIFICATION_STATUS.QUALIFIED,
  AnnualRequirements: {
    VolumeRequirements: [],
    PercentRequirements: [],
    BadgeRequirements: [],
    FQRequirement: {
      ShowFQRequirement: false,
      ShowFqTileByStack: false,
      FqStatusesMet: [true, true, false],
      TotalFqs: 3,
      Met: false,
      StatusCount: "8",
      TrackingCount: "10",
      CurrentYtdCount: "10+",
    },
    TotalRequirements: 1,
    RequirementsMet: 1,
    RequirementMetStatuses: [true],
  },
  MonthlyRequirements: {
    MonthlyRubyRequirement: [],
    BadgeRequirements: [],
    TotalRequirements: 0,
    RequirementsMet: 0,
    RequirementMetStatuses: [],
  },
  AutomaticallyApproved: false,
  Options: {
    ShowOptions: false,
    FirstOption: {
      AnnualRequirements: {
        VolumeRequirements: [],
        PercentRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
        FQRequirement: undefined,
      },
      MonthlyRequirements: {
        MonthlyRubyRequirement: [],
        BadgeRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
      },
    },

    SecondOption: {
      AnnualRequirements: {
        VolumeRequirements: [],
        PercentRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
        FQRequirement: undefined,
      },
      MonthlyRequirements: {
        MonthlyRubyRequirement: [],
        BadgeRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
      },
    },
  },
  secondTime: false,
  hideRequirements: false,
  PQRequirementsData: {
    ShowPQRequirement: false,
    PQHistory: [],
    TotalPqs: 0,
    AchievedPqs: 0,
    Status: QUALIFICATION_STATUS.QUALIFIED,
  },
  CallResults: [],
  Error: undefined,
};

// Scenario 2 - Monthly and Annual sections (secondTime: true)
export const lsOverviewResponseMonthlyAnnualLayout: LSOverviewResponse = {
  Status: QUALIFICATION_STATUS.QUALIFIED,
  AnnualRequirements: {
    VolumeRequirements: [],
    PercentRequirements: [],
    BadgeRequirements: [
      {
        Name: REQUIREMENT_NAME.BASIS_FOUNDERS_PLATINUM,
        Met: true,
        Target: "1",
        Status: QUALIFICATION_STATUS.QUALIFIED,
        ShowLabel: true,
      },
    ],
    FQRequirement: {
      ShowFQRequirement: false,
      ShowFqTileByStack: true,
      FqStatusesMet: [true, true, false],
      TotalFqs: 3,
      Met: false,
      StatusCount: "8",
      TrackingCount: "10",
      CurrentYtdCount: "10+",
    },
    TotalRequirements: 2,
    RequirementsMet: 1,
    RequirementMetStatuses: [true, false],
  },
  MonthlyRequirements: {
    MonthlyRubyRequirement: [
      {
        Name: REQUIREMENT_NAME.MONTHLY_GPV,
        RequirementsMet: {
          NoLegPVMet: false,
          WithLegPVMet: false,
        },
        Exempt: false,
        Volume: {
          Current: {
            Actual: 50000,
            Formatted: "50,000",
          },
          Target: {
            Actual: 100000,
            Formatted: "100,000",
          },
          TargetWithLeg: {
            Actual: 0,
            Formatted: "",
          },
          Remaining: {
            Actual: 50000,
            Formatted: "50,000",
          },
        },
        QualifiedLeg: {
          Actual: 0,
          Target: 0,
        },
        HasBooster: false,
      },
    ],
    BadgeRequirements: [],
    TotalRequirements: 1,
    RequirementsMet: 0,
    RequirementMetStatuses: [false],
  },
  AutomaticallyApproved: false,
  Options: {
    ShowOptions: false,
    FirstOption: {
      AnnualRequirements: {
        VolumeRequirements: [],
        PercentRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
        FQRequirement: undefined,
      },
      MonthlyRequirements: {
        MonthlyRubyRequirement: [],
        BadgeRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
      },
    },
    SecondOption: {
      AnnualRequirements: {
        VolumeRequirements: [],
        PercentRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
        FQRequirement: undefined,
      },
      MonthlyRequirements: {
        MonthlyRubyRequirement: [],
        BadgeRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
      },
    },
  },
  secondTime: true,
  hideRequirements: false,
  PQRequirementsData: {
    ShowPQRequirement: false,
    PQHistory: [],
    TotalPqs: 0,
    AchievedPqs: 0,
    Status: QUALIFICATION_STATUS.QUALIFIED,
  },
  CallResults: [],
  Error: undefined,
};

// Scenario 3 - Options Layout
export const lsOverviewResponseOptionsLayout: LSOverviewResponse = {
  Status: QUALIFICATION_STATUS.QUALIFIED,
  AnnualRequirements: {
    VolumeRequirements: [],
    PercentRequirements: [],
    BadgeRequirements: [],
    TotalRequirements: 0,
    RequirementsMet: 0,
    RequirementMetStatuses: [],
  },
  MonthlyRequirements: {
    MonthlyRubyRequirement: [],
    BadgeRequirements: [],
    TotalRequirements: 0,
    RequirementsMet: 0,
    RequirementMetStatuses: [],
  },
  AutomaticallyApproved: false,
  Options: {
    ShowOptions: true,
    FirstOption: {
      AnnualRequirements: {
        VolumeRequirements: [
          {
            Name: REQUIREMENT_NAME.ANNUAL_GPV,
            Met: true,
            Exempt: false,
            Volume: {
              Current: {
                Actual: 6800000,
                Formatted: "6,800,000",
              },
              Target: {
                Actual: 6800000,
                Formatted: "6,800,000",
              },
              Remaining: {
                Actual: 0,
                Formatted: "0.00",
              },
            },
          },
        ],
        PercentRequirements: [],
        BadgeRequirements: [
          {
            Name: REQUIREMENT_NAME.BASIS_FOUNDERS_PLATINUM,
            Met: true,
            Target: "1",
            Status: QUALIFICATION_STATUS.QUALIFIED,
            ShowLabel: true,
          },
        ],
        TotalRequirements: 3,
        RequirementsMet: 2,
        RequirementMetStatuses: [true, true, false],
        FQRequirement: {
          ShowFQRequirement: false,
          ShowFqTileByStack: false,
          FqStatusesMet: [],
          TotalFqs: 0,
          Met: false,
          StatusCount: "",
          TrackingCount: "",
          CurrentYtdCount: "",
        },
      },
      MonthlyRequirements: {
        MonthlyRubyRequirement: [],
        BadgeRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
      },
    },
    SecondOption: {
      AnnualRequirements: {
        VolumeRequirements: [],
        PercentRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
      },
      MonthlyRequirements: {
        MonthlyRubyRequirement: [
          {
            Name: REQUIREMENT_NAME.MONTHLY_GPV,
            RequirementsMet: {
              NoLegPVMet: false,
              WithLegPVMet: false,
            },
            Exempt: false,
            Volume: {
              Current: {
                Actual: 150000000,
                Formatted: "150,000,000",
              },
              Target: {
                Actual: 150000000,
                Formatted: "150,000,000",
              },
              TargetWithLeg: {
                Actual: 0,
                Formatted: "",
              },
              Remaining: {
                Actual: 0,
                Formatted: "0",
              },
            },
            QualifiedLeg: {
              Actual: 0,
              Target: 0,
            },
            HasBooster: false,
          },
        ],
        BadgeRequirements: [
          {
            Name: REQUIREMENT_NAME.BASIS_FOUNDERS_PLATINUM,
            Met: true,
            Target: "1",
            Status: QUALIFICATION_STATUS.QUALIFIED,
            ShowLabel: true,
          },
        ],
        TotalRequirements: 2,
        RequirementsMet: 1,
        RequirementMetStatuses: [false, true],
      },
    },
  },
  secondTime: true,
  hideRequirements: false,
  PQRequirementsData: {
    ShowPQRequirement: false,
    PQHistory: [],
    TotalPqs: 0,
    AchievedPqs: 0,
    Status: QUALIFICATION_STATUS.QUALIFIED,
  },
  CallResults: [],
  Error: undefined,
};

export const lsOverviewResponseAutomaticallyApproved: LSOverviewResponse = {
  Status: QUALIFICATION_STATUS.MANUALLY_APPROVED,
  AnnualRequirements: {
    VolumeRequirements: [],
    PercentRequirements: [],
    BadgeRequirements: [],
    TotalRequirements: 0,
    RequirementsMet: 0,
    RequirementMetStatuses: [],
  },
  MonthlyRequirements: {
    MonthlyRubyRequirement: [],
    BadgeRequirements: [],
    TotalRequirements: 0,
    RequirementsMet: 0,
    RequirementMetStatuses: [],
  },
  AutomaticallyApproved: true,
  Options: {
    ShowOptions: true,
    FirstOption: {
      AnnualRequirements: {
        VolumeRequirements: [],
        PercentRequirements: [],
        BadgeRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
        FQRequirement: {
          ShowFQRequirement: false,
          ShowFqTileByStack: false,
          FqStatusesMet: [],
          TotalFqs: 0,
          Met: false,
          StatusCount: "",
          TrackingCount: "",
          CurrentYtdCount: "",
        },
      },
      MonthlyRequirements: {
        MonthlyRubyRequirement: [],
        BadgeRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
      },
    },
    SecondOption: {
      AnnualRequirements: {
        VolumeRequirements: [],
        PercentRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
      },
      MonthlyRequirements: {
        MonthlyRubyRequirement: [],
        BadgeRequirements: [],
        TotalRequirements: 0,
        RequirementsMet: 0,
        RequirementMetStatuses: [],
      },
    },
  },
  secondTime: false,
  hideRequirements: true,
  PQRequirementsData: {
    ShowPQRequirement: false,
    PQHistory: [],
    TotalPqs: 0,
    AchievedPqs: 0,
    Status: QUALIFICATION_STATUS.QUALIFIED,
  },
  CallResults: [],
  Error: undefined,
};
