import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  showErrorPage: false,
  availableRouteCheckComplete: false,
  isCurrentPageAvailable: false,
};

const { actions, reducer } = createSlice({
  name: "componentLoader",
  initialState,
  reducers: {
    setShowErrorPage: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      return {
        ...state,
        showErrorPage: payload,
      };
    },
    setAvailableRouteCheckComplete: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      return {
        ...state,
        availableRouteCheckComplete: payload,
      };
    },
    setCurrentPageAvailable: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      return {
        ...state,
        isCurrentPageAvailable: payload,
        availableRouteCheckComplete: true,
      };
    },
  },
});

export const { setShowErrorPage, setAvailableRouteCheckComplete, setCurrentPageAvailable } = actions;

export default reducer;
