import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store";
import CustomerApplicantSlider from "./CustomerApplicantSlider/CustomerApplicantSlider";
import CustomerProfileSlider from "./CustomerProfileSlider/CustomerProfileSlider";

const CustomerProfile = () => {
  useTranslation([
    "customerProfileSlider",
    "customerProfileHeader",
    "leafApplicantCard",
    "customerTags",
    "leafAboName",
    "leafApplicantCard",
    "leafApplicantAboName",
    "leafAboType",
    "leafAboPhoneNumber",
    "leafAboEmail",
    "leafAboAddress",
    "leafPreferredLanguageInfo",
    "leafGenderInfo",
    "leafBirthdayInfo",
    "customerProfileTabViews",
    "overviewTab",
    "leafIdleCustomer",
    "customerAdditionalInfo",
    "leafRecentOrderDateInfo",
    "leafRecentReturnDateInfo",
    "leafPreferredLanguageInfo",
    "leafBirthdayInfo",
    "leafDateJoinedInfo",
    "leafRenewalDateInfo",
    "orderHistoryTab",
    "order",
    "orderType",
    "vcsReasons",
    "leafCustomerPV",
    "leafErrorMessage",
    "leafSortInfo",
    "common",
  ]);

  const boot = useAppSelector((state) => state.boot);
  const showCustomerProfile = boot?.configuration?.profile?.showCustomerProfile;

  if (showCustomerProfile === true) {
    return (
      <>
        <CustomerProfileSlider />
        <CustomerApplicantSlider />
      </>
    );
  }
  return <></>;
};

export default CustomerProfile;
