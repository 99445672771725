import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LeafDropdown } from "../../common/leaf/LeafDropdown/LeafDropdown";
import { getCountryFlag } from "../../common/util/getCountryFlag";
import "./BusinessSelector.scss";
import { setAboName } from "../../reducers/aboName";
import { SetBootForCurrentBusiness } from "../../reducers/model/boot";
import { setBusinessSelectorData } from "../../reducers/businessSelector";
import { IconDropdownCarrot } from "../../common/icons/IconDropdownCarrot/IconDropdownCarrot";
import { WheelPicker } from "../../common/leaf/LeafWheelPicker/LeafWheelPicker";
import { Variant } from "../../common/leaf/LeafButton/LeafButton";
import { setBootLoading } from "../../reducers/boot";
import { useAppDispatch, useAppSelector } from "../../store";
import { storedLocale } from "../impersonation/util";
import leafAboNameContent from "../../content/leafAboName";
import businessSelectorContent from "../../content/businessSelector";
import { Business, FetchBusinessSelectorData } from "./BusinessSelectorAPIDataLayer";

type Props = {
  viewState: "desktop" | "mobile";
  callbackOnBusinessChanged?: CallableFunction;
};

export type Selected = {
  isoCountry: string;
  abo: string;
  aboName: string;
  aff: string;
};

interface AboNameData {
  abo: string;
  aboName: string;
}

export const getPrimaryBusinessAboNumber = (businesses: Business[]): string => {
  if (!businesses) return "";
  const primaryBusiness = businesses.find((business) => business.isPrimary);
  return primaryBusiness && primaryBusiness.abo ? primaryBusiness.abo : "";
};

export const getAboNameData = (aboBusinesses: Business[], confidentialUser: string): AboNameData[] => {
  if (!aboBusinesses) return [{ aboName: "", abo: "" }];
  return aboBusinesses.map((d) => {
    const { aboName, aboLocalName, isoLanguage, privacyFlag } = d;
    const aboDisplayName = isoLanguage === storedLocale().substring(0, 2) ? aboLocalName : aboName;
    return {
      aboName: privacyFlag ? confidentialUser : aboDisplayName,
      abo: d.abo.toString(),
    };
  });
};

export const mapBusinessSelectorData = (businesses: Business[], confidentialUser: string): Business[] => {
  const aboNamesData = getAboNameData(businesses, confidentialUser);
  const aboMap: { [key: string]: string } = {};
  aboNamesData.forEach((aboInfo) => (aboMap[aboInfo.abo.toString()] = aboInfo.aboName));
  const mappedBusinessData = businesses.map((business) => {
    const aboName = aboMap[business.abo];
    return aboName ? { ...business, aboName, abo: `#${business.abo}` } : business;
  });

  return mappedBusinessData;
};

export const getDisabledOptions = (businesses: Business[], disabledAffs: string[]) => {
  if (!businesses) return [];
  return businesses.filter((b) => disabledAffs.includes(b.aff) || b.isoCountry === "").map((b) => b.abo);
};

export const BusinessSelector = ({ viewState, callbackOnBusinessChanged }: Props) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.boot);
  const aboName = useAppSelector((state) => state.aboName.name);
  const { abo: aboNum, aff, isoCountryCode: countryCode } = user;
  const { t } = useTranslation(["leafAboName", "businessSelector"]);
  const confidentialUserString = t("ConfidentialUser", leafAboNameContent["ConfidentialUser"]).toString();
  const dropdownLabel = t("dropdownLabel", businessSelectorContent["dropdownLabel"]);
  const { businessSelectorData } = useAppSelector((state) => state.businessSelectorData);

  let skip = false;
  if (businessSelectorData) {
    const primaryBusinessAboNum = getPrimaryBusinessAboNumber(businessSelectorData);
    if (primaryBusinessAboNum && primaryBusinessAboNum !== aboNum) {
      skip = true;
    }
  }

  const { errorStatus, data: busSelectorData } = FetchBusinessSelectorData(aff, aboNum, skip);
  const [dropdownData, setDropdownData] = useState<Business[]>(businessSelectorData);
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState<Selected>({
    aff: aff,
    abo: aboNum,
    aboName: aboName,
    isoCountry: countryCode,
  });

  useEffect(() => {
    if (user) {
      setSelectedOption({
        aff,
        abo: `#${aboNum}`,
        aboName: aboName,
        isoCountry: countryCode,
      });
    }
  }, [user, aboName, aboNum, aff, countryCode]);

  useEffect(() => {
    if (busSelectorData) {
      const { businesses } = busSelectorData;
      const businessSelectorData = mapBusinessSelectorData(businesses, confidentialUserString);
      setDropdownData(businessSelectorData);
      dispatch(setBusinessSelectorData(businessSelectorData));
    }
  }, [busSelectorData, dispatch, confidentialUserString]);

  const displayLeafDropdown = dropdownData && !errorStatus;

  const getKeyByValue = (array: Business[], value: Selected) => {
    return array.findIndex((k) => k.abo === value.abo && k.aff === value.aff);
  };

  const closeMobileBusinessSelector = () => {
    setIsMobileModalOpen(false);
  };

  const toggleMobileBusinessSelector = () => {
    setIsMobileModalOpen(!isMobileModalOpen);
  };

  const handleOnMobileSelectorSave = (dropdownDataIndex: number) => {
    handleSelectedOptionChange(dropdownData[dropdownDataIndex] as Selected);
    closeMobileBusinessSelector();

    if (callbackOnBusinessChanged) {
      callbackOnBusinessChanged();
    }
  };

  const renderWheelPickerBusinessSelector = () => {
    if (dropdownData.length <= 1) return renderBusinessSelectorNotClickable();

    return (
      <span
        id="busSelector"
        onClick={() => toggleMobileBusinessSelector()}
        className="busSelector__mobile"
        data-testid="busSelector_mobile"
      >
        <h1>{aboName}</h1>
        {displayLeafDropdown && (
          <>
            <span className={"busSelector__subheading"}>
              {getCountryFlag(countryCode)}
              <span>{countryCode}</span>
              <span>#{aboNum}</span>
              <span>{<IconDropdownCarrot open={isMobileModalOpen} />}</span>
            </span>
            <span onClick={(event) => event.stopPropagation()}>
              <WheelPicker
                selectedOptionKey={getKeyByValue(dropdownData, selectedOption)}
                renderTemplate={getWheelPickerRenderTemplate}
                callbackSetSelected={handleOnMobileSelectorSave}
                callbackOnClose={closeMobileBusinessSelector}
                data={dropdownData}
                disabledIndexesFromSelection={getChinaIndexesFromDropdownData()}
                leafButtonEnabledVariant={Variant.BLUE}
                leafButtonDisabledVariant={Variant.DISABLED_BLUE}
                isDisplayed={isMobileModalOpen}
              />
            </span>
          </>
        )}
      </span>
    );
  };

  /**
   * getChinaIndexesFromDropdownData()
   * Must pass the results to the wheel picker leaf, so it knows to render the
   * button to select an option as disabled. This code should not be in the leaf
   * because it is not reusable for all scenarios.
   */
  const getChinaIndexesFromDropdownData = () => {
    // BUSINESS LOGIC
    // ideally should come from an api...
    // disable CHN AFF (360) from selection
    const disabledIndexes: Array<number> = new Array<number>();
    dropdownData.forEach((abo, index) => {
      if (abo.aff === "360") {
        disabledIndexes.push(index);
      }
    });
    return disabledIndexes;
  };

  const getWheelPickerRenderTemplate = ({
    data,
    isSelected,
    isDisabled,
  }: {
    data: Business;
    isSelected: boolean;
    isDisabled: boolean;
  }) => {
    const getStyle = () => {
      let style = "WheelPickerColumn__item WheelPickerColumn__item--leftAligned";

      if (isDisabled) {
        style = "WheelPickerColumn__item WheelPickerColumn__item--disabled WheelPickerColumn__item--leftAligned";
      } else if (isSelected) {
        style = "WheelPickerColumn__item WheelPickerColumn__item--active WheelPickerColumn__item--leftAligned";
      }
      return style;
    };

    const id = `WheelPickerColumn__item:${dropdownData.findIndex((x) => x === data)}`;

    const style = getStyle();

    return (
      <li
        key={id}
        className={`WheelPickerColumn__container ${style}`}
        id={id}
        role="option"
        aria-selected={isSelected ? "true" : "false"}
      >
        <span>{getCountryFlag(data.isoCountry)}</span>
        <span>
          <p className={style}>{data.isoCountry}</p>
        </span>
        <span>
          <p className={style}>{data.abo}</p>
        </span>
        <span>
          <p className={`${style}`}>{data.aboName}</p>
        </span>
      </li>
    );
  };

  const renderBusinessSelectorNotClickable = () => {
    return (
      <span id="busSelector" className="busSelector__mobile" data-testid="busSelector_mobile__no_click">
        <h2>{selectedOption.aboName}</h2>
        {displayLeafDropdown && (
          <>
            <span className={"busSelector__subheading--no-pointer"}>
              {getCountryFlag(countryCode)}
              <span>{countryCode}</span>
              <span>#{aboNum}</span>
            </span>
          </>
        )}
      </span>
    );
  };

  const handleSelectedOptionChange = (selectedOption: Selected) => {
    dispatch(setAboName(selectedOption.aboName));
    const { aff, abo, isoCountry } = selectedOption;
    dispatch(setBootLoading(true));
    SetBootForCurrentBusiness({ user: { aff, abo: abo.replace(/^#/, ""), isoCountryCode: isoCountry } });
  };

  const renderDropdownBusinessSelector = () => {
    return (
      <span id="busSelector" className="busSelector__desktop" data-testid="busSelector_desktop">
        {displayLeafDropdown && (
          <LeafDropdown
            inputData={dropdownData}
            selectedIdentifier={selectedOption}
            options={{
              disabledOptions: getDisabledOptions(dropdownData, ["360"]),
              primaryOption: getPrimaryBusinessAboNumber(dropdownData),
              sortMethod: "aToZ",
            }}
            functions={{
              handleSelectedOptionChange: handleSelectedOptionChange,
              getIconFunction: getCountryFlag,
            }}
            keys={{
              iconKey: "isoCountry",
              colOneKey: "isoCountry",
              colTwoKey: "abo",
              colThreeKey: "aboName",
              idKeyOne: "abo",
              idKeyTwo: "aff",
            }}
            dropdownAriaLabel={dropdownLabel}
          />
        )}
      </span>
    );
  };

  const renderViewState = () => {
    if (viewState === "desktop") return renderDropdownBusinessSelector();
    if (viewState === "mobile") return renderWheelPickerBusinessSelector();

    return renderBusinessSelectorNotClickable();
  };

  return renderViewState();
};
