import { useTranslation } from "react-i18next";
import {
  AUTO_RENEWAL,
  CONTRACT_SIGNED,
  ORDER_BLOCK,
  SPONSOR_BLOCK,
} from "../../constants/additional-reg-info-constants";
import defaultContent from "../../../content/leafAdditionalRegInfo";
import "./LeafAdditionalRegInfo.scss";

type LabelFormat = "true_false" | "yes_no";
type Props = {
  title: typeof SPONSOR_BLOCK | typeof ORDER_BLOCK | typeof AUTO_RENEWAL | typeof CONTRACT_SIGNED;
  status: boolean;
  show?: boolean;
  labelFormat?: LabelFormat;
};

const getLabelKey = (status: boolean, format: LabelFormat): string => {
  switch (format) {
    case "true_false":
      return `${status}`;
    case "yes_no":
      return status ? "yes" : "no";
  }
};

const LeafAdditionalRegInfo = ({ title, status, show = true, labelFormat = "true_false" }: Props) => {
  const { t } = useTranslation(["leafAdditionalRegInfo"]);

  if (show === false) return <></>;

  const labelKey = getLabelKey(status, labelFormat);

  return (
    <div className="leaf-additional-reg-info">
      <div className="leaf-additional-reg-info__title" aria-label={`${t(title, defaultContent[title])}`}>
        {t(title, defaultContent[title])}
      </div>
      <div className="leaf-additional-reg-info__sub-title">{t(`${labelKey}`, defaultContent[`${labelKey}`])}</div>
    </div>
  );
};

export default LeafAdditionalRegInfo;
