import "./TabSwitcher.scss";

type Props = {
  Tabs: Array<Tab>;
  selectedTab: string;
  onTabClick: (tabId: string) => void;
  maxCharacters?: number;
};

type Tab = {
  id: string;
  text: string;
};

const TabSwitcher = ({ Tabs, maxCharacters = 5, selectedTab, onTabClick }: Props) => {
  const shouldWrapToggleSwitch = Tabs.some((tab) => tab.text.length >= maxCharacters);
  return (
    <div className={`TabSwitcher TabSwitcher${shouldWrapToggleSwitch ? "--column" : ""}`}>
      {Tabs.map((tab) => (
        <button
          key={tab.id}
          className={`TabSwitcher__button TabSwitcher__button${selectedTab === tab.id ? "--active" : ""}`}
          onClick={() => onTabClick(tab.id)}
        >
          {tab.text}
        </button>
      ))}
    </div>
  );
};

export default TabSwitcher;
