import "./CardsLoader.scss";
import React from "react";
import LeafRectangleLoader from "../../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";

type Props = {
  isLoading: boolean;
  View: React.ElementType;
  loaders: number;
  theme: string;
};

const CardsLoader = (props: Props) => {
  const { isLoading, View, loaders, theme } = props;
  const height = "1.75rem";
  const width = "100%";

  const RenderCardBox = () => {
    return (
      <div className="cards-loader__child">
        <div className="cards-loader__card">
          <div className="cards-loader__info">
            <LeafRectangleLoader isLoading={isLoading} height={height} width={width} />
          </div>
        </div>
      </div>
    );
  };

  const CardsLoaderView = () => {
    return (
      <div className="cards-loader">
        <div className="cards-loader__header">
          <LeafRectangleLoader isLoading={isLoading} height={height} width={width} />
        </div>
        <div className="cards-loader__container">
          <div className={`cards-loader__${theme}`}>
            {[...Array(loaders)].map((_item, index) => (
              <RenderCardBox key={index} />
            ))}
          </div>
        </div>
      </div>
    );
  };

  return isLoading === true ? <CardsLoaderView /> : <View />;
};

export default CardsLoader;
