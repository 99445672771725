import { ChartDonut } from "@patternfly/react-charts";
import "./PVGreyChart.scss";

const PVGreyChart = () => {
  return (
    <div className="grey-donut-chart__container">
      <div className="grey-donut-chart" data-testid="grey-chart">
        <ChartDonut
          constrainToVisibleArea={true}
          data={[{ x: "0", y: 100 }]}
          labels={({ datum }) => null}
          colorScale={["#F0F0F0"]}
          innerRadius={70}
          padding={{
            left: 25,
            right: 25,
          }}
        />
      </div>
    </div>
  );
};

export default PVGreyChart;
