import "./WheelPicker.scss";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import defaultContent from "../../../content/common";
import LeafButton, { Variant } from "../LeafButton/LeafButton";
import { WheelPickerColumn } from "./WheelPickerColumn/WheelPickerColumn";
import { BottomDrawer } from "./BottomDrawer/BottomDrawer";

export enum HighlightType {
  CENTERED_IN_VIEW,
  SELECTED,
}

export type WheelPickerProps = {
  renderTemplate: Function;
  selectedOptionKey: any;
  callbackSetSelected: CallableFunction;
  callbackOnClose?: CallableFunction;
  data: any;
  highlightType?: HighlightType;
  disabledIndexesFromSelection?: Array<number>;
  leafButtonEnabledVariant: Variant;
  leafButtonDisabledVariant: Variant;
  isDisplayed: boolean;
};

export const WheelPicker = ({
  renderTemplate,
  selectedOptionKey,
  callbackSetSelected,
  callbackOnClose,
  data,
  highlightType = HighlightType.CENTERED_IN_VIEW,
  disabledIndexesFromSelection,
  leafButtonEnabledVariant,
  leafButtonDisabledVariant,
  isDisplayed,
}: WheelPickerProps) => {
  const { t } = useTranslation(["common"]);
  const [selectedItemKey, setSelectedItemKey] = useState<number>(0);
  const [itemKeyInView, setItemKeyInView] = useState<number>(0);
  const [itemKeyInViewIsDisabled, setItemKeyInViewIsDisabled] = useState<any>();
  const [currentLeafButtonVariant, setCurrentLeafButtonVariant] = useState<Variant>(leafButtonEnabledVariant);

  // get selected changes from the other business selector
  useEffect(() => {
    setSelectedItemKey(selectedOptionKey);
  }, [selectedOptionKey]);

  const onItemInViewKeyChanged = (newItemKey: any) => {
    setItemKeyInView(newItemKey);
    setCurrentLeafButtonVariant(itemKeyInViewIsDisabled ? leafButtonDisabledVariant : leafButtonEnabledVariant);

    if (highlightType === HighlightType.CENTERED_IN_VIEW) {
      setSelectedItemKey(itemKeyInView);
    }
  };

  /**
   * grab any value that is currently about to
   * be selected in view inside the WheelPickerColumn
   */
  const onClickAction = () => {
    if (!itemKeyInViewIsDisabled) {
      if (highlightType === HighlightType.SELECTED) {
        setSelectedItemKey(itemKeyInView);
      }
      callbackSetSelected(selectedItemKey);
    }
  };

  const updateItemKeyInViewIsDisabled = (isShowingADisabledItemInView: boolean) => {
    setItemKeyInViewIsDisabled(isShowingADisabledItemInView);
  };

  return (
    <BottomDrawer isOpen={isDisplayed} callbackOnClose={callbackOnClose}>
      <div className="WheelPicker">
        <div className="WheelPicker__wheel">
          <WheelPickerColumn
            templateToRender={renderTemplate}
            dataToRender={data}
            disabledIndexesFromSelection={disabledIndexesFromSelection}
            selectedItemKey={selectedItemKey}
            callbackOnItemInViewKeyChanged={onItemInViewKeyChanged}
            callbackItemKeyInViewIsDisabled={updateItemKeyInViewIsDisabled}
            isOpen={isDisplayed}
          />
        </div>

        <div className="WheelPicker__selected-row"></div>

        <div className="WheelPicker__footer">
          <LeafButton
            ariaLabel={`${t("setButtonText", defaultContent["setButtonText"])}`}
            isDisabled={itemKeyInViewIsDisabled}
            variant={currentLeafButtonVariant}
            id={`WheelPicker__button`}
            clickAction={onClickAction}
          >
            {`${t("setButtonText", defaultContent["setButtonText"])}`}
          </LeafButton>
        </div>
      </div>
    </BottomDrawer>
  );
};
