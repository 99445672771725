import { ReactComponent as PersonPlus } from "./svg/IconPersonPlus.svg";
import "./IconPersonPlus.scss";

export const IconPersonPlus = ({
  color = "gray",
  size = "sm",
  id = "",
}: {
  color?: "green" | "gray";
  size?: "sm" | "md" | "lg";
  id?: string;
}) => {
  return (
    <span id={id} className={`IconPersonPlus IconPersonPlus--${size} IconPersonPlus--${color}`}>
      <PersonPlus />
    </span>
  );
};
