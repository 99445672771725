import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// const tabsConfiguration = ["eligible_tab", "ineligible_tab", "held_tab"];
const initialState = {
  defaultView: "eligible_tab",
  activeView: "eligible_tab",
  activeTabs: ["eligible_tab", "ineligible_tab", "held_tab"],
};

const { actions, reducer } = createSlice({
  name: "elibilityTab",
  initialState,
  reducers: {
    setActiveTabView: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      return {
        ...state,
        activeView: payload,
      };
    },
  },
});

export const { setActiveTabView } = actions;

export default reducer;
