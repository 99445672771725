import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setBoot } from "../boot";
import {
  FilterOption,
  FilterState,
  getFilterMenu,
  getQuickFilterMenu,
  getUpdatedFilterGroups,
  getUpdatedMenuConfig,
  updateFilterMenuEnableStatus,
} from "../model/filters";

export const ORDER_FILTER_IDS = {
  VCS: "vcs",
  NON_VCS: "nonVCS",
  RETURN: "return",
  ORDER: "order",
  UPLIFT: "uplift",
  ADJUSTMENT: "adjustment",
  REWORKS: "reworks",
  RMS: "rms",
  CVR: "cvr",
  MARS: "mars",
  BACKDATE: "backdate",
};

const initialFilterGroup = [
  {
    group: "filterOrderByVcs",
    queryParamValue: void 0,
    isMultipleOption: false,
    options: [
      {
        id: ORDER_FILTER_IDS.VCS,
        checked: false,
        quickFilter: true,
        value: "VCS",
        enable: false,
      },
      {
        id: ORDER_FILTER_IDS.NON_VCS,
        checked: false,
        quickFilter: true,
        value: "NON_VCS",
        enable: false,
      },
    ],
  },
  {
    group: "filterOrderBySourceType",
    queryParamValue: void 0,
    isMultipleOption: true,
    options: [
      {
        id: ORDER_FILTER_IDS.RETURN,
        checked: false,
        quickFilter: true,
        value: "004",
        enable: false,
      },
      {
        id: ORDER_FILTER_IDS.ORDER,
        checked: false,
        quickFilter: false,
        value: "003",
        enable: false,
      },
      {
        id: ORDER_FILTER_IDS.UPLIFT,
        checked: false,
        quickFilter: false,
        value: "140",
        enable: false,
      },
      {
        id: ORDER_FILTER_IDS.ADJUSTMENT,
        checked: false,
        quickFilter: false,
        value: "001",
        enable: false,
      },
      {
        id: ORDER_FILTER_IDS.REWORKS,
        checked: false,
        quickFilter: false,
        value: "006",
        enable: false,
      },
      {
        id: ORDER_FILTER_IDS.RMS,
        checked: false,
        quickFilter: false,
        value: "016",
        enable: false,
      },
      {
        id: ORDER_FILTER_IDS.CVR,
        checked: false,
        quickFilter: false,
        value: "110",
        enable: false,
      },
      {
        id: ORDER_FILTER_IDS.MARS,
        checked: false,
        quickFilter: false,
        value: "160",
        enable: false,
      },
      {
        id: ORDER_FILTER_IDS.BACKDATE,
        checked: false,
        quickFilter: false,
        value: "176",
        enable: false,
      },
    ],
  },
];

const intialFilterMenu = getFilterMenu(initialFilterGroup);
const initialQuickFilterMenu = getQuickFilterMenu(intialFilterMenu);

const initialState: FilterState = {
  showFilterMenu: false,
  reset: true,
  filterGroups: initialFilterGroup,
  filterMenu: intialFilterMenu,
  quickFilterMenu: initialQuickFilterMenu,
  searchKeyword: "",
  filterCount: 0,
  filterLastUpdatedAt: 0,
};

const { actions, reducer } = createSlice({
  name: "orderFilter",
  initialState,
  reducers: {
    toggleFilterMenu: (state) => ({
      ...state,
      showFilterMenu: !state.showFilterMenu,
    }),
    // resets the filter to default options
    resetFilter: (state) => {
      const { filterMenu, filterGroups } = state;

      const _filterMenu = filterMenu.map((filter) => ({
        ...filter,
        checked: false,
      }));

      return {
        ...state,
        ...initialState,
        ...getUpdatedFilterGroups(filterGroups, _filterMenu),
        searchKeyword: state.searchKeyword,
        showFilterMenu: state.showFilterMenu,
      };
    },
    // updates the filter options after clicking on apply filters
    updateFilterOptions: (state, action: PayloadAction<FilterOption[]>) => {
      const { payload } = action;
      const { filterGroups } = state;
      const updatedFilters = getUpdatedFilterGroups(filterGroups, payload);
      return {
        ...state,
        ...updatedFilters,
      };
    },
    // updates single filter option, applicable for quickFilterMenu
    updateFilterOption: (state, action: PayloadAction<FilterOption>) => {
      const { payload } = action;
      const { filterGroups } = state;
      return {
        ...state,
        ...getUpdatedFilterGroups(filterGroups, [payload]),
      };
    },
    // search order
    updateSearchKeyword: (state, action: PayloadAction<string>) => ({
      ...state,
      searchKeyword: action.payload,
      filterLastUpdatedAt: new Date().getTime(),
    }),
    // Resets the existing filter and applies the given filter
    updateSelectedFilter: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      const { filterMenu, filterGroups } = state;

      const _filterMenu = filterMenu.map((filter) => ({
        ...filter,
        checked: filter.id === payload,
      }));

      return {
        ...state,
        ...getUpdatedFilterGroups(filterGroups, _filterMenu),
      };
    },
    // Update filter menu based on period selected
    updateFilterMenuByPeriod: (
      state,
      action: PayloadAction<{ filterOptions: FilterOption[]; toggleEnable: boolean }>,
    ) => {
      const { filterOptions, toggleEnable } = action.payload;
      const { filterGroups } = state;

      return {
        ...initialState,
        ...updateFilterMenuEnableStatus(filterGroups, filterOptions, toggleEnable),
        filterLastUpdatedAt: new Date().getTime(),
        searchKeyword: state.searchKeyword,
        showFilterMenu: state.showFilterMenu,
      };
    },
  },
  extraReducers: (builder) => {
    // listen to boot event and update filter configuration accordingly
    builder.addCase(setBoot, (state, { payload }) => {
      const {
        orderList: { filters },
      } = payload.configuration.globalCML;

      const { filterGroups, filterMenu } = state;
      const enableMenu = getUpdatedMenuConfig(filters, filterMenu);
      const updatedFilters = getUpdatedFilterGroups(filterGroups, enableMenu);

      return {
        ...state,
        ...updatedFilters,
        filterLastUpdatedAt: new Date().getTime(),
      };
    });
  },
});

export const {
  toggleFilterMenu,
  resetFilter,
  updateFilterOptions,
  updateFilterOption,
  updateSearchKeyword,
  updateSelectedFilter,
  updateFilterMenuByPeriod,
} = actions;

export default reducer;
