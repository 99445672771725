import { BootType } from "../../reducers/boot";
import { loadTealiumScript } from "./tealium";

export const loadAwsRumConfig = (state: any) => {
  sessionStorage.setItem("awsRum", JSON.stringify(state.configuration.awsRum));
};

export const loadSiteTools = (state: BootType) => {
  if (!window.utag_data) {
    window.utag_data = {};
  }

  window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
  window.utag_cfg_ovrd = { noview: true };

  loadTealiumScript(state);
  loadAwsRumConfig(state);
};
