import { Markets } from "@amwaycommon/acc-es-data/model";
import { t } from "i18next";
import { Config } from "../../common/interfaces/config";
import { fetchConfig } from "../../common/util/config";
import { createMenuConfiguration, getDefaultHomePage } from "../../common/util/navigationUtil";
import { updateContentPathForMarket } from "../../i18n.config";
import { setAboName } from "../../reducers/aboName";
import { setShellConfig, setUser } from "../../reducers/boot";
import { setBusinessSelectorData } from "../../reducers/businessSelector";
import { LoginFields } from "../../reducers/loginLocalStorage";
import { SetLoginFields } from "../../reducers/model/boot";
import { LazyGetBusinessSelectorQueryType } from "../../services/businessSelectorAPI";
import { LazyGetShellConfigQueryType } from "../../services/getShellConfigAPI";
import { store } from "../../store";
import { mapBusinessSelectorData } from "../BusinessSelector/BusinessSelector";
import { getCurrentPeriod } from "../../common/util/period";
import {
  isGatewayError,
  normalizeLocale,
  normalizeSsoOrigin,
  parseHash,
  parseScope,
  redirectAuthFailed,
  redirectBusinessSelector,
  redirectComingSoon,
  redirectLocaleFailed,
  redirectUnavailable,
} from "./impersonation-util";
import { localeCodes } from "./locale-codes";
import { sessionLog } from "./util";

const MULTI_ACCOUNT_ERROR_PATTERN = /.*Multiple\+ABOShips\+Found.*/;

export const getScope = () => {
  const hash = parseHash(window.location.hash);
  return parseScope(hash.scope);
};

export const checkWebsiteUnavailable = (defaultConfig: Config, navigate: Function) => {
  if (defaultConfig.websiteUnavailable) {
    redirectUnavailable(navigate);
    return;
  }
};

export const logRedactedHash = () => {
  // Log the redacted hash
  let redactedHash = window.location.hash;
  redactedHash = redactedHash.replace(/access_token=[a-zA-Z0-9]+/, "access_token=REDACTED");
  redactedHash = redactedHash.replace(/refresh_token=[a-zA-Z0-9]+/, "refresh_token=REDACTED");
  sessionLog(`Token: receiver hash: ${redactedHash}`);
};

export const setlocalNSessionStorage = (defaultConfig: Config, localStorageFields: LoginFields, navigate: Function) => {
  // Attempt to determine the correct locale code when we receive an invalid code
  const localeData = localeCodes.filter(
    (item) => item.code === localStorageFields.locale || defaultConfig.defaultLocale,
  );
  if (localeData.length === 0) {
    redirectLocaleFailed(navigate);
    return;
  }

  // Session storage loginOrigin
  sessionStorage.setItem("loginOrigin", localStorageFields.loginOrigin!);
  SetLoginFields({ fields: { locale: localStorageFields.locale! || defaultConfig.defaultLocale! } });
};

export const getLoginFields = (): LoginFields => {
  // Grab fields
  // The localStorage fields are from the amway id redirect page.
  const params = new URLSearchParams(window.location.search);
  const hash = parseHash(window.location.hash);
  const fieldsfromStorage = store.getState().loginLocalStorage.loginFields;
  return {
    locale: normalizeLocale(params.get("locale")) || normalizeLocale(hash.locale) || fieldsfromStorage.locale,
    token: params.get("token") || "",
    ssoOrigin:
      normalizeSsoOrigin(params.get("ssoOrigin")) || normalizeSsoOrigin(hash.ssoOrigin) || fieldsfromStorage.ssoOrigin,
    entryPage: params.get("entryPage") || hash.entryPage || fieldsfromStorage.entryPage || "",
    targetAbo: params.get("targetAbo") || hash.targetAbo || fieldsfromStorage.targetAbo || "",
    loginOrigin: fieldsfromStorage.loginOrigin || "",
    impersonating: fieldsfromStorage.loginOrigin === "impersonation",
    cognitoToken: params.get("cognitoToken") || hash?.cognitoToken || fieldsfromStorage?.cognitoToken || "",
  };
};

export const getDisplayMode = () => {
  const params = new URLSearchParams(window.location.search);
  const hash = parseHash(window.location.hash);

  const headless = params.get("headless") || hash.headless;
  const footless = params.get("footless") || hash.footless;

  return {
    headless: headless === "true",
    footless: footless === "true",
  };
};

export const getPassthroughParameters = (loginFields: LoginFields) => {
  const passthroughParams = new URLSearchParams();

  const { headless, footless } = getDisplayMode();

  if (headless === true) {
    passthroughParams.set("headless", "true");
  }

  if (footless === true) {
    passthroughParams.set("footless", "true");
  }

  if (loginFields.targetAbo) {
    passthroughParams.set("targetAbo", loginFields.targetAbo);
  }

  const passthroughQueryString = passthroughParams.toString();

  return passthroughQueryString ? `?${passthroughQueryString}` : "";
};

export const checkForAuthError = (navigate: Function) => {
  const hash = parseHash(window.location.hash);
  // Check for auth error
  if ("error" in hash) {
    // Multiple businesses are associated this amway id account.
    // Parse and send to the business selector page.
    if (MULTI_ACCOUNT_ERROR_PATTERN.test(hash.error_description)) {
      const strings = decodeURIComponent(hash.scope).split(";");
      const businessList: { a: string; c: string }[] = [];
      strings.forEach((s) => {
        // Verify this scrope string has all the fields we need...
        if (/statusCd/.test(s) && /cntryCd/.test(s) && /accountId/.test(s)) {
          // Extract fields and build cusiness list
          const active = /statusCd=([A-Z]+)/.exec(s)![1] === "ACTIVE";
          if (active) {
            const isoCnty = /cntryCd=([A-Z]+)/.exec(s)![1];
            const abo = /accountId=([0-9]+)/.exec(s)![1];
            businessList.push({ a: abo, c: isoCnty });
          }
        }
      });
      redirectBusinessSelector(businessList, navigate);
      return;
    } else {
      redirectAuthFailed(navigate);
      return;
    }
  }
};

export const setHomePage = async (
  navigate: Function,
  getShellConfig: LazyGetShellConfigQueryType,
  getBusinessSelectorData: LazyGetBusinessSelectorQueryType,
) => {
  const progressBar = Array.from(
    document.getElementsByClassName("JwtImpersonation__bar") as HTMLCollectionOf<HTMLElement>,
  );
  progressBar[0].style.width = "70%";
  const state = await store.getState();
  const loginFields = getLoginFields();
  SetLoginFields({ fields: loginFields });
  const localStorageFields = state.loginLocalStorage.loginFields;
  // Service call to get this user's country code
  const boot = state.boot;
  const { configuration, user } = boot;
  const { aff, abo } = user;
  let shellData;
  let businessSelectorResponse;

  try {
    [shellData, businessSelectorResponse] = await Promise.all([
      getShellConfig(
        {
          baseUrl: configuration.shellLambdaUrlBase,
          affAbo: `${aff}-${abo}`,
          period: getCurrentPeriod(),
          faaWindow: configuration.faaEligibilityWindow,
        },
        true,
      ).unwrap(),
      getBusinessSelectorData({
        baseUrl: configuration.lambdaUrlBase,
        aff: aff,
        abo: abo,
        selectedPeriod: getCurrentPeriod(),
        useLite: true,
      }).unwrap(),
    ]);
  } catch (e: any) {
    sessionLog(`Service call failed. Error: '${e}'`);
    isGatewayError(e) ? redirectUnavailable(navigate) : redirectAuthFailed(navigate);
    return;
  }

  progressBar[0].style.width = "80%";

  // Get the country code from the service response
  let isoCountryCode = shellData.aboInfo.isoCountryCode;

  // if isoCountry code isn't returned from the service, let's use the aff code to get it
  if ((!isoCountryCode || isoCountryCode === "--") && user.aff) {
    isoCountryCode = new Markets().getByAff(user.aff)[0].iso;
  }

  const { name, localeName, isoLanguageCode } = shellData.aboInfo;
  const locale = localStorageFields.locale || boot.locale.languageCode;
  const showLocalizedName = locale.substring(0, 2) === isoLanguageCode;

  // display localized ABO Name if locales match and localized name exists from service
  const aboDisplayName = showLocalizedName ? (!localeName || localeName.length === 0 ? name : localeName) : name;

  store.dispatch(setAboName(aboDisplayName));
  store.dispatch(
    setShellConfig({
      ...shellData,
      showLogoutLink: !configuration.hideLogoutForMarkets.includes(isoCountryCode),
      menuConfig: createMenuConfiguration({ user, configuration }, shellData),
    }),
  );
  store.dispatch(setUser({ user: { isoCountryCode: isoCountryCode } }));
  await updateContentPathForMarket(isoCountryCode);

  // Check that we received a valid iso alpha 2 country code
  if (!/^[A-Z]{2}$/.test(isoCountryCode)) {
    sessionLog(`The country code received from the service failed validation. Code: '${isoCountryCode}'`);
    redirectAuthFailed(navigate);
    return;
  }

  SetLoginFields({ fields: { ssoOrigin: store.getState().loginLocalStorage.loginFields.ssoOrigin } });

  // Now that we have the real country code, load the market's config
  const marketConf = await fetchConfig();

  const { businesses = [] } = businessSelectorResponse;
  const businessSelectorData = mapBusinessSelectorData(
    businesses,
    t("ConfidentialUser", "Confidential User", { ns: "leafAboName" }).toString(),
  );
  store.dispatch(setBusinessSelectorData(businessSelectorData));

  progressBar[0].style.width = "90%";

  // Verify that this is a launched market
  if (!localStorageFields.impersonating && !marketConf.launchedMarkets.includes(isoCountryCode)) {
    sessionLog(`Unlaunched market. Sending to coming soon page.`);
    redirectComingSoon(navigate);
    return;
  }

  // Determine home page
  let homePage = getDefaultHomePage(marketConf.cppModHomePage);

  if (localStorageFields.entryPage && marketConf.cppModPages[localStorageFields.entryPage]) {
    homePage = marketConf.cppModPages[localStorageFields.entryPage];
  }

  const passthroughParameters = getPassthroughParameters(localStorageFields);

  // Clean up
  SetLoginFields({ fields: { entryPage: "", loginOrigin: "", targetAbo: "" } });

  // Log in!
  sessionLog("Successful login!");
  progressBar[0].style.width = "100%";
  window.location.replace(window.location.origin + homePage + passthroughParameters);
};

export const deleteCache = () => {
  caches.keys().then((keyList) => {
    return Promise.all(
      keyList.map((key) => {
        if (key.indexOf("$__acc") > -1) return caches.delete(key);
        return Promise.resolve();
      }),
    );
  });
};

export const deleteRequestCache = () => {
  const deleteDbRequestCache = indexedDB.deleteDatabase("RequestCache");
  deleteDbRequestCache.onerror = (event) => {
    console.warn("ACC-LOAD: Failed deleting RequestCache.");
  };
  deleteDbRequestCache.onsuccess = (event) => {
    console.log("ACC-LOAD: RequestCache deleted.");
  };
};

export const getApigeeAccessToken = async (configuration: any, authorizationCode: string) => {
  const requestBody =
    `client_id=${configuration.secureLinkApiClientId}` +
    `&grant_type=authorization_code` +
    `&code=${authorizationCode}` +
    `&redirect_uri=${encodeURI(configuration.tokenReceiverUri)}`;

  const fetchTokenResponse = await fetch(`${configuration.requestUrlBase}${configuration.apiTokenURL}`, {
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: requestBody,
  });

  return await fetchTokenResponse.json();
};
