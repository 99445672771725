import "./IconErrorLock.scss";
import { ReactComponent as ErrorLock } from "./svg/icon-error-lock.svg";

export const IconErrorLock = ({ id = "" }: { id?: string }) => {
  return (
    <span id={id} className="Icon-Lock">
      <ErrorLock />
    </span>
  );
};
