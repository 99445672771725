import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import { useGetSavedKpiPreferencesQuery } from "../../../../services/savedKpiPreferencesAPI";
import { SavedDataViews } from "../../../../common/interfaces/savedKpiPreferences";
import { useGetAnnualKeyMetricsQuery } from "../../../../services/annualPerformanceDashboardAPI";
import { AnnualKeyMetricsResponse } from "../../../../common/interfaces/annualPerformanceDashboard";
import { storedLocale } from "../../../impersonation/util";

export const defaultKeyMetricsData = (): AnnualKeyMetricsResponse => {
  return {
    CallResult: {
      responseBody: "",
      url: "",
      error: null,
      status: 0,
      duration: 0,
    },
    KeyMetrics: {
      superLegs: "0",
      spMonths: "0",
      annualGroupPV: "0",
      annualRubyPV: "0",
      annualVePV: "0",
      annualPersonalPV: "0",
    },
    error: null,
  };
};

export const FetchKeyMetricsData = ({
  period,
  performanceYear,
  availableKpis,
  savedKpiPreferencesData,
}: {
  period: string;
  performanceYear: string;
  availableKpis: Array<string>;
  savedKpiPreferencesData: SavedDataViews;
}) => {
  const { configuration, user } = useAppSelector((state) => state.boot);

  useGetSavedKpiPreferencesQuery(
    {
      baseUrl: configuration.mdmsGeoCacheDomain,
      abo: user.abo,
      aff: user.aff,
      api_key: configuration.magicApiKey,
      urlObj: {
        authorization: `${user.access_token}`,
        enableV4Apis: configuration.enableV4Apis,
      },
    },
    {
      skip: Boolean(savedKpiPreferencesData?.savedDataViews),
    },
  );

  const {
    data: payloadData,
    isFetching,
    isError,
    refetch,
  } = useGetAnnualKeyMetricsQuery({
    abo: user.abo,
    aff: user.aff,
    period,
    kpiList: availableKpis,
    performanceYear,
    locale: storedLocale(),
    market: user.isoCountryCode,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [keyMetricsData, setKeyMetricsData] = useState<AnnualKeyMetricsResponse>(defaultKeyMetricsData());

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (!isFetching && !isError && payloadData) {
      setKeyMetricsData(payloadData);
      setErrorStatus(payloadData.error != null);
    }
  }, [isFetching, period, isError, payloadData]);

  return { isLoading, keyMetricsData, errorStatus, refetch };
};
