import { useEffect, useState } from "react";
import { QUALIFICATION_STATUS } from "../../../common/enums/incentive-enums";
import { PggGcrOverviewResponse } from "../../../common/interfaces/groupGrowthIncentives";
import { lambdaErrorCheck } from "../../../common/util/errorHandling";
import { useAppSelector } from "../../../store";
import { storedLocale } from "../../impersonation/util";
import { MULTIPLIER_DATA_TYPE } from "../../../common/enums/requirements";
import { useGetPggGcrOverviewQuery } from "../../../services/personalGroupGrowthGCR";

export const defaultPggOverviewData = (): PggGcrOverviewResponse => {
  return {
    IncomeData: {
      PerformanceBonusLevelPercent: "",
      PotentialPYEarnings: "",
      IncentiveCap: "",
      Status: QUALIFICATION_STATUS.NOT_QUALIFIED,
    },
    IncentiveData: {
      MultiplierData: [
        {
          Type: MULTIPLIER_DATA_TYPE.INCENTIVE,
          Base: "",
          Multiplier: "",
          Total: "",
        },
      ],
      PQBase: "0",
      CurrentYTDPQMonths: "0",
      CurrentYTDSPMonths: "0",
      AnnualRubyPV: "0",
      TrackingPQMonths: "0",
    },
    Requirements: [
      {
        SPMonth: "0",
        MinAnnualRubyPV: "0",
        PQMonth: "0",
        HighlightRubyPV: false,
        HighlightSPMonth: false,
        HighlightPQMonth: false,
      },
    ],
    MonthTotals: [],
    MultiplierMatrix: {
      ColumnHeaders: [""],
      Rows: [],
      Legend: "",
    },
    DisplayRequirementTiles: true,
    CallResults: [],
    Error: [],
  };
};

export const FetchGcrPggOverviewData = (period: string, skip: boolean) => {
  const { user } = useAppSelector((state) => state.boot);
  const shellConfig = useAppSelector((state) => state.boot.shellConfig);
  const {
    data: payloadData,
    isFetching,
    isError,
    refetch,
  } = useGetPggGcrOverviewQuery(
    {
      abo: user.abo,
      aff: user.aff,
      period: period,
      isoCountryCode: user.isoCountryCode,
      isoCurrencyCode: shellConfig.isoCurrencyCode,
      locale: storedLocale(),
    },
    {
      skip,
    },
  );

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [PggGcrOverviewData, setPggGcrOverviewData] = useState<PggGcrOverviewResponse>(defaultPggOverviewData());

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (!isFetching && !isError && payloadData) {
      setPggGcrOverviewData(payloadData);
      const foundLambdaError = lambdaErrorCheck(payloadData.Error);
      setErrorStatus(foundLambdaError);
    }
  }, [isFetching, period, isError, payloadData]);

  return { isLoading, data: PggGcrOverviewData, errorStatus, refetch };
};
