import { QUALIFICATION_STATUS } from "../enums/incentive-enums";

export const getBannerTitleKey = (status: QUALIFICATION_STATUS, isNewPerformanceYear?: boolean) => {
  if (isNewPerformanceYear) {
    status = QUALIFICATION_STATUS.COMING_SOON;
  }
  switch (status) {
    case QUALIFICATION_STATUS.NOT_QUALIFIED:
      return "noLongerEligible";
    case QUALIFICATION_STATUS.NOT_TRACKING:
      return "notTracking";
    case QUALIFICATION_STATUS.TRACKING:
      return "tracking";
    case QUALIFICATION_STATUS.QUALIFIED:
      return "earned";
    case QUALIFICATION_STATUS.MANUALLY_APPROVED:
      return "manuallyApproved";
    case QUALIFICATION_STATUS.HELD:
      return "held";
    case QUALIFICATION_STATUS.DENIED:
      return "denied";
    case QUALIFICATION_STATUS.COMING_SOON:
      return "newPY";
    default:
      return "tracking";
  }
};

export const getBannerSubtitleKey = (status: QUALIFICATION_STATUS, isNewPerformanceYear?: boolean) => {
  if (isNewPerformanceYear) {
    status = QUALIFICATION_STATUS.COMING_SOON;
  }
  switch (status) {
    case QUALIFICATION_STATUS.COMING_SOON:
      return "newPYDesc";
    case QUALIFICATION_STATUS.MANUALLY_APPROVED:
    case QUALIFICATION_STATUS.HELD:
    case QUALIFICATION_STATUS.DENIED:
      return "contactCustomerService";
    default:
      return "description";
  }
};
