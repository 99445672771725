import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SORT_OPTIONS, SortOption, SortState, getEnabledSortOptions } from "../../model/bgtSort";
import { setBoot } from "../../boot";

export const initialSortBy: SortOption[] = [
  {
    id: SORT_OPTIONS.DESC_GRADUATION_DATE,
    text: "Graduation Record by Date",
    selected: true,
  },
];

const initialState: SortState = {
  sortBy: initialSortBy,
  selectedSort: initialSortBy[0],
};

const setDefaultSelected = (sortBy: SortOption[]) => {
  return sortBy.map((sortOption, index) => ({
    ...sortOption,
    selected: index === 0, // set first option to selected
  }));
};

const getUpdatedSortOptions = (sortList: SortOption[], option: SortOption) => {
  return sortList.map((sortOption) => ({
    ...sortOption,
    selected: sortOption.id === option.id,
  }));
};

const { actions, reducer } = createSlice({
  name: "graduatedSort",
  initialState,
  reducers: {
    // resets the sort to default options
    resetSort: (state) => {
      const { sortBy } = state;

      return {
        ...state,
        sortBy: setDefaultSelected(sortBy),
        selectedSort: initialSortBy[0],
      };
    },

    // updates sort list based on user selection
    updateSortOption: (state, action: PayloadAction<SortOption>) => {
      const { payload } = action;
      return {
        ...state,
        sortBy: getUpdatedSortOptions(state.sortBy, payload),
        selectedSort: payload,
      };
    },
  },
  extraReducers: (builder) => {
    // listen to boot event and update sort configuration accordingly
    builder.addCase(setBoot, (state, { payload }) => {
      const { sortBy: sortByConfig } = payload.configuration.bronzeGroupTracking.graduated;

      return {
        ...state,
        ...getEnabledSortOptions(state.sortBy, sortByConfig, "graduated"),
      };
    });
  },
});

export const { resetSort, updateSortOption } = actions;

export default reducer;
