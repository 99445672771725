import { CreateNoteBody, CreateNoteResponse } from "../reducers/model/events/createNote";
import { DeleteNoteParams, DeleteNoteResponse } from "../reducers/model/events/deleteNote";
import { GetNoteParams, GetNoteResponse } from "../reducers/model/events/getNote";
import { UpdateNoteParams, UpdateNoteResponse } from "../reducers/model/events/updateNote";
import { accAPI } from ".";

const basePath = "/events";

const TAG_GET_NOTE = "GetNote";

export const noteAPI = accAPI
  .enhanceEndpoints({
    addTagTypes: [TAG_GET_NOTE],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getNote: build.query<GetNoteResponse, GetNoteParams>({
        query: (params) => {
          const { affAbo, limit } = params;
          return `${basePath}/notes?affAbo=${affAbo}&limit=${limit}`;
        },
        providesTags: [{ type: TAG_GET_NOTE }],
      }),
      createNote: build.mutation<CreateNoteResponse, CreateNoteBody>({
        query: (body) => ({
          url: `${basePath}/notes`,
          method: "POST",
          body,
        }),
      }),
      updateNote: build.mutation<UpdateNoteResponse, UpdateNoteParams>({
        query: (params) => {
          const { body, noteId } = params;
          return {
            url: `${basePath}/notes/${noteId}`,
            method: "PUT",
            body,
          };
        },
      }),
      deleteNote: build.mutation<DeleteNoteResponse, DeleteNoteParams>({
        query: (params) => {
          const { noteId } = params;
          return {
            url: `${basePath}/notes/${noteId}`,
            method: "DELETE",
          };
        },
      }),
    }),
  });

export const {
  useGetNoteQuery,
  useLazyGetNoteQuery,
  useCreateNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
  util,
} = noteAPI;
