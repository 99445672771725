import { IconAlert } from "../../icons/IconAlert/IconAlert";
import "./LeafMessageBox.scss";

type Props = {
  color: "red" | "orange";
  children: any;
};

export const LeafMessageBox = ({ children, color }: Props) => {
  return (
    <div className={`LeafMessageBox ${color}`} data-testid={`leafMessageBox`}>
      <span className="icon" hidden={color === "red"} data-testid={`leafMessageBox-icon`}>
        <IconAlert />
      </span>
      <span>{children}</span>
    </div>
  );
};
