import { useEffect, useState } from "react";
import { useGetMonthlyPggOverviewQuery } from "../../../services/monthlyPGGAPI";
import { useAppSelector } from "../../../store";
import { storedLocale } from "../../impersonation/util";
import { MonthlyPGGOverviewResponse } from "../../../common/interfaces/groupGrowthIncentives";
import { lambdaErrorCheck } from "../../../common/util/errorHandling";
import { QUALIFICATION_STATUS } from "../../../common/enums/incentive-enums";

const defaultMonthlyPGGOverviewData = () => {
  return {
    IncomeData: {
      PerformanceBonusLevelPercent: "",
      PotentialPYEarnings: "",
      IncentiveCap: "",
      Status: QUALIFICATION_STATUS.NOT_QUALIFIED,
    },
    IncentiveData: {
      MultiplierData: [],
      YtdTotal: "",
    },
    PerformanceBonusData: [],
    MonthlyRequirements: {
      BadgeRequirements: [],
      MonthlyRubyRequirement: [],
      RequirementMetStatuses: [],
      TotalRequirements: 0,
      RequirementsMet: 0,
    },
    ShowDateRangeError: false,
    CallResults: [],
    Error: null,
  };
};

export const FetchMonthlyPGGOverviewData = (period: string) => {
  const { user } = useAppSelector((state) => state.boot);
  const shellConfig = useAppSelector((state) => state.boot.shellConfig);
  const {
    data: payloadData,
    isFetching,
    isError,
    refetch,
  } = useGetMonthlyPggOverviewQuery({
    abo: user.abo,
    aff: user.aff,
    period: period,
    isoCountryCode: user.isoCountryCode,
    isoCurrencyCode: shellConfig.isoCurrencyCode,
    locale: storedLocale(),
  });

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [monthlyPGGOverviewData, setMonthlyPggOverviewData] = useState<MonthlyPGGOverviewResponse>(
    defaultMonthlyPGGOverviewData(),
  );

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (!isFetching && !isError && payloadData) {
      setMonthlyPggOverviewData(payloadData);
      const foundLambdaError = lambdaErrorCheck(payloadData.Error);
      setErrorStatus(foundLambdaError);
    }
  }, [isFetching, period, isError, payloadData]);

  return { isLoading, monthlyPGGOverviewData, errorStatus, refetch };
};
