import { ReactNode, RefObject } from "react";
import LeafTileValue from "../../../common/leaf/LeafTileValue/LeafTileValue";
import "./IncentiveCalculation.scss";
import { IconMultiply } from "../../../common/icons/IconMultiply/IconMultiply";
import { IconEquals } from "../../../common/icons/IconEquals/IconEquals";
import { MULTIPLIER_DATA_TYPE } from "../../enums/requirements";

type IncentiveCalc = {
  title: string;
  amount: string;
  icon?: ReactNode;
  iconAriaLabel?: string;
  symbolIcon?: ReactNode;
  callbackFn?: Function;
  ref?: RefObject<HTMLButtonElement>;
  hideDesktopIcon?: boolean;
  hideMobileIcon?: boolean;
  type?: MULTIPLIER_DATA_TYPE;
};

type Props = {
  isLoading: boolean;
  base: IncentiveCalc;
  multiplier: IncentiveCalc;
  total: IncentiveCalc;
  isExpanded?: boolean;
};

export const IncentiveCalculation = ({ isLoading, base, multiplier, total, isExpanded }: Props) => {
  return (
    <div className="IncentiveCalculation">
      <LeafTileValue
        id="multiplier-base"
        isLoading={isLoading}
        title={base.title}
        value={base.amount}
        icon={base?.icon ?? <></>}
        iconAriaLabel={base?.iconAriaLabel}
        callback={base?.callbackFn}
        hideDesktopIcon={base?.hideDesktopIcon}
        hideMobileIcon={base?.hideMobileIcon}
      />
      <span className="IncentiveCalculation__symbol IncentiveCalculation__multiplierSymbol">
        {multiplier?.symbolIcon || <IconMultiply />}
      </span>
      <LeafTileValue
        id={
          isExpanded && multiplier.type === MULTIPLIER_DATA_TYPE.INCENTIVE
            ? "IncentiveCalculation-multiplier"
            : "multiplier-base"
        }
        isLoading={isLoading}
        title={multiplier.title}
        value={multiplier.amount}
        icon={multiplier?.icon ?? <></>}
        iconAriaLabel={base?.iconAriaLabel}
        callback={multiplier?.callbackFn}
        symbolIcon={multiplier?.symbolIcon ?? <></>}
        buttonRef={multiplier.ref}
        hideDesktopIcon={multiplier?.hideDesktopIcon}
        hideMobileIcon={multiplier?.hideMobileIcon}
      />
      <div className="IncentiveCalculation__divider" />
      <span className="IncentiveCalculation__symbol IncentiveCalculation__equalSymbol">
        <IconEquals />
      </span>
      <LeafTileValue
        id="multiplier-base"
        isLoading={isLoading}
        title={total.title}
        value={total.amount}
        icon={total?.icon ?? <></>}
        iconAriaLabel={base?.iconAriaLabel}
        callback={total?.callbackFn}
        symbolIcon={total?.symbolIcon ?? <></>}
        hideDesktopIcon={total?.hideDesktopIcon}
        hideMobileIcon={total?.hideMobileIcon}
      />
    </div>
  );
};
