import {
  CustomerPVChartResponse,
  CustomerPVChartParams,
  CustomerPVChartTransformResponse,
} from "../reducers/model/overview/customerPVChart";
import { accAPI } from ".";

export const customerPVChartAPI = accAPI.injectEndpoints({
  endpoints: (build) => ({
    getCustomerPVChart: build.query<CustomerPVChartTransformResponse, CustomerPVChartParams>({
      query: (params) => {
        const { period, affAbo } = params;
        const query = `{aboDetail(period:"${period}",affAbo:"${affAbo}"){pvbv{customerPV,personalPV,vcsPV,nonVCSPV,otherNonVCSPV}}}`;
        const queryParams = new URLSearchParams({
          query,
        });
        return `/gql/los/graphql?${queryParams}`;
      },
      transformResponse: (response: CustomerPVChartResponse) => {
        const { data } = response || {};
        const { aboDetail } = data || {};
        const { pvbv } = aboDetail || {};
        const { customerPV = 0, personalPV = 0, vcsPV = 0, nonVCSPV = 0, otherNonVCSPV = 0 } = pvbv || {};
        const isValidCustomerPV = [vcsPV, nonVCSPV, otherNonVCSPV].every((pv) => pv <= customerPV && pv >= 0);

        return {
          customerPV,
          personalPV,
          vcsPV,
          nonVCSPV,
          otherNonVCSPV,
          isValidCustomerPV,
        };
      },
    }),
  }),
});

export const { useGetCustomerPVChartQuery } = customerPVChartAPI;
