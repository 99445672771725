import LeafRectangleLoader from "../../LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import "./LeafProfileLoader.scss";

type Props = {
  row: number;
  applicant?: boolean;
  card?: boolean;
  isFetching: boolean;
};

const LeafProfileLoader = (props: Props) => {
  const { row, applicant, card, isFetching } = props;

  if (isFetching === false) {
    return <></>;
  }

  return (
    <>
      {[...Array(row)].map((_item, index) => (
        <div className={`leaf-profile-loader`} key={index}>
          <div className={`leaf-profile-loader${card ? "--active-card" : ""}${applicant ? "--applicant-card" : ""}`}>
            <LeafRectangleLoader isLoading={true} width={"100%"} height={`1.75rem`} />
          </div>
        </div>
      ))}
    </>
  );
};

export default LeafProfileLoader;
