import { JwtToken, UrlObject } from "../interfaces/constructUrl";

/**
 * Parse JWT token to JSON
 * @param token
 * @returns parsed JWT token
 */
const jwtToJson = (token: string): JwtToken | null => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join(""),
    );
    return JSON.parse(jsonPayload) as JwtToken;
  } catch (e) {
    return null;
  }
};

export const isV4Compatible = (tokenString: string): boolean => {
  const token = jwtToJson(tokenString);
  return Boolean(
    token && token.scp && token.scp.includes("bonus:all:read") && token.scp.includes("mdms:uberprofile:read"),
  );
};

export const getVersion = (urlObj: UrlObject): string => {
  return isV4(urlObj) ? "v4" : "v3";
};

export const isV4 = (urlObj: UrlObject): boolean => {
  const { authorization, enableV4Apis } = urlObj;
  return enableV4Apis && isV4Compatible(authorization);
};
