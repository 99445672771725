import { useTranslation } from "react-i18next";
import { storedLocale } from "../../../../components/impersonation/util";
import defaultContent from "../../../../content/leafAboName";
import "./LeafABOName.scss";

type Props = {
  name: string;
  localName: string;
  privacyFlag: boolean;
  aboNo?: string;
  languageCode: string;
  fontSize?: "small" | "normal" | "medium" | "big" | "huge" | "large";
  fontWeight?: "thin" | "regular" | "medium" | "semibold" | "bold";
};

type ABONameDetails = Pick<Props, "name" | "localName" | "privacyFlag" | "languageCode" | "aboNo">;

export const getABOName = ({
  name,
  localName,
  privacyFlag,
  languageCode,
  aboNo,
  confidentialText,
  notAvailableText,
}: ABONameDetails & {
  confidentialText: string;
  notAvailableText: string;
}) => {
  const selectedLanguageCode = storedLocale().split("-")[0];
  const displayLocalLanguage = languageCode === selectedLanguageCode;
  const selectedName = displayLocalLanguage === true ? localName : name;
  const shouldDisplayNA = privacyFlag === null && (!selectedName || selectedName === "--");
  const aboName = aboNo ? `${selectedName} #${aboNo}` : selectedName;

  if (shouldDisplayNA) {
    return notAvailableText;
  }
  return privacyFlag === true ? confidentialText : aboName;
};

const LeafABOName = (props: Props) => {
  const { t } = useTranslation(["leafAboName"]);

  const { fontSize, fontWeight } = props;

  const confidentialText = t("ConfidentialUser", defaultContent["ConfidentialUser"]);
  const notAvailableText = t("notAvailable", defaultContent["notAvailable"]);

  return (
    <span className={`${fontSize ? `abo-name--${fontSize} ` : ""}${fontWeight ? `abo-name--${fontWeight}` : ""}`}>
      {getABOName({ ...props, confidentialText, notAvailableText })}
    </span>
  );
};

export default LeafABOName;
