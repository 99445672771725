import "./IconBack.scss";

type Props = {
  width: string;
  height: string;
};

export const IconBack = (props: Props) => {
  const { width, height } = props;
  return <span className="icon-back" style={{ width, height }}></span>;
};
