import { ReactEventHandler } from "react";
import "./OverviewTab.scss";
import { getLoginAffAbo } from "../../../../../../reducers/model/boot";
import { useGetOrderListQuery } from "../../../../../../services/customerOrderListAPI";
import { useGetCustomerListQuery } from "../../../../../../services/customerListAPI";
import { useAppSelector } from "../../../../../../store";
import { LeafErrorMessage } from "../../../../../../common/leaf/LeafErrorMessage/LeafErrorMessage";
import { profileOverviewTabErrorTrack } from "../../../../../../common/tealium/CustomerProfile/Profile/ProfileTabViewsTrack/OverviewTabTrack";
import { includeGuestCustomerBN } from "../../../../../../reducers/model/customerList";
import { getCurrentPeriod } from "../../../../../../common/util/period";
import RecentOrderListView from "./RecentOrderListView/RecentOrderListView";
import IdleCustomerView from "./IdleCustomerView/IdleCustomerView";

type Props = {
  viewAllOnClick: ReactEventHandler;
};

const OverviewTab = (props: Props) => {
  const { viewAllOnClick } = props;

  const profileABO = useAppSelector((state) => state.profile.affAbo);
  const customerId = Number(profileABO.split("-")[1]); // extract customer number only

  const { selectedPeriod } = useAppSelector(({ period }) => period);

  const boot = useAppSelector(({ boot }) => boot);
  const affAbo = getLoginAffAbo({ boot });
  const enableGuestCustomer = boot?.configuration?.globalCML?.enableGuestCustomer;

  const businessNature = includeGuestCustomerBN(enableGuestCustomer);

  const {
    isFetching: isFetchingIdle,
    isError: isErrorIdle,
    data: customerData,
  } = useGetCustomerListQuery({
    affAbo,
    period: getCurrentPeriod(),
    pageNum: 1,
    pageSize: 1,
    sortValue: "LAST_ORDER_DATE",
    sortOrder: "DESC",
    customerIds: `${customerId}`,
    ...businessNature,
  });

  if (isErrorIdle === true) {
    profileOverviewTabErrorTrack();
  }

  const { isFetching: isFetchingOrder, data: orderData } = useGetOrderListQuery({
    affAbo,
    period: selectedPeriod,
    pageNum: 1,
    pageSize: 1,
    sortValue: "ORDER_DATE",
    sortOrder: "DESC",
    customerIds: customerId,
    ...businessNature,
  });

  return (
    <div className="overview-tab">
      <LeafErrorMessage show={isErrorIdle} />
      <div className="overview-tab__idle-customer">
        <IdleCustomerView isFetching={isFetchingIdle} customerData={customerData} profileABO={profileABO} />
      </div>
      <RecentOrderListView isFetching={isFetchingOrder} order={orderData} viewAllOnClick={viewAllOnClick} />
    </div>
  );
};

export default OverviewTab;
