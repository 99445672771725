import React, { useState, useEffect, useRef } from "react";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import defaultContent from "../../../content/wheelSpinnerColumn";
import "./WheelSpinnerColumn.scss";

export const WheelSpinnerColumn = ({
  options,
  value,
  onChange,
  isOpen,
  formatter,
}: {
  options: Array<string>;
  value: string;
  onChange: Function;
  isOpen: boolean;
  formatter: Function;
}) => {
  const scroller = useRef<HTMLUListElement>(null);
  useEffect(() => {
    if (isOpen) {
      const selectedIndex = options.indexOf(value);
      if (scroller.current && scroller.current.scrollTop !== selectedIndex * 40) {
        scrollToSelected(selectedIndex, scroller);
      }
    }
  }, [value, options, isOpen]);

  const [updating, setIsUpdating] = useState(false);
  const updatePosY = debounce((y) => {
    return setSelectedItem(options[y]);
  }, 100);

  const { t } = useTranslation(["wheelSpinnerColumn"]);

  const handleScroll = (e: React.UIEvent<HTMLUListElement>) => {
    e.preventDefault();
    if (updating || !isOpen) {
      return;
    }
    const scrollYFromTop = e.currentTarget.scrollTop;
    const findNearestItem = Math.round(scrollYFromTop / 40);

    updatePosY(findNearestItem);
  };

  const scrollToSelected = (y: number, container: any) => {
    setIsUpdating(true);
    container.current.scrollTo({ top: y * 40, behavior: "instant" });
    setTimeout(() => setIsUpdating(false), 0);
  };

  const onValueSelected = (newValue: any) => {
    onChange(newValue);
  };

  const setSelectedItem = (option: string) => {
    if (option !== value) {
      onValueSelected(option);
    }
  };

  return (
    <ul
      className="wheel-column"
      onScroll={handleScroll}
      ref={scroller}
      aria-label={`${t("bonusPeriodList", defaultContent["bonusPeriodList"])}`}
      role="listbox"
    >
      {options.map((option: string, index: number) => {
        const className = option === value ? "wheel-column__item--active" : "wheel-column__item";
        return (
          <li
            key={index}
            className={className}
            id={`wheel-column__item:${option}`}
            role="option"
            aria-selected={option === value}
            tabIndex={0}
          >
            {formatter(option)}
          </li>
        );
      })}
    </ul>
  );
};
