/**
 * Prevents page scrolling when modal is open
 * @param {boolean} shouldLock
 */
export const lockBodyScrolling = (shouldLock: boolean) => {
  if (shouldLock) {
    document.body.style.overflow = "hidden";
    document.body.style.height = "100vh";
  } else {
    document.body.style.overflow = "initial";
    document.body.style.height = "initial";
  }
};
