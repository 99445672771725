import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import TEALIUM from "../../../../common/enums/tealium-enums";
import defaultContent from "../../../../content/frontlineGrowth";
import { IconPlainTable } from "../../../../common/icons/IconPlainTable/IconPlainTable";
import { FetchMultiplerDetailsData } from "../MultiplierDetailsDataLayer";
import { MultiplierDetail } from "../../../../common/components/MultiplierDetail/MultiplierDetail";
import { setModalBtnFocus } from "../MultiplierDetailsUtil";
import { CurrentFQModal } from "./CurrentFQModal/CurrentFQModal";

interface Props {
  currentFQs: string;
  prevOpenRef: MutableRefObject<boolean>;
  selectedPeriod: string;
}

export const CurrentFQ = ({ currentFQs, prevOpenRef, selectedPeriod }: Props) => {
  const { t } = useTranslation(["frontlineGrowth"]);
  const [showCurrentFqModal, setShowCurrentFqModal] = useState(false);
  const currentFqModalButton = useRef<HTMLButtonElement>(null);
  const [period, setPeriod] = useState<string>("");
  const [skipRequest, setSkipRequest] = useState<boolean>(true);

  // This prevents FetchMultiplerDetailsData from making request before skipRequest is updated.
  useEffect(() => {
    setPeriod(selectedPeriod);
    setSkipRequest(!currentFQs || currentFQs === "0");
  }, [currentFQs, selectedPeriod]);
  const { multiplierDetailsData, isLoading, errorStatus, refetch } = FetchMultiplerDetailsData(period, skipRequest);

  const currentFqModalToggle = () => {
    setShowCurrentFqModal(!showCurrentFqModal);
    setModalBtnFocus(currentFqModalButton, prevOpenRef, showCurrentFqModal);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TEALIUM.CATEGORY.FRONTLINE_GROUP_GROWTH,
      `current_FQ_${!showCurrentFqModal ? "open" : "close"}`,
      "view_details_modal",
    );
  };

  const getButtonInfo = () => {
    if (skipRequest) return null;

    return {
      callback: currentFqModalToggle,
      ref: currentFqModalButton,
      text: `${t("multiplierDetails.viewDetails", defaultContent.multiplierDetails.viewDetails)}`,
      icon: <IconPlainTable />,
    };
  };

  return (
    <>
      <MultiplierDetail
        title={t("currentFqs", defaultContent.currentFqs, { fqs: currentFQs })}
        subtitle={`${t("multiplierDetails.currentYtd", defaultContent.multiplierDetails.currentYtd)}`}
        description={""}
        buttonInfo={getButtonInfo()}
      />
      <CurrentFQModal
        currentFQLegs={multiplierDetailsData.FqLegs}
        currentFQs={currentFQs}
        isLoading={isLoading}
        errorStatus={errorStatus}
        refetch={refetch}
        toggleModal={currentFqModalToggle}
        displayModal={showCurrentFqModal}
      />
    </>
  );
};
