import { AWARD_CODES } from "../../../../common/enums/award-codes";

export const annualBonusBreakdownMap = [
  {
    awardCode: AWARD_CODES.ANNUAL_PGG,
    name: "personalGrowth",
  },
  {
    awardCode: AWARD_CODES.PGG_GCR,
    name: "personalGrowthGcr",
  },
  {
    awardCode: AWARD_CODES.FGG,
    name: "frontlineGrowth",
  },
  {
    awardCode: AWARD_CODES.TWO_TIME_CASH_INCENTIVE,
    name: "twoTimeCashIncentive",
  },
  {
    awardCode: AWARD_CODES.FAA,
    name: "faa",
  },
  {
    awardCode: AWARD_CODES.EMERALD,
    name: "emerald",
  },
  {
    awardCode: AWARD_CODES.DIAMOND,
    name: "diamond",
  },
  {
    awardCode: AWARD_CODES.DIAMOND_PLUS,
    name: "diamondPlus",
  },
];
