import { BROWSERS } from "../enums/browser-enums";

export interface DownloadUrl {
  [key: string]: string;
  desktop: string;
  ios: string;
  android: string;
}
export interface BrowerConfig {
  browser: BROWSERS;
  downloadUrl: DownloadUrl;
}

export const browerConfig: BrowerConfig[] = [
  {
    browser: BROWSERS.CHROME,
    downloadUrl: {
      desktop: "https://chromeenterprise.google/browser/download/",
      ios: "https://apps.apple.com/us/app/google-chrome/id535886823",
      android: "https://play.google.com/store/apps/details?id=com.android.chrome&hl=en_US&gl=US",
    },
  },
  {
    browser: BROWSERS.SAFARI,
    downloadUrl: {
      desktop: "https://support.apple.com/downloads/safari",
      ios: "https://apps.apple.com/us/app/safari/id1146562112",
      android: "",
    },
  },
  {
    browser: BROWSERS.EDGE,
    downloadUrl: {
      desktop: "https://www.microsoft.com/en-us/edge",
      ios: "https://apps.apple.com/us/app/microsoft-edge-web-browser/id1288723196",
      android: "https://play.google.com/store/apps/details?id=com.microsoft.emmx&hl=en_US&gl=US",
    },
  },
  {
    browser: BROWSERS.FIREFOX,
    downloadUrl: {
      desktop: "https://www.mozilla.org/en-US/firefox/new/",
      ios: "https://apps.apple.com/us/app/firefox-private-safe-browser/id989804926",
      android: "https://play.google.com/store/apps/details?id=org.mozilla.firefox&hl=en_US&gl=US",
    },
  },
];
