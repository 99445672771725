const content: { [key: string]: string } = {
  pageTitle: "Note",
  editButton: "Edit",
  saveButton: "Save",
  addButton: "Add Note",
  readMore: "Read More",
  viewLess: "View Less",
};

export default content;
