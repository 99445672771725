import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import { useEffect, useState } from "react";
import { PathToBronzeData } from "../../../common/interfaces/pathToBronze";
import { useGetPathToBronzeQuery } from "../../../services/corePlusIncentivesApi";
import { getAbo, getAff } from "../../impersonation/util";
import TEALIUM from "../../../common/enums/tealium-enums";
import { store } from "../../../store";
import { getStatusForTealium, returnDefaultData } from "./pathToBronzeUtils";

export const FetchPtbData = (period: string) => {
  const {
    data: payloadData,
    isLoading: isQueryLoading,
    isFetching,
    isError,
  } = useGetPathToBronzeQuery({
    baseUrl: store.getState().boot.configuration.incentivesApiUrl,
    bonusPeriod: period,
    affAbo: `${getAff()}-${getAbo()}`,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [data, setData] = useState<PathToBronzeData>(returnDefaultData());

  // if loading or fetching set loading flag to true
  useEffect(() => {
    if (isQueryLoading || isFetching) {
      setIsLoading(true);
    }
  }, [isQueryLoading, isFetching, period]);

  useEffect(() => {
    if (isError) {
      setErrorStatus(true);
      setIsLoading(false);
    }
  }, [payloadData, isError, isQueryLoading, isFetching, period]);

  // once loading has finished and data is resolved, save to state and set loading to false
  useEffect(() => {
    if (!isQueryLoading && !isFetching && !isError) {
      setData(payloadData);
      setIsLoading(false);
      setErrorStatus(false);
      track(
        TEALIUM.U_TAG_METHOD.VIEW,
        TEALIUM.EVENT_NAME.CLICK_ACTION,
        TEALIUM.CATEGORY.PATH_TO_BRONZE,
        TEALIUM.CATEGORY.PATH_TO_BRONZE,
        `${getStatusForTealium(payloadData)}:${period}`,
        "",
      );
    }
  }, [payloadData, isError, isQueryLoading, isFetching, period]);

  return { isLoading, data, errorStatus };
};
