import "./LeafSortBy.scss";
import { useEffect } from "react";
import LeafSortByRadioButton from "./LeafSortByRadioButton/LeafSortByRadioButton";

type Item = {
  id: string;
  text: string;
  selected: boolean;
};

type Props = {
  sort: Item[];
  onRadioButtonClick: Function;
};

const LeafSortBy = (props: Props) => {
  const { sort, onRadioButtonClick } = props;

  useEffect(() => {
    let itemSelected = false;
    sort.forEach((item) => {
      if (item.selected) {
        const element = document.getElementById(item.id);
        element?.focus();
        itemSelected = true;
      }
    });

    if (!itemSelected) {
      const element = document.getElementById(sort[0].id);
      element?.focus();
    }
  }, [sort]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, item: Item) => {
    switch (e.key) {
      case " ":
      case "Enter":
        selectButton(item.id);
        break;
      case "ArrowDown":
      case "ArrowRight":
        selectNextButton(item.id);
        break;
      case "ArrowUp":
      case "ArrowLeft":
        selectPrevButton(item.id);
        break;
      case "Tab":
      default:
        break;
    }
  };

  const selectButton = (itemId: string) => {
    const index = sort.findIndex((item) => item.id === itemId);
    if (!sort[index].selected) {
      onRadioButtonClick(sort[index]);
    }
  };

  const selectNextButton = (itemId: string) => {
    const index = sort.findIndex((item) => item.id === itemId);
    const nextIndex = (index + 1) % sort.length;
    const nextItem = sort[nextIndex];
    onRadioButtonClick(nextItem);
  };

  const selectPrevButton = (itemId: string) => {
    const index = sort.findIndex((item) => item.id === itemId);
    const prevIndex = (index - 1 + sort.length) % sort.length;
    const prevItem = sort[prevIndex];
    onRadioButtonClick(prevItem);
  };

  return (
    <div className="leaf-sort-by" role="radiogroup">
      {sort.map((item) => (
        <LeafSortByRadioButton
          key={item.id}
          item={item}
          onRadioButtonClick={onRadioButtonClick}
          handleKeyDown={handleKeyDown}
        />
      ))}
    </div>
  );
};

export default LeafSortBy;
