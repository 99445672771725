import { track } from "@amwaycommon/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../../enums/tealium-enums";

export const learnMoreOnClick = () => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    TEALIUM.CATEGORY.BRONZE_FOUNDATION_INCENTIVE,
    TEALIUM.CATEGORY.BRONZE_FOUNDATION_INCENTIVE,
    "",
    "learn_more",
  );
};
