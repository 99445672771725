import { ReactComponent as ShieldEarned } from "./svg/iconShieldMet.svg";
import { ReactComponent as ShieldNotEarned } from "./svg/iconShieldMissed.svg";
import { ReactComponent as ShieldUnavailable } from "./svg/IconShieldNA.svg";
import { ReactComponent as ShieldAchieved } from "./svg/iconShieldAchieve.svg";
import { ReactComponent as ShieldPotential } from "./svg/iconShieldPotential.svg";
import { ReactComponent as ShieldOpen } from "./svg/iconShieldOpen.svg";
import { ReactComponent as ShieldPaydayOutline } from "./svg/iconShieldPaydayOutline.svg";
import { ReactComponent as ShieldPaydayFilled } from "./svg/iconShieldPaydayFilled.svg";

import "./IconShield.scss";

export enum BADGE_TYPE {
  EARNED = "earned",
  NOT_EARNED = "notEarned",
  UNAVAILABLE = "unavailable",
  POTENTIAL = "potential",
  OPEN = "open",
  ACHIEVED = "achieved",
  PAYDAY_ACHIEVED = "paydayAchieved",
  PAYDAY_POTENTIAL = "paydayPotential",
}

export enum BADGE_SIZE {
  EXTRA_SMALL = "extra-small",
  SMALL = "small",
  MEDIUM = "medium",
  MEDIUM_LARGE = "medium-large",
  LARGE = "large",
  EXTRA_LARGE = "xl",
}

const ShieldIcon = ({ type, title, className }: { type: BADGE_TYPE; title: string | undefined; className: string }) => {
  switch (type) {
    case BADGE_TYPE.EARNED:
      return <ShieldEarned {...{ title, className }} />;
    case BADGE_TYPE.NOT_EARNED:
      return <ShieldNotEarned {...{ title, className }} />;
    case BADGE_TYPE.UNAVAILABLE:
      return <ShieldUnavailable {...{ title, className }} />;
    case BADGE_TYPE.POTENTIAL:
      return <ShieldPotential {...{ title, className }} />;
    case BADGE_TYPE.OPEN:
      return <ShieldOpen {...{ title, className }} />;
    case BADGE_TYPE.PAYDAY_ACHIEVED:
      return <ShieldPaydayFilled {...{ title, className }} />;
    case BADGE_TYPE.PAYDAY_POTENTIAL:
      return <ShieldPaydayOutline {...{ title, className }} />;
    case BADGE_TYPE.ACHIEVED:
      return <ShieldAchieved {...{ title, className }} />;
  }
};

export const IconShield = ({
  type = BADGE_TYPE.POTENTIAL,
  size = BADGE_SIZE.SMALL,
  title = undefined,
}: {
  type?: BADGE_TYPE;
  size?: BADGE_SIZE;
  title?: string;
}) => {
  return <ShieldIcon title={title} type={type} className={`icon-shield--${size}`} />;
};
