import LeafApplicantCarousel from "../../../../../common/leaf/LeafProfile/LeafApplicant/LeafApplicantCarousel/LeafApplicantCarousel";
import { useGetABOProfileQuery } from "../../../../../services/profileAPI";
import { useAppSelector } from "../../../../../store";
import "./ABOProfileApplicantCarousel.scss";

const ABOProfileApplicantCarousel = () => {
  // Get Login ABO
  const user = useAppSelector((state) => state.boot?.user);
  const affAbo = useAppSelector((state) => state.profile.affAbo);
  const boot = useAppSelector(({ boot }) => boot);
  const profileInfo = boot?.configuration?.profile?.aboInfo || {};

  const { selectedPeriod } = useAppSelector(({ period }) => period);

  const { isFetching, data } = useGetABOProfileQuery({
    period: selectedPeriod,
    affAbo,
    reqAffAbo: `${user.aff}-${user.abo}`,
  });

  const applicantList = data?.partyInfo?.filter(({ isPrimary }) => isPrimary === false).slice(0, 2);

  return (
    <div className={`${applicantList && applicantList.length > 0 ? "abo-profile-applicants" : ""}`}>
      <LeafApplicantCarousel
        aboInfo={data?.aboInfo}
        partyInfo={applicantList}
        isFetching={isFetching}
        profileInfo={profileInfo}
      />
    </div>
  );
};

export default ABOProfileApplicantCarousel;
