import { createApi } from "@reduxjs/toolkit/query/react";
import { PggGcrOverviewResponse } from "../common/interfaces/groupGrowthIncentives";
import { dynamicBaseQuery } from ".";

interface PGGBaseRequestParams {
  abo: string;
  aff: string;
  locale: string;
  period: string;
}

interface PGGExtendedRequestParams extends PGGBaseRequestParams {
  isoCountryCode: string;
  isoCurrencyCode: string;
}

export const pggGcrAPI = createApi({
  reducerPath: "pggGcrAPI",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getPggGcrOverview: builder.query<PggGcrOverviewResponse, PGGExtendedRequestParams>({
      query: (params) => {
        const { abo, aff, locale, period, isoCountryCode, isoCurrencyCode } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
          IsoCountryCode: isoCountryCode,
          IsoCurrencyCode: isoCurrencyCode,
        });

        return `/incentives-v2/api/pgg/gcr/overview?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
  }),
});

export const { useGetPggGcrOverviewQuery } = pggGcrAPI;
