import { track } from "@amwaycommon/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../../../enums/tealium-enums";

const actionMapper: { [tabId: string]: string } = {
  overview: "view_overview",
  profile: "view_profile",
  orderHistory: "view_order_history",
};

export const profileTabClickTrack = (tabId: string) => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.GLOBAL_CUSTOMER_PROFILE,
    "",
    actionMapper[tabId],
  );
};

export const orderHistoryClickTrack = () => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.GLOBAL_CUSTOMER_PROFILE,
    "",
    "view_all_most_recent_order",
  );
};
