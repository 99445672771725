import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { PPERequirements } from "../../../services/performancePlusEliteOverviewAPI";
import { IconCheckCircle } from "../../../common/icons/IconCheckCircle/IconCheckCircle";
import defaultContent from "../../../content/ppe";
import "./PPETrackProgressTile.scss";
import { BADGE_SIZE, BADGE_TYPE, IconShield } from "../../../common/icons/IconShield/IconShield";
import { BaseTile } from "../../../common/components/BaseTile/BaseTile";
import LeafRectangleLoader from "../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";

type props = {
  requirements: PPERequirements;
  isLoading: boolean;
  isPerfElite: boolean;
};

const TrackProgressLoader = ({ isPerfElite }: { isPerfElite: boolean }) => {
  return (
    <>
      <LeafRectangleLoader isLoading={true} width="100%" height="2.5rem" margin="0 0 2rem 0" />
      <LeafRectangleLoader isLoading={true} width="80%" height="3rem" margin="0 auto 2rem auto" />
      <LeafRectangleLoader isLoading={true} width="90%" height="1.25rem" margin="0" />
      {isPerfElite && <LeafRectangleLoader isLoading={true} width="80%" height="1.25rem" margin=".5rem 0 0 0" />}
    </>
  );
};

export const PPETrackProgressTile = ({ requirements, isLoading, isPerfElite }: props) => {
  const { t } = useTranslation(["ppe"]);

  const hasPerfElite = requirements.PPERubyRequirements.length === 2;
  const perfEliteMet = hasPerfElite && requirements.PPERubyRequirements[1].Met;
  const perfEliteProgressLbl = perfEliteMet ? "metSubTitle" : "inProgressSubTitle";
  const [maxWidth, setMaxWidth] = useState(0);

  useMemo(() => {
    const iconWidth = 48;
    const gap = 32;

    if (!isLoading) {
      let numReqs = requirements.TotalRequirements;
      const hasPerfElite = requirements.PPERubyRequirements.length === 2;

      if (hasPerfElite) numReqs += 2;

      setMaxWidth(numReqs * iconWidth + (numReqs - 1) * gap);
    }
  }, [requirements, isLoading]);

  const CircleChecks = () => {
    return (
      <div className="PPETrackProgress__icon-container">
        {requirements.RequirementMetStatuses.map((met) => (
          <span>
            <IconCheckCircle color={met ? "green" : "gray"} size="xlg" />
          </span>
        ))}

        {hasPerfElite ? (
          <>
            <span className="PPETrackProgress__vertical-line"></span>
            <span className={`PPETrackProgress__extraShield${perfEliteMet ? "--met" : ""}`}>
              <IconShield type={BADGE_TYPE.POTENTIAL} size={BADGE_SIZE.LARGE} />
            </span>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const getProgressText = () => {
    return (
      <Trans
        ns="ppe"
        i18nKey={"progressTile.progressLabel"}
        defaults={defaultContent.progressTile.progressLabel}
        values={{
          current: requirements.RequirementsMet,
          needed: requirements.TotalRequirements,
        }}
        components={{
          1: (
            <span
              className={`PPETrackProgress__bold${
                requirements.TotalRequirements === requirements.RequirementsMet ? "--met" : ""
              }`}
            ></span>
          ),
        }}
      />
    );
  };

  return (
    <div className="PPETrackProgress">
      <BaseTile>
        {isLoading ? (
          <TrackProgressLoader isPerfElite={isPerfElite} />
        ) : (
          <>
            <div className="PPETrackProgress__title">
              {t(`progressTile.title`, defaultContent.progressTile["title"], {})}
            </div>
            <div className="PPETrackProgress__checksContainer" style={{ maxWidth: `${maxWidth}px` }}>
              <CircleChecks />
            </div>
            <section>
              <div className="PPETrackProgress__progressText">{getProgressText()}</div>
              {hasPerfElite && (
                <div className="PPETrackProgress__subtext">
                  <Trans
                    ns="ppe"
                    i18nKey={`progressTile.performancePlusElite.${perfEliteProgressLbl}`}
                    defaults={defaultContent["progressTile"]["performancePlusElite"][perfEliteProgressLbl]}
                    components={{
                      1: <span className="PPETrackProgress__bold"></span>,
                    }}
                  />
                </div>
              )}
            </section>
          </>
        )}
      </BaseTile>
    </div>
  );
};
