import React from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import CustomerPVChart from "../CustomerPVChart/CustomerPVChart";
import PersonalPVChart from "../PersonalPVChart/PersonalPVChart";
import { IconArrowLeft } from "../../../../../common/icons/IconArrowLeft/IconArrowLeft";
import { IconArrowRight } from "../../../../../common/icons/IconArrowRight/IconArrowRight";
import { getLoginAffAbo } from "../../../../../reducers/model/boot";
import { useAppSelector } from "../../../../../store";
import { useGetCustomerPVChartQuery } from "../../../../../services/customerPVChartAPI";
import "./ChartSlider.scss";
import { cpvChartClickTrack } from "../../../../../common/tealium/Customers/Overview/Chart/CustomerPVTrack";
import { ppvChartClickTrack } from "../../../../../common/tealium/Customers/Overview/Chart/PersonalPVTrack";
import { customerTypeChartClickTrack } from "../../../../../common/tealium/Customers/Overview/Chart/CustomerTypeTrack";
import { isProgramActive } from "../../../../../common/util/checkProgramActiveStatus";
import { AWARD_CODES } from "../../../../../common/enums/award-codes";
import CustomerTypeChart from "../CustomerTypeChart/CustomerTypeChart";
import { useGetCustomerListQuery } from "../../../../../services/customerListAPI";

type ArrowsProps = {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const ChartSlider = () => {
  useTranslation(["customerPVChart", "personalPVChart"]);
  const { selectedPeriod } = useAppSelector(({ period }) => period);

  const boot = useAppSelector(({ boot }) => boot);
  const affAbo = getLoginAffAbo({ boot });
  const eligibleIncentives = boot?.shellConfig?.eligibleIncentives;
  const enableGuestCustomer = boot?.configuration?.globalCML?.enableGuestCustomer;
  const { showPPVChart, showCustomerPVChart } = boot?.configuration?.globalCML?.overview || {};

  const { data, isFetching, isError } = useGetCustomerPVChartQuery({ affAbo, period: selectedPeriod });
  const {
    customerPV = 0,
    vcsPV = 0,
    nonVCSPV = 0,
    otherNonVCSPV = 0,
    personalPV = 0,
    isValidCustomerPV = true,
  } = data || {};

  const featureFlag = isValidCustomerPV === true && showCustomerPVChart === true;
  const shouldShowCustomerPVChart = isProgramActive(eligibleIncentives, AWARD_CODES.CSI, featureFlag);

  const {
    isFetching: isFetchingCustomer,
    isError: isErrorFetchingCustomer,
    data: customerData,
  } = useGetCustomerListQuery({
    affAbo,
    period: selectedPeriod,
    pageNum: 1,
    pageSize: 1,
    sortValue: "LAST_ORDER_DATE",
    sortOrder: "DESC",
    businessNature: "3, 4, 17",
  });

  const {
    totalMemberTypeCustomer = 0,
    totalGuestTypeCustomer = 0,
    totalShareBarTypeCustomer = 0,
    totalCustomerCount = 0,
  } = customerData || {};

  const NextArrow = (props: ArrowsProps) => {
    const { className, onClick } = props;

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (onClick) {
        onClick(event);
      }
    };

    return className?.includes("slick-disabled") ? (
      <></>
    ) : (
      <div className="slick-arrow-next" onClick={handleClick}>
        <IconArrowRight />
      </div>
    );
  };

  const PrevArrow = (props: ArrowsProps) => {
    const { className, onClick } = props;

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (onClick) {
        onClick(event);
      }
    };

    return className?.includes("slick-disabled") ? (
      <></>
    ) : (
      <div className="slick-arrow-prev" onClick={handleClick}>
        <IconArrowLeft />
      </div>
    );
  };

  const allCharts = [
    {
      ChartComponent: (
        <CustomerPVChart
          isLoading={isFetching}
          vcsPV={vcsPV}
          nonVCSPV={nonVCSPV}
          otherNonVCSPV={otherNonVCSPV}
          customerPV={customerPV}
          isError={isError}
          period={selectedPeriod}
          key="customer-pv-chart"
        />
      ),
      track: cpvChartClickTrack,
      enable: shouldShowCustomerPVChart === true && isFetching === false,
    },
    {
      ChartComponent: (
        <PersonalPVChart
          isLoading={isFetching}
          customerPV={customerPV}
          personalPV={personalPV}
          isError={isError}
          period={selectedPeriod}
          key="personal-pv-chart"
        />
      ),
      track: ppvChartClickTrack,
      enable: showPPVChart,
    },
    {
      ChartComponent: (
        <CustomerTypeChart
          isLoading={isFetchingCustomer}
          totalMemberTypeCustomer={totalMemberTypeCustomer}
          totalGuestTypeCustomer={totalGuestTypeCustomer}
          totalShareBarTypeCustomer={totalShareBarTypeCustomer}
          totalCustomerCount={totalCustomerCount}
          isError={isErrorFetchingCustomer}
          period={selectedPeriod}
          key="customer-type-chart"
        />
      ),
      track: customerTypeChartClickTrack,
      enable: enableGuestCustomer,
    },
  ];

  const visibleChart = allCharts.filter(({ enable }) => enable);

  const settings = {
    dots: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: false,
    beforeChange: (currentSlide: number, nextSlide: number) => {
      visibleChart[nextSlide].track();
    },
  };

  return <Slider {...settings}>{visibleChart.map(({ ChartComponent }) => ChartComponent)}</Slider>;
};

export default ChartSlider;
