import "./IconGear.scss";
import { ReactComponent as IconGearGray } from "./svg/icon_gear_gray.svg";
import { ReactComponent as IconGearBlue } from "./svg/icon_gear_blue.svg";

type Props = {
  color?: "" | "gray" | "blue";
};

export const IconGear = ({ color = "" }: Props) => {
  const renderIcon = () => {
    switch (color) {
      case "blue":
        return <IconGearBlue />;
      default:
        return <IconGearGray />;
    }
  };
  return <span className={`icon-gear icon-gear__${color}`}>{renderIcon()}</span>;
};
