import { useMemo } from "react";
import { useAppSelector } from "../../../store";
import { ActionReports, Report } from "../../../common/interfaces/config";

/**
 * Sort action reports by market preference
 * @returns {actionReportMenuOptions}
 */
export const useActionReportMenuOptions = () => {
  const { configuration } = useAppSelector((state) => state.boot);

  const sortReportsByMarketPreference = (actionReports: ActionReports) => {
    const { reportMenuSequence, reports } = actionReports;
    return reports
      .slice()
      .sort((reportA: Report, reportB: Report) => reportMenuSequence[reportA.id] - reportMenuSequence[reportB.id]);
  };

  const actionReportMenuOptions = useMemo(
    () => sortReportsByMarketPreference(configuration.actionReports),
    [configuration],
  );

  return { actionReportMenuOptions };
};
