import { useState } from "react";
import { IconArrowLink } from "../../../../icons/IconArrowLink/IconArrowLink";
import { ABOInfo, ProfileInfo, PartyInfo } from "../../../../../reducers/model/profile/index";
import { useAppSelector } from "../../../../../store";
import LeafABOAddress from "../../LeafABOAddress/LeafABOAddress";
import LeafABOEmail from "../../LeafABOEmail/LeafABOEmail";
import LeafABOPhoneNumber from "../../LeafABOPhoneNumber/LeafABOPhoneNumber";
import LeafABOType from "../../LeafABOType/LeafABOType";
import LeafGenderInfo from "../../LeafInfo/LeafGenderInfo/LeafGenderInfo";
import LeafBirthdayInfo from "../../LeafInfo/LeafBirthdayInfo/LeafBirthdayInfo";
import LeafPreferredLanguageInfo from "../../LeafInfo/LeafPreferredLanguageInfo/LeafPreferredLanguageInfo";
import LeafPrimaryTag from "../../LeafTags/LeafPrimaryTag/LeafPrimaryTag";
import LeafApplicantABOName from "../LeafApplicantABOName/LeafApplicantABOName";
import { toggleApplicantDetailClickTrack } from "../../../../../common/tealium/CustomerProfile/Profile/ApplicantCardTrack";
import "./LeafApplicantItem.scss";

type Props = {
  aboInfo?: ABOInfo;
  partyInfo?: PartyInfo;
  isExpandable: boolean;
  applicantTrackOnclick: Function;
  profileInfo: Partial<ProfileInfo>;
};

const LeafApplicantItem = (props: Props) => {
  const { aboInfo, partyInfo, isExpandable, applicantTrackOnclick, profileInfo } = props;
  const [isToggle, setIsToggle] = useState(!partyInfo?.isPrimary);

  const { aboName: nameFormat } = useAppSelector((store) => store?.boot?.configuration?.profile?.format);

  const iconArrowLinkOnClick = () => {
    setIsToggle(!isToggle);
    toggleApplicantDetailClickTrack(isToggle);
  };

  const boot = useAppSelector(({ boot }) => boot);

  const { showBirthday, showLanguage, showGender } = profileInfo;
  const { showPartyId = true } = boot?.configuration?.profile?.party || {};

  if (partyInfo) {
    const {
      partyId,
      isPrimary,
      displayName,
      localeDisplayName,
      localeName,
      latinName,
      isoBirthdate,
      gender,
      preferredLanguageCode,
      emailAddresses,
      phoneNumbers,
      addresses,
    } = partyInfo;
    const { privacyFlag = false, businessNatureCode = 0, registrationChannelCd = "" } = aboInfo || {};
    const { email } = emailAddresses?.[0] || {};
    const { displayNumber } = phoneNumbers?.[0] || {};
    const { addressLines, municipality, region, postalCode, country, type } = addresses?.[0] || {};

    return (
      <div className="leaf-applicant-item">
        <div className="leaf-applicant-item__header">
          <div className="leaf-applicant-item__header--tag">
            <LeafPrimaryTag isPrimary={isPrimary} />
          </div>

          <div className="leaf-applicant-item__header--details">
            <LeafApplicantABOName
              displayName={displayName}
              localeDisplayName={localeDisplayName}
              localeName={localeName}
              latinName={latinName}
              privacyFlag={privacyFlag}
              preferredLanguageCode={preferredLanguageCode}
              nameFormat={nameFormat}
            ></LeafApplicantABOName>
            {showPartyId && <div className="leaf-applicant-item__header--abo-number">{partyId}</div>}
          </div>

          {isExpandable === true && (
            <>
              <div className="leaf-applicant-item__header--type">
                <LeafABOType businessNature={businessNatureCode} registrationChannelCd={registrationChannelCd} />
                <div
                  className={`leaf-applicant-item__header__arrow${isToggle === false ? "--down" : ""}`}
                  onClick={iconArrowLinkOnClick}
                  tabIndex={0}
                >
                  <IconArrowLink />
                </div>
              </div>
            </>
          )}
        </div>

        <div
          className={`leaf-applicant-item__content ${
            isToggle && isExpandable && "leaf-applicant-item__content--toggled"
          }`}
        >
          <LeafABOPhoneNumber
            primaryNumber={displayNumber}
            privacyFlag={privacyFlag}
            applicantTrackOnclick={applicantTrackOnclick}
          />
          <LeafABOEmail
            email={email}
            privacyFlag={privacyFlag}
            applicantTrackOnclick={applicantTrackOnclick}
          ></LeafABOEmail>

          {isExpandable === true && (
            <>
              <LeafABOAddress
                addresses={{
                  addressLines,
                  municipality,
                  region,
                  postalCode,
                  country,
                  type,
                }}
                privacyFlag={privacyFlag}
                applicantTrackOnclick={applicantTrackOnclick}
              />
              <div className="leaf-applicant-item__line"></div>
              <LeafPreferredLanguageInfo
                isoLanguage={preferredLanguageCode}
                privacyFlag={privacyFlag}
                show={showLanguage}
              />
              <LeafGenderInfo gender={gender} privacyFlag={privacyFlag} show={showGender} />
              <LeafBirthdayInfo date={isoBirthdate} privacyFlag={privacyFlag} show={showBirthday} />
            </>
          )}
        </div>
      </div>
    );
  }

  return <></>;
};

export default LeafApplicantItem;
