import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import LeafRectangleLoader from "../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import LeafTile from "../../../common/leaf/LeafTile/LeafTile";
import "./VisionBonusPageLoader.scss";

const VisionBonusPageLoader = () => {
  const Loader = () => {
    return (
      <div className="vision-bonus-loader">
        <div className={"vision-bonus-loader__overview"}>
          <div className="vision-bonus-loader__overview__header">
            <LeafRectangleLoader isLoading={true} numRows={1} width="100%" height="3rem" />
          </div>
          <div className="vision-bonus-loader__row">
            <LeafTile isLoading={true} title={" "} />
            <LeafTile isLoading={true} title={" "} />
          </div>
        </div>
        <div className="vision-bonus-loader__calculation">
          <div className="vision-bonus-loader__calculation__header">
            <LeafRectangleLoader isLoading={true} numRows={1} width="100%" height="2.5rem" />
          </div>
          <div className="vision-bonus-loader__calculation__sub-header">
            <LeafRectangleLoader isLoading={true} numRows={1} width="100%" height="1rem" />
          </div>
          <div className="vision-bonus-loader__calculation__menu">
            <LeafRectangleLoader isLoading={true} numRows={1} width="100%" height="2.25rem" />
          </div>
          <div className="vision-bonus-loader__row">
            <LeafTile isLoading={true} title={" "} rectangleLoaderNumRows={1} rectangleLoaderHeightPerLine={"1.5rem"} />
            <LeafTile isLoading={true} title={" "} rectangleLoaderNumRows={1} rectangleLoaderHeightPerLine={"1.5rem"} />
            <LeafTile isLoading={true} title={" "} rectangleLoaderNumRows={1} rectangleLoaderHeightPerLine={"1.5rem"} />
          </div>
        </div>
        <div className="vision-bonus-loader__requirement">
          <div className="vision-bonus-loader__header">
            <LeafRectangleLoader isLoading={true} numRows={1} width="70%" height="3rem" />
          </div>
          <div className="vision-bonus-loader__row">
            <LeafTile isLoading={true} title={" "} />
            <LeafTile isLoading={true} title={" "} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <main className="vision-bonus-container">
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
    </main>
  );
};

export default VisionBonusPageLoader;
