import { useTranslation } from "react-i18next";
import LeafPanel from "../../../../common/leaf/LeafPanel/LeafPanel";
import LeafSortBy from "../../../../common/leaf/LeafFilter/LeafSortBy/LeafSortBy";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { updateSortOption } from "../../../../reducers/BronzeGroupTracking/tracking/sort";
import defaultContent from "../../../../content/leafSortInfo";
import useWindowSize from "../../../../common/hooks/useWindowSize";
import { SortOption } from "../../../../reducers/model/bgtSort";
import LeafFilterButton from "../../../../common/leaf/LeafFilter/LeafFilterButton/LeafFilterButton";
import bgtDefaultContent from "../../../../content/bronzeGroupTracking";
import breakpoints from "../../../../common/scss/breakpoints.module.scss";

type Props = {
  show: boolean;
  onCloseClick: Function;
  applyChangesFunction: Function;
};

const TrackingSort = ({ show, onCloseClick, applyChangesFunction }: Props) => {
  const { t } = useTranslation(["leafSortInfo", "bgtDefaultContent"]);
  const { sortBy } = useAppSelector((state) => state.trackingSort);

  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const sortList = sortBy.map((sort) => ({
    ...sort,
    text: `${t(`options.${sort.id}`, defaultContent["options"][`${sort.id}`])}`,
  }));

  const onSortSelection = (sort: SortOption) => {
    dispatch(updateSortOption(sort));
    if (width && Number(width) < Number(breakpoints.tabletSmall)) applyChangesFunction();
  };

  return (
    <section className="tracking-sort">
      <LeafPanel
        id={"tracking-sort-modal"}
        footer={
          <>
            <LeafFilterButton
              text={t("cancel", bgtDefaultContent["cancel"], { ns: "bronzeGroupTracking" })}
              onButtonClick={() => onCloseClick(false)}
            />
            <LeafFilterButton
              text={t("viewResults", bgtDefaultContent["viewResults"], { ns: "bronzeGroupTracking" })}
              onButtonClick={() => applyChangesFunction()}
              theme="dark"
            />
          </>
        }
        {...{ show, onCloseClick, title: t("text", defaultContent["text"] as string) }}
      >
        <LeafSortBy onRadioButtonClick={(sort: SortOption) => onSortSelection(sort)} sort={sortList} />
      </LeafPanel>
    </section>
  );
};

export default TrackingSort;
