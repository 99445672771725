import { useTranslation } from "react-i18next";
import defaultContent from "../../../../content/biEligibilitySlider";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { hideEligibilitySlider } from "../../../../reducers/bronzeIncentive";
import LeafPanel from "../../../../common/leaf/LeafPanel/LeafPanel";
import BIEligibilitySliderBody from "./BIEligibilitySliderBody/BIEligibilitySliderBody";

export type Props = {
  previousElement?: HTMLElement;
};

const BIEligibilitySlider = (props: Props) => {
  const { previousElement } = props;
  const { t } = useTranslation(["biEligibilitySlider"]);
  const { awardCode, showEligibilitySliderFlag } = useAppSelector((state) => state.bronzeIncentive);
  const dispatch = useAppDispatch();

  const closeProfile = () => {
    dispatch(hideEligibilitySlider());
    // TODO :: Tealium tagging
  };

  return (
    <LeafPanel
      title={t("eligibility", defaultContent["eligibility"])}
      show={showEligibilitySliderFlag}
      onCloseClick={closeProfile}
      id={"bi-eligibility-slider"}
      previousElement={previousElement}
    >
      <BIEligibilitySliderBody isShowSlider={showEligibilitySliderFlag} awardCode={awardCode} />
    </LeafPanel>
  );
};

export default BIEligibilitySlider;
