import { track } from "@amwaycommon/acc-mixins/src/modules/tealium/index";
import { useEffect } from "react";
import TEALIUM from "../../enums/tealium-enums";

export const useOverviewBGTPageLoadTrack = ({ period }: { period: string }) => {
  useEffect(() => {
    track(
      TEALIUM.U_TAG_METHOD.VIEW,
      "",
      "overview",
      TEALIUM.CATEGORY.BRONZE_TRACKING,
      `${TEALIUM.CATEGORY.BRONZE_TRACKING}:${period}`,
      "",
    );
  }, [period]);
};
