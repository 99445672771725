import { useTranslation } from "react-i18next";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import { storedLocale } from "../impersonation/util";
import "./Footer.scss";
import defaultContent from "../../content/footer";
import TEALIUM from "../../common/enums/tealium-enums";
import { storedIsoCountry } from "../../common/util/config";
import { useAppSelector } from "../../store";

export const Footer = () => {
  const { t } = useTranslation(["footer"]);
  const isCountryCode = storedIsoCountry();
  const locale = storedLocale();

  const boot = useAppSelector(({ boot }) => boot);
  const { configuration, isBootLoading } = boot;

  const RenderVerticalBar = ({ link }: { link: string }) => {
    return ((configuration?.termsOfUse || configuration?.marketInformation) && link === "privacyNotice") ||
      (configuration?.marketInformation && link === "termsOfUse") ? (
      <span className="footer__divider"></span>
    ) : (
      <></>
    );
  };

  const tealiumActions: any = {
    privacyNotice: "footer:privacy_notice_link",
    termsOfUse: "footer:terms_of_use_link",
    marketInfo: "footer:market_information_link",
  };

  const tealiumClick = (link: string) => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      "core_plus_portal",
      tealiumActions[link],
      tealiumActions[link],
    );
  };

  const PrivacyNotice = () => {
    return configuration?.privacyNotice && configuration?.privacyNotice.length > 0 ? (
      <span>
        <a
          href={configuration?.privacyNotice}
          onClick={() => tealiumClick("privacyNotice")}
          id="privacy"
          target="_blank"
          rel="noreferrer"
        >
          {`${t("privacyLabel", defaultContent["privacyLabel"])}`}
        </a>
        <RenderVerticalBar link="privacyNotice" />
      </span>
    ) : (
      <></>
    );
  };

  const formatTermsOfUseLink = (link: string) => {
    // ana markets have locale as path param in the format `en_US`
    const lang = !locale.includes(isCountryCode) ? `en_${isCountryCode}` : locale.replace("-", "_");
    return link.replace("{{lang}}", lang);
  };

  const TermsOfUse = () => {
    return configuration?.termsOfUse && configuration?.termsOfUse.length > 0 ? (
      <span>
        <a
          href={formatTermsOfUseLink(configuration?.termsOfUse)}
          onClick={() => tealiumClick("termsOfUse")}
          id="terms"
          target="_blank"
          rel="noreferrer"
        >
          {`${t("termsLabel", defaultContent["termsLabel"])}`}
        </a>
        <RenderVerticalBar link="termsOfUse" />
      </span>
    ) : (
      <></>
    );
  };

  const MarketInfo = () => {
    return configuration?.marketInformation && configuration?.marketInformation.length > 0 ? (
      <span>
        <a
          href={configuration?.marketInformation}
          onClick={() => tealiumClick("termsOfUse")}
          id="marketInfo"
          target="_blank"
          rel="noreferrer"
        >
          {`${t("marketLabel", defaultContent["marketLabel"])}`}
        </a>
      </span>
    ) : (
      <></>
    );
  };

  const RenderFooterLinks = () => {
    return (
      <div className="footer__footer-links">
        <PrivacyNotice />
        <TermsOfUse />
        <MarketInfo />
      </div>
    );
  };

  const RenderFooter = () => {
    return configuration ? (
      <div>
        <RenderFooterLinks />
        <div className="copyright" id="copyright">
          {`${t("copyright", defaultContent["copyright"], { YEAR: new Date().getFullYear() })}`}
        </div>
        <div id="confidential">{`${t("confidential", defaultContent["confidential"])}`}</div>
        <div id="aboOnly">{`${t("aboOnlyLabel", defaultContent["aboOnlyLabel"])}`}</div>
      </div>
    ) : (
      <></>
    );
  };

  const FooterSkeletonLoader = () => {
    return (
      <div>
        <div className="footer__footer-links">
          <p className="loading-pulse" style={{ height: "1.25rem", width: "40%" }}></p>
        </div>
        <div style={{ alignItems: "center", display: "flex", flexDirection: "column", gap: ".4rem" }}>
          <p className="loading-pulse" style={{ height: "0.875rem", width: "10%" }}></p>
          <p className="loading-pulse" style={{ height: "0.875rem", width: "50%" }}></p>
          <p className="loading-pulse" style={{ height: "0.875rem", width: "30%" }}></p>
        </div>
      </div>
    );
  };

  return (
    <footer id="footer" className="footer">
      {isBootLoading ? <FooterSkeletonLoader /> : <RenderFooter />}
    </footer>
  );
};
