import { useTranslation } from "react-i18next";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import defaultContent from "../../../../content/keyMetricsTile";
import { useAppSelector } from "../../../../store";
import "./KpiTiles.scss";
import { KeyMetricsData } from "../../../../common/interfaces/monthlyPerformanceDash";
import { KPI } from "../../../enums/kpis";
import { formatToIDStr } from "../../../util/format";

type Props = {
  keyMetricsData: KeyMetricsData;
  kpis: string[];
  isLoading: boolean;
};

export default function KpiTiles(props: Props) {
  const { t } = useTranslation(["keyMetricsTile"]);
  const { user } = useAppSelector((state) => state.boot);
  const { keyMetricsData, kpis, isLoading } = props;

  const getKpiLabel = (kpi: string, kpiValue: string | number) => {
    let kpiLabel;
    switch (kpi) {
      case KPI.SP_MONTHS:
        kpiLabel = Number(kpiValue) === 1 ? "spMonthsLabelSingular" : "spMonthsLabelPlural";
        break;
      default:
        kpiLabel = "";
    }

    return kpiLabel !== "" ? `${t(kpiLabel, defaultContent[kpiLabel], { value: kpiValue })}` : `${kpiValue}`;
  };

  const getTileData = (kpi: string): string => {
    const value = Object.prototype.hasOwnProperty.call(keyMetricsData.KeyMetrics, kpi)
      ? keyMetricsData.KeyMetrics[kpi]
      : "--";
    if (value === "") return "--";
    return getKpiLabel(kpi, value);
  };

  return (
    <section className="KeyMetrics__container">
      {kpis.map((kpi) => {
        const leafTileIDStr = formatToIDStr(`${kpi}-kpi-tile`);
        return (
          <LeafTile key={kpi} isLoading={isLoading} id={leafTileIDStr}>
            <p className="KeyMetrics__kpi-name">{`${t(`${kpi}`, defaultContent[`${kpi}`])}`}</p>
            <p className="KeyMetrics__kpi-data">{getTileData(kpi)}</p>
          </LeafTile>
        );
      })}
    </section>
  );
}
