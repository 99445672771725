import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/customerTags";
import "./LeafPrimaryTag.scss";

type Props = {
  isPrimary?: boolean;
};

const LeafPrimaryTag = (props: Props) => {
  const { isPrimary } = props;
  const { t } = useTranslation(["customerTags"]);

  return isPrimary ? <span className="tag-primary">{t("primary", defaultContent["primary"])}</span> : <></>;
};

export default LeafPrimaryTag;
