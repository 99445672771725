import { ReactNode, useEffect, useState } from "react";
import "./BottomDrawer.scss";

type BottomDrawerProps = {
  isOpen: boolean;
  callbackOnClose?: CallableFunction;
  children?: ReactNode;
};

export const BottomDrawer = ({ isOpen, callbackOnClose, children }: BottomDrawerProps) => {
  const [open, setOpen] = useState(false);
  const handleClickyAreaClick = () => {
    if (callbackOnClose) {
      callbackOnClose();
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const drawerClasses = open ? "BottomDrawer__drawer--open" : "BottomDrawer__drawer--closed";

  const renderBottomDrawer = () => {
    return (
      <div className="BottomDrawer__container">
        <div className="BottomDrawer__clickyArea" onClick={() => handleClickyAreaClick()}></div>
        <div className={drawerClasses}>
          <div className="BottomDrawer__handle" onClick={() => handleClickyAreaClick()}>
            <svg width="50" height="3" viewBox="0 0 50 3" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="50" height="3" rx="1.5" fill="#5E5E5E" />
            </svg>
          </div>
          {children}
        </div>
      </div>
    );
  };

  return open ? renderBottomDrawer() : <></>;
};
