import "./IconFilter.scss";

export enum FILTER_TYPE {
  ENABLED = "enabled",
  DISABLED = "disabled",
}

export const IconFilter = ({ type = FILTER_TYPE.ENABLED }: { type?: FILTER_TYPE }) => {
  return <span className={`icon-filter icon-filter__${type}`}></span>;
};
