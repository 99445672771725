import { useTranslation } from "react-i18next";
import IconPerson, { IconPersonColor, IconPersonType } from "../../icons/IconPerson/IconPerson";
import commonContent from "../../../content/common";
import "./LeafIncentiveLegCounts.scss";
import LeafRectangleLoader from "../LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";

type Props = {
  actual: number;
  target: number;
  isLoading?: boolean;
};

const LeafIncentiveLegCounts = (props: Props) => {
  const { t } = useTranslation(["common"]);

  const { actual, target, isLoading = false } = props;
  const isCompleted = actual >= target;
  const iconType = isCompleted ? IconPersonType.SOLID : IconPersonType.OUTLINE;

  if (isLoading === true) {
    return (
      <>
        <LeafRectangleLoader isLoading={isLoading} height="2rem" width="auto" />
        <LeafRectangleLoader isLoading={isLoading} height="3rem" width="auto" />
      </>
    );
  }

  return (
    <div className="LeafIncentiveLegCounts">
      <div className="LeafIncentiveLegCounts__container">
        {Array.from({ length: target }, (_, i) => {
          const currentLeg = i + 1;
          const fulfilled = currentLeg <= actual;
          const colorNotCompleted = fulfilled ? IconPersonColor.ORANGE : IconPersonColor.GRAY;
          const color = isCompleted ? IconPersonColor.GREEN : colorNotCompleted;

          return (
            <div
              className="LeafIncentiveLegCounts__container__leg"
              key={currentLeg}
              aria-label={`${
                fulfilled
                  ? t("legCountCompleted", commonContent["legCountCompleted"], { count: currentLeg })
                  : t("legCountIncomplete", commonContent["legCountIncomplete"], { count: currentLeg })
              }`}
            >
              <div className={`LeafIncentiveLegCounts__container__leg__label ${fulfilled}`}>
                {t("legCount", commonContent["legCount"], { count: currentLeg })}
              </div>
              <div className="LeafIncentiveLegCounts__container__leg__icon" aria-hidden={true}>
                <IconPerson color={color} type={iconType} count={currentLeg} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LeafIncentiveLegCounts;
