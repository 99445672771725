import LeafApplicantsList from "../../../common/leaf/LeafProfile/LeafApplicant/LeafApplicantsList/LeafApplicantsList";
import { useGetProfileQuery } from "../../../services/profileAPI";
import { useAppSelector } from "../../../store";
import {
  cardActionClickTrack,
  profileApplicantCardErrorTrack,
} from "../../../common/tealium/CustomerProfile/Profile/ApplicantCardTrack";
import { LeafErrorMessage } from "../../../common/leaf/LeafErrorMessage/LeafErrorMessage";

type Props = {
  isShowApplicant: boolean;
};

const CustomerApplicantBody = (props: Props) => {
  const { isShowApplicant } = props;

  const affAbo = useAppSelector((state) => state.profile.affAbo);
  const { selectedPeriod } = useAppSelector(({ period }) => period);

  const applicantTrackOnclick = (event?: string) => {
    cardActionClickTrack(event, false);
  };

  const { isFetching, isError, data } = useGetProfileQuery({ period: selectedPeriod, affAbo });

  if (isError === true) {
    profileApplicantCardErrorTrack();
  }

  const boot = useAppSelector(({ boot }) => boot);
  const profileInfo = boot?.configuration?.profile?.customerInfo || {};

  if (isShowApplicant && isFetching === false && data?.aboInfo && data.partyInfo) {
    const { aboInfo, partyInfo } = data;
    return (
      <LeafApplicantsList
        partyInfo={partyInfo}
        aboInfo={aboInfo}
        applicantTrackOnclick={applicantTrackOnclick}
        profileInfo={profileInfo}
      />
    );
  }

  return <LeafErrorMessage show={isError} />;
};

export default CustomerApplicantBody;
