import { BADGE_TYPE, IconShield } from "../../../icons/IconShield/IconShield";
import LeafTile from "../../../leaf/LeafTile/LeafTile";
import "./TwoBadgesRequirementTile.scss";
import { IconPlainTable } from "../../../icons/IconPlainTable/IconPlainTable";
import LeafFilterButton from "../../../leaf/LeafFilter/LeafFilterButton/LeafFilterButton";

type Props = {
  isLoading: boolean;
  title: string;
  badge1: {
    title: string;
    met: boolean;
  };
  badge2: {
    title: string;
    met: boolean;
  };
  viewDetailButton: {
    text: string;
    buttonOnClick: () => void;
  };
};

const TwoBadgesRequirementTile = ({ isLoading, title, badge1, badge2, viewDetailButton }: Props) => {
  const getBadgeType = (status: boolean) => {
    if (status === true) {
      return BADGE_TYPE.EARNED;
    }
    return BADGE_TYPE.POTENTIAL;
  };

  return (
    <div className="two-badges-req-tile">
      <LeafTile
        isLoading={isLoading}
        title={title}
        showMetStatus={true}
        circleCheckmarkId={"twoBadgesReqTile"}
        met={badge1.met || badge2.met}
      >
        <div className="two-badges-req-tile__content">
          <div className="two-badges-req-tile__content__FQ">
            {badge1.title}
            <IconShield type={getBadgeType(badge1.met)} />
          </div>
          <div className="two-badges-req-tile__content__separator"></div>
          <div className="two-badges-req-tile__content__QC">
            {badge2.title}
            <IconShield type={getBadgeType(badge2.met)} />
          </div>
        </div>
        <div className="two-badges-req-tile__content--view-details-button">
          <LeafFilterButton text={""} onButtonClick={viewDetailButton.buttonOnClick}>
            {viewDetailButton.text}
            <IconPlainTable />
          </LeafFilterButton>
        </div>
      </LeafTile>
    </div>
  );
};

export default TwoBadgesRequirementTile;
