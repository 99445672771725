import "./IconRefresh.scss";
import { ReactComponent as RefreshIcon } from "./svg/refresh.svg";

export const IconRefresh = ({ color = "white" }: { color?: "white" | "black" | "blue" }) => {
  return (
    <span className={`icon-refresh--${color}`}>
      <RefreshIcon />
    </span>
  );
};
