export enum CUSTOMER_PROFILE_TABS_ID {
  OVERVIEW = "overview",
  PROFILE = "profile",
  ORDER_HISTORY = "orderHistory",
}

export enum ABO_PROFILE_TABS_ID {
  INSIGHTS = "insights",
  OVERVIEW = "overview",
  DATES = "dates",
  SPONSORSHIP = "sponsorship",
}
