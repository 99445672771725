import { Trans, useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { track } from "@amwaycommon/acc-mixins/analytics";
import { BannerTile } from "../../../common/components/BannerTile/BannerTile";
import TEALIUM from "../../../common/enums/tealium-enums";
import { QUALIFICATION_STATUS } from "../../../common/enums/incentive-enums";
import defaultContent from "../../../content/ppe";
import { useAppSelector } from "../../../store";
import { getCurrentPeriod, getFullMonth, getMonthString, getYear } from "../../../common/util/period";
import { storedLocale } from "../../impersonation/util";
import { PPEIncomeData } from "../../../services/performancePlusEliteOverviewAPI";

const getBannerTitle = (
  status: QUALIFICATION_STATUS,
  selectedPeriod: string,
  titleKey: string,
  locale: string,
  translate: TFunction,
) => {
  let contentKey = "";
  let contentObjects = {};

  switch (status) {
    case QUALIFICATION_STATUS.QUALIFIED:
      contentKey = "earned";
      break;
    case QUALIFICATION_STATUS.TRACKING:
      contentKey = "tracking";
      break;
    case QUALIFICATION_STATUS.NOT_TRACKING:
      contentKey = "notTracking";
      break;
    case QUALIFICATION_STATUS.MANUALLY_APPROVED:
      contentKey = "manuallyApproved";
      break;
    case QUALIFICATION_STATUS.HELD:
      contentKey = "held";
      break;
    case QUALIFICATION_STATUS.NOT_QUALIFIED:
    case QUALIFICATION_STATUS.DENIED:
      contentKey = "notEligible";
      break;
  }

  contentObjects = {
    monthYear: getFullMonth(selectedPeriod, locale) + " " + getYear(selectedPeriod, locale),
  };

  return status === QUALIFICATION_STATUS.HELD
    ? translate(`bannerTile.${contentKey}`, String(defaultContent["bannerTile"][contentKey]), contentObjects)
    : translate(
        `bannerTile.${titleKey}.${contentKey}`,
        String(defaultContent["bannerTile"][titleKey][contentKey]),
        contentObjects,
      );
};

type Props = {
  incomeData: PPEIncomeData;
  selectedPeriod: string;
  isLoading: boolean;
  titleKey: string;
};

export const PpeBannerTile = ({ incomeData, selectedPeriod, isLoading, titleKey }: Props) => {
  const { t } = useTranslation(["ppe"]);
  const { configuration } = useAppSelector((store) => store.boot);
  const locale = storedLocale();
  const learnMoreLink = configuration.dashboardLinks?.learnMore?.performancePlusElite || "";

  const monthYear = getFullMonth(selectedPeriod, locale) + " " + getYear(selectedPeriod, locale);

  const handleTealiumTrackClickActionLearnMore = () => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "perf_plus_elite",
      TEALIUM.CATEGORY.PERFORMANCE_PLUS_ELITE,
      "perf_plus_elite",
      "learn_more",
    );
  };

  return (
    <BannerTile
      isLoading={isLoading}
      title={getBannerTitle(incomeData.Status, selectedPeriod, titleKey, locale, t)}
      description={t(
        `bannerTile.${titleKey}.description`,
        String(defaultContent["bannerTile"][titleKey].description),
        {},
      )}
      bonusAmountLabel={t("bannerTile.potentialEarnings", defaultContent.bannerTile.potentialEarnings, {
        monthYear: monthYear,
      })}
      shouldDisplayAmount={incomeData.Status !== QUALIFICATION_STATUS.NOT_QUALIFIED}
      bonusAmount={incomeData.PotentialEarnings}
      incentiveCapLabel={
        incomeData.IncentiveCap
          ? t("bannerTile.incentiveCap", defaultContent.bannerTile.incentiveCap, {
              bonusCap: incomeData.IncentiveCap,
            })
          : ""
      }
      learnMoreLink={learnMoreLink}
      learnMoreTealiumFunction={handleTealiumTrackClickActionLearnMore}
    />
  );
};
