import { toSingleLine } from "../common/util/format";
import {
  ABOProfileParams,
  ProfileParams,
  ProfileResponse,
  ProfileTransformResponse,
  SponsorProfileResponse,
  SponsorProfileTransformResponse,
} from "../reducers/model/profile";
import { accAPI } from ".";

export const profileAPI = accAPI.injectEndpoints({
  endpoints: (build) => ({
    getProfile: build.query<ProfileTransformResponse, ProfileParams>({
      query: (params) => {
        const { period, affAbo } = params;
        const query = `{
          aboDetail(period:"${period}",affAbo:"${affAbo}"){
            aboInfo {
              affNo
              aboNo
              name
              localName
              isoLanguageCode
              privacyFlag
              isNew
              isNewInCurrentMonth
              isNewMigrated
              entryDate
              joinedDate
              countryCode
              verifiedCustomerFlag
              businessNatureCode
              isoLanguageCode
              isoBirthdate
              renewalDate
              registrationChannelCd
            }
            partyInfo {
              partyId
              localeDisplayName
              displayName
              localeName {
                givenName
                familyName
              }
              latinName {
                givenName
                familyName
              }
              isPrimary
              gender
              isoBirthdate
              preferredLanguage
              preferredLanguageCode
              phoneNumbers {
                displayNumber
                extension
                number
                outOfCountryCallingNumber
              }
              emailAddresses {
                email
                type
              }
              addresses {
                addressLines
                municipality
                region
                postalCode
                country
                type
              }
            }
          }
        }`;
        const queryParams = new URLSearchParams({
          query: toSingleLine(query),
        });
        return `/gql/los/graphql?${queryParams}`;
      },
      transformResponse: (response: ProfileResponse) => {
        if (response) {
          const {
            data: {
              aboDetail: { aboInfo, partyInfo },
            },
          } = response;

          return {
            aboInfo,
            partyInfo,
          };
        }
        return {};
      },
    }),
    getContactInfo: build.query<ProfileTransformResponse, ProfileParams>({
      query: (params) => {
        const { period, affAbo } = params;
        const query = `{
          aboDetail(period:"${period}",affAbo:"${affAbo}"){
            aboInfo {
                privacyFlag
                sponsor {
                  affNo
                  aboNo
                  name
                }
            }
            partyInfo {
              phoneNumbers {
                displayNumber
              }
              emailAddresses {
                email
              }
            }
          }
        }`;
        const queryParams = new URLSearchParams({
          query: toSingleLine(query),
        });
        return `/gql/los/graphql?${queryParams}`;
      },
      transformResponse: (response: ProfileResponse) => {
        if (response) {
          const {
            data: {
              aboDetail: { aboInfo, partyInfo },
            },
          } = response;

          return {
            aboInfo,
            partyInfo,
          };
        }
        return {};
      },
    }),
    getABOProfile: build.query<ProfileTransformResponse, ABOProfileParams>({
      query: (params) => {
        // reqAffAbo is required for aboInfo.aboRecentOrderDate field
        const { period, affAbo, reqAffAbo } = params;
        const query = `{
          aboDetail(period:"${period}",affAbo:"${affAbo}",reqAffAbo: "${reqAffAbo}"){
            aboInfo {
              affNo
              aboNo
              name
              localName
              privacyFlag
              status
              businessNatureCode
              verifiedCustomerFlag
              isoBirthdate
              entryDate
              renewalDate
              recentSponsorDate
              isoLanguageCode
              sponsor {
                affNo
                aboNo
                isValid
                isPlatinum
              }
              platinumSponsor {
                affNo
                aboNo
                isValid
              }
              fosterSponsor {
                affNo
                aboNo
                isValid
                isPlatinum
              }
              internationalSponsor {
                affNo
                aboNo
                isValid
              }
            }
            awardInfo {
              currentPin
              currentPinDate
              currentPinLevel
              highestAwardAchieved
              highestAwardAchievedDate
              highestAwardAchievedLevel
              highestTrackingAward
              highestTrackingAwardLevel
            }
            partyInfo {
              isPrimary
              partyId
              displayName
              localeDisplayName
              preferredLanguageCode
              localeName {
                givenName
                familyName
              }
              latinName {
                givenName
                familyName
              }
              phoneNumbers {
                displayNumber
                extension
                number
                outOfCountryCallingNumber
              }
              emailAddresses {
                email
                type
              }
              addresses {
                addressLines
                municipality
                region
                postalCode
                country
                type
              }
            }
            additionalRegInfo {
              autoRenewal
              orderBlock
              sponsorBlock
              passport
              contractSigned
              isRegisteredCustomerPlus
            }
            orderInfo {
              aboRecentOrderDate
            }
          }
        }`;
        const queryParams = new URLSearchParams({
          query: toSingleLine(query),
        });
        return `/gql/los/graphql?${queryParams}`;
      },
      transformResponse: (response: ProfileResponse) => {
        if (response) {
          const {
            data: {
              aboDetail: { aboInfo, awardInfo, partyInfo, additionalRegInfo, orderInfo },
            },
          } = response;

          return {
            aboInfo,
            awardInfo,
            partyInfo,
            additionalRegInfo,
            orderInfo,
          };
        }
        return {};
      },
    }),
    getSponsorInfo: build.query<SponsorProfileTransformResponse, ProfileParams>({
      query: (params) => {
        const { period, affAbo } = params;
        const query = `{
          aboDetail(period:"${period}",affAbo:"${affAbo}"){
            aboInfo {
              affNo
              aboNo
              name
              localName
              privacyFlag
              isoLanguageCode
              phoneNumbers {
                  numbers {
                      displayNumber
                  }
              }
              emailAddresses {
                  addresses {
                      address
                  }
              }
              addresses {
                  addressLines
                  municipality
                  region
                  postalCode
                  country
                  type
              }
            }
          }
        }`;
        const queryParams = new URLSearchParams({
          query: toSingleLine(query),
        });
        return `/gql/los/graphql?${queryParams}`;
      },
      transformResponse: (response: SponsorProfileResponse) => {
        if (response) {
          const {
            data: {
              aboDetail: { aboInfo },
            },
          } = response;
          return {
            aboInfo,
          };
        }
        return {};
      },
    }),
  }),
});

export const { useGetProfileQuery, useGetContactInfoQuery, useGetABOProfileQuery, useGetSponsorInfoQuery } = profileAPI;
