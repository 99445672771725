import { ReactNode } from "react";
import "./LeafButton.scss";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  clickAction: Function;
  children?: ReactNode;
  Icon?: ReactNode;
  id: string;
  variant?: string;
  isDisabled?: boolean;
  ariaLabel?: string;
}

export enum Variant {
  DEFAULT = "LeafButton__default",
  SOLID = "LeafButton__solid",
  BLUE = "LeafButton__blue",
  DISABLED_BLUE = "LeafButton__blue LeafButton__blue--disabled",
}

const LeafButton = ({
  clickAction,
  children,
  Icon,
  id,
  variant = Variant.DEFAULT,
  isDisabled = false,
  ariaLabel,
  ...htmlAttributes
}: Props) => {
  return (
    <button
      {...htmlAttributes}
      aria-label={ariaLabel}
      disabled={isDisabled}
      id={id}
      data-testid={id}
      className={`LeafButton ${variant}${htmlAttributes.className ? " " + htmlAttributes.className : ""}`}
      onClick={() => clickAction()}
    >
      {children}
      {Icon && <span className="LeafButton__icon">{Icon}</span>}
    </button>
  );
};

export default LeafButton;
