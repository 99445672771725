const content: { [key: string]: any } = {
  pqModal: {
    monthlyGpv: "Monthly Target Volume Status",
    previousYtd: "Previous Performance Year",
    currentYtd: "Current YTD",
    performanceYear: "PY{performanceYear}",
    qualified: "Qualified",
    rubyPv: "Ruby PV",
    gpv: "Group PV",
    qualifiedLegs: "# of In-Market Qualified Legs",
    rubyPvNeeded: "Ruby PV Needed",
    boosterRequirements: "Booster Requirements",
  },
  legend: {
    earned: "Earned",
    potential: "Potential",
    notEarned: "Not Earned",
    unavailable: "Unavailable",
  },
} as const;

export default content;
