import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../../../store";
import { lambdaErrorCheck } from "../../../../common/util/errorHandling";
import { useLazyGetBonusBreakdownQuery } from "../../../../services/monthyPerformanceDashboardAPI";
import { BonusBreakdownData } from "../../../../common/interfaces/monthlyPerformanceDash";
import { storedLocale } from "../../../impersonation/util";

export const defaultBonusBreakdownData = (): BonusBreakdownData => {
  return {
    BonusAmountSubTotal: "",
    BonusAmount: [],
    CallResults: [],
  };
};

export const FetchBonusBreakdownData = (period: string) => {
  const { configuration, user, isShellFetching, isShellLoading } = useAppSelector((state) => state.boot);
  const shellConfig = useAppSelector((state) => state.boot.shellConfig);

  const { persona, hasAchieved15PercentOrHigher } = shellConfig || {};

  const isShellDataReady = useMemo(
    () => shellConfig && !isShellLoading && !isShellFetching,
    [shellConfig, isShellLoading, isShellFetching],
  );

  const [triggerBonusBreakdownQuery, { data: payloadData, isFetching, isError }] = useLazyGetBonusBreakdownQuery();

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [bonusBreakdownData, setBonusBreakdownData] = useState<BonusBreakdownData>(defaultBonusBreakdownData());

  useMemo(() => {
    if (isShellDataReady) {
      triggerBonusBreakdownQuery(
        {
          baseUrl: configuration.monthlyDashboardApiUrl,
          abo: user.abo,
          aff: user.aff,
          period: period,
          isoCountryCode: user.isoCountryCode,
          isoCurrencyCode: shellConfig?.isoCurrencyCode,
          locale: storedLocale(),
          persona: persona,
          hasQualified: hasAchieved15PercentOrHigher,
          bonusPeriodCount: 1,
        },
        true,
      ); // use cached response if available
    }
  }, [isShellDataReady, period]);

  useEffect(() => {
    setIsLoading(isFetching || isShellLoading || isShellFetching);
    setErrorStatus(isError);

    if (!isFetching && !isError && payloadData && isShellDataReady) {
      setBonusBreakdownData(payloadData);
      const foundLambdaError = lambdaErrorCheck(payloadData?.errors);
      setErrorStatus(foundLambdaError);
    }
  }, [isFetching, isError, payloadData, isShellFetching, isShellLoading, isShellDataReady]);

  return { isLoading, bonusBreakdownData, errorStatus, refetch: triggerBonusBreakdownQuery };
};
