import { useTranslation } from "react-i18next";
import defaultContent from "../../../content/leafCustomerPV";
import { LeafPV } from "../LeafFormat/LeafPV/LeafPV";
import "./LeafCustomerPV.scss";

type Props = {
  vcsPV: number;
  customerPV: number;
};

const LeafCustomerPV = (props: Props) => {
  const { t } = useTranslation(["leafCustomerPV"]);
  const { vcsPV, customerPV } = props;

  return (
    <span className="leaf-customer-pv" data-testid="leafCustomerPv">
      <span className="leaf-customer-pv__pv-value" data-testid="leafCustomerPvValue">
        <LeafPV pv={customerPV} />
      </span>
      <span className="leaf-customer-pv__label" data-testid="leafCustomerPvLabel">
        {vcsPV === customerPV && customerPV !== 0 ? t("vcsPV", defaultContent["vcsPV"]) : t("pv", defaultContent["pv"])}
      </span>
    </span>
  );
};

export default LeafCustomerPV;
