import { store } from "../../store";
import { DROPDOWN_KEYS, ROUTE_LABELS } from "../enums/routing-enums";
import { NavDropdown } from "../interfaces/routing";
import { storedSsoOrigin } from "./config";
import { getPerformanceYear } from "./period";

export const getLearnMoreLink = (menuConfig: NavDropdown[]) => {
  let learnMoreLink = "";
  const resources = menuConfig.find((dropdown) => dropdown.key === DROPDOWN_KEYS.RESOURCES);
  if (resources) {
    resources.items.forEach((NavDropdownItem) => {
      if (NavDropdownItem.label === ROUTE_LABELS.LEARN_ABOUT_CPP) {
        learnMoreLink = NavDropdownItem.path;
      }
    });
  }
  return learnMoreLink;
};

export const displayFeedbackLink = (menuConfig: NavDropdown[]): boolean => {
  const resources = menuConfig.find((dropdown) => dropdown.key === DROPDOWN_KEYS.RESOURCES);
  return resources ? resources.items.some((d) => d.label === ROUTE_LABELS.FEEDBACK) : false;
};

export const getDisplayAcademyLink = (menuConfig: NavDropdown[]): boolean => {
  const academy = menuConfig.find((dropdown) => dropdown.key === DROPDOWN_KEYS.ACADEMY);
  return academy?.label === ROUTE_LABELS.ACADEMY;
};

export const getAcademyLink = (menuConfig: NavDropdown[]) => {
  const academy = menuConfig.find((dropdown) => dropdown.key === DROPDOWN_KEYS.ACADEMY);
  return academy?.path || "";
};

// convert Date object into bonus period format. Eg. 202301
export const getBonusPeriod = (dateTime: Date) => {
  const month = dateTime.getMonth() + 1;
  const bonusMonth = month < 10 ? `0${month}` : month;
  return `${dateTime.getFullYear()}${bonusMonth}`;
};

export const getLastPerformancePeriod = (selectedPeriod: string) => {
  const perfYear = getPerformanceYear(selectedPeriod);
  // return YYYMM date format
  return `${perfYear}08`;
};

export const showLogoutLink = (): boolean => {
  const ssoOrigin = storedSsoOrigin();
  const { hideLogoutForMarkets } = store.getState().boot.configuration;
  return !hideLogoutForMarkets?.some((origin: string) => origin === ssoOrigin);
};

export const checkUpperCaseEqual = (firstStr: string, secondStr: string) => {
  if (typeof firstStr === "string" && typeof secondStr === "string") {
    return firstStr.toUpperCase() === secondStr.toUpperCase();
  }
  return firstStr === secondStr;
};
