import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import { ErrorTile } from "../../common/components/ErrorTile/ErrorTile";
import { QUALIFICATION_STATUS } from "../../common/enums/incentive-enums";
import TEALIUM from "../../common/enums/tealium-enums";
import { IncomeData } from "../../common/interfaces/groupGrowthIncentives";
import LeafTile from "../../common/leaf/LeafTile/LeafTile";
import defaultContent from "../../content/frontlineGrowth";
import { SELECTOR_TYPE, updateSelectorType } from "../../reducers/period";
import { useAppDispatch, useAppSelector } from "../../store";
import { RequirementTileMapper } from "../../common/components/RequirementTileMapper/RequirementTileMapper";
import { PageTitleSection } from "../../common/components/PageTitleSection/PageTitleSection";
import FGGIncentiveCalculation from "./FGGIncentiveCalculation/FGGIncentiveCalculation";
import { FggBannerTile } from "./FggBannerTile/FggBannerTile";
import "./FrontlineGroupGrowth.scss";
import { FetchFggOverviewData } from "./FrontlineGroupGrowthDataLayer";
import { MultiplierDetails } from "./MultiplierDetails/MutliplierDetails";
import TrackingProgressTile from "./TrackingProgressTile/TrackingProgressTile";

export default function FrontlineGroupGrowth() {
  const { t } = useTranslation(["frontlineGrowth"]);
  const { shellConfig } = useAppSelector((state) => state.boot);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { isComingSoon = false } = shellConfig;
  const [multiplierDetailsExpanded, setMultiplierDetailsExpanded] = useState(false);

  const {
    fggOverviewData,
    isLoading,
    errorStatus,
    refetch: refetchFggOverviewData,
  } = FetchFggOverviewData(selectedPeriod, isComingSoon);
  const dispatch = useAppDispatch();

  useEffect(() => {
    track(TEALIUM.U_TAG_METHOD.VIEW, "", "frontline-growth", "frontline-growth", `${selectedPeriod}`, "");
  }, [selectedPeriod]);

  const displayOnlyBanner = (data: IncomeData) => {
    const { Status } = data;
    return (
      Status === QUALIFICATION_STATUS.MANUALLY_APPROVED ||
      Status === QUALIFICATION_STATUS.HELD ||
      Status === QUALIFICATION_STATUS.DENIED
    );
  };

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.PY));
  }, [dispatch]);

  if (errorStatus) {
    return (
      <LeafTile hideBorder={true}>
        <ErrorTile clickFunction={refetchFggOverviewData} errorStatus={errorStatus} isLoading={isLoading} />
      </LeafTile>
    );
  }

  return (
    <main className="Fgg">
      <div className="Fgg__container">
        <PageTitleSection title={`${t("pageTitle", defaultContent["pageTitle"])}`} />
        <div className="Fgg__container-split">
          {displayOnlyBanner(fggOverviewData.IncomeData) || isComingSoon ? (
            <div className="Fgg__section-padding Fgg__container-row">
              <section className="Fgg__banner">
                <FggBannerTile
                  incomeData={fggOverviewData.IncomeData}
                  isLoading={isLoading}
                  isNewPerformanceYear={isComingSoon}
                />
              </section>
            </div>
          ) : (
            <>
              <div className="Fgg__section-padding Fgg__container-row">
                <section className="Fgg__banner">
                  <FggBannerTile incomeData={fggOverviewData.IncomeData} isLoading={isLoading} />
                </section>
                <section className="Fgg__tracking">
                  <TrackingProgressTile
                    isLoading={isLoading}
                    totalRequirements={fggOverviewData.AnnualRequirements.TotalRequirements}
                    totalRequirementsMet={fggOverviewData.AnnualRequirements.RequirementsMet}
                    requirementMetStatuses={fggOverviewData.AnnualRequirements.RequirementMetStatuses}
                    id="Tracking Progress Tile"
                  />
                </section>
              </div>
              <div className="Fgg__section-padding Fgg__calculation">
                <div>
                  <h2>{t("incentiveCalc.sectionHeader", defaultContent.incentiveCalc.sectionHeader).toString()}</h2>
                </div>
                <div className="Fgg__multiplication">
                  <FGGIncentiveCalculation
                    multiplierData={fggOverviewData.IncentiveData.MultiplierData}
                    isLoading={isLoading}
                    selectedPeriod={selectedPeriod}
                    isExpanded={multiplierDetailsExpanded}
                  />
                </div>
                <MultiplierDetails
                  fggOverviewData={fggOverviewData}
                  selectedPeriod={selectedPeriod}
                  isLoading={isLoading}
                  onExpand={setMultiplierDetailsExpanded}
                />
              </div>
              <div className="Fgg__section-padding Fgg__requirements">
                <div>
                  <h2>{t("requirements.sectionHeader", defaultContent.requirements.sectionHeader).toString()}</h2>
                </div>
                <div className="Fgg__requirements-content">
                  <RequirementTileMapper
                    isLoading={isLoading}
                    requirements={fggOverviewData.AnnualRequirements}
                    origin="fgg"
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <section className="Fgg__footer">
          <BonusInfoFooter />
        </section>
      </div>
    </main>
  );
}
