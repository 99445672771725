import React, { useMemo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "../../util/debounce";
import { IconSearch } from "../../icons/IconSearch/IconSearch";
import { IconClose } from "../../icons/IconClose/IconClose";
import "./LeafSearchInput.scss";
import defaultContent from "../../../content/leafFilter";

export type Props = {
  placeholder: string;
  callback: Function;
  clearOnClick: Function;
  debounceMS: number;
  minDigit?: number;
  minChar?: number;
  searchKeyword: string;
};

const LeafSearchInput = (props: Props) => {
  const { t } = useTranslation(["leafFilter"]);
  const { placeholder, callback, clearOnClick, debounceMS, minDigit = 0, minChar = 0, searchKeyword = "" } = props;
  const [focused, setFocused] = useState(false);
  const [inputText, setInputText] = useState(searchKeyword);

  const cancelOnClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
    setInputText("");
    setFocused(false);
  };

  const _clearOnClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
    setInputText("");
    clearOnClick();
  };

  const onFocus = (e: React.FocusEvent<HTMLSpanElement>) => {
    setFocused(true);
  };

  const onBlur = (e: React.FocusEvent<HTMLSpanElement>) => {
    if (e.relatedTarget === null) {
      setFocused(false);
    }
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((keyword: string) => {
        const isPureNumberic = keyword && isNaN(Number(keyword)) === false;
        const matchDigitLimit = keyword.length >= minDigit;
        const matchCharLimit = keyword.length >= minChar;
        const matchMinLimit = isPureNumberic === true ? matchDigitLimit : matchCharLimit;

        if (matchMinLimit === true) {
          callback(keyword);
        }

        if (keyword.length === 0) {
          clearOnClick();
        }
      }, debounceMS),
    [callback, clearOnClick, debounceMS, minChar, minDigit],
  );

  const changeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const inputText = e.target.value;
      setInputText(inputText);
      debouncedSearch(inputText);
    },
    [debouncedSearch],
  );

  return (
    <div className={`search-container ${focused && "search-container--focused"}`}>
      <div className="search-container__input-icons">
        <div className="search-container__icon">
          {inputText !== "" ? (
            <span data-testid="search-container__button-close" tabIndex={0} onClick={_clearOnClick}>
              <IconClose height="1.25rem" width="1.188rem" />
            </span>
          ) : (
            <IconSearch />
          )}
        </div>
        <input
          type={minChar && minChar > 0 ? "text" : "number"}
          value={inputText}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          onChange={changeHandler}
        />
      </div>
      {focused === true && (
        <span tabIndex={0} className="search-container__cancel-button" onClick={cancelOnClick}>
          {t("cancel", defaultContent["cancel"])}
        </span>
      )}
    </div>
  );
};

export default LeafSearchInput;
