import { useState, useEffect, useRef } from "react";
import { IconArrowDropdown } from "../../icons/IconArrowDropdown/IconArrowDropdown";
import { IconArrowUp } from "../../icons/IconArrowUp/IconArrowUp";
import { FocusTrap } from "../../components/FocusTrap/FocusTrap";
import "./LeafSelector.scss";

export type Option = {
  id: string;
  label: string;
  selected?: boolean;
};

export type Props = {
  options: Option[];
  onSelected: (option: Option) => void;
  ariaLabel?: string;
};

const LeafSelector = (props: Props) => {
  const { options, onSelected, ariaLabel } = props;
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownOptionRef = useRef<HTMLDivElement>(null);
  const toggleButtonRef = useRef<HTMLButtonElement>(null);
  const firstOptionRef = useRef<HTMLLIElement>(null);
  const selectedOption = options.find((option) => option.selected) || null;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (toggleButtonRef.current) {
      toggleButtonRef.current.focus();
    }
  };

  const handleOptionClick = (option: Option) => {
    onSelected(option);
    setIsOpen(false);
    if (toggleButtonRef.current) {
      toggleButtonRef.current.focus();
    }
  };

  const handleOptionKeyDown = (e: React.KeyboardEvent, option: Option) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      handleOptionClick(option);
    }
  };

  const handleButtonKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Tab" && isOpen && !e.shiftKey) {
      e.preventDefault();
      if (firstOptionRef.current) {
        firstOptionRef.current.focus();
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownOptionRef.current && !dropdownOptionRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    const button = toggleButtonRef.current;
    if (button) {
      button.addEventListener("keydown", handleButtonKeyDown);
    }
    return () => {
      if (button) {
        button.removeEventListener("keydown", handleButtonKeyDown);
      }
    };
  }, [isOpen]);

  return (
    <div ref={dropdownRef} className="leaf-selector__dropdown-menu">
      <button
        ref={toggleButtonRef}
        className={`leaf-selector__dropdown-toggle leaf-selector__dropdown-toggle${isOpen === true ? "--open" : ""}`}
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-label={ariaLabel}
        onClick={toggleMenu}
      >
        <div>{selectedOption ? selectedOption.label : ""}</div>
        <div className="leaf-selector__dropdown-icon">
          {isOpen === true ? <IconArrowUp backgroundClass={"blue"} /> : <IconArrowDropdown backgroundClass={"black"} />}
        </div>
      </button>
      {isOpen === true && (
        <FocusTrap id={ariaLabel} handleOnClose={() => {}}>
          <div ref={dropdownOptionRef}>
            <ul className="leaf-selector__dropdown-options" role="listbox">
              {options.map((option, index) => (
                <li
                  key={option.id}
                  ref={index === 0 ? firstOptionRef : null}
                  className={option === selectedOption ? "selected" : ""}
                  role="option"
                  onClick={() => handleOptionClick(option)}
                  tabIndex={0}
                  onKeyDown={(e) => handleOptionKeyDown(e, option)}
                  aria-selected={selectedOption === option}
                  aria-label={option.label}
                >
                  {option.label}
                </li>
              ))}
            </ul>
          </div>
        </FocusTrap>
      )}
    </div>
  );
};

export default LeafSelector;
