const content: { [key: string]: string } = {
  title: "Total Customer PV",
  subtitle: "Total:",
  cpv: "CPV",
  legendVcsPvText: "VCS PV",
  legendVcsPvValue: "({PV}) {PERCENTAGE}%",
  legendNonVcsPvText: "Non-VCS PV",
  legendNonVcsPvValue: "({PV}) {PERCENTAGE}%",
  legendOtherNonVcsPvText: "Other",
  legendOtherNonVcsPvValue: "({PV}) {PERCENTAGE}%",
};

export default content;
