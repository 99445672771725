import "./IconArrowDropdown.scss";

type Props = {
  backgroundClass: "blue" | "white" | "black";
  thickness?: "thick" | "";
};

export const IconArrowDropdown = ({ backgroundClass = "black", thickness = "" }: Props) => {
  return (
    <span
      className={`icon-arrow-dropdown icon-arrow-dropdown--${backgroundClass} icon-arrow-dropdown--${thickness}`}
    ></span>
  );
};
