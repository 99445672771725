import { track } from "@amwaycommon/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../../../enums/tealium-enums";

export const searchOrderClickTrack = () => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.GLOBAL_CUSTOMER_PROFILE,
    "",
    "search_order",
  );
};

export const profileOrderHistoryTabErrorTrack = () => {
  track(TEALIUM.U_TAG_METHOD.VIEW, "", "", TEALIUM.CATEGORY.GLOBAL_CML, "profile_order_history_tab_error", "");
};
