import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store";
import { SELECTOR_TYPE, updateSelectorType } from "../../reducers/period";
import defaultContent from "../../content/qcGrowth";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import { PageTitleSection } from "../../common/components/PageTitleSection/PageTitleSection";
import { MULTIPLIER_DATA_TYPE } from "../../common/enums/requirements";
import TrackingProgressTile from "./TrackingProgressTile/TrackingProgressTile";
import QCGBannerTile from "./QCGBannerTile/QCGBannerTile";
import QCGIncentiveCalculation from "./QCGIncentiveCalculation/QCGIncentiveCalculation";
import { IncomeData, notTrackingRequirements, requirements } from "./qcqMockData";
import { QCGRequirements } from "./QCGRequirements/QCGRequirements";
import "./QCGrowth.scss";
import { qcgOverviewData } from "./qcgMockData";

export const QCGrowth = () => {
  const { t } = useTranslation(["qcGrowth"]);
  const dispatch = useAppDispatch();
  const { selectedPeriod } = useAppSelector((state) => state.period);

  const isLoading = false;

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.PY));
  }, [dispatch]);

  return (
    <main className="Qcg">
      <PageTitleSection title={`${t("pageTitle", defaultContent["pageTitle"])}`} />
      <div className="Qcg__container">
        <div className="Qcg__section-padding Qcg__container-row">
          <section className="MonthlyPgg__banner">
            <QCGBannerTile isLoading={isLoading} incomeData={IncomeData} />
          </section>
          <section className="Qcg__tracking">
            <TrackingProgressTile
              isLoading={isLoading}
              totalRequirements={requirements.TotalRequirements}
              totalRequirementsMet={requirements.RequirementsMet}
              requirementMetStatuses={requirements.RequirementMetStatuses}
              id="Tracking Progress Tile"
            />
          </section>
        </div>
        <div className="Qcg__section-padding Qcg__calculation">
          <div>
            <h2>{t("incentiveCalc.sectionHeader", defaultContent.incentiveCalc.sectionHeader).toString()}</h2>
          </div>
          <div className="Qcg__multiplication">
            <QCGIncentiveCalculation
              incentiveData={qcgOverviewData.IncentiveData}
              multiplierData={[
                {
                  Type: MULTIPLIER_DATA_TYPE.INCENTIVE,
                  Base: "$0.00",
                  Multiplier: "0%",
                  Total: "$0.00",
                },
              ]}
              multiplierMatrix={qcgOverviewData.MultiplierMatrix}
              isLoading={isLoading}
              selectedPeriod={selectedPeriod}
            />
          </div>
        </div>
        <section className="Qcg__section-padding">
          <QCGRequirements requirements={notTrackingRequirements} isLoading={isLoading} />
        </section>
        <section className="Qcg__footer">
          <BonusInfoFooter />
        </section>
      </div>
    </main>
  );
};
