// maps a route to the correct tealium action name
const routeToTealiumClickActionMapper: { [key: string]: string } = {
  "/action-reports.html": "action_reports_link",
  "/annual-performance": "annual_performance",
  "/bronze-builder": "bronze_builder",
  "/bronze-foundation": "bronze_foundation",
  "/calculator.html": "earnings_calculator",
  "/cml.html": "customer_member_list",
  "/csi": "customer_sales_incentive",
  "/edlos": "edlos_link",
  "/frontlineGrowth": "frontline_group_growth",
  "/rwlos.html": "los_link",
  "/monthly-performance": "monthly_performance",
  "/performance-bonus": "performance_bonus",
  "/performance-plus-elite": "performance_plus_elite",
  "/personalGrowth": "personal_group_growth",
  "/personalGrowthMonthly": "personal_group_growth",
  "/ttci": "two_time_cash_incentive",
  "/watchlist.html": "watchlist_link",
  "/cbr-action-report.html": "cbr_action_reports_link",
  "/ibo-onboarding-action-report.html": "ibo_onboarding_action_reports_link",
  "/path-to-bronze": "path_to_bronze",
  "/birthdays-action-report.html": "birthdays_action_reports_link",
  "/whos-new-action-report.html": "whos_new_action_reports_link",
  "/whos-close-action-report.html": "whos_close_action_reports_link",
  "/nbbv-action-report.html": "nbbv-action-reports_link",
};

const navigationDropDownTextToTealiumClickActionMapper: { [key: string]: string } = {
  "Action Reports": "action_reports_link",
  "Annual Performance": "annual_performance",
  "Annual Personal Group Growth+": "personal_group_growth",
  "Bronze Foundation+": "bronze_foundation",
  "Bronze Builder+": "bronze_builder",
  "Customer Member List": "customer_member_list",
  "Customer Sales Incentive+": "customer_sales_incentive",
  "Earnings Calculator": "earnings_calculator",
  "Emerald & Diamond LOS": "edlos_link",
  "Frontline Group Growth+": "frontline_group_growth",
  "Learn About Core Plus Portal": "provide_feedback",
  "Line of Sponsorship (LOS)": "los_link",
  "Monthly Performance": "monthly_performance",
  "Monthly Personal Group Growth+": "personal_group_growth",
  "Performance Bonus Detail": "performance_bonus",
  "Performance Plus & Elite+": "performance_plus_elite",
  "Provide Us Feedback": "provide_feedback",
  "Two Time Cash Incentive+": "two_time_cash_incentive",
  Watchlist: "watchlist_link",
};

export const TealiumClickActionMapper: { [key: string]: string } = {
  ...routeToTealiumClickActionMapper,
  ...navigationDropDownTextToTealiumClickActionMapper,
};
