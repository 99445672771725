import "./IconTwoUsers.scss";
import { ReactComponent as TwoUsersIcon } from "./svg/iconTwoUsers.svg";
import { ReactComponent as TwoUsersIconFilled } from "./svg/iconTwoUsersFilled.svg";

interface Props {
  color?: "orange" | "gray" | "green";
  style?: "outline" | "filled";
}

export const IconTwoUsers = ({ color = "gray", style = "outline" }: Props) => {
  return (
    <span className={`icon-two-users--${color}`}>
      {style === "outline" ? <TwoUsersIcon /> : <TwoUsersIconFilled />}
    </span>
  );
};
