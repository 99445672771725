import { _createGlobalUserID, storedLocale } from "../../components/impersonation/util";
import { storedIsoCountry } from "./config";

/**
 * This function loads the correct Tealium script for the page
 */
export const loadTealiumScript = async (state: any) => {
  const isoCountryCode = storedIsoCountry() || "US";
  const locale = storedLocale() || "en-US";

  try {
    const config = state.configuration;
    const countries = config.countries;
    const abo = state.user.abo;
    const aff = state.user.aff;
    const partyId = state.user.partyId;
    const siteType = "responsive";
    const userProfile = "abo";

    //disable automated page tracking
    window.utag_cfg_ovrd = { noview: true };
    // set utag user data
    window.utag_data = {
      site_type: siteType,
      site_country: isoCountryCode.toLowerCase(),
      site_language: locale.split("-")[1],
      site_region: countries[isoCountryCode][3],
      site_subRegion: countries[isoCountryCode][0],
      site_currencyCode: countries[isoCountryCode][1],
      site_prefix: "amway",
      site_type_short: "web",
      site_webProperty: "coreplus",
      visitor_customerID: "",
      visitor_imcID: _createGlobalUserID(aff, abo),
      visitor_partyID: partyId,
      visitor_userProfile: userProfile,
    };

    let src = "//tags.tiqcdn.com/utag/amway/coreplus/dev/utag.js";

    if (config.env === "prod") {
      src = "//tags.tiqcdn.com/utag/amway/coreplus/prod/utag.js";
    }

    (function (a, b, c, d) {
      // @ts-expect-error
      a = src;
      // @ts-expect-error
      b = document;
      // @ts-expect-error
      c = "script";
      // @ts-expect-error
      d = b.createElement(c);
      // @ts-expect-error
      d.src = a;
      // @ts-expect-error
      d.type = "text/java" + c;
      // @ts-expect-error
      d.async = true;
      // @ts-expect-error
      a = b.getElementsByTagName(c)[0];
      // @ts-expect-error
      a.parentNode.insertBefore(d, a);
    })();
  } catch (e) {
    console.warn("tealium utag data not set: ", e);
  }
};
