/* eslint-disable import/no-named-as-default-member */
import i18n, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi, { HttpBackendOptions } from "i18next-http-backend";
import { supportedLanguageCodes } from "./components/impersonation/locale-codes";
import { namespaces } from "./content/namespace/namespaces";
import { EVENTS } from "./common/enums/events";
import { store } from "./store";
import { SetLoginFields } from "./reducers/model/boot";

const configuration: InitOptions = {
  backend: {
    loadPath: () => {
      const market = store.getState().boot.user.isoCountryCode || "US";
      return `/content/${market}/text/acc-abo-tools-portal/{ns}/{lng}.json`;
    },
    allowMultiLoading: false,
    crossDomain: false,
    requestOptions: {
      mode: "cors",
      credentials: "same-origin",
      cache: "default",
    },
  },
  fallbackLng: false,
  interpolation: {
    escapeValue: false,
    prefix: "{",
    suffix: "}",
  },
  react: {
    useSuspense: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "span"],
  },
  supportedLngs: supportedLanguageCodes,
  // loads content file in format {locale}-{country} i.e. 'en-US'
  load: "currentOnly",
  // load all of content namespaces upfront - prevents content flicker
  ns: namespaces,
  detection: {
    lookupLocalStorage: "locale",
    caches: ["localStorage"],
  },
};

i18n.use(initReactI18next).use(HttpApi).use(LanguageDetector).init(configuration);

const languageSelectorEffect = (locale: string, setLocale: Function) => {
  const initLocaleOnPageLoad = () => {
    setLocale(locale);
    SetLoginFields({ fields: { locale: locale } });
    i18n.changeLanguage(locale);
  };

  initLocaleOnPageLoad();

  const languageSelectorChange = async (event: any) => {
    setLocale(event.detail.locale);
    SetLoginFields({ fields: { locale: event.detail.locale } });
    i18n.changeLanguage(event.detail.locale);
  };

  document.addEventListener(EVENTS.EVENT_LISTENER.LANGUAGE_CHANGED, languageSelectorChange);

  return () => {
    document.removeEventListener(EVENTS.EVENT_LISTENER.LANGUAGE_CHANGED, languageSelectorChange);
  };
};

const updateContentPathForMarket = async (newMarket = "") => {
  const newLoadPath = `/content/${newMarket}/text/acc-abo-tools-portal/{ns}/{lng}.json`;
  const backendOptions: HttpBackendOptions = i18n?.options?.backend || {};

  if (backendOptions.loadPath === newLoadPath) {
    Promise.resolve();
  }

  i18n.options.backend = {
    ...i18n.options.backend,
    loadPath: newLoadPath,
  };

  i18n.options.react = {
    ...i18n.options.react,
    useSuspense: false,
  };
  await i18n.init();
};

export { i18n, languageSelectorEffect, updateContentPathForMarket };
