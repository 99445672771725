import { track } from "@amwaycommon/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../../enums/tealium-enums";

export const viewEligibilityButtonOnClick = () => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    TEALIUM.SECTION.ELIGIBILITY_TILE,
    TEALIUM.CATEGORY.BRONZE_BUILDER_INCENTIVE,
    "",
    "eligibility_modal_button",
  );
};
