import { ReactComponent as LightningIcon } from "./svg/icon_lightning.svg";
import "./IconLightning.scss";

type props = {
  color?: "blue" | "white";
};
export const IconLightning = ({ color = "blue" }: props) => {
  return (
    <span className={`icon-lightning--${color}`}>
      <LightningIcon />
    </span>
  );
};
