import { storedLocale } from "../../../components/impersonation/util";

const LeafDecodeStoredCountryCode = ({ countryCode }: { countryCode: string }) => {
  try {
    const locale = storedLocale();
    return <>{new Intl.DisplayNames(locale, { type: "region" }).of(countryCode)}</>;
  } catch (error) {
    return <>{countryCode}</>;
  }
};

export default LeafDecodeStoredCountryCode;
