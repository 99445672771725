import "./IconCancel.scss";

type Props = {
  width: string;
  height: string;
  title?: string;
};

export const IconCancel = (props: Props) => {
  const { width, height, title = "" } = props;
  return title ? (
    <span role="img" title={title} className="icon-cancel" style={{ width, height }}></span>
  ) : (
    <span className="icon-cancel" style={{ width, height }}></span>
  );
};
