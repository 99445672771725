const content: { [key: string]: any } = {
  title: "{firstLegTaget} New Legs at {volumeNeeded} {volumeType}",
  descriptionOne: "Legs are considered to be new within <1>{numMonths} months</1> from registration date.",
  descriptionTwo: "Additionally level up your incentive payment when you achieve ",
  newLegs: "3 new legs",
  gpvNeeded: " with {gpvNeeded} {volumeType}.",
  additional: "Additional ",
  tagSingular: "Additional <1>{legsNeeded} leg needed</1>",
  tagPlural: "Additional <1>{legsNeeded} legs needed</1>",
  tagMetRequirement: "You’ve met this requirement.",
  additionalLegNeeded: "Additional 1 leg needed",
  earnedLegReqSingular: "{bonusAmount} earned for {legTarget} new leg",
  earnedLegReqPlural: "{bonusAmount} earned for {legTarget} new legs",
};

export default content;
