import { store as reduxStore } from "../../store";
/**
 * Returns isoCurrency code from redux store
 * @returns {string}
 */
export const storedIsoCountry = () => {
  return reduxStore.getState().boot.user.isoCountryCode || "DEFAULT";
};

/**
 * Returns ssoOrigin from redux store
 * @returns {string}
 */
export const storedSsoOrigin = () => {
  return (
    reduxStore.getState().loginLocalStorage.loginFields.ssoOrigin ||
    reduxStore.getState().boot.user.isoCountryCode ||
    "DEFAULT"
  );
};

/**
 * Returns a market's default currency code
 * @returns {string}
 */
export const getDefaultCurrencyCode = () => {
  return reduxStore.getState().boot.configuration.defaultCurrencyCode || "USD";
};

/**
 * Return the configuration file path based on the user's
 * SSO Origin (which is just their country code now).
 * @returns {String} config file path.
 */
export const regionalConfigPath = () => {
  // Sometimes we don't have an ssoOrigin (like on the impersonation
  // page), so we need to load the default config.
  const marketCode = storedSsoOrigin();

  return `/configs/${marketCode}.configuration.json`;
};

let __inProgressCacheTimer = Date.now();

/**
 * Holds the in progress fetch() actions that request() is queuing; can be used
 * by multiple instances
 */
const __inProgressCache: any = [];

/**
 * Baseline cache timings
 */
const __time = {
  millisecondsInOneHour: 1000 * 60 * 60 * 1,
  millisecondsIn15seconds: 1000 * 15,
};

/**
 * Returns configuration.json as an object. Useful when config needs to be
 * accessed before acc-boot has been initialized.
 * @returns {Promise}
 */
export const fetchConfig = async (path = regionalConfigPath()) => {
  let requestFound;

  // Check to verify if our memcache should be checked and cleared
  if (__inProgressCacheTimer + __time.millisecondsIn15seconds < Date.now()) {
    __inProgressCache.forEach((element: any, index: any) => {
      // if a request is in use, don't delete it otherwise we'll end up with
      // bad data
      if (element.useCount <= 0) {
        __inProgressCache.splice(index, 1);
      }
    });
    // reset the timer
    __inProgressCacheTimer = Date.now();
  }

  // Find if we have a request in progress that we can use
  if (__inProgressCache.find((item: any) => item.target === path)) {
    requestFound = __inProgressCache.find((item: any) => item.target === path);
    requestFound.useCount += 1;
  } else {
    // fetch a new request
    requestFound = {
      target: path,
      request: fetch(path),
      useCount: 1,
    };
    __inProgressCache.push(requestFound);
  }

  const response = await requestFound.request;

  if (!response.ok) {
    return new Error(`Could not fetch ${path}`);
  }

  const data = await response.clone().json();

  // decrement the use count within the memcache
  requestFound.useCount -= 1;

  return data;
};

export const mapAttributes = (element: any, config: any, options: any) => {
  // Verify `element` is an object.
  if (element === null || typeof element !== "object") {
    throw new Error("Expected parameter 'element' to be an object.");
  }
  // Verify `config` is an object.
  if (config === null || typeof config !== "object") {
    throw new Error("Expected parameter 'config' to be an object.");
  }
  const attributes = Object.keys(config);
  // Run this check for all keys first, to avoid setting some
  // attributes without setting all attributes.
  for (const attr of attributes) {
    const value = config[attr];
    if (value === null || value === undefined) {
      throw new Error(`Attribute value '${attr}' cannot be null or undefined.`);
    }
  }
  // Set attributes
  for (let attr of attributes) {
    const value = config[attr];
    const type = typeof value;

    // transform the attribute name if provided
    if (options.transform) {
      attr = options.transform(attr);
    }
    if (type === "string") {
      element.setAttribute(attr, value);
    } else if (type === "boolean") {
      if (value === true) {
        element.setAttribute(attr, "");
      }
    } else if (type === "number") {
      element.setAttribute(attr, value.toString());
    } else if (type === "object") {
      element.setAttribute(attr, JSON.stringify(value));
    } else {
      console.warn(`Unsupported data type '${type}' for attribute '${attr}'.`);
    }
  }
};

// Transform enum
export const AttributeTransform = {
  kebab: stringToKebabTransform,
};

Object.freeze(AttributeTransform);

/**
 * stringToKebabTransform
 *
 * Converts a string to kebab case
 * @param {string} input
 * @returns string
 */
export function stringToKebabTransform(input: any) {
  // regex performs some positive lookaround to look for locations to add a '-',  one of the following conditions exist
  // 1. add a '-' if previous character is lower case, current is upper
  // 2. add a '-' if previous is upper, current is upper, next is lower (JSONParser -> json-parser)
  // ** has some limitations (e.g. JSONAPI -> jsonapi, not json-api)
  const kebabStr =
    (input &&
      input
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map((x: any) => x.toLowerCase())
        .join("-")) ||
    input;

  return kebabStr;
}
