import { Font, StyleSheet } from "@react-pdf/renderer";
import NotoSansTCRegular from "../../common/fonts/notosans-tc/NotoSansTC-VariableFont_wght.ttf";
import NotoSansTCBold from "../../common/fonts/notosans-tc/NotoSansTC-Bold.ttf";
import themes from "./PDFExport.module.scss";

Font.register({
  family: "NotoSansTC",
  fonts: [{ src: NotoSansTCRegular }, { src: NotoSansTCBold, fontWeight: "bold" }],
});

// Create styles
// 1rem = 16px = 12pt
const styles = StyleSheet.create({
  body: {
    fontFamily: "NotoSansTC",
  },
  logo: {
    width: 80,
    height: 50,
  },
  backgroudAmwayWhite: {
    backgroundColor: themes.colorAmwayWhite,
  },
  displayFlexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  paddingLG: {
    padding: "18pt", // 24px
  },
  marginTopXSM: {
    marginTop: "6pt", // 8px
  },
  marginTopMD: {
    marginTop: "12pt", // 16px
  },
  marginTopLG: {
    marginTop: "18pt", // 24px
  },
  marginBottomXSM: {
    marginBottom: "6pt", // 8px
  },
  marginBottomSM: {
    marginBottom: "10.5pt", // 14px
  },
  marginBottomMD: {
    marginBottom: "12pt", // 16px
  },
  marginBottomLG: {
    marginBottom: "18pt", // 24px
  },
  fontLarge: {
    fontSize: "18pt", // 24px
    fontWeight: 800,
  },
  fontBig: {
    fontSize: "13.5pt", // 18px
  },
  fontStandard: {
    fontSize: "12pt", // 16px
  },
  fontMedium: {
    fontSize: "10.5pt", // 14px
  },
  fontSmall: {
    fontSize: "8.25pt", // 11px
  },
  fontBold: {
    fontWeight: Number(themes.fontWeightBold),
  },
  fontRegular: {
    fontWeight: Number(themes.fontWeightRegular),
  },
  fontColorDarkGrayOnWhite: {
    color: themes.colorDarkGrayOnWhite,
  },
  fontColorBlue: {
    color: themes.colorBlue,
  },
  textAlignRight: {
    textAlign: "right",
  },
  borderBox: {
    border: `1pt solid ${themes.colorLightGray}`,
    borderTopLeftRadius: "9pt",
    borderTopRightRadius: "9pt",
    borderBottomLeftRadius: "9pt",
    borderBottomRightRadius: "9pt",
  },
  borderTopLeftRight: {
    borderTopLeftRadius: "9pt",
    borderTopRightRadius: "9pt",
  },
  paymentSummary: {
    backgroundColor: themes.colorVeryLightGray,
    fontWeight: Number(themes.fontWeightBold),
    padding: "12pt",
  },
  paymentSummaryAmount: {
    display: "flex",
    flexDirection: "row",
    fontSize: "10.5pt",
    justifyContent: "space-between",
  },
  lineThick: {
    border: `0.6pt solid ${themes.colorLightGray}`,
    marginTop: "9.6pt",
    marginBottom: "9.6pt",
  },
  lineThin: {
    height: "0.6pt",
    backgroundColor: themes.colorLightGray,
    // margin: "9pt 0",
  },
  accountInfo: {
    display: "flex",
    flexDirection: "column",
    padding: "12pt",
  },
  generatorHeader: {
    padding: "6pt",
    color: themes.colorWhite,
    backgroundColor: themes.colorBlack,
  },
  generatorRow: {
    display: "flex",
    padding: "6pt",
  },
  geteratorDetailRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "3pt",
    paddingLeft: "6pt",
    paddingRight: "6pt",
  },
});

export default styles;
