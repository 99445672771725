import { ReactNode } from "react";
import { Trans } from "react-i18next";
import LeafRectangleLoader from "../LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { IconCheckCircle } from "../../icons/IconCheckCircle/IconCheckCircle";
import "./LeafTile.scss";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  hideBorder?: boolean;
  borderColor?: string;
  backgroundColor?: string;
  isLoading?: boolean;
  rectangleLoaderNumColumns?: number;
  rectangleLoaderNumRows?: number;
  rectangleLoaderHeightPerLine?: string;
  description?: string | ReactNode;
  showMetStatus?: boolean;
  met?: boolean;
  children?: ReactNode;
  id?: string;
  circleCheckmarkId?: string;
}

const LeafTile = ({
  title,
  hideBorder,
  borderColor = "auto",
  backgroundColor,
  description,
  isLoading = false,
  rectangleLoaderNumColumns = 1,
  rectangleLoaderNumRows = 3,
  rectangleLoaderHeightPerLine = "1.25rem",
  showMetStatus = false,
  met = false,
  children,
  id,
  circleCheckmarkId = "",
  ...htmlAttributes
}: Props) => {
  const hideTitle = !title ? "--hidden" : "";
  const descOnly = !title ? " desc-only" : "";
  const isGrayBackground = backgroundColor === "gray" ? " leaf-tile--gray" : "";
  const isHideBorder = hideBorder ? " leaf-tile--no-border" : "";

  const getLeafTileClass = () => {
    return "leaf-tile" + isGrayBackground + isHideBorder + descOnly;
  };

  return (
    <div {...htmlAttributes} className={getLeafTileClass()} id={id} style={{ borderColor: borderColor }}>
      <LeafRectangleLoader
        isLoading={isLoading}
        numColumns={rectangleLoaderNumColumns}
        numRows={rectangleLoaderNumRows}
        width="100%"
        height={rectangleLoaderHeightPerLine}
      >
        <>
          <div data-testid="LeafTile">
            <h2 className={`leaf-tile__title${hideTitle}`}>
              {showMetStatus === true ? (
                <IconCheckCircle id={circleCheckmarkId} color={met === true ? "green" : "gray"} size="md" />
              ) : (
                <></>
              )}
              <Trans>{title}</Trans>
            </h2>
            <div className="leaf-tile__description">
              <Trans>{description}</Trans>
            </div>
          </div>
          <div className="leaf-tile__children">{children}</div>
        </>
      </LeafRectangleLoader>
    </div>
  );
};

export default LeafTile;
