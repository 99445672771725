import { useTranslation } from "react-i18next";
import defaultContent from "../../../content/bronzeGroupTracking";
import commonContent from "../../../content/common";
import {
  filterTrackingBGT,
  resetTrackingFilter,
  resetTrackingSearch,
  updateSelectedFilter,
} from "../../../reducers/BronzeGroupTracking/tracking/filter";
import { setActiveTabView } from "../../../reducers/bronzeGroupTrackingTab";
import { useAppDispatch } from "../../../store";
import "./BGTOverview.scss";
import {
  filterGraduatedBGT,
  resetGraduatedFilter,
  resetGraduatedSearch,
} from "../../../reducers/BronzeGroupTracking/graduated/filter";
import { bgtViewAllClickActionTrack } from "../../../common/tealium/BronzeGroupTracking/BGTOverview";
import { BGT_TABS } from "../../../common/enums/bronze-group-tracking-enums";

type Props = {
  onAllTrackingClick: Function;
};

const BGTOverview = (props: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["bronzeGroupTracking", "common"]);

  const viewAllLabel = t("common:viewAll", commonContent.viewAll).toString();

  const closeToBronzeFilter = () => {
    dispatch(resetTrackingSearch());
    dispatch(updateSelectedFilter("tags-close"));
    dispatch(setActiveTabView(BGT_TABS.TRACKING_TAB));
    dispatch(filterTrackingBGT());
    bgtViewAllClickActionTrack("clostToBronze");
  };

  const newToBronzeFilter = () => {
    dispatch(resetTrackingSearch());
    dispatch(updateSelectedFilter("tags-new"));
    dispatch(setActiveTabView(BGT_TABS.TRACKING_TAB));
    dispatch(filterTrackingBGT());
    bgtViewAllClickActionTrack("newToBronze");
  };

  const allTrackingFilter = () => {
    dispatch(resetTrackingFilter());
    dispatch(resetTrackingSearch());
    dispatch(setActiveTabView(BGT_TABS.TRACKING_TAB));
    dispatch(filterTrackingBGT());
    bgtViewAllClickActionTrack("allTracking");
  };

  const graduatedFilter = () => {
    dispatch(resetGraduatedFilter());
    dispatch(resetGraduatedSearch());
    dispatch(setActiveTabView(BGT_TABS.GRADUATED_TAB));
    dispatch(filterGraduatedBGT());
    bgtViewAllClickActionTrack("graduated");
  };

  const overviewItems = [
    {
      id: "close_to_bronze_section",
      title: t(
        "overviewTabContent.closeToBronzeTitle",
        defaultContent["overviewTabContent"]["closeToBronzeTitle"],
      ).toString(),
      description: t(
        "overviewTabContent.closeToBronzeDesc",
        defaultContent["overviewTabContent"]["closeToBronzeDesc"],
      ).toString(),
      viewAllFunction: closeToBronzeFilter,
    },
    {
      id: "new_to_bronze_section",
      title: t(
        "overviewTabContent.newToBronzeTitle",
        defaultContent["overviewTabContent"]["newToBronzeTitle"],
      ).toString(),
      description: t(
        "overviewTabContent.newToBronzeDesc",
        defaultContent["overviewTabContent"]["newToBronzeDesc"],
      ).toString(),
      viewAllFunction: newToBronzeFilter,
    },
    {
      id: "all_tracking_section",
      title: t(
        "overviewTabContent.allTrackingTitle",
        defaultContent["overviewTabContent"]["allTrackingTitle"],
      ).toString(),
      description: t(
        "overviewTabContent.allTrackingDesc",
        defaultContent["overviewTabContent"]["allTrackingDesc"],
      ).toString(),
      viewAllFunction: allTrackingFilter,
    },
    {
      id: "graduated_section",
      title: t("overviewTabContent.graduatedTitle", defaultContent["overviewTabContent"]["graduatedTitle"]).toString(),
      description: t(
        "overviewTabContent.graduatedDesc",
        defaultContent["overviewTabContent"]["graduatedDesc"],
      ).toString(),
      viewAllFunction: graduatedFilter,
    },
  ];

  const mappedList = overviewItems.map((item) => (
    <section key={item.id} className="overview-content__item">
      <div className="overview-content__item-header">
        <h2 className="overview-content__item-title leaf-tile__title">{item.title}</h2>
        <button onClick={() => item.viewAllFunction()}>{viewAllLabel}</button>
      </div>
      <div className="overview-content__item-description">{item.description}</div>
    </section>
  ));

  return (
    <div className="overview-content" id="bronzeGroupTracking-overview">
      <div className="overview-tile leaf-tile">{mappedList}</div>
    </div>
  );
};

export default BGTOverview;
