// @ts-nocheck
// these values should match what is in src/content/common under awardLabels
const awardCodes = {
  full: {
    0: "not-tracking",
    305: "silver-sponsor",
    310: "silver-producer",
    320: "gold-producer",
    330: "platinum",
    340: "ruby",
    342: "founders-platinum",
    345: "founders-ruby",
    350: "pearl",
    365: "sapphire",
    367: "founders-sapphire",
    370: "emerald",
    375: "founders-emerald",
    380: "diamond",
    385: "founders-diamond",
    390: "executive-diamond",
    395: "founders-executive-diamond",
    400: "double-diamond",
    405: "founders-double-diamond",
    410: "triple-diamond",
    415: "founders-triple-diamond",
    420: "crown",
    425: "founders-crown",
    430: "crown-ambassador",
    435: "founders-crown-ambassador",
    440: "fca-40",
    445: "fca-45",
    450: "fca-50",
    455: "fca-55",
    460: "fca-60",
    465: "fca-65",
    470: "fca-70",
    203: "3-percent",
    206: "6-percent",
    209: "9-percent",
    212: "12-percent",
    215: "15-percent",
    218: "18-percent",
    221: "21-percent",
    223: "23-percent",
    393: "gar-executive-diamond",
    398: "gar-founders-executive-diamond",
    403: "gar-double-diamond",
    408: "gar-founders-double-diamond",
    413: "gar-triple-diamond",
    418: "gar-founders-triple-diamond",
    423: "gar-crown",
    428: "gar-founders-crown",
    433: "gar-crown-ambassador",
    438: "gar-founders-crown-ambassador",
    968: "silver",
    969: "gold",
    970: "platinum",
    971: "foundersPlatinum",
    972: "sapphire",
    973: "foundersSapphire",
    974: "inMarketEmerald",
    975: "inMarketFoundersEmerald",
    976: "inMarketDiamond",
    977: "inMarketFoundersDiamond",
  },
  abbreviation: {
    0: "0P",
    305: "SI6",
    310: "SI4",
    320: "GLD",
    330: "DD",
    340: "RBY",
    342: "FDD",
    345: "FRB",
    350: "PRL",
    365: "SPH",
    367: "FSP",
    370: "EM",
    375: "FEM",
    380: "DIA",
    385: "FDI",
    390: "XDI",
    395: "FED",
    400: "DDI",
    405: "F2D",
    410: "TDI",
    415: "F3D",
    420: "CRN",
    425: "FCN",
    430: "CAM",
    435: "FCA",
    440: "F40",
    445: "F45",
    450: "F50",
    455: "F55",
    460: "F60",
    465: "F65",
    470: "F70",
    203: "03P",
    206: "6P",
    209: "9P",
    212: "12P",
    215: "15P",
    218: "18P",
    221: "21P",
    223: "23P",
    393: "GXD",
    398: "GFE",
    403: "G2D",
    408: "GF2",
    413: "GTD",
    418: "GF3",
    423: "GCN",
    428: "GFC",
    433: "GCA",
    438: "GFA",
    970: "DD",
    971: "FDD",
    972: "SPH",
    973: "FSP",
    974: "EM",
    975: "FEM",
    976: "DIA",
    977: "FDI",
  },
};

export const getAwardLabelKeyFromAwardCode = (
  awardCode: number | string,
  awardLevel: string,
  getAbbreviation?: boolean,
) => {
  if (!awardCode) {
    return awardLevel;
  }
  if (getAbbreviation) {
    return awardCodes.abbreviation[awardCode.toString()];
  }
  return awardCodes.full[awardCode.toString()];
};
