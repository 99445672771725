import { useEffect } from "react";
import { useAsync } from "react-async";
import { useTranslation } from "react-i18next";
import defaultContent from "../../content/csi";
import { SELECTOR_TYPE, updateSelectorType } from "../../reducers/period";
import { useAppDispatch, useAppSelector } from "../../store";
import { storedLocale } from "../impersonation/util";
import { PageTitleSection } from "../../common/components/PageTitleSection/PageTitleSection";

const loadComponent = async () => {
  return import("../Lit/acc-coreplus-incentives/acc-csi.js");
};

export default function CSI() {
  const { t } = useTranslation(["csi"]);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { configuration } = useAppSelector((state) => state.boot);
  const dispatch = useAppDispatch();
  const { isPending } = useAsync({ promiseFn: loadComponent });

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));
  }, [dispatch]);

  useEffect(() => {
    if (!isPending) {
      const ele = document.getElementById("acc_csi_component") as HTMLElement;

      ele.setAttribute("learn-more-link", configuration.dashboardLinks?.learnMore?.customerSalesIncentive);
      ele.setAttribute("cml-link", configuration.dashboardLinks?.customerMemberList);
    }
  }, [isPending, configuration]);

  if (isPending) {
    return <></>;
  }
  return (
    <main>
      <PageTitleSection title={`${t("pageTitle", defaultContent["pageTitle"])}`} />
      <acc-csi id="acc_csi_component" mode="standalone" period={selectedPeriod} locale={storedLocale()}></acc-csi>{" "}
    </main>
  );
}
