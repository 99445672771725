import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LeafBannerToast } from "../../common/leaf/LeafBannerToast/LeafBannerToast";
import { IconAlert } from "../../common/icons/IconAlert/IconAlert";
import defaultContent from "../../content/regulationInfoBanner";
import "./RegulationInfoBanner.scss";
import { useAppSelector } from "../../store";
import { getLoginAffAbo } from "../../reducers/model/boot";
import regulationLists from "./RegulationLists/RegulationLists";

export const RegulationInfoBanner = () => {
  const [showBanner, setShowBanner] = useState(true);
  const { t } = useTranslation(["regulationInfoBanner"]);

  const boot = useAppSelector(({ boot }) => boot);
  const affAbo = getLoginAffAbo({ boot });

  const hideBanner = () => {
    setShowBanner(false);
  };

  const shouldShow = regulationLists.includes(affAbo);

  return (
    <div className="regulation-info-banner">
      <LeafBannerToast theme="yellow" closeFunction={hideBanner} show={showBanner && shouldShow}>
        <section className="regulationInfoBanner">
          <span className="regulationInfoBanner__title">
            <div className="regulationInfoBanner__icon">
              <IconAlert />
            </div>
            {t("title", defaultContent["title"])}
          </span>
          <p className="regulationInfoBanner__message">{t("message", defaultContent["message"])}</p>
        </section>
      </LeafBannerToast>
    </div>
  );
};
