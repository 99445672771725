import { useEffect, useState } from "react";
import { useGetBgtMiniProfileApiQuery } from "../../../services/corePlusIncentivesApi";
import { store } from "../../../store";

export const FetchBGTMiniProfileData = (period: string, affAbo: string) => {
  const {
    data: payloadData,
    isLoading: isQueryLoading,
    isFetching,
    isError,
  } = useGetBgtMiniProfileApiQuery({
    baseUrl: store.getState().boot.configuration.incentivesApiUrl,
    affAbo,
    bonusPeriod: period,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [data, setData] = useState<any>();

  // if loading or fetching set loading flag to true
  useEffect(() => {
    if (isQueryLoading || isFetching) {
      setIsLoading(true);
    }
  }, [isQueryLoading, isFetching, period]);

  useEffect(() => {
    if (isError) {
      setErrorStatus(true);
      setIsLoading(false);
    }
  }, [payloadData, isError, isQueryLoading, isFetching, period]);

  useEffect(() => {
    if (!isQueryLoading && !isFetching && !isError) {
      setData(payloadData);
      setIsLoading(false);
      setErrorStatus(false);
    }
  }, [payloadData, isError, isQueryLoading, isFetching, period]);

  return { isLoading, data, errorStatus };
};
