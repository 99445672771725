import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

export type Props = {
  currentAwardDetail: {
    currentAwardRank: number;
    highestAwardRank: number;
    isGlobalAward?: boolean;
    nextPyCurrentAwardRank?: number;
    trackingLevelRank?: number;
  };
  currentProcessingMonth?: string;
  garDetail: {
    closingPeriods: number[];
    foundersPlatinumLegs: number;
    globalAwardRank: number;
    isFoundersDiamond: boolean;
    lastMonthProcessed: number;
    qualCreditDtls: {
      achieved: boolean;
      count: number;
      maxCountPerLeg: number;
    }[];
    qualCreditMaxPerLeg: number;
    qualCredits: number;
    qualCreditsNotCounting: number;
  };
  hasGarAccess?: boolean;
  hasHatAccess?: boolean;
  hatDetail: {
    inMarketLegs: number;
    internationalLegs: number;
    legTrackingAwardRank: string;
    totalLegs: number;
  };
};

export const hatSummaryAPI = createApi({
  reducerPath: "hatSummaryAPI",
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const { user, configuration } = (getState() as RootState).boot;
      if (user && user.access_token) {
        headers.set("Authorization", `Bearer ${user.access_token}`);
        headers.set("x-mashery-domain", configuration.requestUrlBase);
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getHATSummary: builder.query({
      query: (params) => ({
        url: `${params.baseUrl}/hat/hatsummary?aboNo=${params.aboNo}&affNo=${params.affNo}&bonusPeriod=${params.period}&overridePeriod=${params.overridePeriod}`,
      }),
    }),
  }),
});

export const { useGetHATSummaryQuery } = hatSummaryAPI;
