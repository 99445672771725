import { useTranslation } from "react-i18next";
import { IconCheckCircle } from "../../../../../../../common/icons/IconCheckCircle/IconCheckCircle";
import defaultContent from "../../../../../../../content/orderType";
import "./OrderType.scss";
import LeafVCSReason from "../../../../../../../common/leaf/LeafVCSReason/LeafVCSReason";

type Props = {
  vcsFlag: boolean;
  source: string;
  vcsReasons: string[];
  isCompact?: boolean;
  orderIndex: number;
  orderDate?: string;
  showVCSOrderTag: boolean;
};

const OrderType = (props: Props) => {
  const { vcsFlag, source, vcsReasons, isCompact, orderIndex, orderDate, showVCSOrderTag } = props;
  const { t } = useTranslation(["orderType"]);

  const OrderTypeContent = ({ theme = "positive", content }: { theme?: string; content: string }) => (
    <span className={`order-type__text order-type__text--${theme}`}>{t(content, defaultContent[content])}</span>
  );

  const NonVCSType = () => (
    <>
      <span className="order-type__icon">
        <LeafVCSReason vcsReasons={vcsReasons} showAsTooltips={true} orderIndex={orderIndex} orderDate={orderDate} />
      </span>
      <OrderTypeContent theme="negative" content={"nonVCSOrder"} />
    </>
  );

  const VCSType = () => (
    <>
      <span className="order-type__icon">
        <IconCheckCircle color="green" />
      </span>
      <OrderTypeContent content={"vcsOrder"} />
    </>
  );

  const UpliftOrderType = () => (
    <>
      <span className="order-type__icon">
        <IconCheckCircle color="green" />
      </span>
      <OrderTypeContent content={"upliftOrder"} />
    </>
  );

  const Type = () => {
    switch (`${source}`) {
      case "001":
        return <OrderTypeContent content="adjustment" />;
      case "003": {
        if (showVCSOrderTag === true) {
          return vcsFlag === true ? <VCSType /> : <NonVCSType />;
        }
        return <OrderTypeContent content="order" />;
      }
      case "004":
        return <OrderTypeContent theme="neutral" content="return" />;
      case "140":
        return <UpliftOrderType />;
      case "006":
        return <OrderTypeContent content="reworks" />;
      case "016":
        return <OrderTypeContent content="rmsAdjust" />;
      case "110":
        return <OrderTypeContent content="cvr" />;
      case "160":
        return <OrderTypeContent content="mars" />;
      case "176":
        return <OrderTypeContent content="backDate" />;
      default:
        return <OrderTypeContent content="other" />;
    }
  };

  return (
    <div className={`order-type ${isCompact ? "order-type--compact" : ""}`}>
      <Type />
    </div>
  );
};

export default OrderType;
