const pinLevelCodeToContentKey: Record<string, string> = {
  "-1": "no-pin-level",
  "203": "percent-3",
  "206": "percent-6",
  "209": "percent-9",
  "212": "percent-12",
  "215": "percent-15",
  "218": "percent-18",
  "221": "percent-21",
  "223": "percent-23",
  "305": "silver-sponsor",
  "310": "silver-producer",
  "320": "gold-producer",
  "330": "platinum",
  "340": "ruby",
  "342": "founders-platinum",
  "345": "founders-ruby",
  "350": "pearl",
  "365": "sapphire",
  "367": "founders-sapphire",
  "370": "emerald",
  "375": "founders-emerald",
  "380": "diamond",
  "381": "diamond-plus",
  "385": "founders-diamond",
  "390": "executive-diamond",
  "393": "gar-executive-diamond",
  "395": "founders-executive-diamond",
  "398": "gar-founders-executive-diamond",
  "400": "double-diamond",
  "403": "gar-double-diamond",
  "405": "founders-double-diamond",
  "408": "gar-founders-double-diamond",
  "410": "triple-diamond",
  "413": "gar-triple-diamond",
  "415": "founders-triple-diamond",
  "418": "gar-founders-triple-diamond",
  "420": "crown",
  "423": "gar-crown",
  "425": "founders-crown",
  "428": "gar-founders-crown",
  "430": "crown-ambassador",
  "433": "gar-crown-ambassador",
  "435": "founders-crown-ambassador",
  "438": "gar-founders-crown-ambassador",
  "440": "fca-40",
  "445": "fca-45",
  "450": "fca-50",
  "455": "fca-55",
  "460": "fca-60",
  "465": "fca-65",
  "470": "fca-70",
};

export function getPinLevelContentName(pinLevelCode: number): string {
  return pinLevelCodeToContentKey[pinLevelCode.toString()];
}
