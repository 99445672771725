import { ReactComponent as FlagIcon } from "./svg/be-flag.svg";
import "../flag-icon.scss";

export const BEFlag = () => {
  return (
    <span className="flag-icon">
      <FlagIcon />
    </span>
  );
};
