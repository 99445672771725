import "./IconErrorGeneral.scss";
import { ReactComponent as GeneralError } from "./svg/icon-error-general.svg";

export const IconErrorGeneral = ({ id = "" }: { id?: string }) => {
  return (
    <span id={id} className="Icon-General">
      <GeneralError />
    </span>
  );
};
