const content: { [key: string]: string } = {
  bonusBreakdown: "Bonus Breakdown",
  monthlyBonus: "Monthly Bonus",
  monthlyBonusSubtotal: "Monthly Bonus Subtotal",
  amount: "Amount",
  performanceBonus: "Performance Total",
  personal: "Personal",
  differential: "Differential",
  ruby: "Ruby",
  leadership: "Leadership",
  foster: "Foster",
  depth: "Depth",
  internationalLeadership: "International Leadership",
  newAboActivation: "Path to Bronze Incentive+",
  csi: "CSI+",
  strongStartIncentive: "Strong Start Incentive+",
  bronzeFoundation: "Bronze Foundation Incentive+",
  bronzeBuilder: "Bronze Builder Incentive+",
  performancePlus: "Performance Plus+",
  performanceElite: "Performance Elite+",
  performancePlusElite: "Performance Plus & Elite+",
  personalGroupGrowthMonthly: "Monthly Personal Group Growth+",
  annualBonusBreakdown: "Annual Bonus Breakdown",
  annualBonusBreakdownDescription: "Some bonuses are not calculated until the end of the Performance Year",
  annualBonus: "Annual Bonus",
  annualBonusEstimatedTotal: "Annual Bonus Estimated Total",
  annualMonthlyBonusEstimatedTotal: "Annual Monthly Bonus Estimated Total",
  annualAccumulatedBonusEstimatedTotal: "Annual Accumulated Bonus Estimated Total",
  personalGrowth: "Personal Group Growth+",
  personalGrowthGcr: "Personal Group Growth+",
  frontlineGrowth: "Frontline Group Growth+",
  twoTimeCashIncentive: "Two-time Cash Incentives+",
  faa: "Founders Achievement Award",
  emerald: "Emerald¹",
  diamond: "Diamond¹",
  diamondPlus: "Diamond Plus¹",
};

export default content;
