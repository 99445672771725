import { Route, Navigate } from "react-router-dom";
import { lazy } from "react";
import Customers from "../components/Customers/Customers";
const Overview = lazy(() => import("../components/Customers/Overview/Overview"));
const CustomerList = lazy(() => import("../components/Customers/CustomerList/CustomerList"));
const OrderHistory = lazy(() => import("../components/Customers/OrderHistory/OrderHistory"));

export const CUSTOMER_BASE_PATH = "/customer/";
export const CUSTOMER_OVERVIEW = `overview`;
export const CUSTOMER_LIST = `list`;
export const CUSTOMER_ORDER = `order`;

export const CUSTOMER_OVERVIEW_ROUTE = `${CUSTOMER_BASE_PATH}${CUSTOMER_OVERVIEW}`;
export const CUSTOMER_ROUTE_PATHS = [CUSTOMER_OVERVIEW, CUSTOMER_LIST, CUSTOMER_ORDER].map(
  (route) => `${CUSTOMER_BASE_PATH}${route}`,
);

export const CustomersRoutes = () => {
  return (
    <Route path={CUSTOMER_BASE_PATH} element={<Customers />}>
      <Route path={CUSTOMER_OVERVIEW} element={<Overview />} />
      <Route path={CUSTOMER_LIST} element={<CustomerList />} />
      <Route path={CUSTOMER_ORDER} element={<OrderHistory />} />
      <Route path={CUSTOMER_BASE_PATH} element={<Navigate to={CUSTOMER_OVERVIEW} />} />
    </Route>
  );
};
