import "./LeafTileTitleLink.scss";
import { Link } from "react-router-dom";
import { MouseEventHandler } from "react";
import { IconArrowLink } from "../../icons/IconArrowLink/IconArrowLink";

type Props = {
  title: string;
  link: string;
  disable?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};

const LeafTileTitleLink = ({ title, link, disable, onClick = () => {} }: Props) => {
  return (
    <div>
      {disable ? (
        <div className="leafTileTitleLink--disabled-link" aria-label={title + " disabled"}>
          <h3>{title}</h3>
          <div className="icon-wrapper">
            <IconArrowLink iconColor="gray" />
          </div>
        </div>
      ) : (
        <Link to={link} className="leafTileTitleLink" aria-label={title} onClick={onClick}>
          <h3>{title}</h3>
          <div className="icon-wrapper">
            <IconArrowLink iconColor="blue" />
          </div>
        </Link>
      )}
    </div>
  );
};

export default LeafTileTitleLink;
