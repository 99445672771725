import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";
import { useGetBronzeEarningQuery, useGetBronzeEligibilityQuery } from "../../../../services/bronzeIncentivesAPI";
import { useAppSelector } from "../../../../store";
import BronzeIncentivesLoader from "../BronzeIncentivesLoader/BronzeIncentivesLoader";

type Props = {
  affAbo: string;
  period: string;
  awardCode: number;
  locale: string;
  children: (props: {
    displayBannerOnly: boolean;
    displayEligibleBannerOnly: boolean;
    showAllBannerTiles: boolean;
  }) => React.ReactNode;
};

const getBannerDisplay = (
  status: string,
  method: string,
  eligible: boolean,
  graduated: boolean,
  inWindows: boolean,
) => {
  const showBannerCondition1 = status === QUALIFICATION_STATUS.HELD || status === QUALIFICATION_STATUS.DENIED;
  const showBannerCondition2 =
    status === QUALIFICATION_STATUS.QUALIFIED && method === QUALIFICATION_STATUS.MANUALLY_APPROVED;
  const showBannerCondition3 = eligible === false && graduated === false && inWindows === false;

  return showBannerCondition1 === true || showBannerCondition2 === true || showBannerCondition3 === true;
};

const getEligibilityBannerDisplay = (
  eligible: boolean,
  graduated: boolean,
  inWindows: boolean,
  qualifiedCount: number,
  mostRecentAcheivedPeriod: number,
  selectedPeriod: number,
) => {
  const isExpired = eligible === false && graduated === true && inWindows === false;
  const isAllQualified = qualifiedCount >= 12 && selectedPeriod > mostRecentAcheivedPeriod;

  return isExpired || isAllQualified;
};

const BronzeDisplayRules = ({ affAbo, period, awardCode, locale, children }: Props) => {
  const boot = useAppSelector(({ boot }) => boot);
  const { isoCountryCode = "" } = boot?.user || {};
  const { isoCurrencyCode = "" } = boot?.shellConfig || {};

  const { data: earningAPIResponse, isFetching: isEarningDataFetching } = useGetBronzeEarningQuery({
    affAbo,
    period,
    awardCode,
    locale,
    isoCountryCode,
    isoCurrencyCode,
  });

  const { data: eligibilityAPIResponse, isFetching: isEligibilityDataFetching } = useGetBronzeEligibilityQuery({
    affAbo,
    period,
    awardCode,
    locale,
  });

  const isLoading = isEarningDataFetching === true || isEligibilityDataFetching === true;

  if (isLoading === false && earningAPIResponse && eligibilityAPIResponse) {
    const { status, method } = earningAPIResponse;

    const { eligible, graduated, inWindows, qualifiedCount, mostRecentAcheivedPeriod } = eligibilityAPIResponse;

    const displayBannerOnly = getBannerDisplay(status, method, eligible, graduated, inWindows);

    const displayEligibleBannerOnly = getEligibilityBannerDisplay(
      eligible,
      graduated,
      inWindows,
      qualifiedCount,
      mostRecentAcheivedPeriod,
      Number(period),
    );

    const showAllBannerTiles = displayBannerOnly === false && displayEligibleBannerOnly === false;

    return <>{children({ displayBannerOnly, displayEligibleBannerOnly, showAllBannerTiles })}</>;
  }

  return <BronzeIncentivesLoader />;
};

export default BronzeDisplayRules;
