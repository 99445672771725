const content: { [key: string]: string } = {
  keepGoing: "Keep going to earn the Bronze Builder Incentive+!",
  notEligible: "You were not eligible for the Bronze Builder Incentive+ in {dateText}",
  greatJob: "Great Job! You are earning the Bronze Builder Incentive+.",
  manual: "The Bronze Builder Incentive+ has been manually approved",
  onHold: "Your award is on hold.",
  moreInfo: "If you require more information, please contact Customer Support for further details.",
  incentiveLabel: "Bronze Builder Incentive+",
};

export default content;
