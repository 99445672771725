import { useTranslation } from "react-i18next";
import { TtciEligibilityAwards } from "../../../../common/interfaces/ttciData";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import { getAwardLabelKeyFromAwardCode } from "../../../../common/util/awardLabels";
import defaultContent from "../../../../content/ttci";
import commonContent from "../../../../content/common";
import { LeafDisplayCard } from "../../../../common/leaf/LeafDisplayCard/LeafDisplayCard";
import { Card } from "../TtciEligibilityModal";
import { IconHeart } from "../../../../common/icons/IconHeart/IconHeart";
import "../TtciEligibilityModal.scss";
import { TTCI_STATUS } from "../../../../common/enums/ttci-enums";

type Props = {
  data: TtciEligibilityAwards[];
};

export const createHeldLeafCards = (data: TtciEligibilityAwards[]) => {
  const heldCards: Card[] = [];
  data.forEach((eligibilityData) => {
    if (eligibilityData.status === TTCI_STATUS.HELD) {
      heldCards.push({
        awardLabel: getAwardLabelKeyFromAwardCode(eligibilityData.awardCode, eligibilityData.awardLevel),
        awardCode: eligibilityData.awardCode,
        isRequalAward: false,
        tagStatus: eligibilityData.status === TTCI_STATUS.HELD ? "onHold" : "",
        tagColorVariant: "red",
        id: `${eligibilityData.awardCode}-firstTime`,
      });
    }
    if (eligibilityData.requalificationAward.requalAwardStatus === TTCI_STATUS.HELD) {
      heldCards.push({
        awardLabel: getAwardLabelKeyFromAwardCode(eligibilityData.awardCode, eligibilityData.awardLevel),
        awardCode: eligibilityData.awardCode,
        isRequalAward: true,
        tagStatus: eligibilityData.requalificationAward.requalAwardStatus === TTCI_STATUS.HELD ? "onHold" : "",
        tagColorVariant: "red",
        id: `${eligibilityData.awardCode}-requal`,
      });
    }
  });

  return heldCards.sort((a, b) => a.awardCode - b.awardCode);
};

export const HeldTab = ({ data }: Props) => {
  const { t } = useTranslation(["ttci", "common"]);
  const heldCards = createHeldLeafCards(data);
  const pinsHeldDesc = `${t("ttciEligibility.heldDesc", defaultContent.ttciEligibility["heldDesc"])}`;
  const noHeldPinsDesc = `${t("ttciEligibility.noHeldPinsDesc", defaultContent.ttciEligibility["noHeldPinsDesc"])}`;
  const heldDesc = heldCards.length > 0 ? pinsHeldDesc : noHeldPinsDesc;

  const getAwardLabel = (isRequalAward: boolean, awardCode: number, awardLabel: string) => {
    const requalification = isRequalAward ? ` ${t(`common:requalification`, commonContent.requalification)}` : "";
    const label = `${t(
      `common:awardLabels.${getAwardLabelKeyFromAwardCode(awardCode, awardLabel)}`,
      commonContent.awardLabels[getAwardLabelKeyFromAwardCode(awardCode, awardLabel)],
    )}${requalification}`;
    return label;
  };

  const returnLeafCards = (cards: Card[]) => {
    if (cards.length > 0) {
      return cards.map((d) => {
        return (
          <LeafDisplayCard
            id={d.id}
            key={d.id}
            awardLabel={getAwardLabel(d.isRequalAward, d.awardCode, d.awardLabel)}
            awardStatus={`${t(`${d.tagStatus}`, defaultContent[d.tagStatus])}`}
            leafTagVariant={d.tagColorVariant}
          ></LeafDisplayCard>
        );
      });
    }
    return (
      <LeafDisplayCard
        id={"no-held-pins"}
        key={"no-held-pins"}
        awardLabel={`${t(`ttciEligibility.noHeldPins`, defaultContent.ttciEligibility["noHeldPins"])}.`}
        awardStatus={""}
        leafTagVariant={"blue"}
      ></LeafDisplayCard>
    );
  };

  const createNoPinsComponent = (heldCards: Card[]) => {
    if (heldCards.length === 0) {
      return (
        <section className="no-pins-section">
          <IconHeart />
          <span className="no-pins-section__title">
            {`${t(`ttciEligibility.noHeldPins`, defaultContent.ttciEligibility["noHeldPins"])}`}
          </span>
          <span className="no-pins-section__body">
            {`${t(`ttciEligibility.noHeldPinsAtThisTime`, defaultContent.ttciEligibility["noHeldPinsAtThisTime"])}`}
          </span>
        </section>
      );
    }
    return <></>;
  };

  return (
    <div>
      <LeafTile isLoading={false} description={heldDesc}>
        {returnLeafCards(heldCards)}
      </LeafTile>
      <div>{createNoPinsComponent(heldCards)}</div>
    </div>
  );
};
