import { ChangeEventHandler } from "react";
import "./LeafToggleSwitch.scss";

export const LeafToggleSwitch = ({
  isToggled,
  onToggle,
  name,
  label,
}: {
  isToggled: boolean;
  onToggle: ChangeEventHandler<HTMLInputElement>;
  name: string;
  label?: string;
}) => {
  return (
    <div className="leafToggleSwitch">
      <span className="leafToggleSwitch__label">{label}</span>
      <label className="leafToggleSwitch__switch">
        <input type="checkbox" checked={isToggled} onChange={onToggle} name={name} />
        <span className="leafToggleSwitch__switchKnob" />
      </label>
    </div>
  );
};
