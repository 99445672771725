import { useTranslation } from "react-i18next";
import LeafIncentiveProgress from "../../../../common/leaf/LeafIncentiveProgress/LeafIncentiveProgress";
import defaultContent from "../../../../content/bfiIncentiveProgress";
import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";
import { useGetBronzeEarningQuery, useGetBronzeRequirementsQuery } from "../../../../services/bronzeIncentivesAPI";
import { useAppSelector } from "../../../../store";
import { storedLocale } from "../../../impersonation/util";
import { getLoginAffAbo } from "../../../../reducers/model/boot";
import bronzeIncentiveProgressOrder from "../../BronzeCommon/BronzeIncentiveProgressOrder/BronzeIncentiveProgressOrder";

type Props = {
  awardCode: number;
  show?: boolean;
};

const BFIIncentiveProgress = ({ awardCode, show = true }: Props) => {
  const { t } = useTranslation(["bfiIncentiveProgress"]);

  const locale = storedLocale();

  const boot = useAppSelector(({ boot }) => boot);
  const { selectedPeriod } = useAppSelector((state) => state.period);

  const affAbo = getLoginAffAbo({ boot });
  const { user, configuration } = boot;
  const { isoCountryCode = "" } = boot?.user || {};
  const { isoCurrencyCode = "" } = boot?.shellConfig || {};
  const { isStandardRetailEnhancementCountry = false } = configuration?.corePlus || {};

  const { data: earningAPIResponse, isFetching: isEarningResponseFetching } = useGetBronzeEarningQuery({
    affAbo: affAbo,
    period: selectedPeriod,
    awardCode: awardCode,
    locale: locale,
    isoCountryCode,
    isoCurrencyCode,
  });

  const { data: requirementAPIResponse, isFetching: isRequirementResponseFetching } = useGetBronzeRequirementsQuery({
    affAbo: affAbo,
    period: selectedPeriod,
    awardCode: awardCode,
    locale: locale,
    countryCode: user.isoCountryCode,
    isStandarRetailEnhancementCountry: isStandardRetailEnhancementCountry,
  });

  const { status = "" } = earningAPIResponse || {};
  const { requiredCount = 0, metCount = 0 } = requirementAPIResponse || {};
  const isLoading = isEarningResponseFetching === true || isRequirementResponseFetching === true;

  // The order of the requirements here will also reflect on the order of (BFIRequirements) component
  const requirementsMet = bronzeIncentiveProgressOrder(requirementAPIResponse);

  // show would be true if the status is not DENIED or HELD
  const validStatus = [`${QUALIFICATION_STATUS.DENIED}`, `${QUALIFICATION_STATUS.HELD}`].every((s) => s !== status);

  const getTrackIconAriaLabel = (index: number) => {
    const number = index + 1;

    if (number <= requiredCount) {
      return `${t("bronzeFoundationRequirement", defaultContent["bronzeFoundationRequirement"], { number })}`;
    }

    return `${t("trackingBronzeFoundationRequirement", defaultContent["trackingBronzeFoundationRequirement"], {
      number,
    })}`;
  };

  return (
    <LeafIncentiveProgress
      requiredCount={requiredCount}
      metCount={metCount}
      isLoading={isLoading}
      status={requirementsMet}
      getTrackIconAriaLabel={getTrackIconAriaLabel}
      show={show && validStatus}
    />
  );
};
export default BFIIncentiveProgress;
