import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";

export const getBronzeStatus = ({
  status,
  method,
  eligible,
  graduated,
}: {
  status: string;
  method: string;
  eligible: boolean;
  graduated: boolean;
}) => {
  if (status === QUALIFICATION_STATUS.QUALIFIED && method === QUALIFICATION_STATUS.MANUALLY_APPROVED) {
    return QUALIFICATION_STATUS.MANUALLY_QUALIFIED;
  }

  if (eligible === false && graduated === false) {
    return QUALIFICATION_STATUS.NOT_ELIGIBLE;
  }

  return status;
};
