import { QUALIFICATION_STATUS } from "../../common/enums/incentive-enums";
import { REQUIREMENT_NAME } from "../../common/enums/requirements";
import { RequirementsWithQC } from "../../common/interfaces/coreplusIncentives";

export const IncomeData = {
  PerformanceBonusLevelPercent: "",
  PotentialPYEarnings: "$105.00",
  IncentiveCap: "",
  Status: QUALIFICATION_STATUS.TRACKING,
};

export const requirements = {
  VolumeRequirements: [],
  PercentRequirements: [],
  BadgeRequirements: [
    {
      Name: REQUIREMENT_NAME.DIAMOND_BONUS,
      Met: false,
      Target: "1",
      Status: QUALIFICATION_STATUS.NOT_TRACKING,
      ShowLabel: true,
    },
    {
      Name: REQUIREMENT_NAME.MAINTAIN_GAR,
      Met: false,
      Target: "1",
      Status: QUALIFICATION_STATUS.NOT_TRACKING,
      ShowLabel: false,
    },
    {
      Name: REQUIREMENT_NAME.MAINTAIN_QC,
      Met: false,
      Target: "1",
      Status: QUALIFICATION_STATUS.NOT_TRACKING,
      ShowLabel: false,
    },
  ],
  TotalRequirements: 3,
  RequirementsMet: 0,
  RequirementMetStatuses: [false, false, false],
};
export const notTrackingRequirements: RequirementsWithQC = {
  VolumeRequirements: [],
  PercentRequirements: [],
  BadgeRequirements: [
    {
      Name: REQUIREMENT_NAME.DIAMOND_BONUS,
      Met: false,
      Target: "1",
      Status: QUALIFICATION_STATUS.NOT_TRACKING,
      ShowLabel: true,
    },
    {
      Name: REQUIREMENT_NAME.MAINTAIN_GAR,
      Met: false,
      Target: "1",
      Status: QUALIFICATION_STATUS.NOT_TRACKING,
      ShowLabel: false,
    },
  ],
  QCRequirements: [
    {
      Name: REQUIREMENT_NAME.MAINTAIN_QC,
      Met: false,
      Target: "1",
      Status: QUALIFICATION_STATUS.NOT_TRACKING,
      ShowLabel: false,
      CurrentYtd: "10",
      PyStatus: "10",
    },
  ],
  TotalRequirements: 3,
  RequirementsMet: 0,
  RequirementMetStatuses: [false, false, false],
};

export const qualifiedRequirements: RequirementsWithQC = {
  VolumeRequirements: [],
  PercentRequirements: [],
  BadgeRequirements: [
    {
      Name: REQUIREMENT_NAME.DIAMOND_BONUS,
      Met: true,
      Target: "1",
      Status: QUALIFICATION_STATUS.QUALIFIED,
      ShowLabel: true,
    },
    {
      Name: REQUIREMENT_NAME.MAINTAIN_GAR,
      Met: true,
      Target: "1",
      Status: QUALIFICATION_STATUS.QUALIFIED,
      ShowLabel: false,
    },
  ],
  QCRequirements: [
    {
      Name: REQUIREMENT_NAME.MAINTAIN_QC,
      Met: true,
      Target: "1",
      Status: QUALIFICATION_STATUS.QUALIFIED,
      ShowLabel: false,
      CurrentYtd: "10",
      PyStatus: "10",
    },
  ],
  TotalRequirements: 3,
  RequirementsMet: 3,
  RequirementMetStatuses: [true, true, true],
};
