const content = {
  headerLabel:
    "Achieve Founders Platinum by completing the requirement for <1>ONE</1> of the methods below in the current Performance Year",
  subLabel1: "Totals as of {timestamp}",
  spTitle: "{spMonthsTarget} Silver Producer (SP) Months",
  gpvTitle: "GPV Equivalent Method*",
  vepvTitle: "VE PV Equivalent Method",
  disclaimerLabel: "*This is a pilot program at this time",
  gpvNeededLabel: "{gpvSpMonths} GPV Needed",
  vepvNeededLabel: "{vePvSpMonths} VE PV Needed",
  spMonthsNeededLabel: "{spMonthsNeeded} SP Months Needed",
  notAvailableLabel: "No Longer Available",
  currentlyHaveLabel: "You currently have",
  currentSpMonthsLabel: "{currentSpMonths} Silver Producer (SP) Months",
  gpvSpMonthsLabel: "{currentGpv} GPV and {currentSpMonths} SP Months",
  vePvSpMonthsLabel: "{currentVePv} VE PV and {currentSpMonths} SP Months",
  tooltips: {
    spMonths: {
      tooltipTitle: "{spMonthsTarget} Silver Producer (SP) Months",
      sectionTitle: "Silver Producer Months",
      currentSection: "{spMonthsAchieved} SP Months",
      tooltipDesc:
        "You can earn the Founders Platinum Pin by achieving {spMonthsTarget} Silver Producer (SP) months in a performance year.",
      tooltipDescAlternate: "An ABO with {spMonthsTarget} Silver Producer (SP) months",
    },
    gpvMonths: {
      tooltipTitle: "GPV Equivalent Method*",
      gpvQual: "GPV",
      spMonthsQual: "{monthsRequired} SP Months",
      unavailable: "No Longer Available",
      gpvNeeded: "{annualGpvNeeded} GPV Needed",
      spNeeded: "{monthsNeeded} SP Months Needed",
      currentSection: "{annualGpvEarned} GPV and {monthsAchieved} SP Months",
      tooltipDesc:
        "You can earn the Founders Platinum Pin by earning at least {gpvMethodMonthsRequired} Silver Producer months plus {gpvTarget} Group PV. Earning  Founders Platinum through the GPV Equivalent method counts as a Founders Platinum Leg for your upline.",
      tooltipDescAlternate:
        "An ABO with at least {gpvMethodMonthsRequired} SP months and {gpvTarget} Group PV. A leg with GPV FPL is considered a Founders leg for the upline.",
    },
    vepvMonths: {
      tooltipTitle: "VE PV Equivalent Method",
      vepvQual: "VE PV",
      spMonthsQual: "{monthsRequired} SP Months",
      tooltipDesc:
        "You can earn the Founders Platinum Pin by earning at least {vepvMethodMonthsRequired} Silver Producer months plus {vePvTarget} VE PV. Earning Founders Platinum through the VE PV Equivalent method counts as a Founders Platinum Leg for your upline.",
      tooltipDescAlternate:
        "An ABO with at least {vepvMethodMonthsRequired} SP months and total downline in-market volume of {vePvTarget} VE PV. A leg with VE FPL is considered a Founders leg for the upline.",
    },
  },
  foundersPlatinumProgressLabel: "Founders Platinum Progress",
};

export default content;
