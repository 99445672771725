import { useEffect, useState } from "react";
import { getAbo, getAff } from "../../impersonation/util";
import { useAppSelector } from "../../../store";
import { lambdaErrorCheck } from "../../../common/util/errorHandling";
import { useGetMonthlyPerformanceDashboardQuery } from "../../../services/monthyPerformanceDashboardAPI";
import { MonthlyPerformanceDashData } from "../../../common/interfaces/monthlyPerformanceDash";

export const defaultMonthlyDashData = (): MonthlyPerformanceDashData => {
  return {
    GroupPv: 0,
    CurrentPerformanceBonusLevel: 0,
    CurrentPerformanceBonusVolume: 0,
    NextPerformanceBonusLevel: 0,
    NextPerformanceBonusVolume: 0,
    VolumeNeeded: 0,
    CorePlanQualified: false,
    QualifiedLegs: 0,
    Schedules: {
      ISOCountry: "",
      ISOCurrency: "",
      bonusDetails: [
        {
          Volume: 0,
          Percent: 0,
        },
      ],
    },
    CallResults: [],
    Error: null,
  };
};
export const FetchMonthlyPerformanceDashData = (period: string) => {
  const { user, configuration } = useAppSelector((state) => state.boot);
  const {
    data: payloadData,
    isFetching,
    isError,
    refetch,
  } = useGetMonthlyPerformanceDashboardQuery({
    baseUrl: configuration.monthlyDashboardApiUrl,
    aff: `${getAff()}`,
    abo: `${getAbo()}`,
    pvTargetWithOneQleg: configuration.monthlyDashboard.monthlyPerformance.pvTargetWithOneQleg,
    isoCountryCode: `${user.isoCountryCode}`,
    period,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [monthlyPerformanceDashData, setMonthlyPerformanceDashData] = useState<MonthlyPerformanceDashData>(
    defaultMonthlyDashData(),
  );

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (!isFetching && !isError) {
      setMonthlyPerformanceDashData(payloadData);
      const foundLambdaError = lambdaErrorCheck(payloadData.errors);
      setErrorStatus(foundLambdaError);
    }
  }, [isFetching, period, isError, payloadData]);

  return { isLoading, monthlyPerformanceDashData, errorStatus, refetch };
};
