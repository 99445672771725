import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/customerTags";
import "./LeafVCSTag.scss";

type Props = {
  vcsFlag?: boolean;
};

const LeafVCSTag = (props: Props) => {
  const { vcsFlag } = props;
  const { t } = useTranslation(["customerTags"]);

  switch (vcsFlag) {
    case true:
      return <span className="tag-vcs">{t("verified", defaultContent["verified"])}</span>;
    case false:
      return <span className="tag-non-vcs">{t("nonVerified", defaultContent["nonVerified"])}</span>;
    default:
      return <></>;
  }
};

export default LeafVCSTag;
