import { ChangeEvent, useState } from "react";
import "./EditNote.scss";

type Props = {
  value?: string;
  noteOnChange: (note: string) => void;
  maxLength?: number;
  show?: boolean;
};

const EditNote = ({ noteOnChange, value, maxLength = 1000, show = true }: Props) => {
  const [note, setNote] = useState(value);

  const _noteOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const note = e.target.value;
    noteOnChange(note);
    setNote(note);
  };

  if (show === true) {
    return (
      <div className="edit-note">
        <textarea
          className="edit-note__text-area"
          rows={8}
          maxLength={maxLength}
          value={note}
          onChange={_noteOnChange}
        />
        <div className="edit-note__counter">
          {note?.length || 0}/{maxLength}
        </div>
      </div>
    );
  }

  return <></>;
};

export default EditNote;
