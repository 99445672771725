export type sponsor = {
  affNo: string;
  aboNo: string;
  isValid: boolean;
  isPlatinum: boolean;
};

export type platinumSponsor = {
  affNo: string;
  aboNo: string;
  isValid: boolean;
};

export type fosterSponsor = {
  affNo: string;
  aboNo: string;
  isValid: boolean;
  isPlatinum: boolean;
};

export type internationalSponsor = {
  affNo: string;
  aboNo: string;
  isValid: boolean;
};

export type ABOInfo = {
  affNo: string;
  aboNo: string;
  name: string;
  localName: string;
  isoLanguageCode: string;
  privacyFlag: boolean;
  isNew: boolean;
  isNewInCurrentMonth: boolean;
  isNewMigrated: boolean;
  entryDate: string;
  joinedDate: string;
  countryCode: string;
  verifiedCustomerFlag: boolean;
  businessNatureCode: number;
  isoBirthdate: string;
  renewalDate: string;
  registrationChannelCd: string;
  status: string;
  recentSponsorDate: string;
  sponsor: sponsor;
  platinumSponsor: platinumSponsor;
  fosterSponsor: fosterSponsor;
  internationalSponsor: internationalSponsor;
};

export type AwardInfo = {
  currentPin: number;
  currentPinDate: string;
  currentPinLevel: string;
  highestAwardAchieved: number;
  highestAwardAchievedDate: string;
  highestAwardAchievedLevel: string;
  highestTrackingAward: number;
  highestTrackingAwardLevel: string;
};

export type PhoneNumber = {
  displayNumber: string;
  extension: string;
  number: string;
  outOfCountryCallingNumber: string;
};

export type Email = {
  type: string;
  email: string;
};

export type Address = {
  type: string;
  addressLines: string[];
  municipality: string;
  region: string;
  postalCode: string;
  country: string;
};

export type PartyLatinName = {
  givenName: string;
  familyName: string;
};

export type PartyLocaleName = {
  givenName: string;
  familyName: string;
};

export type PartyInfo = {
  partyId: string;
  localeDisplayName: string;
  displayName: string;
  localeName: PartyLocaleName;
  latinName: PartyLatinName;
  isPrimary: boolean;
  gender: string;
  isoBirthdate: string;
  preferredName: string;
  preferredLanguage: string;
  preferredLanguageCode: string;
  phoneNumbers: PhoneNumber[];
  emailAddresses: Email[];
  addresses: Address[];
};

export type AdditionalRegInfo = {
  autoRenewal: boolean;
  orderBlock: boolean;
  sponsorBlock: boolean;
  passport: boolean;
  contractSigned: boolean;
  isRegisteredCustomerPlus: boolean;
};

export type OrderInfo = {
  aboRecentOrderDate: string;
};

export type ProfileResponse = {
  data: {
    aboDetail: {
      aboInfo: ABOInfo;
      awardInfo: AwardInfo;
      partyInfo: PartyInfo[];
      additionalRegInfo: AdditionalRegInfo;
      orderInfo: OrderInfo;
    };
  };
};

export type ProfileInfo = {
  showBirthday: boolean;
  showEntryDate: boolean;
  showGender: boolean;
  showLanguage: boolean;
  showRecentOrderDate: boolean;
  showRecentReturnOrderDate: boolean;
  showRenewalDate: boolean;
  showCurrentPin: boolean;
  showHighestPin: boolean;
  showHighestTrackingPin: boolean;
  showRecentSponsorDate: boolean;
};

export type SponsorProfileTransformResponse = {
  aboInfo?: {
    affNo: string;
    aboNo: string;
    name: string;
    localName: string;
    privacyFlag: boolean;
    isoLanguageCode: string;
    phoneNumbers: {
      numbers: {
        displayNumber: string;
      }[];
    }[];
    emailAddresses: {
      addresses: {
        address: string;
      }[];
    }[];
    addresses: Address[];
  };
};

export type SponsorProfileResponse = {
  data: {
    aboDetail: {
      aboInfo: {
        affNo: string;
        aboNo: string;
        name: string;
        localName: string;
        privacyFlag: boolean;
        isoLanguageCode: string;
        phoneNumbers: {
          numbers: {
            displayNumber: string;
          }[];
        }[];
        emailAddresses: {
          addresses: {
            address: string;
          }[];
        }[];
        addresses: Address[];
      };
    };
  };
};

export type ProfileTransformResponse = {
  aboInfo?: ABOInfo;
  partyInfo?: PartyInfo[];
  awardInfo?: AwardInfo;
  additionalRegInfo?: AdditionalRegInfo;
  orderInfo?: OrderInfo;
};

export type ProfileParams = {
  period: string;
  affAbo: string;
};

export type ABOProfileParams = {
  period: string;
  affAbo: string;
  reqAffAbo: string;
};

export const getDefaultTab = (tabs: string[]): string => (Array.isArray(tabs) && tabs.length ? tabs[0] : "");
