import { createApi } from "@reduxjs/toolkit/query/react";
import { SsiOverviewResponse } from "../common/interfaces/strongStartIncentive";
import { dynamicBaseQuery } from ".";

interface SsiBaseRequestParams {
  abo: string;
  aff: string;
  locale: string;
  period: string;
}

interface SsiExtendedRequestParams extends SsiBaseRequestParams {
  isoCountryCode: string;
  isoCurrencyCode: string;
}

export const ssiAPI = createApi({
  reducerPath: "ssiAPI",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getSsiOverview: builder.query<SsiOverviewResponse, SsiExtendedRequestParams>({
      query: (params) => {
        const { abo, aff, locale, period, isoCountryCode, isoCurrencyCode } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
          IsoCountryCode: isoCountryCode,
          IsoCurrencyCode: isoCurrencyCode,
        });

        return `/incentives-v2/api/strong-start/overview?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
  }),
});

export const { useGetSsiOverviewQuery } = ssiAPI;
