import {
  VCS_PERCENT,
  GROUP_PV,
  LEADERSHIP_PV,
  NEW_ABO,
  PERSONAL_PV,
  QCPV,
  RUBY_PV,
  TOTAL_ABOS,
  VCS_PV,
  PERSONAL_ORDERS,
  CUSTOMER_ORDERS,
  GROUP_ORDERS,
  NEW_CUSTOMERS,
  TOTAL_CUSTOMERS,
  PERFORMANCE_BONUS,
} from "../../../../../../../../common/constants/perf-history-kpis";
import { PerfHistoryData, PerfHistoryResponse } from "../../../../../../../../services/performanceHistoryAPI";
import ABOProfilePerfHistoryCustomerOrder from "./ABOProfilePerfHistoryCustomerOrder/ABOProfilePerfHistoryCustomerOrder";
import ABOProfilePerfHistoryGPV from "./ABOProfilePerfHistoryGPV/ABOProfilePerfHistoryGPV";
import ABOProfilePerfHistoryGroupOrder from "./ABOProfilePerfHistoryGroupOrder/ABOProfilePerfHistoryGroupOrder";
import ABOProfilePerfHistoryLeadershipPV from "./ABOProfilePerfHistoryLeadershipPV/ABOProfilePerfHistoryLeadershipPV";
import ABOProfilePerfHistoryNewABO from "./ABOProfilePerfHistoryNewABO/ABOProfilePerfHistoryNewABO";
import ABOProfilePerfHistoryNewCustomer from "./ABOProfilePerfHistoryNewCustomer/ABOProfilePerfHistoryNewCustomer";
import ABOProfilePerfHistoryPPV from "./ABOProfilePerfHistoryPPV/ABOProfilePerfHistoryPPV";
import ABOProfilePerfHistoryPerformanceBonus from "./ABOProfilePerfHistoryPerformanceBonus/ABOProfilePerfHistoryPerformanceBonus";
import ABOProfilePerfHistoryPersonalOrder from "./ABOProfilePerfHistoryPersonalOrder/ABOProfilePerfHistoryPersonalOrder";
import ABOProfilePerfHistoryQCPV from "./ABOProfilePerfHistoryQCPV/ABOProfilePerfHistoryQCPV";
import ABOProfilePerfHistoryRubyPV from "./ABOProfilePerfHistoryRubyPV/ABOProfilePerfHistoryRubyPV";
import ABOProfilePerfHistoryTotalABO from "./ABOProfilePerfHistoryTotalABO/ABOProfilePerfHistoryTotalABO";
import ABOProfilePerfHistoryTotalCustomer from "./ABOProfilePerfHistoryTotalCustomer/ABOProfilePerfHistoryTotalCustomer";
import ABOProfilePerfHistoryVCSPV from "./ABOProfilePerfHistoryVCSPV/ABOProfilePerfHistoryVCSPV";
import ABOProfilePerfHistoryVCSPercent from "./ABOProfilePerfHistoryVCSPercent/ABOProfilePerfHistoryVCSPercent";

export type PerfHistoryDataProps = {
  affAbo: string;
  locale: string;
  isoCountryCode: string;
  period: string;
  periodCount?: number;
  children: (props: {
    data?: PerfHistoryResponse;
    isFetching: boolean;
    isError: boolean;
    overrideFormatTableKPIData?: (data: PerfHistoryData) => string | number;
    overrideFormatChartData?: (data: number | string) => string;
  }) => React.ReactNode;
};

const ABOProfilePerfHistoryKPIs = [
  {
    id: GROUP_PV,
    PerfHistoryData: ABOProfilePerfHistoryGPV,
  },
  {
    id: PERSONAL_PV,
    PerfHistoryData: ABOProfilePerfHistoryPPV,
  },
  {
    id: LEADERSHIP_PV,
    PerfHistoryData: ABOProfilePerfHistoryLeadershipPV,
  },
  {
    id: NEW_ABO,
    PerfHistoryData: ABOProfilePerfHistoryNewABO,
  },
  {
    id: QCPV,
    PerfHistoryData: ABOProfilePerfHistoryQCPV,
  },
  {
    id: RUBY_PV,
    PerfHistoryData: ABOProfilePerfHistoryRubyPV,
  },
  {
    id: TOTAL_ABOS,
    PerfHistoryData: ABOProfilePerfHistoryTotalABO,
  },
  {
    id: VCS_PERCENT,
    PerfHistoryData: ABOProfilePerfHistoryVCSPercent,
  },
  {
    id: VCS_PV,
    PerfHistoryData: ABOProfilePerfHistoryVCSPV,
  },
  {
    id: PERSONAL_ORDERS,
    PerfHistoryData: ABOProfilePerfHistoryPersonalOrder,
  },
  {
    id: CUSTOMER_ORDERS,
    PerfHistoryData: ABOProfilePerfHistoryCustomerOrder,
  },
  {
    id: GROUP_ORDERS,
    PerfHistoryData: ABOProfilePerfHistoryGroupOrder,
  },
  {
    id: NEW_CUSTOMERS,
    PerfHistoryData: ABOProfilePerfHistoryNewCustomer,
  },
  {
    id: TOTAL_CUSTOMERS,
    PerfHistoryData: ABOProfilePerfHistoryTotalCustomer,
  },
  {
    id: PERFORMANCE_BONUS,
    PerfHistoryData: ABOProfilePerfHistoryPerformanceBonus,
  },
];

export default ABOProfilePerfHistoryKPIs;
