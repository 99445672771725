import { PLATINUM_AND_SPONSOR, SPONSOR } from "../../../../../constants/sponsor-constants";
import { BasicSponsor } from "../../LeafSponsorship";
import LeafSponsorDetail from "../LeafSponsorDetail";

type Sponsor = { isPlatinum: boolean } & BasicSponsor;

type Props = {
  sponsor: Sponsor;
  fosterSponsor: Sponsor;
};

const LeafSponsorOrSponsorAndPlatinum = (props: Props) => {
  const { sponsor, fosterSponsor } = props;
  const { aboNo, affNo, isValid, isPlatinum } = sponsor;
  const isSponsor = sponsor.isValid === true && sponsor.isPlatinum === false && fosterSponsor.isValid === false;
  const isSponsorAndPlatinum =
    sponsor.isValid === true && sponsor.isPlatinum === true && fosterSponsor.isPlatinum === false;
  const show = isSponsor === true || isSponsorAndPlatinum === true;
  const sponsorType = isSponsor === true ? SPONSOR : PLATINUM_AND_SPONSOR;

  return (
    <LeafSponsorDetail
      sponsor={{
        aboNo,
        affNo,
        isValid,
        isPlatinum,
      }}
      show={show}
      sponsorType={sponsorType}
    />
  );
};

export default LeafSponsorOrSponsorAndPlatinum;
