import "./IconNoData.scss";

type Props = {
  width?: string;
  height?: string;
};

export const IconNoData = (props: Props) => {
  const { width, height } = props;
  return <span className="icon-no-data" style={{ width, height }}></span>;
};
