import { useEffect } from "react";
import { useAsync } from "react-async";
import { useTranslation } from "react-i18next";
import defaultContent from "../../content/bronzeBuilder";
import { SELECTOR_TYPE, updateSelectorType } from "../../reducers/period";
import { useAppDispatch, useAppSelector } from "../../store";
import { storedLocale } from "../impersonation/util";
import { getLongPeriodFormat } from "../../common/util/period";
import { BronzeBannerNewPyToast } from "../BronzeIncentives/BronzeBannerToast/NewPYBanner/NewPYBanner";

const loadComponent = async () => {
  return import("../Lit/acc-coreplus-incentives/acc-bronze-builder.js");
};

export default function BronzeBuilder() {
  const { t } = useTranslation(["bronzeBuilder"]);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { configuration } = useAppSelector((state) => state.boot);
  const dispatch = useAppDispatch();
  const { isPending } = useAsync({ promiseFn: loadComponent });
  const locale = storedLocale();

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));
  }, [dispatch]);

  useEffect(() => {
    if (!isPending) {
      const ele = document.getElementById("acc_bronze_builder_component") as HTMLElement;

      ele.setAttribute("learn-more-link", configuration.dashboardLinks?.learnMore?.bronzeBuilder);
      ele.setAttribute("cml-link", configuration.dashboardLinks?.customerMemberList);
      ele.setAttribute("los-link", configuration.dashboardLinks?.lineOfSponsorship);
    }
  }, [isPending, configuration]);

  if (isPending) {
    return <></>;
  }

  const capitalizeFirstLetter = (period: string) => {
    return period.charAt(0).toUpperCase() + period.slice(1);
  };
  const localePeriod = capitalizeFirstLetter(getLongPeriodFormat(selectedPeriod, locale));

  return (
    <main>
      <BronzeBannerNewPyToast />
      <h1 className="bronze_builder_header" id="bronze_builder_header">
        {t("pageTitle", defaultContent["pageTitle"])}
      </h1>
      <acc-bronze-builder
        id="acc_bronze_builder_component"
        mode="standalone"
        period={selectedPeriod}
        locale={locale}
        localeperiod={localePeriod}
      ></acc-bronze-builder>
    </main>
  );
}
