import { useTranslation } from "react-i18next";
import { getPinLevelContentName } from "../../constants/pin-level-codes";
import defaultContent from "../../../content/common";
import { PIN_LEVEL_CODE } from "../../enums/pin-level-codes";

type Props = {
  pinLevelCode: PIN_LEVEL_CODE | number;
};

const LeafPinLevelName = ({ pinLevelCode }: Props) => {
  const { t } = useTranslation(["common"]);
  const contentKey = getPinLevelContentName(pinLevelCode);
  const content = contentKey
    ? t("awardLabels." + contentKey, defaultContent.awardLabels[contentKey])
    : t("awardLabels.no-pin-level", defaultContent.awardLabels["no-pin-level"]);
  return <span data-testid="leafPinLevelName">{`${content}`}</span>;
};

export default LeafPinLevelName;
