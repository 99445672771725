import React from "react";
import LeafRectangleLoader from "../../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import LeafCircleLoader from "../../../../../common/leaf/LeafLoadingSkeleton/LeafCircleLoader/LeafCircleLoader";
import "./PieChartLoader.scss";

type Props = {
  isLoading: boolean;
  View: React.ElementType;
};

const PieChartLoader = (props: Props) => {
  const { isLoading, View } = props;
  const height = "100%";
  const width = "100%";

  const RenderRectangleLoader = () => {
    return <LeafRectangleLoader isLoading={isLoading} height={"1.8rem"} width={"40%"} />;
  };

  const RenderLegends = () => {
    return (
      <div className="personal-pv-loader__legend">
        <RenderRectangleLoader />
        <RenderRectangleLoader />
      </div>
    );
  };

  const PieChartLoaderView = () => {
    return (
      <div className="personal-pv-loader">
        <div className="personal-pv-loader__container">
          <div className="personal-pv-loader__title-container">
            <LeafRectangleLoader isLoading={isLoading} height={height} width={width} />
          </div>
          <div className="personal-pv-loader__donut-chart">
            <div className="personal-pv-loader__donut-chart-container">
              <LeafCircleLoader isLoading={isLoading} height={height} width={width} />
            </div>
          </div>
          <div className="personal-pv-loader__legend-container">
            <RenderLegends />
            <RenderLegends />
          </div>
        </div>
      </div>
    );
  };

  return isLoading === true ? <PieChartLoaderView /> : <View />;
};

export default PieChartLoader;
