import { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { QUALIFICATION_STATUS } from "../../../enums/incentive-enums";
import { IconShield, BADGE_TYPE, BADGE_SIZE } from "../../../icons/IconShield/IconShield";
import { AwardProgressBadge } from "../../AwardProgressBadge/AwardProgressBadge";
import { AWARD_PROGRESS } from "../../../enums/award-progress-codes";
import LeafTile from "../../../leaf/LeafTile/LeafTile";
import LeafTileDynamicDivider from "../../../leaf/LeafTileDynamicDivider/LeafTileDynamicDivider";
import defaultContent from "../../../../content/qcGrowth";
import { useAppSelector } from "../../../../store";
import { getSelectedPerformanceYearData } from "../../../util/period";
import "./BadgeRequirementPreviousTile.scss";

type Props = {
  title: string;
  status: QUALIFICATION_STATUS;
  isLoading: boolean;
  showLabel?: boolean;
  hideBorder?: boolean;
  pyStatus?: string;
  currentYtd?: string;
};

export const BadgeRequirementPreviousTile = ({
  title,
  status,
  isLoading,
  showLabel = true,
  hideBorder = false,
  pyStatus,
  currentYtd,
}: Props) => {
  const [badgeType, setBadgeType] = useState(BADGE_TYPE.POTENTIAL);
  const [progressStatus, setProgressStatus] = useState<AWARD_PROGRESS>(AWARD_PROGRESS.NOT_TRACKING);
  const { t } = useTranslation(["qcGrowth"]);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { selectedPerformanceYear: previousPerformanceYear } = getSelectedPerformanceYearData(selectedPeriod, 1);
  const previousPY = previousPerformanceYear.slice(2, 4);

  useEffect(() => {
    switch (status) {
      case QUALIFICATION_STATUS.QUALIFIED:
        setBadgeType(BADGE_TYPE.EARNED);
        setProgressStatus(AWARD_PROGRESS.ACHIEVED);
        break;
      case QUALIFICATION_STATUS.NOT_QUALIFIED:
      case QUALIFICATION_STATUS.NOT_TRACKING:
        setBadgeType(BADGE_TYPE.NOT_EARNED);
        setProgressStatus(AWARD_PROGRESS.NOT_TRACKING);
        break;
      default:
        setBadgeType(BADGE_TYPE.POTENTIAL);
        setProgressStatus(AWARD_PROGRESS.TRACKING);
        break;
    }
  }, [status]);

  return (
    <LeafTile>
      <div className="BadgeReqPrevious">
        <LeafTile
          id="BadgeReqPrevious__maintainQc"
          title={title}
          showMetStatus={true}
          met={status === QUALIFICATION_STATUS.QUALIFIED}
          hideBorder={hideBorder}
          isLoading={isLoading}
        >
          <div className="BadgeReqPrevious__tileContainer">
            <div className="BadgeReqPrevious__icon">
              <IconShield size={BADGE_SIZE.EXTRA_LARGE} type={badgeType} />
            </div>
            {progressStatus !== AWARD_PROGRESS.TRACKING && showLabel ? (
              <AwardProgressBadge status={progressStatus} hideIcon={true} />
            ) : (
              <div className="BadgeReqPrevious__phantomLabel"></div>
            )}
          </div>
        </LeafTile>

        {isLoading ? (
          <></>
        ) : (
          <LeafTile id="BadgeReqPrevious__compareTile" backgroundColor="gray">
            <div className="BadgeReqPrevious__compareContainer">
              <div className="BadgeReqPrevious__compareHeading">
                <span>
                  <Trans>
                    {t("maintainQc.previousYearTile", defaultContent.requirements.maintainQc.previousYearTile["title"])}
                  </Trans>
                </span>
              </div>
              <div className="BadgeReqPrevious__compareYears">
                <div className="BadgeReqPrevious__compareYearsItem">
                  <span>
                    <Trans>
                      {t(
                        "maintainQc.previousYearTile.previousPyHeader",
                        defaultContent.requirements.maintainQc.previousYearTile["previousPyHeader"],
                        { performanceYear: previousPY },
                      )}
                    </Trans>
                  </span>
                  <span className="BadgeReqPrevious__compareYearsItemBottomText">{pyStatus}</span>
                </div>
                <LeafTileDynamicDivider />
                <div className="BadgeReqPrevious__compareYearsItem">
                  <Trans>
                    {t(
                      "maintainQc.previousYearTile.currentPyHeader",
                      defaultContent.requirements.maintainQc.previousYearTile["currentPyHeader"],
                    )}
                  </Trans>
                  <span className="BadgeReqPrevious__compareYearsItemBottomText">{currentYtd}</span>
                </div>
              </div>
            </div>
          </LeafTile>
        )}
      </div>
    </LeafTile>
  );
};
