import { ReactNode, useCallback, useState, useRef } from "react";
import "./PerformanceProgressBar.scss";
import colors from "../../scss/colors.module.scss";
import useWindowSize from "../../hooks/useWindowSize";
import breakpoints from "../../../common/scss/breakpoints.module.scss";
import { ProgressCaretIcon } from "./PerformanceProgressCaret/PerformanceProgressCaret";

type ProgressValue = {
  actual: number;
  display: string;
};

type ProgressValues = {
  current: ProgressValue;
  target: ProgressValue;
};

type ColorScale = {
  threshold: number;
  color: string;
};

type Milestone = {
  value: number;
  label?: ReactNode;
  subLabel?: ReactNode;
};

type Props = {
  progressValues: ProgressValues;
  progressMilestones?: Milestone[];
  hideCaret?: boolean;
  showMaxFill?: boolean;
  colorScales?: ColorScale[];
  progressRatio?: number;
  displayMilestoneMarkers?: boolean;
};

export const PerformanceProgressBar = ({
  progressMilestones,
  progressValues,
  hideCaret = false,
  showMaxFill = false,
  colorScales = [{ threshold: 0, color: colors.blue }],
  progressRatio = undefined,
  displayMilestoneMarkers: displayMilestones = false,
}: Props) => {
  let progress = Math.min(
    progressRatio ? progressRatio : (progressValues.current.actual / progressValues.target.actual) * 100,
    100,
  );

  if (progress < 0) {
    progress = 0;
  }

  if (showMaxFill) {
    progress = 100;
  }

  const activeLevel = colorScales.filter((scale) => scale.threshold <= progress);
  const fillColor = activeLevel && activeLevel.length > 0 ? activeLevel[activeLevel.length - 1].color : "";

  // When bar has currentValue is zero a small blue is added to the bar. hence 0.5 value added to the progress bar
  const progressBarWidth = `${progress > 0 ? progress : progress === 0 ? 0.5 : 0}%`;
  const [alignBarText, setAlignBarText] = useState(`${progress}%`);
  const [barTextClass, setBarTextClass] = useState("");

  const extraSpaceBesideTextInPerc = 2;
  const progressForCalc = progress > 0 ? progress : 0;

  const mileStoneWidth = (milestone: number): string => {
    return `${(milestone / progressValues.target.actual) * 100}%`;
  };

  const mileStoneColor = (milestone: number): string => {
    const milestonePercent = (milestone / progressValues.target.actual) * 100;

    if (milestonePercent === 0 || milestonePercent === 100) return "transparent";

    return milestonePercent < progress ? "white" : colors.mediumGray;
  };

  const { width } = useWindowSize();

  // Adding or removing extra space beside progress bar text if its to the left or right
  const barTextRef = useCallback(
    (node: any) => {
      let additionalClass = "";
      const bigProgress = 95;
      const smallProgress = 21;
      const widthTwoLines = 55;
      const widthOneLine = 85;
      if (node !== null && progress >= 50) {
        additionalClass = "performanceProgressBar__long-bar";
        let nodeWidth = node.getBoundingClientRect().width;
        if (progress > bigProgress) {
          if (Number(width) < Number(breakpoints.mobile)) {
            nodeWidth = (nodeWidth * widthOneLine) / widthTwoLines;
            additionalClass += " performanceProgressBar__long-space";
          }
        }
        setBarTextClass(additionalClass);
        const calcBarTextLeft = `calc(${progress - extraSpaceBesideTextInPerc}% - ${nodeWidth}px)`;
        setAlignBarText(calcBarTextLeft);
      } else {
        if (progress < smallProgress) {
          additionalClass = "performanceProgressBar__long-space";
        }
        setBarTextClass(additionalClass);
        setAlignBarText(`${progressForCalc + extraSpaceBesideTextInPerc}%`);
      }
    },
    [progress, progressForCalc, width],
  );

  const CaretNText = () => {
    return hideCaret ? null : (
      <div className="performanceProgressBar__container">
        <div className="performanceProgressBar__caret" style={{ left: `calc(${progressForCalc}% - 1rem` }}>
          <ProgressCaretIcon fill={fillColor} />
        </div>
        <span
          ref={barTextRef}
          className={"performanceProgressBar__bar-text " + barTextClass}
          style={{ left: alignBarText }}
        >
          <span>{progressValues.current.display}</span>
          {progressValues.target.display ? (
            <span className="performanceProgressBar__bar-slash">&nbsp;/&nbsp;</span>
          ) : (
            <></>
          )}
          <span className="performanceProgressBar__bar-text-target">{`${
            progressValues.target.display ? `${progressValues.target.display}` : progressValues.target.display
          }`}</span>
        </span>
      </div>
    );
  };

  const ProgressBar = () => (
    <div className="performanceProgressBar__progress-contatiner">
      <div
        className="performanceProgressBar__progress-filled"
        style={{
          width: progressBarWidth,
          background: fillColor,
        }}
      ></div>
      {/* Progress milestones */}
      {displayMilestones && renderMileStoneMarks()}
    </div>
  );

  const renderMileStoneMarks = () =>
    progressMilestones?.map((milestone: Milestone, index: number) => (
      <div
        key={index}
        className="performanceProgressBar__milestones"
        style={{
          left: mileStoneWidth(milestone.value),
          background: mileStoneColor(milestone.value),
        }}
      ></div>
    ));

  return (
    <div className="performanceProgressBar">
      {/* Caret indicator */}
      <CaretNText />

      {/* Progress bar */}
      <ProgressBar />

      {/* Labels and values */}
      <div className="performanceProgressBar__labels-values">
        {progressMilestones?.map((milestone) => {
          if (milestone.label) {
            return (
              <div className="performanceProgressBar__labels-values--milestone">
                <span className="performanceProgressBar__label">{milestone.label}</span>
                <span className="performanceProgressBar__value">{milestone.subLabel}</span>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
