export const addParams = (paramName: string, value: string, openModal: boolean, removeParam: boolean) => {
  const url = new URL(window.location.href);

  if (removeParam) {
    url.searchParams.delete(paramName);
  } else {
    url.searchParams.set(paramName, value);
  }

  window.history.pushState({ modalOpen: openModal }, "", url.toString());
};
