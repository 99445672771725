import { Trans, useTranslation } from "react-i18next";
import "./AnaAcademyBanner.scss";
import { ReactComponent as AnaAcademyLogo } from "./ana-academy-education.svg";

const AnaAcademyBanner = () => {
  const { t } = useTranslation(["academyBanner"]);
  const defaultHeadline = "Welcome to <br>Amway Education";
  const defaultSubline = "Ignite your business with dynamic, on-demand learning!";
  return (
    <div className="ana-academy-banner">
      <div className="banner-overlay">
        <div className="banner-text">
          <AnaAcademyLogo className="banner-text_ana-academy-education" />
          <div className="banner-text_wrapper">
            <p className="banner-text_headline">
              <Trans>{t("headline", defaultHeadline)}</Trans>
            </p>
            <p className="banner-text_sub-headline">{t("subheadline", defaultSubline)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnaAcademyBanner;
