import "./LeafSortByRadioButton.scss";

type Item = {
  id: string;
  text: string;
  selected: boolean;
};

type Props = {
  item: Item;
  onRadioButtonClick: Function;
  handleKeyDown: (e: React.KeyboardEvent<HTMLDivElement>, item: Item) => void;
};

const LeafSortByRadioButton = (props: Props) => {
  const { item, onRadioButtonClick, handleKeyDown } = props;

  const handleChange = () => {
    onRadioButtonClick(item);
  };

  return (
    <div
      className="leaf-radio-button"
      tabIndex={item.selected ? 0 : -1}
      onKeyDown={(e) => handleKeyDown(e, item)}
      id={item.id}
      role="radio"
      aria-checked={item.selected}
    >
      <label className="leaf-radio-button__label">
        <input type="radio" checked={item.selected} onChange={handleChange} />
        <div className="leaf-radio-button__circle"></div>
        <div className="leaf-radio-button__text">{item.text}</div>
      </label>
    </div>
  );
};

export default LeafSortByRadioButton;
