export enum MARKETS {
  UNITED_STATES = "US",
  DOMINICAN_REPUBLIC = "DO",
  CANADA = "CA",
}

export enum MARKET_CODES {
  UNITED_STATES = "010",
  DOMINICAN_REPUBLIC = "580",
  CANADA = "020",
}
