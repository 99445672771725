import { LeafColorBlock } from "../LeafColorBlock/LeafColorBlock";
import { LeafLearnMoreLink } from "../LeafLearnMoreLink/LeafLearnMoreLink";
import LeafRectangleLoader from "../LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import LeafTile from "../LeafTile/LeafTile";
import "./LeafIncentiveBannerTile.scss";

type Props = {
  bannerMessages: {
    status: string[];
    title: string;
    subtitle?: string;
  }[];
  incentive: {
    title: string;
    status: string;
    amount: string;
    statusToHideIncentive?: string[];
  };
  isLoading: boolean;
  learnMoreLink?: string;
  show?: boolean;
  isCompactView?: boolean;
  learnMoreTealiumFunction?: () => void;
  hideBonusAmount?: boolean;
};

const LeafIncentiveBannerTile = (props: Props) => {
  const {
    bannerMessages,
    incentive,
    isLoading,
    show = true,
    learnMoreLink,
    learnMoreTealiumFunction,
    isCompactView = true,
    hideBonusAmount = false,
  } = props;
  const { statusToHideIncentive = [] } = incentive;

  const bannerDetails = bannerMessages.find(({ status }) => status.includes(incentive.status));
  const showIncentiveAmount = statusToHideIncentive.every((statusToHide) => statusToHide !== incentive.status);

  const textOnlyMode = !learnMoreLink && !showIncentiveAmount;
  const learnMoreLinkOnlyMode = showIncentiveAmount === false || hideBonusAmount === true;

  const compactView = isCompactView === true ? "compact" : "";
  const learnMoreLinkOnlyView = learnMoreLinkOnlyMode ? "learnMoreLinkOnly" : "";

  if (!bannerDetails || show === false) {
    return <></>;
  }

  const BonusDetails = () => {
    if (textOnlyMode === true) {
      return <></>;
    }

    return (
      <section className={`LeafIncentiveBannerTile__bonusDetail ${compactView} ${learnMoreLinkOnlyView}`}>
        {!learnMoreLinkOnlyMode && (
          <LeafRectangleLoader
            isLoading={isLoading}
            height="6rem"
            width="auto"
            children={
              <div data-testid="incentive-amount">
                <LeafColorBlock>
                  <span>{incentive.title}</span>
                  <div className="lower-content">{incentive.amount}</div>
                </LeafColorBlock>
              </div>
            }
          />
        )}
        {learnMoreLink && (
          <LeafRectangleLoader
            isLoading={isLoading}
            height="2rem"
            width="auto"
            children={
              <div className="LeafIncentiveBannerTile__learnMoreButton" data-testid="learn-more-link">
                <LeafLearnMoreLink href={learnMoreLink} onClickFn={learnMoreTealiumFunction} />
              </div>
            }
          />
        )}
      </section>
    );
  };

  return (
    <div className="LeafIncentiveBannerTile">
      <LeafTile isLoading={isLoading}>
        <section className={`LeafIncentiveBannerTile__bannerContents ${compactView}`}>
          <section className={`LeafIncentiveBannerTile__bonusInfo ${compactView} ${textOnlyMode ? "textOnly" : ""}`}>
            <LeafRectangleLoader
              isLoading={isLoading}
              height="3rem"
              width="auto"
              children={<div className={`LeafIncentiveBannerTile__title ${compactView}`}>{bannerDetails.title}</div>}
            />
            {bannerDetails.subtitle && (
              <LeafRectangleLoader
                isLoading={isLoading}
                height="1rem"
                width="auto"
                numRows={3}
                margin=".25rem"
                children={<div className="LeafIncentiveBannerTile__description">{bannerDetails.subtitle}</div>}
              />
            )}
          </section>
          <BonusDetails />
        </section>
      </LeafTile>
    </div>
  );
};

export default LeafIncentiveBannerTile;
