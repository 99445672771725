import { useTranslation } from "react-i18next";
import { IconCarrot } from "../../../../../common/icons/IconCarrot/IconCarrot";
import defaultContent from "../../../../../content/newCustomerListOverview";
import { Customer } from "../../../../../common/interfaces/newCustomerListOverview";
import { showProfile } from "../../../../../reducers/profile";
import { useAppDispatch } from "../../../../../store";
import "./NewCustomerItem.scss";
import LeafDateTime from "../../../../../common/leaf/LeafDateTime/LeafDateTime";
import LeafCustomerPV from "../../../../../common/leaf/LeafCustomerPV/LeafCustomerPV";
import { recentJoinedOpenProfileClickTrack } from "../../../../../common/tealium/Customers/Overview/RecentlyJoinedTrack";
import LeafCustomerTags from "../../../../../common/leaf/LeafProfile/LeafCustomerTags/LeafCustomerTags";

const NewCustomerItem = (customer: Customer) => {
  const { t } = useTranslation(["newCustomerListOverview"]);
  const {
    name,
    lastOrderDate,
    totalCustomerPV,
    affAbo,
    totalVCSPV,
    isNew,
    isNewInCurrentMonth,
    isNewMigrated,
    selectedPeriod,
    registrationChannelCd,
  } = customer;
  const dispatch = useAppDispatch();

  const customerOnClick = () => {
    dispatch(showProfile(affAbo));
    recentJoinedOpenProfileClickTrack();
  };

  return (
    <div className="card" onClick={customerOnClick}>
      <div className="card__container">
        <div className="card__details">
          <p className="card__lastOrderDate">
            {t("lastOrderDate", defaultContent["lastOrderDate"])}{" "}
            <LeafDateTime
              date={lastOrderDate}
              options={{ month: "long", day: "numeric", year: "numeric" }}
              ignoreTimeZone={true}
            />
          </p>
          <p className="card__customer-name">{name}</p>
          <div className="card__customer-details">
            <p className="card__customer-tag">
              <LeafCustomerTags
                isNewCustomer={isNew}
                isNewMigrated={isNewMigrated}
                isNewInCurrentMonth={isNewInCurrentMonth}
                selectedPeriod={selectedPeriod}
                registrationChannelCd={registrationChannelCd}
                aboNo={affAbo}
              />
            </p>
            <p className="card__customer-vcs-pv">
              <span className="card__tag-vcs-pv">
                <LeafCustomerPV vcsPV={totalVCSPV} customerPV={totalCustomerPV} />
              </span>
            </p>
          </div>
        </div>
        <div className="card__right-arrow">
          <span className="card__icon-right">
            <IconCarrot orientation="right" color="black" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default NewCustomerItem;
