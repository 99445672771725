const content: { [key: string]: any } = {
  termsLabel: "Terms of Use",
  marketLabel: "Market Information",
  copyright: "{YEAR} Alticor Inc.",
  confidential: "Confidential. Contains Amway LOS Information. Use only as permitted by contract with Amway.",
  aboOnlyLabel: "For ABO Use Only.  Not for prospect use.",
  privacyLabel: "Privacy Notice",
};

export default content;
