import "./LeafVerticalScale.scss";

export type TickMarks = {
  value: number;
  label: string | number;
};

type leafVerticalScaleProps = {
  tickMarks: TickMarks[];
  legendLabels: string[] | number[];
  scaleMax: number;
  scaleMin: number;
  scaleCurrent: number;
  highlightedLegendLabel?: number | string;
  scaleTitle: string;
  legendTitle: string;
};

export const LeafVerticalScale = ({
  scaleCurrent = 0,
  tickMarks,
  legendLabels,
  scaleMax,
  scaleMin = 0,
  highlightedLegendLabel,
  scaleTitle = "",
  legendTitle = "",
}: leafVerticalScaleProps) => {
  const fill_percent = scaleCurrent / (scaleMax - scaleMin) < 1 ? (scaleCurrent * 100) / (scaleMax - scaleMin) : 100;

  const findSelectedRange = () => {
    const size = Number((1 / tickMarks.length) * 100);
    const items = tickMarks.filter((value, index) => index * size < fill_percent);
    return items.slice(items.length - 2).map((mark) => tickMarks.indexOf(mark));
  };

  const selectedRange = findSelectedRange();

  const TickMark = ({ markerValue, met }: { markerValue: string | number; met: boolean }) => {
    return (
      <span className="leafVerticalScaleTick__tickRow" key={markerValue}>
        <div className={`leafVerticalScaleTick__tickLine${met ? "--met" : ""}`}></div>
        <div className="leafVerticalScaleTick__tickLabel">{markerValue}</div>
      </span>
    );
  };

  const buildTickMarks = () => {
    return (
      <>
        {tickMarks.map((tick, index) => {
          return <TickMark markerValue={tick.label} key={`tick-${index}`} met={tick.value <= scaleCurrent} />;
        })}
      </>
    );
  };

  const height = `${tickMarks.length * 3}rem`;

  return (
    <span data-testid={`leafVerticalScale`}>
      <div className="leafVerticalScale__headers">
        <span className="leafVerticalScale__scaleHeader">{scaleTitle}</span>
        <span className="leafVerticalScale__legendHeader">{legendTitle}</span>
      </div>
      <div className="leafVerticalScaleWrapper" style={{ height }}>
        <div className="leafVerticalScale">
          <div className="leafVerticalScale__progressbar">
            <div className="leafVerticalScale__bar" style={{ height: `${fill_percent}%` }}></div>
          </div>
          <div className="leafVerticalScaleTick" style={{ height }}>
            {buildTickMarks()}
          </div>

          <div className="leafVerticalScale__legend">
            {legendLabels.map((legendValue, index) => (
              <span
                key={legendValue}
                className={`leafVerticalScale__legendrow  ${
                  selectedRange.includes(index) ? "leafVerticalScale__legendrow--active" : ""
                }`}
              >
                <div
                  className={`leafVerticalScale__legendLabel ${
                    legendValue === highlightedLegendLabel ? "leafVerticalScale__legendLabel--active" : ""
                  }`}
                >
                  {legendValue}
                </div>
              </span>
            ))}
          </div>
        </div>
      </div>
    </span>
  );
};
