import { Trans, useTranslation } from "react-i18next";
import { formatAboPV } from "@amwaycommon/acc-mixins/src/modules/l10n";
import defaultContent from "../../../../../content/monthlyPerformanceTotalModals";
import { LeafModal } from "../../../../../common/leaf/LeafModal/LeafModal";
import "../MonthlyPerformanceTotalModals.scss";
import { IconAlert } from "../../../../../common/icons/IconAlert/IconAlert";
import LeafRectangleLoader from "../../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import {
  BonusBreakdownLineData,
  BonusPercent,
  PersonalBonusEntry,
  PersonalNonBonusableEntry,
} from "../../../../../common/interfaces/monthlyPerformanceDash";
import { ANA_MARKET_CODES } from "../../../../../common/constants/market-constants";
import { useAppSelector } from "../../../../../store";
import { marketCode, storedLocale } from "../../../../impersonation/util";

type Table = {
  aboBonusPercent: string;
  aboGpv: string;
  personalBV: string;
  bonusPercent: string;
  personalBonusAmount: string;
  nonBonusable: string;
  total: string;
};
type PerformanceTotal = {
  title: string;
  subTitle: string;
  table: Partial<Table>;
};

type BonusData = {
  bonusPercent: BonusPercent;
  personalBonus: PersonalBonusEntry[];
  personalNonBonusable: PersonalNonBonusableEntry[];
};

const PersonalBonusModal = ({
  openModal,
  onCloseModal,
  performanceData,
  performanceTotal,
  isFetching,
  isError,
}: {
  openModal: boolean;
  onCloseModal: Function;
  performanceData: Partial<BonusData>;
  performanceTotal: Partial<BonusBreakdownLineData>;
  isFetching: boolean;
  isError: boolean;
}) => {
  const { t } = useTranslation("monthlyPerformanceTotalModals");
  const user = useAppSelector((state) => state.boot?.user);
  const anaMarket = ANA_MARKET_CODES.includes(user.aff);
  const locale = storedLocale();
  const market = marketCode();

  const contents = t("personal", { returnObjects: true, defaultValue: defaultContent.personal }) as PerformanceTotal;

  if (isError) return <></>;

  const renderLoader = (children: React.ReactNode) => (
    <LeafRectangleLoader isLoading={isFetching} height="2rem" width="auto">
      {children}
    </LeafRectangleLoader>
  );

  return (
    <div className="monthly-bonus-modal">
      <LeafModal open={openModal} onCloseCallbackFn={onCloseModal}>
        <div className="monthly-bonus-modal_bonus-header">
          <h2>{contents?.title}</h2>
          <p>
            <Trans defaults={contents?.subTitle} />
          </p>
        </div>
        <div className="monthly-bonus-modal_content">
          <div className="monthly-bonus-modal_bonus-details">
            {renderLoader(
              performanceData?.personalBonus?.length ? (
                performanceData?.personalBonus?.map((item: PersonalBonusEntry, index: number) => (
                  <div className="personal-bv" key={index}>
                    <span>{contents?.table?.personalBV}</span>
                    <span>{item.personalBV.formatted}</span>
                  </div>
                ))
              ) : (
                <div className="personal-bv">
                  <span>{contents?.table?.personalBV}</span>
                  <span>{formatAboPV(0, locale, market)}</span>
                </div>
              ),
            )}
            {renderLoader(
              <div className="bonus-percent">
                <span>{contents?.table?.bonusPercent}</span>
                <span>x {performanceData?.bonusPercent?.formatted}</span>
              </div>,
            )}
            {renderLoader(
              performanceData?.personalBonus?.length ? (
                performanceData?.personalBonus?.map(
                  (item: { personalBonusAmount: { formatted: string } }, index: number) => (
                    <div className="current-total" key={index}>
                      <span>{contents?.table?.personalBonusAmount}</span>
                      <span>{item.personalBonusAmount.formatted}</span>
                    </div>
                  ),
                )
              ) : (
                <div className="current-total">
                  <span>{contents?.table?.personalBonusAmount}</span>
                  <span>{formatAboPV(0, locale, market)}</span>
                </div>
              ),
            )}
            {/* can consider api to return structure and value 0 */}
            {renderLoader(
              anaMarket && performanceData?.personalNonBonusable?.length
                ? performanceData?.personalNonBonusable?.map(
                    (item: { personalBV: { formatted: string } }, index: number) => (
                      <div className="non-bonusable-leg" key={index}>
                        <span>{contents?.table?.nonBonusable}</span>
                        <span className="non-bonusable-leg_content">
                          <span>{item.personalBV.formatted}</span>
                          <IconAlert aria-label="Non-bonusable alert" />
                        </span>
                      </div>
                    ),
                  )
                : anaMarket && (
                    <div className="non-bonusable-leg">
                      <span>{contents?.table?.nonBonusable}</span>
                      <span className="non-bonusable-leg_content">
                        <span>{formatAboPV(0, locale, market)}</span>
                        <IconAlert aria-label="Non-bonusable alert" />
                      </span>
                    </div>
                  ),
            )}
            {renderLoader(
              <div className="personal-bonus-total">
                <span className="personal-bonus-total_text">{contents?.table?.total}</span>
                <span className="personal-bonus-total_amount">{performanceTotal?.amount}</span>
              </div>,
            )}
          </div>
        </div>
      </LeafModal>
    </div>
  );
};

export default PersonalBonusModal;
