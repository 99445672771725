export enum BGT_TABS {
  OVERVIEW_TAB = "overview_tab",
  TRACKING_TAB = "tracking_tab",
  GRADUATED_TAB = "graduated_tab",
}

export enum PROFILE_TABS {
  BRONZE_FOUNDATION_TAB = "bronze_foundation_tab",
  BRONZE_BUILDER_TAB = "bronze_builder_tab",
}
