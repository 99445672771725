import { useTranslation } from "react-i18next";
import defaultContent from "../../../../content/leafAboEmail";
import { IconEmail } from "../../../icons/IconEmail/IconEmail";
import "./LeafABOEmail.scss";

type Props = {
  email?: string;
  privacyFlag: boolean;
  applicantTrackOnclick: Function;
};

const LeafABOEmail = (props: Props) => {
  const { t } = useTranslation(["leafAboEmail"]);

  const { email = "", privacyFlag, applicantTrackOnclick } = props;
  const showValidEmail = email && email !== "--" && privacyFlag !== true;
  const displayEmail = showValidEmail ? email : "-";

  const _applicantTrackOnclick = (eventType: string) => {
    applicantTrackOnclick(eventType);
  };

  return (
    <div className="abo-email">
      <div className="abo-email__content">
        <div className="abo-email__label">{t("EmailAddress", defaultContent["EmailAddress"])}</div>
        <div className="abo-email__address">{displayEmail}</div>
      </div>
      {showValidEmail && (
        <a href={`mailto:${email}`} target="_blank" rel="noreferrer" onClick={() => _applicantTrackOnclick("email")}>
          <IconEmail />
        </a>
      )}
    </div>
  );
};

export default LeafABOEmail;
