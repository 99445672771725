import { NavLink } from "react-router-dom";
import { IconCarrot } from "../../../../../common/icons/IconCarrot/IconCarrot";
import "./Card.scss";
import { routeOriginTrack } from "../../../../../common/tealium/Customers/Overview/StatCardsTrack";
import { FilterOption } from "../../../../../reducers/model/filters";

type Props = {
  title: string;
  count: number | string;
  to?: string;
  onCardClick?: Function;
  cardId?: string;
  filterMenu?: FilterOption[];
  showCard?: boolean;
  disableClick?: boolean;
};

const Card = (props: Props) => {
  const {
    title,
    count,
    to = "",
    cardId = "",
    onCardClick = () => {},
    showCard = true,
    filterMenu = [],
    disableClick = false,
  } = props;
  const filterDisabled = filterMenu.some(({ id, enable }) => id === cardId && enable === false);
  const isClickable = disableClick === false && filterDisabled === false;

  const _onCardClick = () => {
    if (isClickable === true) {
      onCardClick(cardId);
      routeOriginTrack(cardId);
    }
  };

  if (showCard === false) {
    return <></>;
  }

  return (
    <NavLink to={isClickable === true ? to : ""}>
      <div className="overviewCard" onClick={_onCardClick}>
        <div className="overviewCard__info">
          <div className="overviewCard__info-title">{title}</div>
          <div className="overviewCard__info-count">{count}</div>
        </div>
        {isClickable === true && (
          <div className="overviewCard__navigation">
            <div className="overviewCard__navigation-arrow">
              <IconCarrot orientation="right" color="black" />
            </div>
          </div>
        )}
      </div>
    </NavLink>
  );
};

export default Card;
