import "react-tooltip/dist/react-tooltip.css";
import "./LeafTooltip.scss";
import { Tooltip } from "react-tooltip";
import { ReactElement, useState } from "react";

type Props = {
  content?: string;
  children?: ReactElement;
  id: string;
  icon: ReactElement;
  openOnClick?: boolean;
};

const LeafTooltip = (props: Props) => {
  const { content, id, icon, children, openOnClick = false } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleTooltipClick = () => {
    if (openOnClick === true) {
      setIsOpen(true);
      setTimeout(() => {
        setIsOpen(false);
      }, 2000);
    }
  };

  return (
    <div className="leaf-tooltip" data-testid={`leafTooltip`} onClick={handleTooltipClick}>
      <div data-tooltip-id={id}>{icon}</div>
      <Tooltip
        id={id}
        content={content}
        children={children}
        className="leaf-tooltip__tooltip"
        disableStyleInjection={"core"}
        openOnClick={openOnClick}
        isOpen={openOnClick ? isOpen : undefined} // Manually  control isOpen state only if OpenOnClick is true
      />
    </div>
  );
};

export default LeafTooltip;
