import LeafTag, { TagColor } from "../LeafTag/LeafTag";
import "./LeafDisplayCard.scss";

type Props = {
  id: string;
  awardLabel: string;
  awardStatus?: string;
  leafTagVariant: TagColor;
};
export const LeafDisplayCard = ({ id, awardLabel, awardStatus = "", leafTagVariant }: Props) => {
  return (
    <div id={id} className="leaf-display-card" data-testid={`leafDisplayCard_${id}`}>
      <div className="leaf-display-card__award-label">{awardLabel}</div>
      {awardStatus.length > 0 ? (
        <LeafTag id={id} variant={leafTagVariant}>
          {awardStatus}
        </LeafTag>
      ) : (
        <></>
      )}
    </div>
  );
};
