import { useTranslation } from "react-i18next";
import LeafIncentiveBannerTile from "../../../common/leaf/LeafIncentiveBannerTile/LeafIncentiveBannerTile";
import defaultContent from "../../../content/visionBonus";
import { QUALIFICATION_STATUS } from "../../../common/enums/incentive-enums";

type Props = {
  isLoading: boolean;
  learnMoreLink?: string;
  status: string;
};

const VisionBonusBanner = ({ isLoading, learnMoreLink, status }: Props) => {
  const { t } = useTranslation(["visionBonus"]);

  const bannerMessages = [
    {
      // eligible and fulfilled FQ or eligible and fulfilled QC
      status: [QUALIFICATION_STATUS.QUALIFIED],
      title: `${t("bannerTile.earned", defaultContent["bannerTile"]["earned"])}`,
      subtitle: `${t("bannerTile.onTrackEarnedSubtitle", defaultContent["bannerTile"]["onTrackEarnedSubtitle"])}`,
    },
    {
      // eligible and on track
      status: [QUALIFICATION_STATUS.ELIGIBLE],
      title: `${t("bannerTile.onTrack", defaultContent["bannerTile"]["onTrack"])}`,
      subtitle: `${t("bannerTile.onTrackEarnedSubtitle", defaultContent["bannerTile"]["onTrackEarnedSubtitle"])}`,
    },
    {
      // not tracking for Vision Bonus
      status: [QUALIFICATION_STATUS.NOT_TRACKING],
      title: `${t("bannerTile.notTracking", defaultContent["bannerTile"]["notTracking"])}`,
      subtitle: `${t("bannerTile.onTrackEarnedSubtitle", defaultContent["bannerTile"]["onTrackEarnedSubtitle"])}`,
    },
  ];

  return (
    <div className="vision-bonus-banner">
      <LeafIncentiveBannerTile
        bannerMessages={bannerMessages}
        incentive={{
          title: "",
          status,
          amount: "",
        }}
        isLoading={isLoading}
        learnMoreLink={learnMoreLink}
        isCompactView={false}
        hideBonusAmount={true}
      />
    </div>
  );
};

export default VisionBonusBanner;
