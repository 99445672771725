import { useTranslation } from "react-i18next";
import defaultContent from "../../../../content/academyBanner";
import "./AcademyBanner.scss";

const AcademyBanner = () => {
  const { t } = useTranslation(["academyBanner"]);
  return (
    <div className="leaf-academy-banner">
      <div className="text-overlay">
        <p className="text-overlay_mark">{t("mark", defaultContent["mark"])}</p>
        <p className="text-overlay_headline">{t("headline", defaultContent["headline"])}</p>
        <p className="text-overlay_sub-headline">{t("subheadline", defaultContent["subheadline"])}</p>
      </div>
    </div>
  );
};

export default AcademyBanner;
