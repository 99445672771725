import React from "react";
import { Link } from "react-router-dom";
import { ROUTE_TO_EXTERNAL } from "../constants/routing-constants";

type LinkUtilProps = {
  to: any;
  id: any;
};

/**
 * Util to be used when a clickable link in dropdowns could be internal or external
 *
 * @param props
 * @returns
 */
export const LinkUtil = (props: React.PropsWithChildren<LinkUtilProps>) => {
  if (ROUTE_TO_EXTERNAL.includes(props.to.label)) {
    return (
      <a id={props.to.label} href={props.to.path} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    );
  }

  return (
    <Link
      to={props.to.path}
      id={props.to.label}
      key={props.id}
      state={{ titleKey: props.to.key || props.to.label }}
      reloadDocument={props.to.reloadDocument}
    >
      {props.children}
    </Link>
  );
};
