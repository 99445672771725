import "./IconStar.scss";
import { ReactComponent as StarSVG } from "./svg/icon_star.svg";

export const IconStar = ({ size = "sm", id = "" }: { size?: "sm" | "md" | "lg"; id?: string }) => {
  return (
    <span id={id} className={`icon-star--${size}`}>
      <StarSVG />
    </span>
  );
};
