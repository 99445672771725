import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { ABO_PROFILE_TABS_ID } from "../../../../../common/enums/profile-enums";
import defaultContent from "../../../../../content/aboProfileTabViews";
import LeafTabViews from "../../../../../common/leaf/LeafTabViews/LeafTabViews";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { setAboActiveTab } from "../../../../../reducers/profile";
import { profileTabClickTrack } from "../../../../../common/tealium/ABOProfile/Profile/ProfileTabViewsTrack/IndexTrack";
import InsightsTab from "./InsightsTab/InsightsTab";
import OverviewTab from "./OverviewTab/OverviewTab";
import DatesTab from "./DatesTab/DatesTab";
import SponsorshipTab from "./SponsorshipTab/SponsorshipTab";

const ABOProfileTabViews = () => {
  const { t } = useTranslation(["aboProfileTabViews"]);

  const { aboActiveTab, aboActiveTabs } = useAppSelector((state) => state.profile);
  const dispatch = useAppDispatch();

  const internalTabsView = [
    {
      id: ABO_PROFILE_TABS_ID.INSIGHTS,
      title: t("insights", defaultContent["insights"]),
      View: <InsightsTab />,
    },
    {
      id: ABO_PROFILE_TABS_ID.OVERVIEW,
      title: t("overview", defaultContent["overview"]),
      View: <OverviewTab />,
    },
    {
      id: ABO_PROFILE_TABS_ID.DATES,
      title: t("dates", defaultContent["dates"]),
      View: <DatesTab />,
    },
    {
      id: ABO_PROFILE_TABS_ID.SPONSORSHIP,
      title: t("sponsorship", defaultContent["sponsorship"]),
      View: <SponsorshipTab />,
    },
  ];

  const tabsView = aboActiveTabs.map((id: ABO_PROFILE_TABS_ID) => ({
    ...internalTabsView.find((item) => item.id === id),
  }));

  const setViewOnClick = (aboActiveTab: ABO_PROFILE_TABS_ID) => {
    dispatch(setAboActiveTab(aboActiveTab));
    profileTabClickTrack(aboActiveTab);
  };

  return (
    <div>
      <LeafTabViews setViewOnClick={setViewOnClick} Views={tabsView} activeView={aboActiveTab} />
    </div>
  );
};

export default ABOProfileTabViews;
