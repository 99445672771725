import { createApi } from "@reduxjs/toolkit/query/react";
import {
  AnnualKeyMetricsResponse,
  AnnualKeyMetricsParams,
  FoundersPlatinumTrackingResponse,
  FoundersPlatinumTrackingRequest,
  AnnualIncentiveTrackingParams,
  AnnualIncentiveTrackingResponse,
  FoundersPlatinumProgressRequest,
  FoundersPlatinumProgressResponse,
} from "../common/interfaces/annualPerformanceDashboard";
import { dynamicBaseQuery } from ".";

export const annualPerformanceDashboardAPI = createApi({
  reducerPath: "annualPerformanceDashboardAPI",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getAnnualKeyMetrics: builder.query<AnnualKeyMetricsResponse, AnnualKeyMetricsParams>({
      query: (params) => {
        const { abo, aff, period, performanceYear, kpiList, locale, market } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          CurrentPeriod: period,
          PerformanceYear: performanceYear,
          KpiList: kpiList.join(","),
          Locale: locale,
          Market: market,
        });

        return `/key-metrics?${queryParams}`;
      },
      extraOptions: { urlBase: "annualDashboard" },
    }),
    getFoundersPlatinumTracking: builder.query<FoundersPlatinumTrackingResponse, FoundersPlatinumTrackingRequest>({
      query: (params) => {
        const { abo, aff, period } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Period: period,
        });

        return `/founders-platinum/tracking?${queryParams}`;
      },
      extraOptions: { urlBase: "annualDashboard" },
    }),
    getFoundersPlatinumProgress: builder.query<FoundersPlatinumProgressResponse, FoundersPlatinumProgressRequest>({
      query: (params) => {
        const {
          abo,
          aff,
          period,
          isoCountryCode,
          gpvMethodMonthsRequired,
          vepvMethodMonthsRequired,
          spMethodMonthsRequired,
        } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Period: period,
          IsoCountryCode: isoCountryCode,
          GpvMethodMonthsRequired: gpvMethodMonthsRequired.toString(),
          VepvMethodMonthsRequired: vepvMethodMonthsRequired.toString(),
          SpMethodMonthsRequired: spMethodMonthsRequired.toString(),
        });

        return `/founders-platinum/progress?${queryParams}`;
      },
      extraOptions: { urlBase: "annualDashboard" },
    }),
    getBonusBreakdown: builder.query({
      query: (params) => {
        const { abo, aff, locale, period, isoCountryCode, isoCurrencyCode, persona, eligibleIncentives } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
          IsoCountryCode: isoCountryCode,
          IsoCurrencyCode: isoCurrencyCode,
          Persona: persona,
          EligibleIncentives: eligibleIncentives.join(","),
        });

        return `/bonus-breakdown?${queryParams}`;
      },
      extraOptions: { urlBase: "annualDashboard" },
    }),
    getAnnualIncentiveTracking: builder.query<AnnualIncentiveTrackingResponse, AnnualIncentiveTrackingParams>({
      query: (params) => {
        const { abo, aff, period, eligibleIncentives, locale, isoCountryCode, isoCurrencyCode } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
          IsoCountryCode: isoCountryCode,
          IsoCurrencyCode: isoCurrencyCode,
          EligibleIncentives: eligibleIncentives.join(","),
        });

        return `/annual-incentive-tracking?${queryParams}`;
      },
      extraOptions: { urlBase: "annualDashboard" },
    }),
  }),
});

export const {
  useGetAnnualKeyMetricsQuery,
  useGetFoundersPlatinumTrackingQuery,
  useGetFoundersPlatinumProgressQuery,
  useGetBonusBreakdownQuery,
  useGetAnnualIncentiveTrackingQuery,
} = annualPerformanceDashboardAPI;
