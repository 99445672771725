import "./Cards.scss";
import { useTranslation } from "react-i18next";
import { storedLocale, formattedPV } from "../../../impersonation/util";
import { CUSTOMER_BASE_PATH, CUSTOMER_LIST, CUSTOMER_ORDER } from "../../../../routing/CustomersRouting";
import { updateSelectedFilter as updateOrderFilterOptions, ORDER_FILTER_IDS } from "../../../../reducers/order/filter";
import {
  updateSelectedFilter as updateCustomerFilterOptions,
  resetFilter as resetCustomerFilter,
  CUSTOMER_FILTER_IDS,
} from "../../../../reducers/customer/filter";
import { useAppSelector, useAppDispatch } from "../../../../store";
import CustomerPVStatistic from "../Statistic/CustomerPVStatistic/CustomerPVStatistic";
import CardsLoader from "../Loader/CardsLoader/CardsLoader";
import defaultContent from "../../../../content/cardsOverview";
import { getLoginAffAbo } from "../../../../reducers/model/boot";
import { useGetOverViewCardsDetailsQuery } from "../../../../services/overviewCardsAPI";
import { LeafErrorMessage } from "../../../../common/leaf/LeafErrorMessage/LeafErrorMessage";
import { overviewPageErrorTrack } from "../../../../common/tealium/Customers/Overview/IndexTrack";
import { isProgramActive } from "../../../../common/util/checkProgramActiveStatus";
import { AWARD_CODES } from "../../../../common/enums/award-codes";
import { useGetCustomerListQuery } from "../../../../services/customerListAPI";
import { includeGuestCustomerBN } from "../../../../reducers/model/customerList";
import { useGetOrderListQuery } from "../../../../services/customerOrderListAPI";
import { getFullMonth } from "../../../../common/util/period";
import Card from "./Card/Card";

const Cards = () => {
  const { t } = useTranslation(["cardsOverview"]);
  const dispatch = useAppDispatch();

  const locale = storedLocale();

  const { selectedPeriod } = useAppSelector(({ period }) => period);
  const selectedPeriodMonth = getFullMonth(selectedPeriod, locale);
  const boot = useAppSelector(({ boot }) => boot);
  const affAbo = getLoginAffAbo({ boot });

  const enableGuestCustomer = boot?.configuration?.globalCML?.enableGuestCustomer;
  const showVerifiedCustomerPV = boot?.configuration?.globalCML?.overview?.showVerifiedCustomerPV;
  const eligibleIncentives = useAppSelector(({ boot }) => boot?.shellConfig?.eligibleIncentives);

  const showCard = isProgramActive(eligibleIncentives, AWARD_CODES.CSI, showVerifiedCustomerPV);
  const cardContainerType = showCard === true ? "container3cols" : "container2cols";

  const businessNature = includeGuestCustomerBN(enableGuestCustomer);

  const {
    data: gqlData,
    isError: gqlError,
    isFetching: gqlFetching,
  } = useGetOverViewCardsDetailsQuery({ affAbo, period: selectedPeriod });

  const {
    data: custData,
    isError: custError,
    isFetching: custFetching,
  } = useGetCustomerListQuery({
    affAbo,
    period: selectedPeriod,
    pageSize: 1,
    pageNum: 1,
    sortValue: "LAST_ORDER_DATE",
    sortOrder: "ASC",
    ...businessNature,
  });

  const {
    data: orderData,
    isError: orderError,
    isFetching: orderFetching,
  } = useGetOrderListQuery({
    affAbo,
    period: selectedPeriod,
    pageSize: 1,
    pageNum: 1,
    sortValue: "ORDER_DATE",
    sortOrder: "ASC",
    filterOrderBySourceType: "003",
    ...businessNature,
  });

  const isError = gqlError || custError || orderError;
  const isFetching = gqlFetching || custFetching || orderFetching;

  const { customerPV = 0, vcsPV = 0, nonVCSPV = 0 } = gqlData || {};
  const { newCustomerCount = 0, totalCustomerCount = 0 } = custData || {};
  const { totalOrderCount = 0 } = orderData || {};

  const { filterMenu: orderFilterMenu } = useAppSelector(({ orderFilter }) => orderFilter);
  const { filterMenu: customerFilterMenu } = useAppSelector(({ customerFilter }) => customerFilter);

  const updateCustomerFilter = (id: string) => {
    dispatch(updateCustomerFilterOptions(id));
  };

  const updateOrderFilter = (id: string) => {
    dispatch(updateOrderFilterOptions(id));
  };

  const resetCustomerFilterOverride = () => {
    dispatch(resetCustomerFilter());
  };

  if (isError === true) {
    overviewPageErrorTrack(selectedPeriod);
  }

  const CardsView = () => {
    return (
      <div className="overviewCards">
        <div className="overviewCards__header">
          <div className="overviewCards__header__heading">
            {t("title", defaultContent["title"], { month: selectedPeriodMonth })}
          </div>
          <div className="overviewCards__header__subheading">
            <CustomerPVStatistic />
          </div>
        </div>
        <LeafErrorMessage show={isError} />
        {isError === false && (
          <div className={`overviewCards__${cardContainerType}`}>
            <Card
              title={t("newCust", defaultContent["newCust"])}
              count={newCustomerCount}
              to={`${CUSTOMER_BASE_PATH}${CUSTOMER_LIST}`}
              cardId={CUSTOMER_FILTER_IDS.NEW_CUSTOMER}
              onCardClick={updateCustomerFilter}
            />

            <Card
              title={t("totalCustomers", defaultContent["totalCustomers"])}
              count={totalCustomerCount}
              to={`${CUSTOMER_BASE_PATH}${CUSTOMER_LIST}`}
              cardId="totalCustomers"
              onCardClick={resetCustomerFilterOverride}
            />

            <Card
              title={t("totalOrders", defaultContent["totalOrders"])}
              count={totalOrderCount}
              to={`${CUSTOMER_BASE_PATH}${CUSTOMER_ORDER}`}
              cardId={ORDER_FILTER_IDS.ORDER}
              onCardClick={updateOrderFilter}
              filterMenu={orderFilterMenu}
            />
            <Card
              title={t("vcsPV", defaultContent["vcsPV"])}
              count={formattedPV(vcsPV)}
              to={`${CUSTOMER_BASE_PATH}${CUSTOMER_LIST}`}
              showCard={showCard}
              cardId={CUSTOMER_FILTER_IDS.VERIFIED}
              onCardClick={updateCustomerFilter}
              filterMenu={customerFilterMenu}
              disableClick={true}
            />
            <Card
              title={t("nonVcsPv", defaultContent["nonVcsPv"])}
              count={formattedPV(nonVCSPV)}
              to={`${CUSTOMER_BASE_PATH}${CUSTOMER_LIST}`}
              showCard={showCard}
              cardId={CUSTOMER_FILTER_IDS.NO_VERIFIED}
              onCardClick={updateCustomerFilter}
              filterMenu={customerFilterMenu}
              disableClick={true}
            />
            <Card
              title={t("customerPV", defaultContent["customerPV"])}
              count={formattedPV(customerPV)}
              to={`${CUSTOMER_BASE_PATH}${CUSTOMER_LIST}`}
              cardId="customerPV"
              onCardClick={resetCustomerFilterOverride}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <CardsLoader
      isLoading={isFetching}
      View={CardsView}
      loaders={showCard === true ? 6 : 4}
      theme={cardContainerType}
    />
  );
};

export default Cards;
