import LeafCheckBox from "../../../LeafCheckBox/LeafCheckBox";

type Item = {
  text: string;
  checked: boolean;
  enable: boolean;
};

type Props = {
  itemOnClick: Function;
  item: Item;
};

const LeafFilterByCheckBox = (props: Props) => {
  const { itemOnClick, item } = props;

  const handleChange = () => {
    itemOnClick(item);
  };

  return item.enable === true ? (
    <LeafCheckBox
      id={`leaf-checkbox__${item.text}`}
      key={`leaf-checkbox__${item.text}`}
      label={item.text}
      checked={item.checked}
      onChange={handleChange}
    />
  ) : (
    <></>
  );
};

export default LeafFilterByCheckBox;
