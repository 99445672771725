import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TabSwitcher from "../../common/components/TabSwitcher/TabSwitcher";
import annualPGGContent from "../../content/annualPGG";
import monthlyPGGContent from "../../content/monthlyPGG";

const PGGTabSwitcher = () => {
  const { t } = useTranslation(["monthlyPGG", "annualPGG"]);
  const navigate = useNavigate();
  const location = useLocation();

  const PGG_TABS = {
    MONTHLY: "monthly",
    ANNUAL: "annual",
  };

  const [selectedTab, setSelectedTab] = useState(PGG_TABS.ANNUAL);

  const handleTabClick = (tabId: string) => {
    if (tabId === PGG_TABS.MONTHLY) {
      navigate("/personal-growth-monthly");
    } else {
      navigate("/personal-growth");
    }
    setSelectedTab(tabId);
  };

  useEffect(() => {
    if (location.pathname === "/personal-growth-monthly") {
      setSelectedTab(PGG_TABS.MONTHLY);
    } else if (location.pathname === "/personal-group-growth") {
      setSelectedTab(PGG_TABS.ANNUAL);
    }
  }, [location.pathname]);

  const monthlyPGG = t("monthlyPGGI", monthlyPGGContent["monthlyPGGI"], { ns: "monthlyPGG" });
  const annualPGG = t("annualPGGI", annualPGGContent["annualPGGI"], { ns: "annualPGG" });

  return (
    <TabSwitcher
      Tabs={[
        {
          id: PGG_TABS.MONTHLY,
          text: monthlyPGG,
        },
        {
          id: PGG_TABS.ANNUAL,
          text: annualPGG,
        },
      ]}
      onTabClick={handleTabClick}
      selectedTab={selectedTab}
    />
  );
};

export default PGGTabSwitcher;
