import "./ActionReports.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IconCarrot } from "../../common/icons/IconCarrot/IconCarrot";
import defaultContent from "../../content/actionReports";
import { PageTitleSection } from "../../common/components/PageTitleSection/PageTitleSection";
import { useActionReportMenuOptions } from "./hooks/useActionReportMenuOptions";

const ActionReportLinkItem = ({ reportId, reportUrl }: { reportId: string; reportUrl: string }) => {
  const { t } = useTranslation(["actionReports"]);
  const reportTitle = t(`${reportId}Title`, defaultContent[`${reportId}Title`]);
  const reportDescription = t(`${reportId}Description`, defaultContent[`${reportId}Description`]);

  const viewReportBtn = t("viewReport", defaultContent["viewReport"]);

  return (
    <li className="actionReportsItem" id={reportId} key={reportId} data-testid="actionReportsItem">
      <Link to={reportUrl} className="actionReportsItem__link" reloadDocument>
        <div className="actionReportsItem__header">
          <span className="actionReportsItem__title">{`${reportTitle}`}</span>
          <span className="actionReportsItem__button">
            {`${viewReportBtn}`}
            <IconCarrot orientation="right" color="black" />
          </span>
        </div>
        <p className="actionReportsItem__description">{`${reportDescription}`}</p>
      </Link>
    </li>
  );
};

export default function ActionReports() {
  const { actionReportMenuOptions } = useActionReportMenuOptions();
  const { t } = useTranslation(["actionReports"]);
  return (
    <main className="actionReports">
      <PageTitleSection title={`${t("pageTitle", defaultContent["pageTitle"])}`} hideTimeStamp />
      <ul className="actionReports__report-list">
        {actionReportMenuOptions.map(({ id, url }) => (
          <ActionReportLinkItem key={id} reportId={id} reportUrl={url} />
        ))}
      </ul>
    </main>
  );
}
