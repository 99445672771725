const content: { [key: string]: string } = {
  keepGoing: "Keep going to earn the Bronze Foundation Incentive+!",
  notEligible: "You were not eligible for the Bronze Foundation Incentive+ in {dateText}",
  greatJob: "Great Job! You are earning the Bronze Foundation Incentive+.",
  manual: "The Bronze Foundation Incentive+ has been manually approved",
  onHold: "Your award is on hold.",
  moreInfo: "If you require more information, please contact Customer Support for further details.",
  incentiveLabel: "Bronze Foundation Incentive+",
};

export default content;
