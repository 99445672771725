import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { EVENTS } from "../../../common/enums/events";
export function withActionReportSelectorRouting(ActionReportComponent: JSX.Element) {
  // listen for action report selector change and navigate to selected report
  const ActionReportSelectorRouter = () => {
    const navigate = useNavigate();

    const handleActionReportChange = useCallback(
      (event: CustomEventInit) => {
        navigate(event.detail.url);
      },
      [navigate],
    );

    useEffect(() => {
      document.addEventListener(EVENTS.EVENT_LISTENER.ACC_REPORT_CHANGE, handleActionReportChange);
      return () => {
        document.removeEventListener(EVENTS.EVENT_LISTENER.ACC_REPORT_CHANGE, handleActionReportChange);
      };
    }, [handleActionReportChange]);

    return ActionReportComponent;
  };
  return <ActionReportSelectorRouter />;
}
