import { AWARD_CODES } from "../../common/enums/award-codes";
import { DROPDOWN_LABELS, DROPDOWN_KEYS, ROUTE_LABELS, ROUTE_PATHS } from "../../common/enums/routing-enums";
import { NavData, NavDropdown } from "../../common/interfaces/routing";
import EarningsCalculator from "../../components/EarningsCalculator/EarningsCalulator";
import MBI from "../../components/MBI/MBI";
import PerformanceDashboard from "../../components/PerformanceDashboard/PerformanceDashboard";
import { filterDropdown } from "./createMenuConfig";

export const PERFORMANCE_COMPONENT_MAP = {
  [ROUTE_PATHS.PERFORMANCE_DASHBOARD]: <PerformanceDashboard />,
  [ROUTE_PATHS.EARNINGS_CALCULATOR]: <EarningsCalculator />,
  [ROUTE_PATHS.MBI]: <MBI />,
};
export const createPerformanceDropdown = (navConfigs: NavData[]): NavDropdown => {
  const performanceDropdown = {
    label: DROPDOWN_LABELS.PERFORMANCE,
    key: DROPDOWN_KEYS.PERFORMANCE,
    items: [
      {
        label: ROUTE_LABELS.PERFORMANCE_DASHBOARD,
        path: ROUTE_PATHS.PERFORMANCE_DASHBOARD,
        class: ROUTE_PATHS.PERFORMANCE_DASHBOARD,
        code: AWARD_CODES.MONTHLY_PERFORMANCE,
        navPosition: 0,
      },
      {
        label: ROUTE_LABELS.EARNINGS_CALCULATOR,
        path: ROUTE_PATHS.EARNINGS_CALCULATOR,
        class: ROUTE_PATHS.EARNINGS_CALCULATOR,
        code: AWARD_CODES.EARNINGS_CALCULATOR,
        navPosition: 3,
      },
      {
        label: ROUTE_LABELS.MBI,
        path: ROUTE_PATHS.MBI,
        class: ROUTE_PATHS.MBI,
        code: AWARD_CODES.MBI,
        navPosition: 4,
      },
    ],
  };

  return filterDropdown(navConfigs, performanceDropdown);
};
