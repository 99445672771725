import "./App.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";
import { MobileHeader } from "./components/MobileHeader/MobileHeader";
import { Footer } from "./components/Footer/Footer";
import { DesktopHeader } from "./components/DesktopHeader/DesktopHeader";
import { authRoutes, AUTH_ROUTE_PATHS, ROUTES_WITH_NO_TEALIUM_TRACKING } from "./routing/AuthRouting";
import { languageSelectorEffect, i18n } from "./i18n.config";
import { EVENTS } from "./common/enums/events";
import { NavDropdownMin, NavDropdownItemMin } from "./common/interfaces/routing";
import { store, useAppDispatch, useAppSelector } from "./store";
import { updatePeriod } from "./reducers/period";
import defaultContent from "./content/common";
import { errorRoutes, ERROR_ROUTE_SUBPATHS, getFullRoutePath } from "./routing/ErrorRouting";
import { verifyLogin } from "./common/util/verifyLogin";
import { ROUTE_PATHS } from "./common/enums/routing-enums";
import { getBrowserSupport, deviceInfo } from "./common/util/browserSupport";
import { GrayListBanner } from "./components/GrayListBanner/GrayListBanner";
import { BROWSER_SUPPORT } from "./common/enums/browser-enums";
import { ComponentLoader } from "./components/ComponentLoader/ComponentLoader";
import { loadSiteTools } from "./common/util/siteTools";
import { SetBootForCurrentBusiness } from "./reducers/model/boot";
import { BusinessSelectorToast } from "./components/BusinessSelector/BusinessSelectorToast/BusinessSelectorToast";
import { INNER_ROUTE_SHOW_PERIOD_SELECTOR, ROUTES_WITHOUT_PERIOD_SELECTOR } from "./common/constants/routing-constants";
import { getDisplayMode } from "./components/impersonation/token";
import { setLocalStorageItem } from "./reducers/loginLocalStorage";
import { awsRum } from "./common/util/awsRum";
import useWindowSize from "./common/hooks/useWindowSize";
import breakpoints from "./common/scss/breakpoints.module.scss";
import { shouldPolyfillLocale } from "./common/util/polyfillLocale";
import BannerHeader from "./components/BannerHeader/BannerHeader";
import { useHandleCookie } from "./common/util/cookies";
import { getCurrentPeriod } from "./common/util/period";

const isUrlIncludesRoutes = (routes: NavDropdownMin[]) => {
  return (
    routes.filter((NavDropdown: NavDropdownMin) => {
      return NavDropdown.items.find((item: NavDropdownItemMin) => window.location.href.includes(item.path));
    }).length > 0
  );
};

const isComponentRoute = !isUrlIncludesRoutes([...authRoutes, ...errorRoutes]);

const SiteHeader = ({ displayPeriodSelector }: { displayPeriodSelector: boolean }) => {
  const { width } = useWindowSize();
  if (isUrlIncludesRoutes([...authRoutes, ...errorRoutes])) {
    return <></>;
  }
  return (
    <>
      <BusinessSelectorToast />
      <span className="App__header">
        {Number(width) >= Number(breakpoints.desktop) ? (
          <DesktopHeader displayPeriodSelector={displayPeriodSelector} />
        ) : (
          <MobileHeader displayPeriodSelector={displayPeriodSelector} />
        )}
        <div className="App__banner">
          <BannerHeader />
        </div>
      </span>
    </>
  );
};

const loadLocaleFromLocalStorage = () => {
  let currentLocale = store.getState().loginLocalStorage.loginFields.locale;
  if (currentLocale === "" || !currentLocale) {
    currentLocale = "en-US";
  }
  return currentLocale;
};

const SiteFooter = () => {
  const { footless } = useAppSelector((state) => state.loginLocalStorage);

  return !isUrlIncludesRoutes(authRoutes) && footless === false ? (
    <div className="App__footer">
      <Footer />
    </div>
  ) : (
    <></>
  );
};

const App = () => {
  const currentLocale = loadLocaleFromLocalStorage();
  const boot = useAppSelector(({ boot }) => boot);
  const aboName = useAppSelector((state) => state.aboName);
  const { handleCookieLogic } = useHandleCookie(boot, currentLocale, aboName);
  const { user } = boot;
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common"]);
  const [locale, setLocale] = useState(currentLocale);
  const [displayPeriodSelector, setPeriodSelectorDisplay] = useState(true);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const [browserStatus, setBrowserStatus] = useState<BROWSER_SUPPORT>();

  document.title = `${t("newSiteTitle", defaultContent["newSiteTitle"])}`;

  const location = useLocation();

  // Do not start tealium tracking until after authentication with new user is complete.
  const isNewAboLogin = !ROUTES_WITH_NO_TEALIUM_TRACKING.includes(pathname) && boot.user.abo;

  const [isLocaleLoaded, setIsLocaleLoaded] = useState(false);
  // We need to wait for locale to load if an unsupported locale is used.
  useEffect(() => {
    const loadLocaleData = async () => {
      try {
        await shouldPolyfillLocale(currentLocale);
        setIsLocaleLoaded(true);
      } catch (error) {
        console.error("Error loading locale data:", error);
      }
    };
    loadLocaleData();
  }, []);

  useEffect(() => {
    if (isNewAboLogin) {
      handleCookieLogic();
    }
  }, []);

  useEffect(() => {
    if (awsRum) {
      awsRum.recordPageView(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    languageSelectorEffect(locale, setLocale);
    if (user.access_token && user.abo) {
      SetBootForCurrentBusiness({ user });
    }
    const _browserStatus = getBrowserSupport();
    setBrowserStatus(_browserStatus);
    if (_browserStatus === BROWSER_SUPPORT.UNSUPPORTED) {
      navigate(getFullRoutePath(ERROR_ROUTE_SUBPATHS.UNSUPPORTED_BROWSER));
    }
    console.info("device", deviceInfo);
    if (isNewAboLogin) {
      loadSiteTools(boot);
    }
    //eslint-disable-next-line
  }, []);

  const { removePeriodSelectorFromRoute = [], maintenanceConfig } = useAppSelector((state) => state.boot.configuration);

  // when we navigate to a new page check if we should display the period selector
  useEffect(() => {
    const setPeriodSelector = () => {
      const routes = [...ROUTES_WITHOUT_PERIOD_SELECTOR, ...removePeriodSelectorFromRoute];
      return setPeriodSelectorDisplay(!routes.includes(pathname));
    };
    const setPeriodSelectorOnInnerPath = () => {
      const eventBasedPathCheck = (event: CustomEventInit) => {
        const innerPathname = event?.detail?.pathname;

        if (INNER_ROUTE_SHOW_PERIOD_SELECTOR.includes(innerPathname)) {
          if (innerPathname === ROUTE_PATHS.EDLOS_DOWNLINE) {
            dispatch(updatePeriod({ period: getCurrentPeriod() }));
          }
          return setPeriodSelectorDisplay(!removePeriodSelectorFromRoute.includes(innerPathname));
        }

        // Remove the period selector when on the inner route for the HAT QC Details page.
        if ([ROUTE_PATHS.HAT_QC_DETAILS, ROUTE_PATHS.HAT_ABO_TRACKING].includes(innerPathname)) {
          return setPeriodSelectorDisplay(removePeriodSelectorFromRoute.includes(innerPathname));
        }

        return setPeriodSelector();
      };

      document.addEventListener(EVENTS.EVENT_LISTENER.ACC_ES_ROUTER_GO, eventBasedPathCheck);
      return () => {
        document.removeEventListener(EVENTS.EVENT_LISTENER.ACC_ES_ROUTER_GO, eventBasedPathCheck);
      };
    };
    // hide period selector if we are on a page that does not have a period selector
    setPeriodSelector();
    // show/hide period selector based on event for inner path defined by imported component
    setPeriodSelectorOnInnerPath();
  }, [pathname, removePeriodSelectorFromRoute, dispatch]);

  const checkMaintenance = () => {
    const { impersonating } = store.getState().loginLocalStorage.loginFields;
    const getKeyByValue = (value: string): string | undefined => {
      const keys = Object.keys(ROUTE_PATHS) as Array<keyof typeof ROUTE_PATHS>;
      for (const key of keys) {
        if (ROUTE_PATHS[key] === value) {
          return key.toLowerCase();
        }
      }
      return undefined;
    };

    if (
      maintenanceConfig &&
      (maintenanceConfig[getKeyByValue(pathname)!] || maintenanceConfig.appMaintenance) &&
      !impersonating
    ) {
      navigate(AUTH_ROUTE_PATHS.MAINTENANCE);
    }
  };

  // check if our access_token is active. If access_token has expired, navigate to session expired page
  useEffect(() => {
    checkMaintenance();
    if (!isUrlIncludesRoutes([...errorRoutes, ...authRoutes])) {
      if (user && user.access_token) verifyLogin(user);
    }
  }, [pathname, selectedPeriod, user]);

  // set the display mode based on the query parameters
  useEffect(() => {
    dispatch(setLocalStorageItem(getDisplayMode()));
  }, [dispatch]);

  return isLocaleLoaded ? (
    <div className="App" id="App">
      <SiteHeader displayPeriodSelector={displayPeriodSelector} />
      <div id="App__component-section" className="App__component-section">
        <GrayListBanner show={browserStatus === BROWSER_SUPPORT.GRAYLIST} />
        <I18nextProvider i18n={i18n}>
          <Suspense>
            <ComponentLoader
              isComponentRoute={isComponentRoute}
              key={isComponentRoute ? user.abo : "outlet"}
              period={selectedPeriod}
              route={pathname}
            >
              <Outlet />
            </ComponentLoader>
          </Suspense>
        </I18nextProvider>
        <SiteFooter />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default App;
