import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/leafGenderInfo";
import "./LeafGenderInfo.scss";

type Props = {
  gender: string;
  privacyFlag: boolean;
  show?: boolean;
};

const LeafGenderInfo = (props: Props) => {
  const { t } = useTranslation(["leafGenderInfo"]);

  const { gender, privacyFlag, show } = props;

  if (show === true) {
    return (
      <div className="leaf-gender">
        <div className="leaf-gender__container">
          <div className="leaf-gender__content-title">{t("Gender", defaultContent["Gender"])}</div>
          <div className="leaf-gender__content-data">
            {privacyFlag === true ? "-" : t(`${gender}`, defaultContent[gender])}
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default LeafGenderInfo;
