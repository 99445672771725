export enum PIN_LEVEL_CODE {
  NO_PIN_LEVEL = -1,
  PERCENT_3 = 203,
  PERCENT_6 = 206,
  PERCENT_9 = 209,
  PERCENT_12 = 212,
  PERCENT_15 = 215,
  PERCENT_18 = 218,
  PERCENT_21 = 221,
  PERCENT_23 = 223,
  SILVER_SPONSOR = 305,
  SILVER_PRODUCER = 310,
  GOLD_PRODUCER = 320,
  PLATINUM = 330,
  RUBY = 340,
  FOUNDERS_PLATINUM = 342,
  FOUNDERS_RUBY = 345,
  PEARL = 350,
  SAPPHIRE = 365,
  FOUNDERS_SAPPHIRE = 367,
  EMERALD = 370,
  FOUNDERS_EMERALD = 375,
  DIAMOND = 380,
  DIAMOND_PLUS = 381,
  FOUNDERS_DIAMOND = 385,
  EXECUTIVE_DIAMOND = 390,
  GAR_EXECUTIVE_DIAMOND = 393,
  FOUNDERS_EXECUTIVE_DIAMOND = 395,
  GAR_FOUNDERS_EXECUTIVE_DIAMOND = 398,
  DOUBLE_DIAMOND = 400,
  GAR_DOUBLE_DIAMOND = 403,
  FOUNDERS_DOUBLE_DIAMOND = 405,
  GAR_FOUNDERS_DOUBLE_DIAMOND = 408,
  TRIPLE_DIAMOND = 410,
  GAR_TRIPLE_DIAMOND = 413,
  FOUNDERS_TRIPLE_DIAMOND = 415,
  GAR_FOUNDERS_TRIPLE_DIAMOND = 418,
  CROWN = 420,
  GAR_CROWN = 423,
  FOUNDERS_CROWN = 425,
  GAR_FOUNDERS_CROWN = 428,
  CROWN_AMBASSADOR = 430,
  GAR_CROWN_AMBASSADOR = 433,
  FOUNDERS_CROWN_AMBASSADOR = 435,
  GAR_FOUNDERS_CROWN_AMBASSADOR = 438,
  FCA_40 = 440,
  FCA_45 = 445,
  FCA_50 = 450,
  FCA_55 = 455,
  FCA_60 = 460,
  FCA_65 = 465,
  FCA_70 = 470,
}
