import "./MobileHeader.scss";
import { PeriodSelector } from "../PeriodSelector/PeriodSelector";
import { useAppSelector } from "../../store";
import { getCountryFlag } from "../../common/util/getCountryFlag";
import { HamburgerMenu } from "./HamburgerMenu/HamburgerMenu";

type Props = {
  displayPeriodSelector?: boolean;
};

export const MobileHeader = ({ displayPeriodSelector }: Props) => {
  const { user } = useAppSelector((state) => state.boot);
  const aboName = useAppSelector((state) => state.aboName.name);
  const { headless } = useAppSelector((state) => state.loginLocalStorage);
  const { abo: aboNum, isoCountryCode } = user;

  return (
    <header id="mobile-header" className={`mobile-header ${headless === true ? "mobile-header--headless" : ""}`}>
      {headless === false && (
        <>
          <div className="mobile-header__hamburger">
            <HamburgerMenu />
          </div>
          <div id="mobile-header__heading" className="mobile-header__heading">
            <span id="busSelector" className="mobile-header__aboInfo">
              <h2>{aboName}</h2>
              <span className={"busSelector__subheading--no-pointer"}>
                {getCountryFlag(isoCountryCode)}
                <span>{isoCountryCode}</span>
                <span>#{aboNum}</span>
              </span>
            </span>
          </div>
        </>
      )}

      <div
        id="mobile-header__period-selector"
        className={`mobile-header__period-selector${headless === true ? "--headless" : ""}`}
      >
        {displayPeriodSelector && <PeriodSelector isMobile={true} />}
      </div>
    </header>
  );
};
