import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProgramStatusResponse } from "./model/bronze/programStatus";

type BronzeIncentive = {
  showEligibilitySliderFlag: boolean;
  awardCode: number;
  programActive: boolean;
  isEligible: boolean;
  isRestartProgram: boolean;
  isFixedPayment: boolean;
  fixedPaymentAwardCode: number;
};

const initialState: BronzeIncentive = {
  showEligibilitySliderFlag: false,
  awardCode: 0,
  programActive: false,
  isEligible: false,
  isRestartProgram: false,
  isFixedPayment: false,
  fixedPaymentAwardCode: 0,
};

const { actions, reducer } = createSlice({
  name: "bronzeIncentive",
  initialState,
  reducers: {
    showEligibilitySlider: (state) => ({
      ...state,
      showEligibilitySliderFlag: true,
      awardCode: state.awardCode,
    }),
    hideEligibilitySlider: (state) => ({
      ...state,
      showEligibilitySliderFlag: false,
    }),
    updateProgramStatusDetails: (state, action: PayloadAction<ProgramStatusResponse>) => ({
      ...state,
      ...action.payload,
    }),
    resetProgramStatusDetails: () => initialState,
  },
});

export const { showEligibilitySlider, hideEligibilitySlider, updateProgramStatusDetails, resetProgramStatusDetails } =
  actions;

export default reducer;
