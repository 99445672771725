import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconFlag } from "../../../common/icons/IconFlag/IconFlag";
import LeafToastMessage from "../../../common/leaf/LeafToastMessage/LeafToastMessage";
import { useAppSelector } from "../../../store";
import "./BusinessSelectorToast.scss";
import defaultContent from "../../../content/businessSelector";

export const BusinessSelectorToast = () => {
  const { t } = useTranslation(["businessSelector"]);
  const toastMessage = t("toastMessage", defaultContent["toastMessage"]).toString();
  const { user, isBootLoading } = useAppSelector((state) => state.boot);
  const [currentAboNum, setCurrentAboNum] = useState<string>(user.abo);

  const renderBusinessSelectorToastMessage = useMemo(() => {
    if (currentAboNum !== user.abo && isBootLoading === false) {
      setCurrentAboNum(user.abo);

      return (
        <LeafToastMessage
          position={"top-center"}
          autoClose={4000}
          showToast
          hideProgressBar
          closeOnClick={false}
          pauseOnHover={false}
          draggable={false}
          htmlContent={
            <div className="business-selector-toast">
              <IconFlag />
              <span>{toastMessage}</span>
            </div>
          }
        />
      );
    }
    return <></>;
    // eslint-disable-next-line
  }, [user.abo, isBootLoading, toastMessage]);

  return renderBusinessSelectorToastMessage;
};
