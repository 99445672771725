const content: { [key: string]: any } = {
  title: "Track your progress",
  inProgressLabelStart: "You’ve met ",
  inProgressLabelMiddle: "{x} of {y} ",
  inProgressLabelEnd: "requirements to earn this incentive.",
  inProgressLabelEndAdditionalLeg:
    "requirements to earn this incentive and added an additional leg with {volumeRequirement} {volumeType}.",
  inProgressLabelEndAdditionalLegs:
    "requirements to earn this incentive and added an additional 2 legs with {volumeRequirement} {volumeType}.",
  metlabel: "You have maximized the amount of incentive you can reach.",
  requirementLabelStart: "Once all requirements are met, there is opportunity to ",
  requirementLabelMiddle: "level up ",
  requirementLabelEnd: "your incentive by adding an additional leg.",
  requirementLabelStartAdditionalLeg: "There is an opportunity to increase your incentive to ",
  requirementLabelMiddleAdditionalLeg: "{bonus}",
  requirementLabelEndAdditionalLeg: " by adding one additional leg with {volumeRequirement} {volumeType}.",
};

export default content;
