import Bowser from "bowser";
import { BROWSER_SUPPORT } from "../enums/browser-enums";
import { store } from "../../store";
import { setLocalStorageItem } from "../../reducers/loginLocalStorage";

const browser = Bowser.getParser(window.navigator.userAgent);

//supported browsers with versions
const isValidBrowser = browser.satisfies({
  chrome: ">=78",
  safari: ">=14",
  edge: ">=80",
  firefox: ">=78",
  samsung_internet: ">=12",
  yandex: ">=1.0.0",
  tablet: {
    safari: ">=14",
  },
  mobile: {
    safari: ">=14",
  },
});

//unsupported browsers with versions
const isNotValidBrowser = browser.satisfies({
  chrome: "<78",
  safari: "<14",
  edge: "<80",
  firefox: "<78",
  samsung_internet: "<12",
  yandex: "<1.0.0",
  ie: ">1",
  uc: ">0",
  mobile: {
    opera: ">0",
  },
});

const os = browser.getOS();
const osName = browser.getOSName(true);
const browserVersion = browser.getBrowserVersion();
const engine = browser.getEngineName().toLowerCase();

// only need to check the major OS version
const osVersion = Number(os.version?.split(".")[0]);

// support for creators app - the embedded WKWebView UA does not have a browser version
// "Mozilla/5.0 (iPhone; CPU iPhone OS 16_0_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko)"
const isWKWebView = osVersion >= 15 && osName === "ios" && engine === "webkit" && !browserVersion;

const platform = browser.getPlatformType(true);
const isMobile = platform === "mobile" || platform === "tablet";
const deviceType = isMobile && (osName === "ios" || osName === "android") ? osName : "desktop";

store.dispatch(setLocalStorageItem({ deviceType: deviceType }));

export const getBrowserSupport = () => {
  if (isValidBrowser || isWKWebView) {
    return BROWSER_SUPPORT.FULL_SUPPORT;
  } else if (isNotValidBrowser) {
    return BROWSER_SUPPORT.UNSUPPORTED;
  } else {
    return BROWSER_SUPPORT.GRAYLIST;
  }
};

const deviceInfo = {
  ...browser.getResult(),
  _isWKWebView: isWKWebView,
};
export { browser, deviceInfo };
