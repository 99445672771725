const content: { [key: string]: string } = {
  sponsorBlock: "Sponsor Block",
  orderBlock: "Order Block",
  autoRenewal: "Auto Renewal",
  contractSigned: "Contract Signed",
  true: "True",
  false: "False",
  yes: "Yes",
  no: "No",
};
export default content;
