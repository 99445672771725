import { useTranslation } from "react-i18next";
import { BonusBreakdownTile } from "../../../BonusBreakdownTile/BonusBreakdownTile";
import defaultContent from "../../../../content/bonusBreakdown";
import LeafRectangleLoader from "../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import { ErrorTile } from "../../../../common/components/ErrorTile/ErrorTile";
import { useAppSelector } from "../../../../store";
import { BonusBreakdownLineData } from "../../../../common/interfaces/monthlyPerformanceDash";
import { getRoutePathForAwardCode } from "../../../../common/util/navigationUtil";
import { FetchBonusBreakdownData } from "./AnnualBonusBreakdownDataLayer";
import { annualBonusBreakdownMap } from "./AnnualBonusBreakdownMap";

export const AnnualBonusBreakdown = () => {
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { t } = useTranslation(["bonusBreakdown"]);
  const { menuConfig } = useAppSelector((state) => state.boot.shellConfig);

  const renderBonusBreakdown = () => {
    const {
      bonusBreakdownData,
      isLoading,
      errorStatus,
      refetch: refetchBousBreakdownData,
    } = FetchBonusBreakdownData(selectedPeriod);

    if (isLoading) {
      return (
        <div className="bbt-loader-container">
          <LeafRectangleLoader isLoading={isLoading} numRows={1} width="100%" height="25rem" margin={"0 0 0.5rem 0"} />
          <div className="bbt-loader-container__bottom-line">
            <LeafRectangleLoader
              isLoading={isLoading}
              numRows={1}
              width="12rem"
              height="1.5rem"
              margin={"0 0 0.5rem 0"}
            />
            <LeafRectangleLoader
              isLoading={isLoading}
              numRows={1}
              width="7rem"
              height="1.5rem"
              margin={"0 0 0.5rem 0"}
            />
          </div>
        </div>
      );
    }

    if (errorStatus) {
      return <ErrorTile clickFunction={refetchBousBreakdownData} errorStatus={errorStatus} isLoading={isLoading} />;
    }

    const responseData = bonusBreakdownData.BonusAmount;
    const order = annualBonusBreakdownMap.map(({ awardCode }) => awardCode);

    const renderData = responseData
      .map((item: { awardCode: number; bonusAmount: string }) => {
        const mapItem = annualBonusBreakdownMap.find((element) => element.awardCode === item.awardCode);
        const title = mapItem ? mapItem.name : "";
        const result: BonusBreakdownLineData = {
          awardCode: item.awardCode,
          incentiveTitle: t(title, defaultContent[title]),
          tealiumTag: defaultContent[title].replace("+", ""),
          amount: item.bonusAmount,
          link: getRoutePathForAwardCode(menuConfig, item.awardCode),
        };
        return result;
      })
      .sort((a, b) => order.indexOf(a.awardCode) - order.indexOf(b.awardCode));

    return (
      <BonusBreakdownTile
        title={t("annualBonusBreakdown", defaultContent["annualBonusBreakdown"])}
        description={t("annualBonusBreakdownDescription", defaultContent["annualBonusBreakdownDescription"]).toString()}
        subTitle={t("annualBonus", defaultContent["annualBonus"])}
        bonusSubTotalTitle={t("annualBonusEstimatedTotal", defaultContent["annualBonusEstimatedTotal"])}
        bonusSubTotalValue={bonusBreakdownData.BonusAmountSubTotal}
        bonusBreakdown={renderData}
      />
    );
  };

  return <LeafTile>{renderBonusBreakdown()}</LeafTile>;
};
