import { useLocation } from "react-router-dom";
import { ROUTE_PATHS } from "../../common/enums/routing-enums";
import { useAppSelector } from "../../store";
import { BannerConfig } from "../../common/interfaces/config";
import LeafBannerAcademy from "../../common/leaf/LeafBanner/LeafBannerAcademy";

/**
 * Add routes and their corresponding banners and showBannerFlag here
 * when there is a new route or path to be included
 */
const GetBannerConfigMap = (): { [key: string]: BannerConfig } => {
  const showAcademyBanner = useAppSelector(
    (store) => store.boot.configuration.externalIntegration?.academy?.showBanner,
  );

  return {
    [ROUTE_PATHS.PERFORMANCE_DASHBOARD]: {
      component: LeafBannerAcademy,
      showBannerFlag: showAcademyBanner,
    },
  };
};

const BannerHeader = () => {
  const { pathname } = useLocation();
  const configMap = GetBannerConfigMap();
  const bannerConfig = configMap[pathname];
  if (bannerConfig?.showBannerFlag) {
    const BannerComponent = bannerConfig.component;
    return <BannerComponent />;
  }

  return <></>;
};

export default BannerHeader;
