import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AboTileData, RequirementType } from "../../common/interfaces/bronzeGroupTracking";
import { BRONZE_TAGS } from "../model/bgtFilters";
import { isProgramActiveBulkCheck } from "../../common/util/checkProgramActiveStatus";
import { setActiveTabView } from "../bronzeGroupTrackingTab";
import { setShellConfig } from "../boot";
import { EligibleIncentive } from "../model/shellConfig";

export interface ABOProfileData {
  affNumber: string;
  aboNumber: string;
  aboName: string;
  aboLocalName: string;
  isoLanguage: string;
  graduationPeriod: string;
  bronzeIncentive: string;
  bronzeFoundation: AboTileData[];
  bronzeBuilder: AboTileData[];
}

const initialProfileData = {
  affNumber: "0",
  aboNumber: "0",
  aboName: "",
  aboLocalName: "",
  isoLanguage: "",
  graduationPeriod: "",
  bronzeIncentive: "",
  bronzeFoundation: [] as AboTileData[],
  bronzeBuilder: [] as AboTileData[],
};

interface BronzeGroupTrackingProfileState {
  showProfile: boolean;
  currentProfileAboData: ABOProfileData;
}

interface BronzeGroupTrackingState {
  initialTrackingTilesData: AboTileData[];
  initialGraduatedTilesData: AboTileData[];
  finalTrackingTilesData: AboTileData[];
  finalGraduatedTilesData: AboTileData[];
  requirementFilters: RequirementType[];
  restartIsActive?: boolean;
  activeView?: string;
}

interface BGTData extends BronzeGroupTrackingState, BronzeGroupTrackingProfileState {}

interface FinalTrackingState {
  finalTrackingTilesData: AboTileData[];
}

interface FinalGraduatedState {
  finalGraduatedTilesData: AboTileData[];
}

export const initialStateBTG: BGTData = {
  initialTrackingTilesData: [],
  initialGraduatedTilesData: [],
  finalTrackingTilesData: [],
  finalGraduatedTilesData: [],
  requirementFilters: [],
  restartIsActive: true,
  showProfile: false,
  currentProfileAboData: initialProfileData,
  activeView: "tracking_tab",
};

const findBronzeDataForCurrentAbo = (aff: string, abo: string, data: AboTileData[]) => {
  const currentAbo = data.filter((item) => item.affNumber === aff && item.aboNumber === abo);
  const bronzeFoundation = currentAbo.filter((data) => data.incentiveTag === BRONZE_TAGS.BRONZE_FOUNDATION);
  const bronzeBuilder = currentAbo.filter((data) => data.incentiveTag === BRONZE_TAGS.BRONZE_BUILDER);
  return {
    bronzeFoundation,
    bronzeBuilder,
  };
};

const { actions, reducer } = createSlice({
  name: "bronzeGroupTracking",
  initialState: initialStateBTG,
  reducers: {
    /**
     * Once data is recieved from API it is set here and does not update unless the api is called again.
     */
    updateBronzeGroupList: (state, { payload }: PayloadAction<BronzeGroupTrackingState>) => {
      return {
        ...state,
        ...payload,
      };
    },
    /**
     * Independently updates the finalTrackingTilesData as it is getting filtered, sorted, and searched and then saves the state of updated data.
     */
    updateFinalTrackingData: (state, { payload }: PayloadAction<FinalTrackingState>) => {
      return {
        ...state,
        ...payload,
      };
    },
    /**
     * Independently updates the finalGraduatedTilesData as it is getting filtered, sorted, and searched and then saves the state of updated data.
     */
    updateFinalGraduatedData: (state, { payload }: PayloadAction<FinalGraduatedState>) => {
      return {
        ...state,
        ...payload,
      };
    },
    openProfileModal: (state, { payload }: PayloadAction<AboTileData>) => {
      const {
        aboNumber,
        affNumber,
        aboName,
        aboLocalName,
        isoLanguage,
        incentiveTag: bronzeIncentive,
        graduationPeriod,
        graduated,
      } = payload;
      const currentTabData =
        state.activeView === "tracking_tab" ? state.initialTrackingTilesData : state.initialGraduatedTilesData;
      const { bronzeFoundation, bronzeBuilder } = findBronzeDataForCurrentAbo(affNumber, aboNumber, currentTabData);

      return {
        ...state,
        showProfile: true,
        currentProfileAboData: {
          aboName,
          aboLocalName,
          aboNumber,
          affNumber,
          isoLanguage,
          graduationPeriod: graduated ? graduationPeriod : "",
          bronzeIncentive,
          bronzeFoundation,
          bronzeBuilder,
        },
      };
    },
    closeProfileModal: (state) => {
      return {
        ...state,
        showProfile: false,
        currentProfileAboData: initialProfileData,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setShellConfig, (state, action) => {
      const { eligibleIncentives } = action.payload;

      const getRestartIsActive = (eligibleIncentives: EligibleIncentive[]) => {
        let restartIsActive = false;
        if (eligibleIncentives) {
          restartIsActive = isProgramActiveBulkCheck(eligibleIncentives, [948, 949]);
        }

        return restartIsActive;
      };

      state.restartIsActive = getRestartIsActive(eligibleIncentives);
    });
    builder.addCase(setActiveTabView, (state, action) => {
      state.activeView = action.payload;
    });
  },
});

export const {
  updateBronzeGroupList,
  updateFinalTrackingData,
  updateFinalGraduatedData,
  openProfileModal,
  closeProfileModal,
} = actions;

export default reducer;
