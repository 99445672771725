import LeafSponsorOrSponsorAndPlatinum from "./LeafSponsorDetail/LeafSponsorOrSponsorAndPlatinum/LeafSponsorOrSponsorAndPlatinum";
import LeafPlatinumSponsor from "./LeafSponsorDetail/LeafPlatinumSponsor/LeafPlatinumSponsor";
import LeafInternationalSponsor from "./LeafSponsorDetail/LeafInternationalSponsor/LeafInternationalSponsor";
import LeafFosterOrFosterAndPlatinumSponsor from "./LeafSponsorDetail/LeafFosterOrFosterAndPlatinumSponsor/LeafFosterOrFosterAndPlatinumSponsor";
import "./LeafSponsorship.scss";

export type BasicSponsor = {
  aboNo: string;
  affNo: string;
  isValid: boolean;
};

type Sponsor = {
  isPlatinum: boolean;
} & BasicSponsor;

type Props = {
  sponsor: Sponsor;
  platinumSponsor: BasicSponsor;
  internationalSponsor: BasicSponsor;
  fosterSponsor: Sponsor;
  show?: boolean;
};

const LeafSponsorship = (props: Props) => {
  const { sponsor, platinumSponsor, internationalSponsor, fosterSponsor, show = true } = props;

  if (show === false) {
    return <></>;
  }

  return (
    <div className="leaf-sponsorship">
      <LeafSponsorOrSponsorAndPlatinum sponsor={sponsor} fosterSponsor={fosterSponsor} />
      <LeafPlatinumSponsor sponsor={sponsor} platinumSponsor={platinumSponsor} fosterSponsor={fosterSponsor} />
      <LeafInternationalSponsor internationalSponsor={internationalSponsor} />
      <LeafFosterOrFosterAndPlatinumSponsor fosterSponsor={fosterSponsor} />
    </div>
  );
};

export default LeafSponsorship;
