import { Font, StyleSheet } from "@react-pdf/renderer";
import pdfStyles from "../PDFExportStyles";
import themes from "../PDFExport.module.scss";

// Define styles for the PDF document
const styles = StyleSheet.create({
  page: {
    fontFamily: pdfStyles.body.fontFamily,
    flexDirection: "column",
    padding: 20,
  },
  section: {
    marginBottom: 10,
    padding: 10,
  },
  title: {
    fontWeight: pdfStyles.fontBold.fontWeight,
    fontSize: "15.75pt",
    textAlign: "center",
  },
  subTitle: {
    fontSize: pdfStyles.fontStandard.fontSize,
    fontWeight: pdfStyles.fontRegular.fontWeight,
    textAlign: "center",
  },
  tableRow: {
    flexDirection: "row",
    marginBottom: 5,
    justifyContent: "space-between",
  },
  tableCol: {
    width: "50%",
  },
  tableColSeparator: {
    width: "10%",
    textAlign: "center",
  },
  aboBackground: {
    backgroundColor: themes.colorAmwayWhite,
    padding: "6pt 15pt",
  },
  abo: {
    fontWeight: pdfStyles.fontBold.fontWeight,
    fontSize: pdfStyles.fontBig.fontSize,
    color: themes.colorAmwayBlack,
  },
  aboDetail: {
    color: themes.colorDarkGrayOnWhite,
    fontWeight: pdfStyles.fontRegular.fontWeight,
    fontSize: pdfStyles.fontStandard.fontSize,
  },
  detailRow: {
    fontSize: pdfStyles.fontStandard.fontSize,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: "7.5pt 15pt",
    alignItems: "center",
  },
  currentDiffTotal: {
    fontSize: pdfStyles.fontStandard.fontSize,
    fontWeight: pdfStyles.fontBold.fontWeight,
    margin: "6pt 0",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: "7.5pt 15pt",
    alignItems: "center",
  },
  borderBottom: {
    borderBottom: "0.756pt solid #d6d6d6",
  },
  totalDiffBonus: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: themes.colorAmwayBlack,
    color: themes.colorWhite,
    justifyContent: "space-between",
    padding: "12pt",
    fontWeight: pdfStyles.fontBold.fontWeight,
  },
  nbbv: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: themes.colorExtraLightOrange,
    border: "0.756pt solid #d1b278",
    borderRadius: "3pt",
    justifyContent: "space-between",
    padding: "6.75pt 7.5pt",
    color: themes.colorOrange,
    marginBottom: "12pt",
    fontSize: pdfStyles.fontStandard.fontSize,
  },
  icon: {
    width: "13.5pt",
    height: "13.5pt",
    marginLeft: "5pt",
    alignSelf: "center",
  },
  bold: {
    fontWeight: pdfStyles.fontBold.fontWeight,
  },
});

export default styles;
