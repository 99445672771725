import { CustomerErrorRes } from "../interfaces/errorHandling";

/**
 * Meant to check for errors found from services called within lambda.
 *
 * @param errors: CustomerErrorRes[]
 * @returns boolean
 */
export const lambdaErrorCheck = (errors: CustomerErrorRes[]) => {
  if (!errors || errors.length === 0) return false;
  errors.forEach((error) => printLambdaError(error));
  return true;
};

/**
 * Only print errors found from lambdas in PreProd environments.
 *
 * @param errors
 */
const printLambdaError = (error: CustomerErrorRes) => {
  const preProdEnv = /(localhost|test|qa|stage)/.test(window.location.hostname);
  if (preProdEnv) console.error(`${error.statusCode} ERROR RETURNED FROM LAMBDA: (${error.service})`, { error });
};
