import { Trans, useTranslation } from "react-i18next";
import { PggMonthlyRequirements } from "../../../../common/interfaces/groupGrowthIncentives";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import { VolumeRequirementTile } from "../../../../common/components/RequirementTiles/VolumeRequirementTile/VolumeRequirementTile";
import defaultContent from "../../../../content/annualPGG";
import { LeafVolumeLabel } from "../../../../common/leaf/LeafVolumeLabel/LeafVolumeLabel";

export const MonthlyRubyRequirements = ({
  isLoading,
  requirements,
}: {
  isLoading: boolean;
  requirements: PggMonthlyRequirements;
}) => {
  const { MonthlyRubyRequirement } = requirements;
  const { t } = useTranslation(["annualPGG"]);

  const getVolumeNeeded = ({
    HasBooster,
    additionalNeeded,
    volumeType,
  }: {
    HasBooster: boolean;
    additionalNeeded: string;
    volumeType: string;
  }) => {
    return HasBooster ? (
      <Trans
        type="string"
        i18nKey="requirements.rubyPVBoosterLabel"
        defaults={defaultContent["requirements"]["rubyPVBoosterLabel"]}
        ns="annualPGG"
        values={{
          volumeNeeded: additionalNeeded,
          volumeType: LeafVolumeLabel(volumeType),
        }}
        components={{ 1: <strong></strong> }}
      />
    ) : undefined;
  };

  const checkTargetAmount = ({
    targetAmount,
    targetWithLeg,
    QualifiedLeg,
  }: {
    targetAmount: number;
    targetWithLeg: number;
    QualifiedLeg: number;
  }) => {
    return QualifiedLeg > 0 ? targetWithLeg : targetAmount;
  };

  return isLoading ? (
    <div className="Growth-requirement__tile-loader">
      <LeafTile isLoading={isLoading} className="Growth-requirement__tile-loader" />
    </div>
  ) : (
    <>
      {MonthlyRubyRequirement.map((requirement) => {
        return (
          <section
            className="Growth-requirement__tile"
            key={requirement.Name}
            data-testid={"pggTile-" + requirement.Name}
          >
            <VolumeRequirementTile
              isLoading={false}
              title={t(
                `requirements.${requirement.Name}.title`,
                defaultContent["requirements"][requirement.Name]["title"] as string,
                {
                  volume: requirement.Volume.Target.Formatted,
                  volumeRuby: requirement.Volume.Target.Formatted,
                  volumeLegs: requirement.Volume.TargetWithLeg.Formatted,
                },
              )}
              currentAmount={requirement.Volume.Current.Actual}
              targetAmount={checkTargetAmount({
                targetAmount: requirement.Volume.Target.Actual,
                targetWithLeg: requirement.Volume.TargetWithLeg.Actual,
                QualifiedLeg: requirement.QualifiedLeg.Actual,
              })}
              met={requirement.RequirementsMet.NoLegPVMet || requirement.RequirementsMet.WithLegPVMet}
              circleCheckmarkId={requirement.Name}
              volumeType={requirement.Name}
              labels={[requirement.Volume.Current.Formatted, requirement.Volume.Remaining.Formatted]}
              showLabelsOnly={true}
              additionalNeeded={requirement.Volume.Remaining.Formatted}
              exempt={requirement.Exempt}
              volumeNeededLabel={getVolumeNeeded({
                HasBooster: requirement.HasBooster,
                additionalNeeded: requirement.Volume.Remaining.Formatted,
                volumeType: requirement.Name,
              })}
            />
          </section>
        );
      })}
    </>
  );
};
