import "./ABOBusinessKPIBody.scss";
import ABOProfilePerfHistory from "../ABOProfileBody/ABOProfileTabViews/InsightsTab/ABOProfilePerfHistory/ABOProfilePerfHistory";

type Props = {
  isShowProfile: boolean;
};

const ABOBusinessKPIBody = (props: Props) => {
  const { isShowProfile } = props;

  if (isShowProfile === false) {
    return <></>;
  }

  return (
    <>
      <div className="abo-profile-perf-body">
        <ABOProfilePerfHistory showOnlyPerformanceHistory={true} />
      </div>
    </>
  );
};

export default ABOBusinessKPIBody;
