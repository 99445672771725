import { INTERNATIONAL_SPONSOR } from "../../../../../constants/sponsor-constants";
import { BasicSponsor } from "../../LeafSponsorship";
import LeafSponsorDetail from "../LeafSponsorDetail";

type Props = {
  internationalSponsor: BasicSponsor;
};

const LeafInternationalSponsor = (props: Props) => {
  const { internationalSponsor } = props;
  const { aboNo, affNo, isValid } = internationalSponsor;
  const show = internationalSponsor.isValid === true; // Future will have an additional configuration (disableInternational) will be added here

  return (
    <LeafSponsorDetail
      sponsor={{
        aboNo,
        affNo,
        isValid,
      }}
      show={show}
      sponsorType={INTERNATIONAL_SPONSOR}
    />
  );
};

export default LeafInternationalSponsor;
