import "./IconErrorUnavailable.scss";
import { ReactComponent as ErrorUnavailable } from "./svg/icon-error-unavailable.svg";

export const IconErrorUnavailable = ({ id = "" }: { id?: string }) => {
  return (
    <span id={id} className="Icon-Unavailable">
      <ErrorUnavailable />
    </span>
  );
};
