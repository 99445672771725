import { Trans, useTranslation } from "react-i18next";
import LeafFilterButton from "../LeafFilter/LeafFilterButton/LeafFilterButton";
import LeafTile from "../LeafTile/LeafTile";
import "./LeafIncentiveEligibilityBanner.scss";
import defaultContent from "../../../content/leafIncentiveEligibilityBanner";
import { getBronzeDisplayDate } from "../../../components/BronzeIncentives/BronzeCommon/BronzeDate/BronzeDate";

type Props = {
  module: "bbi" | "bfi";
  graduated: boolean;
  eligible: boolean;
  remainingIncentiveEarnings: number;
  endPeriod: string;
  notStarted: boolean;
  eligibleButtonOnClick: () => void;
  show?: boolean;
  isLoading?: boolean;
};

const LeafIncentiveEligibilityBanner = (props: Props) => {
  const { t } = useTranslation(["leafIncentiveEligibilityBanner"]);

  const {
    module,
    graduated,
    eligible,
    remainingIncentiveEarnings,
    endPeriod,
    eligibleButtonOnClick,
    show = true,
    isLoading,
    notStarted,
  } = props;

  const notEligible = eligible === false && graduated === false;
  const completed = graduated === true || (remainingIncentiveEarnings === 0 && notStarted === false);
  const bannerTitle = completed === true ? `${module}CompletedTitle` : `${module}EligibleTitle`;

  if (show === false || notEligible === true) {
    return <></>;
  }

  return (
    <div className="leaf-incentive-eligibility-banner">
      <LeafTile isLoading={isLoading}>
        <div className="leaf-incentive-eligibility-banner__header">
          <div className="leaf-incentive-eligibility-banner__title">
            <h2>{`${t(bannerTitle, defaultContent[bannerTitle])}`}</h2>
          </div>

          {completed === false && notStarted === false ? (
            <div className="leaf-incentive-eligibility-banner__subheader">
              <Trans
                t={t}
                i18nKey={"subHeadlineContent"}
                values={{ remainingIncentiveEarnings, dateText: getBronzeDisplayDate(endPeriod) }}
                components={{
                  tracking: <span className="leaf-incentive-eligibility-banner__text__count" />,
                }}
              >
                {defaultContent["subHeadlineContent"]}
              </Trans>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="leaf-incentive-eligibility-banner__button">
          <LeafFilterButton
            text={`${t("eligibilityButton", defaultContent["eligibilityButton"])}`}
            onButtonClick={eligibleButtonOnClick}
          />
        </div>
      </LeafTile>
    </div>
  );
};

export default LeafIncentiveEligibilityBanner;
