const content: { [key: string]: string } = {
  1: "ABO",
  2: "Provisional ABO",
  3: "Member",
  4: "Client",
  5: "Market Advisor",
  6: "Amway Of",
  7: "Employee/Honorary",
  8: "BSc [India]",
  9: "GPKh (Non Group Leader) [Russia/KZ]",
  0: "Probational Auth Agent",
  10: "Service Site Point Operator",
  12: "Sales Representative",
  15: "Placeholder",
  17: "Guest Customer",
  171: "Sharebar Customer",
};

export default content;
