import "./IconClose.scss";
import { ReactComponent as CloseIcon } from "./svg/icon_close.svg";

type Props = {
  width: string;
  height: string;
  color?: "black" | "grey" | "white";
};

export const IconClose = (props: Props) => {
  const { width, height, color = "black" } = props;
  return (
    <span className={`icon-close icon-close__${color}`} style={{ width, height }}>
      <CloseIcon />
    </span>
  );
};
