import { useEffect, useState } from "react";
import { useGetFggMultiplierBaseQuery } from "../../../services/frontlineGroupGrowthAPI";
import { useAppSelector } from "../../../store";
import { storedLocale } from "../../impersonation/util";
import { lambdaErrorCheck } from "../../../common/util/errorHandling";
import { MultiplierBaseResponse } from "../../../common/interfaces/multiplierBase";

const defaultFggMultiplierBaseData = (): MultiplierBaseResponse => {
  return {
    MultiplierBaseDetails: [{ MultiplierType: "", BonusAwards: [], Achieved: false, DisplaySectionHeader: false }],
    Status: "",
    CallResults: "",
    Error: "",
  };
};

export const FetchFGGMultiplierBaseData = (period: string) => {
  const { user } = useAppSelector((state) => state.boot);
  const shellConfig = useAppSelector((state) => state.boot.shellConfig);
  const {
    data: payloadData,
    isFetching,
    isError,
    refetch,
  } = useGetFggMultiplierBaseQuery({
    abo: user.abo,
    aff: user.aff,
    period: period,
    isoCountryCode: user.isoCountryCode,
    isoCurrencyCode: shellConfig.isoCurrencyCode,
    locale: storedLocale(),
  });

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [fggMultiplierBaseData, setFggMultiplierBaseData] = useState<MultiplierBaseResponse>(
    defaultFggMultiplierBaseData(),
  );

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (!isFetching && !isError && payloadData) {
      setFggMultiplierBaseData(payloadData);
      const foundLambdaError = lambdaErrorCheck(payloadData.Error);
      setErrorStatus(foundLambdaError);
    }
  }, [isFetching, period, isError, payloadData]);

  return { isLoading, fggMultiplierBaseData, errorStatus, refetch };
};
