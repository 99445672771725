import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/customerTags";
import "./LeafCustomerTypeTag.scss";

type Props = {
  businessNatureCode?: number;
  registrationChannelCd?: string;
};

const LeafCustomerTypeTag = (props: Props) => {
  const { businessNatureCode, registrationChannelCd } = props;
  const { t } = useTranslation(["customerTags"]);

  switch (businessNatureCode) {
    case 3:
      return <span className="tag-customer">{t("member", defaultContent["member"])}</span>;
    case 17:
      return (
        <span className="tag-customer">
          {registrationChannelCd === "ShareBar"
            ? t("shareBar", defaultContent["shareBar"])
            : t("guestCustomer", defaultContent["guestCustomer"])}
        </span>
      );
    case 4:
      return <span className="tag-customer">{t("customer", defaultContent["customer"])}</span>;
    default:
      return <></>;
  }
};

export default LeafCustomerTypeTag;
