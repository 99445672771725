import "./LeafSortButton.scss";
import { useTranslation } from "react-i18next";
import { IconSortFilter } from "../../icons/IconSortFilter/IconSortFilter";
import defaultContent from "../../../content/leafSortInfo";

export type sortMethod = "lowHigh" | "highLow" | "aZ" | "Za" | "descGraduationDate";

export type props = {
  currentSortMethod: sortMethod | string;
  clickFunction: Function;
  showIcon?: boolean;
  showSortLabel?: boolean;
};

export const LeafSortButton = ({ currentSortMethod, clickFunction, showIcon = true, showSortLabel = true }: props) => {
  const { t } = useTranslation(["leafSortInfo"]);
  const sortByLabel = showSortLabel ? `${t("text", defaultContent["text"])}: ` : "";
  const sortMethodLabel = t(`options.${currentSortMethod}`, defaultContent["options"][currentSortMethod]);
  const useCursor = currentSortMethod === "descGraduationDate" ? "" : "pointer";

  return (
    <button className="leaf-sort-button" onClick={() => clickFunction()}>
      <span className={`leaf-sort-button__value ${useCursor}`}>{`${sortByLabel}${sortMethodLabel}`}</span>
      {showIcon && <IconSortFilter />}
    </button>
  );
};
