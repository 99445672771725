import { useAppSelector } from "../../../store";
import { ANA_MARKET_CODES } from "../../constants/market-constants";
import AnaAcademyBanner from "./AnaAcademyBanner/AnaAcademyBanner";
import AcademyBanner from "./AcademyBanner/AcademyBanner";

const LeafBannerAcademy = () => {
  const user = useAppSelector((state) => state.boot?.user);
  const anaMarket = ANA_MARKET_CODES.includes(user.aff);
  if (anaMarket) {
    return <AnaAcademyBanner />;
  }
  return <AcademyBanner />;
};

export default LeafBannerAcademy;
