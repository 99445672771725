import { useTranslation } from "react-i18next";
import defaultContent from "../../../content/bronzeGroupTracking";
import "./BGTFilterModal.scss";
import LeafPanel, { ModalProps } from "../../../common/leaf/LeafPanel/LeafPanel";
import LeafGroupFilter from "../../../common/leaf/LeafGroupFilter/LeafGroupFilter";
import { useAppSelector } from "../../../store";
import { RequirementType } from "../../../common/interfaces/bronzeGroupTracking";
import { BRONZE_IDS, FilterItem } from "../../../reducers/model/bgtFilters";

type Props = {
  checkFilterOnClick: Function;
  clearAllOnClick: Function;
  applyOnClick: Function;
  tab: "tracking" | "graduated";
} & ModalProps;

const BGTFilterModal = ({
  checkFilterOnClick,
  clearAllOnClick,
  applyOnClick,
  show,
  onCloseClick,
  title,
  tab,
  id,
}: Props) => {
  const { t } = useTranslation(["bronzeGroupTracking"]);
  const { filterCount, filterGroups } = useAppSelector(({ BGTTrackingFilter, BGTGraduatedFilter }) =>
    tab === "tracking" ? BGTTrackingFilter : BGTGraduatedFilter,
  );
  const { requirementFilters, restartIsActive = false } = useAppSelector(
    ({ bronzeGroupTracking }) => bronzeGroupTracking,
  );

  const textContent = {
    filterTitle: "",
  };

  const filters = filterGroups.map((group) =>
    group.options.map((filter) => {
      const signs = filter.id.split("-");
      const textSign = signs[1];
      const dataFilter = {
        ...filter,
        text: t(textSign, defaultContent[textSign]).toString(),
        group: group.group,
      };
      return dataFilter;
    }),
  );

  const groups = filterGroups.map((group) => ({
    ...group,
    groupLabel: t(group.group, defaultContent[group.group]).toString(),
  }));

  const createActiveReqFilters = (
    requirementFilters: RequirementType[],
    defaultRequirementFilters: FilterItem[],
  ): FilterItem[] => {
    const activeRequirementFilters: FilterItem[] = [];
    requirementFilters.forEach((req) => {
      defaultRequirementFilters.forEach((d: any) => {
        if (
          d.dataKey &&
          d.dataKey.requirement &&
          d.dataKey.requirement.toLowerCase() === req.requirement.toLowerCase()
        ) {
          activeRequirementFilters.push(d);
        }
      });
    });

    return activeRequirementFilters;
  };

  const removeInactiveFilters = (
    filters: any[],
    requirementFilters: RequirementType[],
    restartIsActive: boolean,
  ): FilterItem[] => {
    const nonRequirementFilters: FilterItem[] = filters.filter(
      (d) => !d.id.includes("requirements-") && d.id !== BRONZE_IDS.TAGS_RESTARTED,
    );
    const restartedFilter: FilterItem = filters.find((d: FilterItem) => d.id === BRONZE_IDS.TAGS_RESTARTED);
    const defaultRequirementFilters: FilterItem[] = filters.filter((d) => d.id.includes("requirements-"));
    const activeRequirementFilters: FilterItem[] = createActiveReqFilters(
      requirementFilters,
      defaultRequirementFilters,
    );

    if (restartIsActive) {
      nonRequirementFilters.push(restartedFilter);
    }

    return [...nonRequirementFilters, ...activeRequirementFilters];
  };

  const finalFilters = removeInactiveFilters(filters.flat(), requirementFilters, restartIsActive);

  return (
    <div className="BGT-Filter-Modal">
      <LeafPanel id="BGT-tracking-filter-modal" {...{ show, onCloseClick, title }}>
        <div className="tracking-filter" id={`bronzeGroupTracking-${tab}-filter`}>
          <LeafGroupFilter
            clearAllOnClick={clearAllOnClick}
            closeFilterOnClick={onCloseClick}
            applyOnClick={applyOnClick}
            checkFilterOnClick={checkFilterOnClick}
            textContent={textContent}
            filters={finalFilters}
            filterGroups={groups}
            filterCount={filterCount}
            showFilter
            id={`BGT-${tab}-filter`}
          />
        </div>
      </LeafPanel>
    </div>
  );
};

export default BGTFilterModal;
