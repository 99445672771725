import "./LeafTabViewHeader.scss";

type TabViewHeaderItem = {
  id?: string;
  title?: string;
  activeView: string;
  disabled?: boolean;
};

type Props = {
  tabItem: TabViewHeaderItem;
  tabHeaderOnClick: Function;
};

const LeafTabViewHeader = (props: Props) => {
  const { tabItem, tabHeaderOnClick } = props;
  const { id, title, activeView, disabled } = tabItem;

  const onClickTabHeader = () => {
    if (!disabled) {
      tabHeaderOnClick(id);
    }
  };

  return (
    <div className="leaf-tab-view-header-item">
      <button
        id={id}
        onClick={onClickTabHeader}
        key={id}
        className={`leaf-tab-view-header-item__nav-item${
          id === activeView ? "--active leaf-tab-view-header-item__nav-item" : ""
        }${disabled ? "--disabled leaf-tab-view-header-item__nav-item" : ""}`}
        role="tab"
        aria-selected={id === activeView ? "true" : "false"}
        tabIndex={id === activeView ? 0 : -1}
        aria-controls={`tabpanel-${id}`}
      >
        {title}
      </button>
    </div>
  );
};

export default LeafTabViewHeader;
