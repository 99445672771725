const content: { [key: string]: string } = {
  pageTitle: "Action Reports",
  viewReport: "View Report",
  birthdaysArTitle: "Birthdays",
  birthdaysArDescription: "ABOs who have a birthday this month",
  whosNewTitle: "Who's New",
  whosNewDescription: "New ABOs this month",
  whosCloseTitle: "Who's Close",
  whosCloseDescription: "ABOs who are within 25% of the next bonus level",
  renewalsArTitle: "Renewals",
  renewalsArDescription: "ABOs that are eligible to renew within 100 days",
  cbrArTitle: "CBR Clock",
  cbrArDescription: "IBOs whose CBR Clock has started",
  cvrArTitle: "CVR Compliance",
  cvrArDescription: "IBOs who are not CVR compliant",
  nonBonusableBvArTitle: "Non-Bonusable BV",
  nonBonusableBvArDescription: "Current list of IBOs who are below 60% VCS and generating Non-Bonusable BV",
  provisionalArTitle: "IBO Onboarding",
  provisionalArDescription: "IBOs in progress towards sponsoring eligiblitity",
};

export default content;
