import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import storageSession from "redux-persist/lib/storage/session";
import { Dates, DisplayYear } from "../common/interfaces/config";
import {
  getCurrentPeriod,
  getPerformanceYear,
  getPreviousNPeriods,
  getSelectedPerformanceYearData,
} from "../common/util/period";
import { setShellConfig } from "./boot";
export type Period = {
  selectedPeriod: string;
  previousSelectedPeriod: string;
  monthPeriods: Array<string>;
  annualPeriods: Array<string>;
  periods: Array<string>;
  formattedPeriods: Array<any>;
  selectorType: SELECTOR_TYPE;
  isNextPeriod: boolean;
  isPreviousSelected: boolean;
  navigatedByPeriodChange: boolean;
};

export enum SELECTOR_TYPE {
  PYMONTHS = "PYMONTHS",
  MONTHLY = "MONTHLY",
  PY = "PY",
}

const initialPY = `${getPerformanceYear(getCurrentPeriod())}08`;

const initialPeriod = getCurrentPeriod();

export const setNumOfPeriods = (dates: Dates) => {
  if (dates && dates.availablePeriods && dates.availableYears) {
    return {
      numOfMonthlyPeriods: dates.availablePeriods.length,
      numOfAnnualPeriods: dates.availableYears.length,
    };
  }
  return { numOfMonthlyPeriods: 0, numOfAnnualPeriods: 0 };
};

// Updates the Years displayed once shellConfig call resolves
const getPYsToDisplay = (availableYears: DisplayYear[]) => {
  const displayYears: string[] = [];
  // shellConfig returns an array of available years in the format:
  // [{ "year": "2023-01-01T00:00:00.000Z", "display": "2023-08-01T00:00:00.000Z" }]
  // we want to use the `year` value for the PY period selector
  if (availableYears) {
    availableYears.forEach((years) => {
      displayYears.push(`${years.year.toString().slice(0, 4)}${years.year.toString().slice(5, 7)}`);
    });
  }
  return displayYears;
};

const getFormattedPeriods = (availableYears: DisplayYear[]) => {
  const periods: any = [];
  if (availableYears) {
    availableYears.forEach((years) => {
      periods.push({
        year: `${years.year.toString().slice(0, 4)}${years.year.toString().slice(5, 7)}`,
        display: `${years.display.toString().slice(0, 4)}08`,
      });
    });
  }
  return periods;
};

export const initialState: Period = {
  selectedPeriod: initialPeriod,
  previousSelectedPeriod: initialPeriod,
  monthPeriods: [initialPeriod],
  annualPeriods: [initialPY],
  periods: [initialPeriod],
  formattedPeriods: [],
  selectorType: SELECTOR_TYPE.MONTHLY,
  isNextPeriod: false,
  isPreviousSelected: false,
  navigatedByPeriodChange: false,
};

const getFilteredPeriod = (state: any) => {
  const { selectedPYEndPeriod } = getSelectedPerformanceYearData(state.selectedPeriod);
  const listOfCurrentPYPeriod = getPreviousNPeriods(selectedPYEndPeriod, 12, true);
  const filteredPeriod = listOfCurrentPYPeriod.filter((p) => p <= state.selectedPeriod || p <= getCurrentPeriod());
  return filteredPeriod;
};

// Determines the period selector type by passing in action.payload and current state.
// Returns an object with "periods" and "selectedPeriod" accordingly.
// To enhance modularity, the 'dates' parameter is optional.
const getPeriods = (state: Period, selectorType: SELECTOR_TYPE, dates?: Dates | null) => {
  switch (selectorType) {
    case SELECTOR_TYPE.PY:
      return getPYPeriods(state, dates);
    case SELECTOR_TYPE.PYMONTHS:
      return getPYMonthsPeriods(state);
    case SELECTOR_TYPE.MONTHLY: // Fallthrough
    default:
      return getMonthlyPeriods(state, dates);
  }
};

// Helper function for setting the selected period within PY.
const setPeriodWithinPY = (period: string, annualPeriods: string[]) => {
  const lastMonth = getSelectedPerformanceYearData(period).selectedPYEndPeriod;
  const currentPeriod = getCurrentPeriod();
  const withIn = annualPeriods.includes(period);
  if (currentPeriod === period && withIn) {
    return period;
  } else {
    if (currentPeriod <= lastMonth) {
      return currentPeriod;
    }
    return lastMonth;
  }
};

// Helper function for SELECTOR_TYPE.PY
const getPYPeriods = (state: Period, dates?: Dates | null) => {
  return {
    periods: dates ? getPYsToDisplay(dates.availableYears) : state.annualPeriods,
    selectedPeriod: state.isPreviousSelected
      ? setPeriodWithinPY(state.previousSelectedPeriod, state.annualPeriods)
      : state.annualPeriods[0],
  };
};

// Helper function for SELECTOR_TYPE.PYMONTHS
const getPYMonthsPeriods = (state: Period) => {
  return {
    periods: getFilteredPeriod(state),
    selectedPeriod: state.selectedPeriod,
  };
};

// Helper function for SELECTOR_TYPE.MONTHLY
const getMonthlyPeriods = (state: Period, dates?: Dates | null) => {
  return {
    periods: dates
      ? getPreviousNPeriods(getCurrentPeriod(), setNumOfPeriods(dates).numOfMonthlyPeriods, true)
      : state.monthPeriods,
    selectedPeriod: state.isPreviousSelected ? state.previousSelectedPeriod : state.monthPeriods[0],
  };
};

const calculateNumberOfPeriods = (dates: Dates, state: any) => {
  const { numOfMonthlyPeriods } = setNumOfPeriods(dates);
  const annualPeriodsToDisplay = getPYsToDisplay(dates.availableYears);
  const monthPeriodsToDisplay = getPreviousNPeriods(getCurrentPeriod(), numOfMonthlyPeriods, true);
  const { periods } = getPeriods(state, state.selectorType, dates);
  const formattedPeriods = getFormattedPeriods(dates.availableYears);

  const newPeriods = {
    monthPeriods: monthPeriodsToDisplay,
    annualPeriods: annualPeriodsToDisplay,
    periods: periods,
    formattedPeriods: formattedPeriods,
  };
  return newPeriods;
};

const { actions, reducer } = createSlice({
  name: "period",
  initialState,
  reducers: {
    /**
     * updates the selected period
     * @param action `{period: string}`
     */
    updatePeriod: (state, action: PayloadAction<{ period: string }>) => {
      return { ...state, selectedPeriod: action.payload.period, navigatedByPeriodChange: true };
    },
    updateIsPreviousSelected: (
      state,
      action: PayloadAction<{ isPreviousSelected: boolean; previousSelectedPeriod: string }>,
    ) => {
      return {
        ...state,
        isPreviousSelected: action.payload.isPreviousSelected,
        previousSelectedPeriod: action.payload.previousSelectedPeriod,
      };
    },
    /**
     * change period selector type (PY or MONTHLY)
     * @param `SELECTOR_TYPE`
     */
    updateSelectorType: (state, action: PayloadAction<SELECTOR_TYPE>) => {
      // only update selector if the type has changed
      if (state.selectorType !== action.payload && state.formattedPeriods[0]) {
        const { periods, selectedPeriod } = getPeriods(state, action.payload);
        return {
          ...state,
          selectorType: action.payload,
          periods: periods,
          selectedPeriod: selectedPeriod,
          isNextPeriod: true,
        };
      }
    },
    /**
     * updates the monthly and annual periods to display
     * based on the dates returned from `shellConfig` call
     * @param action `Dates`
     */
    updateNumOfPeriodsDisplayed(state, action: PayloadAction<Dates>) {
      return {
        ...state,
        ...calculateNumberOfPeriods(action.payload, state),
      };
    },
    /**
     * updates next period flag based on the next arrow period selector click
     * @param action `Boolean`
     */
    updateNextPeriodFlag(state, action: PayloadAction<{ isNextPeriod: boolean }>) {
      return { ...state, isNextPeriod: action.payload.isNextPeriod };
    },
    setNavigatedByPeriod(state, action) {
      return {
        ...state,
        navigatedByPeriodChange: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setShellConfig, (state, { payload }) => {
      return {
        ...state,
        ...calculateNumberOfPeriods(payload.dates, state),
      };
    });
  },
});

export const {
  updatePeriod,
  updateSelectorType,
  updateNumOfPeriodsDisplayed,
  updateNextPeriodFlag,
  updateIsPreviousSelected,
  setNavigatedByPeriod,
} = actions;

const periodPersistConfig = {
  key: "period",
  storage: storageSession,
  stateReconciler: autoMergeLevel2,
};

export default persistReducer<Period>(periodPersistConfig, reducer);
