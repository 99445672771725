import { Navigate, Route } from "react-router-dom";
import BronzeIncentives, { BronzeIncentiveContainer } from "../components/BronzeIncentives/BronzeIncentives";

export const BRONZE_INCENTIVE_BASE_PATH = "/bronze-incentives";

export const IncentiveRoutes = () => {
  return (
    <Route path={BRONZE_INCENTIVE_BASE_PATH} element={<BronzeIncentiveContainer />}>
      <Route path={""} element={<BronzeIncentives />} />
      <Route path={BRONZE_INCENTIVE_BASE_PATH} element={<Navigate to={""} />} />
    </Route>
  );
};
