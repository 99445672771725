const content: { [key: string]: string } = {
  sponsor: "Sponsor",
  platinumSponsor: "Platinum",
  fosterSponsor: "Foster",
  platinumAndSponsor: "Sponsor & Platinum",
  platinumFosterSponsor: "Foster & Platinum",
  internationalSponsor: "International Sponsor",
};

export default content;
