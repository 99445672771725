import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAsync } from "react-async";
import defaultContent from "../../content/ppe";
import { storedLocale } from "../impersonation/util";
import { ROUTE_LABELS } from "../../common/enums/routing-enums";
import { useAppDispatch, useAppSelector } from "../../store";
import { SELECTOR_TYPE, updateSelectorType } from "../../reducers/period";
import { getPerformanceIncentive } from "../../routing/MenuConfig/leaderGrowthDropdown";
import { PageTitleSection } from "../../common/components/PageTitleSection/PageTitleSection";

const loadComponent = async () => {
  import("../Lit/acc-coreplus-incentives/acc-performance-plus-elite.js");
};

export default function PerformancePlusEliteLit() {
  const { t } = useTranslation(["ppe"]);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { shellConfig, configuration } = useAppSelector(({ boot }) => boot);
  const dispatch = useAppDispatch();
  const { isPending } = useAsync({ promiseFn: loadComponent });

  const location = useLocation();
  const [titleKey, setTitleKey] = useState<string>(location.state?.titleKey || ROUTE_LABELS.PPE);

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));
  }, [dispatch]);

  useEffect(() => {
    if (!isPending) {
      const ele = document.getElementById("acc_performance_plus_elite_component") as HTMLElement;
      ele.setAttribute("learn-more-link", configuration.dashboardLinks?.learnMore?.performancePlusElite);
    }
  }, [isPending, configuration]);

  useEffect(() => {
    if (shellConfig.eligibleIncentives !== undefined) {
      const { performanceLabel } = getPerformanceIncentive(shellConfig.eligibleIncentives);
      setTitleKey(performanceLabel);
    }
  }, [shellConfig]);

  const ppeKeys: string[] = [ROUTE_LABELS.PPE, ROUTE_LABELS.PERFORMANCE_PLUS, ROUTE_LABELS.PERFORMANCE_ELITE];
  const ppeTitle = ppeKeys.includes(titleKey)
    ? t(`pageTitle.${titleKey}`, defaultContent["pageTitle"][titleKey])
    : t(`${titleKey}`);

  if (isPending) {
    return <></>;
  }
  return (
    <main>
      <PageTitleSection title={`${ppeTitle}`} hideTimeStamp />
      {/* @ts-ignore */}
      <acc-performance-plus-elite
        id="acc_performance_plus_elite_component"
        mode="standalone"
        period={selectedPeriod}
        locale={storedLocale()}
      ></acc-performance-plus-elite>{" "}
    </main>
  );
}
