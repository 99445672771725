import { useEffect } from "react";
import { useLazyGetNewCustomerListQuery } from "../../services/customerListAPI";

interface UseFetchCustomerListProps {
  autoFetch: boolean;
  affAbo: string;
  pageNum: number | null;
  pageSize: number;
  period: string;
  sortValue: string;
  sortOrder: string;
  searchBy?: string;
  locale?: string;
  isVerified?: boolean;
  isNew?: boolean;
  businessNature?: string;
}

const useGetNewCustomerList = ({
  autoFetch,
  affAbo,
  pageNum,
  period,
  pageSize,
  sortOrder,
  sortValue,
  searchBy,
  locale,
  isNew,
  isVerified,
  businessNature,
}: UseFetchCustomerListProps) => {
  const [getCustomerList] = useLazyGetNewCustomerListQuery();

  useEffect(() => {
    if (autoFetch === true && pageNum !== null) {
      getCustomerList({
        affAbo,
        pageNum,
        period,
        pageSize,
        sortOrder,
        sortValue,
        searchBy,
        locale,
        isNew,
        isVerified,
        businessNature,
      });
    }
  }, [
    affAbo,
    autoFetch,
    getCustomerList,
    businessNature,
    isNew,
    isVerified,
    locale,
    pageNum,
    pageSize,
    period,
    searchBy,
    sortOrder,
    sortValue,
  ]);
};

export default useGetNewCustomerList;
