import { LeafErrorMessage } from "../../../../../../common/leaf/LeafErrorMessage/LeafErrorMessage";
import LeafProfileLoader from "../../../../../../common/leaf/LeafProfile/LeafProfileLoader/LeafProfileLoader";
import { getLoginAffAbo } from "../../../../../../reducers/model/boot";
import { useGetCustomerListQuery } from "../../../../../../services/customerListAPI";
import { useGetProfileQuery } from "../../../../../../services/profileAPI";
import { useAppSelector } from "../../../../../../store";
import { profileProfileTabErrorTrack } from "../../../../../../common/tealium/CustomerProfile/Profile/ProfileTabViewsTrack/ProfileTabTrack";
import CustomerAdditionalInfo from "./CustomerAdditionalInfo/CustomerAdditionalInfo";

const ProfileTab = () => {
  const profileABO = useAppSelector((state) => state.profile.affAbo);
  const customerIds = profileABO.split("-")[1]; // extract customer number only

  const { selectedPeriod } = useAppSelector(({ period }) => period);

  const boot = useAppSelector(({ boot }) => boot);
  const affAbo = getLoginAffAbo({ boot });

  const {
    isFetching: isFetchingProfile,
    isError: isErrorProfile,
    data: profile,
  } = useGetProfileQuery({
    period: selectedPeriod,
    affAbo: profileABO,
  });

  const {
    isFetching: isFetchingCustomer,
    isError: isErrorCustomer,
    data: customerData,
  } = useGetCustomerListQuery({
    period: selectedPeriod,
    affAbo,
    pageNum: 1,
    pageSize: 1,
    sortValue: "LAST_ORDER_DATE",
    sortOrder: "ASC",
    customerIds,
  });

  const isFetching = isFetchingProfile || isFetchingCustomer;
  const isFetched = isFetchingProfile === false && isFetchingCustomer === false;
  const isError = isErrorProfile || isErrorCustomer;

  if (isError === true) {
    profileProfileTabErrorTrack();
  }

  if (isFetched === true && profile?.aboInfo && customerData) {
    const { privacyFlag, isoLanguageCode, isoBirthdate, entryDate, renewalDate } = profile.aboInfo;
    const { lastOrderDate, lastReturnDate } = customerData.customerList?.[0] || [];

    return (
      <>
        <CustomerAdditionalInfo
          privacyFlag={privacyFlag}
          recentOrderDate={lastOrderDate}
          recentReturnDate={lastReturnDate}
          isoLanguageCode={isoLanguageCode}
          isoBirthdate={isoBirthdate}
          entryDate={entryDate}
          renewalDate={renewalDate}
        />
      </>
    );
  }

  return (
    <>
      <LeafErrorMessage show={isError} />
      <LeafProfileLoader row={6} card={true} isFetching={isFetching} />
    </>
  );
};

export default ProfileTab;
