import { POLYMER_2_ROUTES } from "../constants/routing-constants";

/**
 * Simple function to force a page reload when going TO or FROM a
 * Polymer 2 imported component
 *
 * This is needed due to the polymer 2 components being imported and CustomElementRegistry not
 * recognising the name "dom-module" for each component is actually a different element.
 *
 * @param prevPath string Ex. '/cml'
 * @param nextPath string Ex. '/performance-bonus'
 * @TODO Remove each Polymer 2 component from the POLYMER_2_ROUTES constant as they are
 * rebuilt as React components.
 * @TODO Remove this function completely once no more polymer 2 components are imported.
 */
export const reloadForP2Components = (location: any, nextPath: any) => {
  const prevPathIsP2 = POLYMER_2_ROUTES.includes(location.pathname);
  const nextPathIsP2 = POLYMER_2_ROUTES.includes(nextPath);
  if (prevPathIsP2 || nextPathIsP2) {
    console.warn("Navigating to/from Polymer 2 Component: Forcing Page Reload");
    setTimeout(() => {
      // save router state
      sessionStorage.setItem("routerState", JSON.stringify(location.state));
      // refresh page
      window.location = nextPath;
    }, 0);

    setTimeout(() => {
      // after page refresh reconstruct router state
      location.state = JSON.parse(sessionStorage.getItem("routerState") || "{}");
      // clean up
      sessionStorage.removeItem("routerState");
    });
  }
};
