type Locale = {
  code: string;
  locale: string;
};

export const localeCodes: Readonly<Locale[]> = [
  { code: "en-AU", locale: "English (Australia)" },
  { code: "en-CA", locale: "English (Canada)" },
  { code: "en-DO", locale: "English (Dominican Republic)" },
  { code: "en-IE", locale: "English (Europe)" },
  { code: "en-IN", locale: "English (India)" },
  { code: "en-GB", locale: "English (GB)" },
  { code: "en-US", locale: "English (US)" },
  { code: "zh-MO", locale: "Chinese (Macao)" },
  { code: "zh-CN", locale: "Chinese (Simplified)" },
  { code: "zh-HK", locale: "Chinese (Traditional - HK)" },
  { code: "zh-TW", locale: "Chinese (Traditional - Taiwan)" },
  { code: "da-DK", locale: "Danish" },
  { code: "nl-NL", locale: "Dutch" },
  { code: "fi-FI", locale: "Finland" },
  { code: "fr-FR", locale: "French" },
  { code: "fr-CA", locale: "French (Canada)" },
  { code: "de-DE", locale: "German" },
  { code: "id-ID", locale: "Indonesian - Bahasa Indonesia" },
  { code: "it-IT", locale: "Italian" },
  { code: "ja-JP", locale: "Japanese" },
  { code: "ko-KR", locale: "Korean" },
  { code: "ms-MY", locale: "Malay - Bahasa Malaysia" },
  { code: "pt-PT", locale: "Portuguese" },
  { code: "ru-KZ", locale: "Russian (Kazakhstan)" },
  { code: "sv-SE", locale: "Sweden" },
  { code: "th-TH", locale: "Thai" },
  { code: "vi-VN", locale: "Vietnamese" },
  { code: "bg-BG", locale: "Bulgarian" },
  { code: "hr-HR", locale: "Croatian" },
  { code: "cs-CZ", locale: "Czech" },
  { code: "es-ES", locale: "Spanish" },
  { code: "es-DO", locale: "Spanish (Dominican Republic)" },
  { code: "es-MX", locale: "Spanish (Mexico)" },
  { code: "es-US", locale: "Spanish (US)" },
  { code: "et-EE", locale: "Estonian" },
  { code: "el-GR", locale: "Greek" },
  { code: "hu-HU", locale: "Hungarian" },
  { code: "lv-LV", locale: "Latvian" },
  { code: "lt-LT", locale: "Lithuanian" },
  { code: "pl-PL", locale: "Polish" },
  { code: "pt-BR", locale: "Portuguese (Brazil)" },
  { code: "ro-RO", locale: "Romanian" },
  { code: "ru-UA", locale: "Russian (Ukraine)" },
  { code: "sk-SK", locale: "Slovak" },
  { code: "sl-SI", locale: "Slovenian" },
  { code: "tr-TR", locale: "Turkish" },
  { code: "uk-UA", locale: "Ukrainian" },
  { code: "nb-NO", locale: "Norwegian" },
  { code: "bi-HT", locale: "Xxxx Xxxx" },
  { code: "kk-KZ", locale: "Kazakh (KZ)" },
  { code: "mn-MN", locale: "Mongolian" },
  { code: "de-AT", locale: "German (Austria)" },
  { code: "de-CH", locale: "German (Switzerland)" },
  { code: "fr-CH", locale: "French (Switzerland)" },
];

/** Array of all language codes supported */
export const supportedLanguageCodes = localeCodes.map((locale) => locale.code);
