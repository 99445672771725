import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import storageSession from "redux-persist/lib/storage/session";

export type AboName = {
  name: string;
};

const { actions, reducer } = createSlice({
  name: "aboName",
  initialState: { name: "" },
  reducers: {
    setAboName: (state, action) => {
      return { name: action.payload };
    },
  },
});

const aboNamePersistConfig = {
  key: "aboName",
  storage: storageSession,
  stateReconciler: autoMergeLevel2,
};

export const { setAboName } = actions;

export default persistReducer<AboName>(aboNamePersistConfig, reducer);
