import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomersResponse, initialState, resetList } from "../model/customerList";
import { updatePeriod } from "../period";
import { setBoot } from "../boot";
import {
  resetFilter,
  updateFilterOptions,
  updateFilterOption,
  updateSearchKeyword,
  updateSelectedFilter,
  updateFilterMenuByPeriod,
} from "./filter";
import { resetSort, updateSortOption } from "./sort";

const { reducer, actions } = createSlice({
  name: "customers",
  initialState,
  reducers: {
    updateCustomerList: (state, { payload }: PayloadAction<{ requestId: string; data: CustomersResponse }>) => {
      const { requestId, data } = payload;

      if (state.requestId === requestId) {
        return {
          ...state,
          ...data,
          customerList: [...state.customerList, ...data.customerList],
          isSuccess: true,
          isError: false,
          isFetching: false,
        };
      }
      return state;
    },
    updateIsFetching: (state, { payload }: PayloadAction<{ requestId: string; isFetching: boolean }>) => ({
      ...state,
      ...payload,
      isError: false,
      isSuccess: false,
    }),
    updateIsError: (state, { payload }: PayloadAction<{ requestId: string; isError: boolean }>) => {
      const { requestId, isError } = payload;
      if (state.requestId === requestId) {
        return {
          ...state,
          requestId,
          isError,
          isSuccess: isError === false,
          isFetching: false,
        };
      }
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updatePeriod, () => resetList());
    builder.addCase(setBoot, () => resetList());
    builder.addCase(updateFilterMenuByPeriod, (state) => resetList(state.requestId));
    builder.addCase(resetFilter, () => resetList());
    builder.addCase(updateFilterOptions, () => resetList());
    builder.addCase(updateFilterOption, () => resetList());
    builder.addCase(updateSearchKeyword, () => resetList());
    builder.addCase(updateSortOption, () => resetList());
    builder.addCase(resetSort, () => resetList());
    builder.addCase(updateSelectedFilter, () => resetList());
  },
});

export const { updateCustomerList, updateIsError, updateIsFetching } = actions;

export default reducer;
