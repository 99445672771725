import { FOSTER_SPONSOR, PLATINUM_FOSTER_SPONSOR } from "../../../../../constants/sponsor-constants";
import { BasicSponsor } from "../../LeafSponsorship";
import LeafSponsorDetail from "../LeafSponsorDetail";

type Sponsor = { isPlatinum: boolean } & BasicSponsor;

type Props = {
  fosterSponsor: Sponsor;
};

const LeafFosterOrFosterAndPlatinumSponsor = (props: Props) => {
  const { fosterSponsor } = props;
  const { aboNo, affNo, isValid, isPlatinum } = fosterSponsor;
  const sponsorType = fosterSponsor.isPlatinum === true ? PLATINUM_FOSTER_SPONSOR : FOSTER_SPONSOR;

  return (
    <LeafSponsorDetail
      sponsor={{
        aboNo,
        affNo,
        isValid,
        isPlatinum,
      }}
      show={isValid}
      sponsorType={sponsorType}
    />
  );
};

export default LeafFosterOrFosterAndPlatinumSponsor;
