import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/leafRenewalDateInfo";
import LeafDateTime from "../../../LeafDateTime/LeafDateTime";
import "./LeafRenewalDateInfo.scss";

type Props = {
  date: string;
  privacyFlag?: boolean;
  show?: boolean;
};

const LeafRenewalDateInfo = (props: Props) => {
  const { date, privacyFlag, show } = props;
  const { t } = useTranslation(["leafRenewalDateInfo"]);

  const renderInfo = date ? (
    <LeafDateTime date={date} options={{ month: "long", day: "numeric", year: "numeric" }} ignoreTimeZone={true} />
  ) : (
    t("NoRenewalDate", defaultContent["NoRenewalDate"])
  );

  const privacyView = privacyFlag === true ? "-" : renderInfo;

  if (show === true) {
    return (
      <div className="leaf-renewal-date">
        <div className="leaf-renewal-date__container">
          <div className="leaf-renewal-date__content-title">{t("RenewalDate", defaultContent["RenewalDate"])}</div>
          <div className="leaf-renewal-date__content-data">{privacyView}</div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default LeafRenewalDateInfo;
