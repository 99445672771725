import KeyMetricsTile from "../../../../common/components/KeyMetricsTile/KeyMetricsTile";
import { useAppSelector } from "../../../../store";
import { FetchKeyMetricsData } from "./MonthlyKeyMetricsDataLayer";

export const MonthlyKeyMetricsTile = () => {
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { savedKpiPreferencesData } = useAppSelector((state) => state.savedKpiPreferencesData);
  const { monthlyDashboard } = useAppSelector((state) => state.boot.configuration);

  const keyMetricsAPIData = FetchKeyMetricsData({
    period: selectedPeriod,
    availableKpis: monthlyDashboard.keyMetrics.availableKpis,
    savedKpiPreferencesData,
  });

  return (
    <KeyMetricsTile
      keyMetricsAPIData={keyMetricsAPIData}
      tealiumDashboardName="monthly"
      keyMetricsConfig={monthlyDashboard.keyMetrics}
    />
  );
};
