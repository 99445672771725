import { useTranslation } from "react-i18next";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import { BannerTile } from "../../../../common/components/BannerTile/BannerTile";
import defaultContent from "../../../../content/monthlyPGG";
import { getBannerTitleKey, getBannerSubtitleKey } from "../../../../common/util/groupGrowthUtils";
import { MonthlyPGGIncomeData } from "../../../../common/interfaces/groupGrowthIncentives";
import { useAppSelector } from "../../../../store";
import { getPerformanceYearShort, getFullMonth } from "../../../../common/util/period";
import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";
import { storedLocale } from "../../../impersonation/util";
import "./MonthlyBannerTile.scss";

type Props = {
  incomeData: MonthlyPGGIncomeData;
  isLoading: boolean;
};

export default function MonthlyBannerTile({ incomeData, isLoading }: Props) {
  const { t } = useTranslation(["monthlyPGG"]);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const locale = storedLocale();

  const month = getFullMonth(selectedPeriod, locale);

  const titleKey = getBannerTitleKey(incomeData.Status);
  const descriptionKey = getBannerSubtitleKey(incomeData.Status);

  const bannerStyle = () => {
    return incomeData.Status === QUALIFICATION_STATUS.HELD || incomeData.Status === QUALIFICATION_STATUS.DENIED
      ? "MonthlyPGGBannerTile__not-qualified-banners-only"
      : "";
  };

  return (
    <div className={`MonthlyPGGBannerTile ${bannerStyle()}`}>
      <BannerTile
        isLoading={isLoading}
        title={t(`bannerTile.${titleKey}`, defaultContent.bannerTile[titleKey], { month })}
        description={t(`bannerTile.${descriptionKey}`, defaultContent.bannerTile[descriptionKey], {
          performanceBonus: incomeData.PerformanceBonusLevelPercent,
        })}
        bonusAmountLabel={t("bannerTile.potentialEarnings", defaultContent.bannerTile.potentialEarnings, {
          month: month,
        })}
        shouldDisplayAmount={![QUALIFICATION_STATUS.HELD, QUALIFICATION_STATUS.DENIED].includes(incomeData.Status)}
        bonusAmount={incomeData.PotentialPYEarnings}
        incentiveCapLabel={
          incomeData.IncentiveCap
            ? t("bannerTile.incentiveCap", defaultContent.bannerTile.incentiveCap, {
                bonusCap: incomeData.IncentiveCap,
              })
            : ""
        }
      />
    </div>
  );
}
