import { track } from "@amwaycommon/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../../enums/tealium-enums";

const cardActionMapper: { [key: string]: string } = {
  call_primary: "call_primary_applicant",
  message_primary: "message_primary_applicant",
  email_primary: "email_primary_applicant",
  address_primary: "copy_applicant_address",
  call_applicant: "call_applicant",
  message_applicant: "message_applicant",
  email_applicant: "email_applicant",
  address_applicant: "copy_applicant_address",
};

export const viewAllApplicantsClickTrack = () => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.GLOBAL_CUSTOMER_PROFILE,
    "",
    "view_all_applicants",
  );
};

export const toggleApplicantDetailClickTrack = (isToggle: boolean) => {
  const clickAction = isToggle === false ? "collapse_applicant_details" : "expand_applicant_details";
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.GLOBAL_CUSTOMER_PROFILE,
    "",
    clickAction,
  );
};

export const cardActionClickTrack = (event?: string, isPrimary?: boolean) => {
  const clickAction = `${event}_${isPrimary === true ? "primary" : "applicant"}`;

  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.GLOBAL_CUSTOMER_PROFILE,
    "",
    cardActionMapper[clickAction],
  );
};

export const profileApplicantCardErrorTrack = () => {
  track(TEALIUM.U_TAG_METHOD.VIEW, "", "", TEALIUM.CATEGORY.GLOBAL_CML, "profile_error", "");
};
