import { track } from "@amwaycommon/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../enums/tealium-enums";

const sectionMapper: { [key: string]: string } = {
  performance_bonus_percent_leg_with_vcs_pv: TEALIUM.SECTION.PERFORMANCE_BONUS_PERCENT_LEG_WITH_VCS_PV,
  performance_bonus_percent_legs: TEALIUM.SECTION.PERFORMANCE_BONUS_PERCENT_LEGS,
};

const clickCategoryMapper: { [key: string]: string } = {
  bfi: TEALIUM.CATEGORY.BRONZE_FOUNDATION_INCENTIVE,
  bbi: TEALIUM.CATEGORY.BRONZE_BUILDER_INCENTIVE,
};

export const viewDownlineInfoButtonOnClick = (category: string, section: string) => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    sectionMapper[section],
    clickCategoryMapper[category],
    "",
    "view_downline_info",
  );
};
