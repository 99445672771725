import "./IconMultipleUsers.scss";
import { ReactComponent as MultipleUsersIcon } from "./svg/iconMultipleUsers.svg";
import { ReactComponent as MultipleUsersIconFilled } from "./svg/iconMultipleUsersFilled.svg";

interface Props {
  color?: "orange" | "gray" | "green";
  style?: "outline" | "filled";
}

export const IconMultipleUsers = ({ color = "gray", style = "outline" }: Props) => {
  return (
    <span className={`icon-multiple-users--${color}`}>
      {style === "outline" ? <MultipleUsersIcon /> : <MultipleUsersIconFilled />}
    </span>
  );
};
