import { useEffect, useState } from "react";
import { padAff, authorization, normalizeLocale } from "./impersonation-util";

export const Impersonate = () => {
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    // moved all the constants inside use effect to avoid all the deps to the use effect dependencies
    const urlParams = new URLSearchParams(window.location.search);
    const abo = urlParams.get("abo");
    const country = urlParams.get("aff");
    const locale = normalizeLocale(urlParams.get("locale"));
    const partyId = urlParams.get("partyId");
    const entryPage = urlParams.get("entryPage");
    const targetAbo = urlParams.get("targetAbo");
    const isJwtAuth = urlParams.get("jwtAuth") === "true";
    if (isNaN(Number(abo)) || isNaN(Number(country)) || !isNaN(Number(locale))) {
      setErrorText("Please pass correct params abo, aff as number & locale as string");
    } else if (abo && country && locale) {
      // calling authorization function to decide to call jwt or mashery based on the params being passed

      if (isJwtAuth) {
        if (partyId) {
          authorization({
            abo,
            country: padAff(country),
            locale,
            partyId,
            sameWindow: true,
            entryPage: entryPage || "default",
            targetAbo: targetAbo || "",
          });
        } else {
          setErrorText("Please pass parameter partyId if jwtAuth is true");
        }
      } else {
        authorization({
          abo,
          country: padAff(country),
          locale,
          sameWindow: true,
          entryPage: entryPage || "default",
          targetAbo: targetAbo || "",
        });
      }
    } else {
      setErrorText("Please pass all required params abo, aff, locale");
    }
  }, []);

  return <h1 style={{ display: "flex", justifyContent: "center", color: "#002f5f" }}>{errorText}</h1>;
};
