import { useTranslation } from "react-i18next";
import { useId } from "react";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import defaultContent from "../../../../content/leafAboPhoneNumber";
import "./LeafABOPhoneNumber.scss";
import { IconPhone } from "../../../icons/IconPhone/IconPhone";
import { IconMessage } from "../../../icons/IconMessage/IconMessage";
import { IconAddress } from "../../../icons/IconAddress/IconAddress";
import { useAppSelector } from "../../../../store";
import LeafTooltip from "../../LeafTooltip/LeafTooltip";

type Props = {
  primaryNumber?: string;
  privacyFlag: boolean;
  applicantTrackOnclick: Function;
  id?: string;
};

const LeafABOPhoneNumber = (props: Props) => {
  const { t } = useTranslation(["leafAboPhoneNumber"]);

  const {
    showCallIcon = false,
    showCopyIcon = false,
    showMessageIcon = false,
  } = useAppSelector((store) => store?.boot?.configuration?.profile);

  const { primaryNumber = "", privacyFlag, applicantTrackOnclick } = props;
  const showValidPhoneNumber = primaryNumber && primaryNumber !== "--" && privacyFlag !== true;

  const phoneNumberParse = parsePhoneNumberFromString(primaryNumber);
  const displayNumber = showValidPhoneNumber && phoneNumberParse ? phoneNumberParse.formatInternational() : "-";
  const uniqueId = useId();

  const _applicantTrackOnclick = (eventType: string) => {
    applicantTrackOnclick(eventType);
  };

  const copyTextToClipboard = () => {
    navigator.clipboard.writeText(displayNumber || "");
    _applicantTrackOnclick("mobile");
  };

  return (
    <div className="leaf-abo-phone-number">
      <div className="leaf-abo-phone-number__content">
        <div className="leaf-abo-phone-number__label">{t("phoneNumber", defaultContent["phoneNumber"])}</div>
        <div className="leaf-abo-phone-number__primary">{displayNumber}</div>
      </div>
      {showValidPhoneNumber && (
        <div className="leaf-abo-phone-number__icon">
          {showMessageIcon && (
            <a
              role="button"
              aria-label={`${t("sms", defaultContent["sms"])}`}
              href={`sms:${displayNumber}`}
              target="_parent"
              className="leaf-abo-phone-number__icon--message"
              onClick={() => _applicantTrackOnclick("message")}
            >
              <span aria-hidden="true">
                <IconMessage />
              </span>
            </a>
          )}
          {showCallIcon && (
            <a
              role="button"
              aria-label={`${t("phoneCall", defaultContent["phoneCall"])}`}
              className="leaf-abo-phone-number__icon--phone"
              href={`tel:${displayNumber}`}
              target="_parent"
              onClick={() => _applicantTrackOnclick("call")}
            >
              <span aria-hidden="true">
                <IconPhone />
              </span>
            </a>
          )}
          {showCopyIcon && (
            <button
              aria-label={`${t("copyPhone", defaultContent["copyPhone"])}`}
              className="leaf-abo-phone-number__icon--copy"
              onClick={copyTextToClipboard}
            >
              <span aria-hidden="true">
                <LeafTooltip
                  id={uniqueId}
                  icon={<IconAddress />}
                  openOnClick={true}
                  content={`${t("tooltipText", defaultContent["tooltipText"])}`}
                />
              </span>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default LeafABOPhoneNumber;
