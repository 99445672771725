import { useEffect, useState } from "react";
import { QUALIFICATION_STATUS } from "../../common/enums/incentive-enums";
import { SsiOverviewResponse } from "../../common/interfaces/strongStartIncentive";
import { lambdaErrorCheck } from "../../common/util/errorHandling";
import { useAppSelector } from "../../store";
import { storedLocale } from "../impersonation/util";
import { useGetSsiOverviewQuery } from "../../services/ssiAPI";

export const defaultSsiOverviewData = (): SsiOverviewResponse => {
  return {
    IncomeData: {
      CurrentEarnings: "",
      Status: QUALIFICATION_STATUS.NOT_QUALIFIED,
      RemainingTimes: 0,
      FinalIncentiveEarnDate: "",
      NextPayment: {
        MonthsNeed: 0,
        BonusAmount: "",
      },
      AdditionalPayments: [
        {
          RequiredMonths: 0,
          BonusAmount: "",
        },
      ],
    },
    Requirements: {
      VolumeRequirements: [],
      PercentRequirements: [],
      TotalRequirements: 0,
      RequirementsMet: 0,
      RequirementMetStatuses: [],
    },
    EligibilityWindow: {
      EntryPeriod: "",
      StartPeriod: "",
      EndPeriod: "",
      CurrentYear: [],
      NextYear: [],
    },
    DisplayRequirementTiles: false,
    ShowDateRangeError: false,
    CallResults: [],
    Error: [],
  };
};

export const FetchSsiOverviewData = (period: string) => {
  const { user } = useAppSelector((state) => state.boot);
  const shellConfig = useAppSelector((state) => state.boot.shellConfig);
  const {
    data: payloadData,
    isFetching,
    isError,
    refetch,
  } = useGetSsiOverviewQuery({
    abo: user.abo,
    aff: user.aff,
    period: period,
    isoCountryCode: user.isoCountryCode,
    isoCurrencyCode: shellConfig.isoCurrencyCode,
    locale: storedLocale(),
  });

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [ssiOverviewData, setSsiOverviewData] = useState<SsiOverviewResponse>(defaultSsiOverviewData());

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (!isFetching && !isError && payloadData) {
      setSsiOverviewData(payloadData);
      const foundLambdaError = lambdaErrorCheck(payloadData.Error);
      setErrorStatus(foundLambdaError);
    }
  }, [isFetching, period, isError, payloadData]);

  return { isLoading, ssiOverviewData, errorStatus, refetch };
};
