import "./IconAmway.scss";

type Props = {
  useBlackIcon?: boolean;
};

export const IconAmway = ({ useBlackIcon = false }: Props) => {
  if (useBlackIcon) {
    return <span className="icon-amway--black"></span>;
  }
  return <span className="icon-amway"></span>;
};
