import { ErrorTile } from "../../../../common/components/ErrorTile/ErrorTile";
import { useAppSelector } from "../../../../store";
import { FetchAnnualIncentiveTracking } from "./AnnualIncentiveTrackingDataLayer";
import { PggTrackingTile } from "./PggTrackingTile/PggTrackingTile";
import { FggTrackingTile } from "./FggTrackingTile/FggTrackingTile";
import "./AnnualIncentiveTracking.scss";
import { FaaTrackingTile } from "./FaaTrackingTile/FaaTrackingTile";

export const AnnualIncentiveTrackingTile = () => {
  const { selectedPeriod } = useAppSelector((store) => store.period);

  const { isLoading, annualIncentivesTrackingData, errorStatus, refetch } = FetchAnnualIncentiveTracking({
    period: selectedPeriod,
  });

  const { PggTile, FggTile, FaaTile } = annualIncentivesTrackingData;

  if (errorStatus) {
    return <ErrorTile clickFunction={refetch} errorStatus={errorStatus} isLoading={isLoading} />;
  }

  return (
    <section className="annualIncentiveTrackingTile__container">
      <div className="annualIncentiveTrackingTile__groupGrowthTiles">
        <div className="annualIncentiveTrackingTile__tile">
          <PggTrackingTile tileData={PggTile} isLoading={isLoading} />
        </div>
        <div className="annualIncentiveTrackingTile__tile">
          <FggTrackingTile tileData={FggTile} isLoading={isLoading} />
        </div>
      </div>
      {!FaaTile.DisableTile && (
        <div className="annualIncentiveTrackingTile__tile--fullwidth">
          <FaaTrackingTile tileData={FaaTile} isLoading={isLoading} />
        </div>
      )}
    </section>
  );
};
