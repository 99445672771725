import { NavLink } from "react-router-dom";
import "./LeafTabNavLink.scss";

type Tab = {
  id: string;
  to: string;
  text: string;
};

type Props = {
  tabs: Tab[];
  navLinkOnClick: Function;
};

const LeafTabNavLink = ({ tabs, navLinkOnClick }: Props) => {
  const TabNavLink = ({ to, text }: Pick<Tab, "to" | "text">) => {
    const _navLinkOnClick = () => {
      navLinkOnClick(to, text);
    };

    return (
      <NavLink
        to={to}
        className={({ isActive }) => `nav__link${isActive === true ? "--active" : ""}`}
        onClick={_navLinkOnClick}
      >
        {text}
      </NavLink>
    );
  };

  return (
    <ul className="nav">
      {tabs.map(({ id, to, text }) => (
        <li className="nav__list" key={id}>
          <TabNavLink to={to} text={text} />
        </li>
      ))}
    </ul>
  );
};

export default LeafTabNavLink;
