import { track } from "@amwaycommon/acc-mixins/src/modules/tealium/index";
import TEALIUM from "../../../../enums/tealium-enums";

export const businessKpiClickTrack = (pv: string, period: string) => {
  const periodYear = period.slice(0, 4);
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.ABO_PROFILE,
    "",
    `${pv}_PY${periodYear}`,
  );
};
