import { AboTileData, Requirement } from "../../../common/interfaces/bronzeGroupTracking";

export enum SORT_OPTIONS {
  HIGH_TO_LOW = "highLow",
  LOW_TO_HIGH = "lowHigh",
  A_TO_Z = "aZ",
  Z_TO_A = "Za",
  DESC_GRADUATION_DATE = "descGraduationDate",
}

export const defaultTrackingSortOptions = [
  SORT_OPTIONS.HIGH_TO_LOW,
  SORT_OPTIONS.LOW_TO_HIGH,
  SORT_OPTIONS.Z_TO_A,
  SORT_OPTIONS.A_TO_Z,
];

export const defaultGraduatedSortOptions = [SORT_OPTIONS.DESC_GRADUATION_DATE];

export interface SortOption {
  id: string;
  text: string;
  selected: boolean;
}

export interface SortState {
  sortBy: SortOption[];
  selectedSort: SortOption;
}

const sortByNameAsc = (filteredData: AboTileData[]) => {
  const sortedData = [...filteredData];
  return sortedData.sort((a, b) => a.aboName.localeCompare(b.aboName));
};

const sortByNameDesc = (filteredData: AboTileData[]) => {
  const sortedData = [...filteredData];
  return sortedData.sort((a, b) => b.aboName.localeCompare(a.aboName));
};

const sortByMetReqsAsc = (filteredData: AboTileData[]) => {
  const sortedData = [...filteredData];
  return sortedData.sort((a, b) => getMetReqs(a.requirements) - getMetReqs(b.requirements));
};

const sortByMetReqsDesc = (filteredData: AboTileData[]) => {
  const sortedData = [...filteredData];
  return sortedData.sort((a, b) => getMetReqs(b.requirements) - getMetReqs(a.requirements));
};

const getMetReqs = (reqs: Requirement[]): number => {
  return reqs.filter((req) => req.met).length;
};

const sortByDescGraduationDate = (filteredData: AboTileData[]) => {
  const sortedData = [...filteredData];
  return sortedData.sort((a, b) => {
    return Number(b.graduationPeriod) - Number(a.graduationPeriod);
  });
};

export const getSortedData = (selectedSort: SortOption, tileData: AboTileData[]) => {
  const { id } = selectedSort;

  switch (id) {
    case SORT_OPTIONS.LOW_TO_HIGH:
      return sortByMetReqsAsc(tileData);
    case SORT_OPTIONS.HIGH_TO_LOW:
      return sortByMetReqsDesc(tileData);
    case SORT_OPTIONS.A_TO_Z:
      return sortByNameAsc(tileData);
    case SORT_OPTIONS.Z_TO_A:
      return sortByNameDesc(tileData);
    case SORT_OPTIONS.DESC_GRADUATION_DATE:
      return sortByDescGraduationDate(tileData);
    default:
      return tileData;
  }
};

/**
 * Returns the sortOption selected based on given data but returns the default
 * High - Low sort option if no sort option found.
 * @param sortBy
 * @returns
 */
export const getCurrentlySelectedSort = (sortBy: SortOption[]): SortOption => {
  const selectedSort = sortBy.find((d) => d.selected);
  return selectedSort ? selectedSort : { id: SORT_OPTIONS.LOW_TO_HIGH, text: "High - Low", selected: true };
};

/**
 * Returns default content strings for each row. This should be overridden in the TrackingSort component
 * with the translated content.
 */
const returnSortContent = (id: string) => {
  switch (id) {
    case SORT_OPTIONS.LOW_TO_HIGH:
      return "Low - High";
    case SORT_OPTIONS.HIGH_TO_LOW:
      return "High - Low";
    case SORT_OPTIONS.A_TO_Z:
      return "A - Z";
    case SORT_OPTIONS.Z_TO_A:
      return "Z - A";
    default:
      return "High - Low";
  }
};

/**
 * Creates the inital SortOption[] array. Returns the sort options from the config or the default enabled sort options.
 *
 * @param enabledSortOptions SortOption[]
 * @param sortByConfig string[]
 * @returns SortOption[]
 */
export const createInitalSortOptions = (enabledSortOptions: SortOption[], sortByConfig: string[]): SortOption[] => {
  const initialSortOptions: SortOption[] = [];
  sortByConfig.forEach((d) => {
    initialSortOptions.push({
      id: d,
      selected: sortByConfig[0] === d,
      text: returnSortContent(d),
    });
  });

  return initialSortOptions ? initialSortOptions : enabledSortOptions;
};

export const enableFirstSortOption = (sortBy: SortOption[]): SortOption[] => {
  return sortBy.map((sortOption, index) => ({
    ...sortOption,
    selected: index === 0, // set first option to selected
  }));
};

/**
 * Sort options can be passed in from the config if needed to override their defaults but should only
 * be done for special cases.
 *
 * @param sortBy SortOption[]
 * @param sortByConfig string[]
 * @param identifier "tracking" | "graduated"
 */
export const getEnabledSortOptions = (
  sortBy: SortOption[],
  sortByConfig: string[],
  identifier: "tracking" | "graduated",
): SortState => {
  if (!sortByConfig || sortByConfig.length < 1) {
    // sortBy config option not found. Setting sortBy option to default
    if (identifier === "tracking") sortByConfig = defaultTrackingSortOptions;
    if (identifier === "graduated") sortByConfig = defaultGraduatedSortOptions;
  }
  const enabledSortOptions = sortBy.filter(({ id }) => sortByConfig.includes(id));
  const initialSortOptions = createInitalSortOptions(enableFirstSortOption(enabledSortOptions), sortByConfig);

  return {
    sortBy: initialSortOptions,
    selectedSort: initialSortOptions[0],
  };
};
